import { ApolloProvider } from '@apollo/client';
import { UiContextProvider, useUiContext } from '@pi/ui';
import React, { useEffect } from 'react';
import { ImageProcessingContextProvider } from 'src/components/ImageProcessingContext';

import { AppContextProvider, useAppContext } from './components/AppContext';
import AppRouter from './components/AppRouter';
import apolloClient from './startup/apollo';
import LS from './utils/LS';
import setJwt from './utils/setJwt';

const AppInner: React.FC = () => {
    const { darkMode } = useAppContext();
    const { update: updateUiContext } = useUiContext();

    useEffect(() => updateUiContext({ darkMode }), [darkMode, updateUiContext]);
    setJwt(LS.get('jwt') || '');
    return (
        <div className={darkMode ? 'bp4-dark' : ''}>
            <AppRouter />
        </div>
    );
};

const App: React.FC = () => (
    <ApolloProvider client={apolloClient}>
        <AppContextProvider>
            <UiContextProvider>
                <ImageProcessingContextProvider>
                    <AppInner />
                </ImageProcessingContextProvider>
            </UiContextProvider>
        </AppContextProvider>
    </ApolloProvider>
);
export default App;
