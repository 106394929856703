const imported_stylus_components = require('.cache/react-style-loader/src/components/Tree/TreeNode.styl');
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import type { NodeState } from './getExpressionCompiler';

export interface TreeNodeProps extends Pick<NodeState, 'indents' | 'background' | 'color'> {
    label?: React.ReactNode;
    blockContent?: boolean;
    disabled?: boolean;
}

export default class TreeNode extends React.PureComponent<TreeNodeProps> {
    static propTypes = {
        indents: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                color: PropTypes.string,
            }).isRequired,
        ).isRequired,
        label: PropTypes.node,
        background: PropTypes.string,
        color: PropTypes.string,
        blockContent: PropTypes.bool,
        preLabel: PropTypes.node,
        disabled: PropTypes.bool,
    }

    render() {
        const {
            indents,
            label,
            children,
            background,
            color,
            blockContent,
            preLabel,
            disabled,
            ...rest
        } = this.props as any;

        return <Node {...rest} data-disabled={disabled}>
            {preLabel &&
                <NodeInfoContainer style={{ paddingLeft: _.size(indents) * 2 + 'rem' }}>
                    {_.map(indents, ({ type, color }, index: number) =>
                        getNodeIndent(
                            index === indents.length - 1 ? 'straight' : type,
                            color,
                            index
                        ))
                    }
                    {preLabel}
                </NodeInfoContainer>
            }
            <NodeInfoContainer style={{ paddingLeft: _.size(indents) * 2 + 'rem' }}>
                {_.map(indents, ({ type, color }, index) => getNodeIndent(type, color, index))}
                <NodeInfo data-content={blockContent ? 'column' : 'row'}>
                    {label &&
                        <NodeLabel
                            style={{
                                background, color,
                                border: `1px solid ${background}`,
                            }}
                        >{label}</NodeLabel>
                    }
                    {children && <NodeContent>{children}</NodeContent>}
                </NodeInfo>
            </NodeInfoContainer>
        </Node>;
    }
}

const getNodeIndent = _.memoize(
    (type, color, index) =>
        <NodeIndent
            key={index}
            data-type={type}
            data-indent={index + 1}
            style={{ color }}
        />,
    (type, color, index) => `${type}-${color}-${index}`,
);

const Node = imported_stylus_components.Node;
const NodeInfo = imported_stylus_components.NodeInfo;
const NodeLabel = imported_stylus_components.NodeLabel;
const NodeContent = imported_stylus_components.NodeContent;
const NodeIndent = imported_stylus_components.NodeIndent;
const NodeInfoContainer = imported_stylus_components.NodeInfoContainer;