import { FormGroup, type FormGroupProps } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { type Field, useOrGetField } from './Form';
import { Text } from './Text';

export interface FormGroupFieldProps extends FormGroupProps {
    field: string | Field<any>;
}

export const FormGroupField: React.FC<FormGroupFieldProps> = observer(({ field, ...props }) => {
    const f = useOrGetField(field);

    if (!f.meta.valid) {
        if (props.label) props.label = <Text intent='danger'>* {props.label}</Text>;
        if (props.labelInfo) props.labelInfo = <Text intent='danger'>{props.labelInfo}</Text>;
    }

    return (
        <FormGroup
            {...props}
            intent={f.meta.valid ? props.intent : 'danger'}
            helperText={f.meta.error ?? props.helperText}
        />
    );
});
