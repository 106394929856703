import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, Fields, type FieldT, Form, type FormContextT, FormEvent } from '@pi/react-form';
import { AjvError, LocalStorage } from '@pi/ui';
import memoizeOne from 'memoize-one';
import { type Expression, Transformation } from '@pi/transformer-compiler';
import { Button, Tooltip, Callout } from '@blueprintjs/core';
import getTransformationOutput from 'src/utils/getTransformationOutput';
import getDefaultEditorProps from 'src/utils/getDefaultEditorProps';

import { type TransformerBuilderProps, TransformerBuilderField } from './TransformerBuilder';
import InputField from './InputField';
import { Container, Column, ScrollContent, ColumnTitle, ColumnTitleName } from './Layout';
import ExecuteError from './ExecuteError';
import TransformerContext, { TransformerContextProvider } from './TransformerContext';
import { JsonEditorField, type JSONRendererFieldProps } from './LegacyReactForm';

export interface TransformerEditorProps {
    data: { [key: string]: any };
    expression: Expression;
    onChange?: (value: Expression) => void;
    canSaveHandler?: (canSave: boolean) => void;
    getSuggestions?: TransformerBuilderProps['getSuggestions'];
    includeNodes?: TransformerBuilderProps['includeNodes'];
    argumentChangeHandlers?: TransformerBuilderProps['argumentChangeHandlers'];
    /**
     * Namespace where to save the relevant props to localStorage.
     * For example saveToLocalStorage='my-key' will save the editor configuraiton in localStorage['my-key']
     * Can be granular customized by passing a { namespace, keys } object
     */
    saveToLocalStorage?:
        | string
        | {
              /**
               * Which key in localStorage to save the data into.
               */
              namespace: string;
              /**
               * Either an arrays of keys to save or a standard preset
               * - 'all'      Save all keys. High localStorage footprint
               * - 'config'   Save only the editor config keys. Does not save data and expression
               *
               * @defaultValue 'config'
               */
              keys?: keyof typeof localStoragePresets | TE_LocalStorageKeys[];
          };
    inputConfig?: {
        visible?: boolean;
        edit?: boolean;
        sortKeys?: boolean;
        filter?: string | null;
        collapseDepth?: number;
        includeHidden?: boolean;
        fontSize?: number;
        coverage?: boolean;
    };
    builderConfig?: {
        defaultCollapseDepth?: number;
        debug?: boolean;
    };
    contextExecute?: typeof Transformation.contextExecute;
    checkCoverage?: typeof Transformation.checkCoverage;
    transformerTitle?: string;
    outputComponent?: React.ComponentType;
    inputComponent?: React.ComponentType;
    outputTitle?: React.ReactNode;
}

type TE_LocalStorageKeys = 'data' | 'expression' | 'inputConfig' | 'builderConfig';

type TE_GlobalOptions = Partial<TransformerEditorProps>;

const localStoragePresets: { [key: string]: TE_LocalStorageKeys[] } = {
    all: ['data', 'expression', 'inputConfig', 'builderConfig'],
    config: ['inputConfig', 'builderConfig'],
};

export class TransformerEditor extends React.PureComponent<TransformerEditorProps> {
    static globalOptions: TE_GlobalOptions = {
        contextExecute: Transformation.contextExecute,
        checkCoverage: Transformation.checkCoverage,
    };

    static setGlobalOptions = (options: TE_GlobalOptions): void => {
        this.globalOptions = {
            ...TransformerEditor.globalOptions,
            ...options,
        };
    };

    render(): JSX.Element {
        return (
            <TransformerContextProvider>
                <TransformerEditorBase {...this.props} />
            </TransformerContextProvider>
        );
    }
}

class TransformerEditorBase extends React.PureComponent<TransformerEditorProps> {
    static contextType = TransformerContext;
    context!: React.ContextType<typeof TransformerContext>;

    static propTypes = {
        data: PropTypes.any.isRequired,
        expression: PropTypes.object.isRequired,
        inputConfig: PropTypes.object,
        saveToLocalStorage: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.shape({
                namespace: PropTypes.string.isRequired,
                keys: PropTypes.any,
            }).isRequired,
        ]),
        transformerTitle: PropTypes.string,
        outputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        inputComponent: PropTypes.func,
    };

    static defaultProps = {
        saveToLocalStorage: null,
        inputConfig: {
            visible: true,
            edit: false,
            sortKeys: false,
            filter: '',
            collapseDepth: 0,
            fontSize: 0.8,
            includeHidden: true,
            coverage: true,
        },
        builderConfig: {
            defaultCollapseDepth: 10,
            debug: false,
        },
    };

    lastProps: any[] = [];
    lastInitialValues: any;
    form?: FormContextT;
    initialized?: boolean;

    /**
     * The point of this is to change the lastInitialValues ONLY IF the expression or data has changed
     */
    setInitialValues = (
        expression: Expression,
        data: any,
        inputConfig: TransformerEditorProps['inputConfig'],
        builderConfig: TransformerEditorProps['builderConfig'],
    ) => {
        const lsConfig = this.getLocalStorageConfig();

        if (!this.initialized && lsConfig) {
            const cache = this.LS.getAll();
            if (lsConfig.keys.includes('data')) data = cache.data || data;
            if (lsConfig.keys.includes('expression')) expression = cache.expression || expression;
            if (lsConfig.keys.includes('inputConfig'))
                inputConfig = cache.inputConfig || inputConfig;
            if (lsConfig.keys.includes('builderConfig'))
                builderConfig = cache.builderConfig || builderConfig;
        }

        if (
            this.lastInitialValues &&
            this.lastProps.includes(expression) &&
            this.lastProps.includes(data) &&
            this.lastProps.includes(inputConfig) &&
            this.lastProps.includes(builderConfig)
        )
            return;

        this.storeLastProps(expression, data, inputConfig, builderConfig);

        this.lastInitialValues = {
            expression,
            data,
            inputConfig: {
                ...(this.constructor as any).defaultProps.inputConfig,
                ...inputConfig,
                ...(this.lastProps.includes(inputConfig)
                    ? this.lastInitialValues?.inputConfig
                    : null),
            },
            builderConfig: {
                ...(this.constructor as any).defaultProps.builderConfig,
                ...builderConfig,
                ...(this.lastProps.includes(builderConfig)
                    ? this.lastInitialValues?.builderConfig
                    : null),
            },
        };

        this.initialized = false;
    };

    storeLastProps(
        expression: Expression,
        data: any,
        inputConfig: TransformerEditorProps['inputConfig'],
        builderConfig: TransformerEditorProps['builderConfig'],
    ) {
        this.lastProps = [
            this.props.expression,
            this.props.data,
            this.props.inputConfig,
            this.props.builderConfig,
            expression,
            data,
            inputConfig,
            builderConfig,
        ];
    }

    changeId = 0;

    triggerInternalChange = memoizeOne(
        _.debounce(
            async (
                input: any,
                expression: Expression,
                includeHidden: boolean,
                coverage: boolean,
                debug: boolean,
            ) => {
                const options = {
                    input,
                    expression,
                    includeHidden,
                    coverage,
                    options: {
                        debug,
                    },
                };
                const output = await getTransformationOutput({
                    ...(TransformerEditor.globalOptions as Required<TE_GlobalOptions>),
                    ...options,
                });

                this.context.update(output.execute?.suggestions);
                this.form?.changeField('output', output);
                this.initialized = true;
            },
            20,
        ),
    );

    get LS() {
        const lsConfig = this.getLocalStorageConfig();

        type LocalStorageData = {
            expression: Expression;
            data: any;
            inputConfig: TransformerEditorProps['inputConfig'];
            builderConfig: TransformerEditorProps['builderConfig'];
        };

        if (lsConfig) return new LocalStorage<LocalStorageData>({ namespace: lsConfig.namespace });

        // this should never happen, but just in case it does - and to cleanup TS branches - return a dummy LS client
        return new LocalStorage<LocalStorageData>({ namespace: '__noop__', store: {} });
    }

    getLocalStorageConfig(): null | { namespace: string; keys: TE_LocalStorageKeys[] } {
        const { saveToLocalStorage } = this.props;

        if (!saveToLocalStorage) return null;

        if (typeof saveToLocalStorage === 'string') {
            return { namespace: saveToLocalStorage, keys: [...localStoragePresets.config] };
        }

        const keys = Array.isArray(saveToLocalStorage.keys)
            ? saveToLocalStorage.keys
            : typeof saveToLocalStorage.keys === 'string'
            ? localStoragePresets[saveToLocalStorage.keys]
            : localStoragePresets.config;

        return { namespace: saveToLocalStorage.namespace, keys };
    }

    getError(formValue: any) {
        return formValue.output?.coverage?.error || formValue.output?.execute?.error;
    }

    handleSaveToLocalStorage = _.debounce((formValue: any) => {
        const lsConfig = this.getLocalStorageConfig();
        if (!lsConfig) return;
        const payload = _.pick(formValue, lsConfig.keys);
        this.LS.set(payload);
    }, 100);

    render() {
        const {
            data,
            expression,
            onChange,
            canSaveHandler,
            inputConfig,
            builderConfig,
            saveToLocalStorage,
            contextExecute,
            checkCoverage,
            argumentChangeHandlers,
            ...rest
        } = { ...TransformerEditor.globalOptions, ...this.props };

        if (!contextExecute || !checkCoverage) {
            return (
                <ExecuteError error='<TransformerEditor /> Must set contextExecute and checkCoverage props via globalOptions or component props' />
            );
        }

        this.setInitialValues(expression, data, inputConfig, builderConfig);

        return (
            <Form initialValues={this.lastInitialValues}>
                <FormEvent
                    on='init'
                    callback={async ({ getFormValue, context }) => {
                        // window.__form = context;
                        const formValue = getFormValue();
                        this.form = context;
                        void this.triggerInternalChange(
                            formValue.data,
                            formValue.expression,
                            formValue.inputConfig.includeHidden,
                            formValue.inputConfig.coverage,
                            formValue.builderConfig.debug,
                        );
                    }}
                />
                <FormEvent
                    on='post:update'
                    callback={({ getFormValue, name }) => {
                        if (!this.initialized) return;
                        const formValue = getFormValue();
                        const nextExpression = formValue.expression;
                        const hasNoError = !this.getError(formValue);

                        const shouldCallOnChange =
                            !this.lastProps.includes(nextExpression) &&
                            hasNoError &&
                            /^output/.test(name);

                        // trigger updates and save data when there are no errors
                        if (onChange && shouldCallOnChange) {
                            this.storeLastProps(
                                nextExpression,
                                formValue.data,
                                formValue.inputConfig,
                                formValue.builderConfig,
                            );
                            onChange(nextExpression);
                        }

                        if (canSaveHandler) {
                            canSaveHandler(hasNoError);
                        }

                        this.handleSaveToLocalStorage(formValue);

                        void this.triggerInternalChange(
                            formValue.data,
                            formValue.expression,
                            formValue.inputConfig.includeHidden,
                            formValue.inputConfig.coverage,
                            formValue.builderConfig.debug,
                        );
                    }}
                />

                <Fields
                    {...rest}
                    names={['inputConfig', 'builderConfig']}
                    component={TransformerEditorContent}
                    argumentChangeHandlers={argumentChangeHandlers}
                />
            </Form>
        );
    }
}

export default TransformerEditor;

const TransformerEditorContent: React.FC<{
    inputConfig: FieldT<TransformerEditorProps['inputConfig']>;
    builderConfig: FieldT<TransformerEditorProps['builderConfig']>;
    names: string[];
    getSuggestions: TransformerEditorProps['getSuggestions'];
    includeNodes: TransformerEditorProps['includeNodes'];
    argumentChangeHandlers?: TransformerEditorProps['argumentChangeHandlers'];
    transformerTitle: React.ReactNode;
    outputComponent: React.FC;
    inputComponent: React.FC;
    outputTitle?: React.ReactNode | ((props: JSONRendererFieldProps) => React.ReactNode);
}> = ({
    inputConfig,
    builderConfig,
    names,
    transformerTitle,
    outputComponent: CustomOutputComponent,
    inputComponent: CustomInputComponent,
    outputTitle = 'Result',
    argumentChangeHandlers,
    ...rest
}) => {
    const config = inputConfig.input.value || {};

    const colDistribution = config.visible ? [27.5, 45, 27.5] : [0, 60, 40];

    return (
        <Container>
            {config.visible && (
                <InputRenderer
                    colDistribution={colDistribution}
                    CustomInputComponent={CustomInputComponent}
                />
            )}
            <Field
                {...rest}
                // {...builderConfig.input.value}
                name='expression'
                component={TransformerBuilderField}
                style={{ width: colDistribution[1] + '%' }}
                title={transformerTitle}
                argumentChangeHandlers={argumentChangeHandlers}
            />
            <Column style={{ width: colDistribution[2] + '%' }}>
                <Fields
                    names={{
                        error: 'output.execute.error',
                        ajvError: 'output.execute.ajvError',
                        result: 'output.execute.result',
                    }}
                    component={OutputRenderer}
                    CustomOutput={CustomOutputComponent}
                    title={outputTitle}
                />
            </Column>
        </Container>
    );
};

const InputRenderer: React.FC<any> = ({
    title = 'Input',
    colDistribution,
    CustomInputComponent,
}) => {
    const [showJson, setShowJson] = React.useState(false);

    return (
        <Column style={{ width: colDistribution[0] + '%' }}>
            <ColumnTitle>
                <ColumnTitleName>{title}</ColumnTitleName>
                {CustomInputComponent && (
                    <Tooltip content={`${showJson ? 'hide' : 'show'} JSON`}>
                        <Button
                            small
                            icon='code'
                            intent={showJson ? 'warning' : 'primary'}
                            onClick={() => setShowJson(!showJson)}
                            style={{ marginLeft: 'auto' }}
                        />
                    </Tooltip>
                )}
            </ColumnTitle>

            {!showJson && CustomInputComponent ? (
                <ScrollContent style={{ margin: 0, fontSize: '.875rem' }}>
                    <CustomInputComponent />
                </ScrollContent>
            ) : (
                <InputField />
            )}
        </Column>
    );
};

const OutputRenderer: React.FC<any> = ({
    CustomOutput,
    title,
    error,
    ajvError,
    result,
    names,
    ...props
}) => {
    const [showJson, setShowJson] = useState(false);

    const ajvErrorVal = ajvError?.input?.value;
    if (ajvErrorVal && _.get(ajvErrorVal, 'errors.length')) {
        return ajvErrorVal.errors.map((error: any, index: number) => (
            <Callout
                key={index}
                intent='danger'
                title='Error Occurred'
                style={{ paddingTop: '10px' }}
            >
                <div>At Pv2AttributeSchema of type '{ajvErrorVal.attribute}':</div>
                <br />
                <AjvError error={error} />
            </Callout>
        ));
    }

    if (error.input.value) return <ExecuteError error={error.input.value} />;

    Object.assign(props, result);

    return (
        <>
            <ColumnTitle>
                <ColumnTitleName>
                    {typeof title === 'function' ? title(props) : title}
                </ColumnTitleName>
                {CustomOutput && (
                    <Tooltip content={`${showJson ? 'hide' : 'show'} JSON`}>
                        <Button
                            small
                            icon='code'
                            intent={showJson ? 'warning' : 'primary'}
                            onClick={() => setShowJson(!showJson)}
                            style={{ marginLeft: 'auto' }}
                        />
                    </Tooltip>
                )}
            </ColumnTitle>
            {!showJson && CustomOutput ? (
                <ScrollContent style={{ fontSize: '.875rem' }}>
                    <CustomOutput {...props} />
                </ScrollContent>
            ) : (
                <JsonEditorField {...getDefaultEditorProps(props)} readOnly />
            )}
        </>
    );
};
