import _ from 'lodash';
import type { Expression, Transformation, TransformationOptions } from '@pi/transformer-compiler';

export type GetTransformationOutputOptions = {
    expression: Expression;
    input: any;
    options?: TransformationOptions;
    coverage: boolean;
    includeHidden: boolean;
    contextExecute: typeof Transformation.contextExecute;
    checkCoverage: typeof Transformation.checkCoverage;
};

export default async function getTransformationOutput(
    {
        expression,
        input,
        options,
        coverage,
        includeHidden,
        contextExecute,
        checkCoverage,
    }: GetTransformationOutputOptions
) {
    const [executeResult, coverageResult] = await Promise.all([
        contextExecute({
            expression,
            input,
            options: {
                ...options,
                uiMode: true,
            },
        }),
        coverage
            ? checkCoverage({
                input,
                expression,
                options: {
                    ..._.omit(options, 'debug'),
                    uiMode: !includeHidden,
                },
            })
            : {
                covered: [],
                notCovered: [],
                warn: [],
            }
    ]);

    return {
        execute: _.omit(executeResult, ['context']),
        coverage: coverageResult,
    };
}
