import config from '../utils/config';

import type { UiConfig, ParentContext, BaseExpression, Expression } from '../types';
import type Context from '../Context';
import type { NodeTypeSignature } from '../Type';

export default class Node<T extends BaseExpression> {
    /** @override */
    static TYPE: NodeTypeSignature;

    /** @override */
    static uiConfig: UiConfig = {
        style: config.styles.default,
        group: undefined,
        description: undefined,
        // examples: [],
    };

    static getDefault(): Expression {
        if (this.TYPE.type === 'Text') throw new Error('Text should override this');

        return {
            $type: this.name as Expression['$type'],
            ...(this.TYPE.getDefault() as any),
        } as Expression;
    }

    static getDefaultFor<T extends Expression['$type']>($type: T, ...args: any[]): Expression {
        // inline import to prevent cyclic requires
        const nodes = require('src/nodes').default;

        return nodes[$type].getDefault(...args);
    }

    /**
     * Nodes can optionally override this method to provide normalization/migration when needed
     * E.g. if the format changes but you don't want to go back and update the data
     *
     * @override
     */
    static migrate(expr: BaseExpression): Expression {
        return expr as Expression;
    }

    expression: T;
    context: Context;

    constructor(expression: T, context: Context) {
        const constructor = this.constructor as any;

        this.expression = constructor.migrate(expression);
        this.expression = constructor.TYPE.transform(this.expression);
        this.expression = { ...constructor.getDefault(), ...this.expression };
        this.context = context;

        const baseExecute = this.execute.bind(this);
        this.execute = async (config: ParentContext): Promise<any> => {
            this.runValidation();
            return baseExecute(config);
        };
    }

    runValidation() {
        const { name } = this.constructor;

        if (!this.expression.$type) throw new Error(`[${name}] Invalid expression: missing $type`);

        if (this.expression.$type !== name) {
            throw new Error(`[${name}] Invalid expression: $type must be ${name}`);
        }

        const error = this.validate();
        if (error) throw new Error(`[${name}] Invalid expression: ${error}`);
    }

    /* @override */
    // Return a string error if node is invalid, return null/undefined if it's valid
    validate(): string | null | undefined {
        return null;
    }

    /* @override */
    async execute(_config: ParentContext): Promise<any> {
        throw new Error('override');
    }
}
