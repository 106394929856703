import _ from 'lodash';
import { Button, ButtonGroup, type ButtonGroupProps, type ButtonProps } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { type Field, type MakeFieldProps, useOrGetField } from './Form';

import type { SelectOption, SelectOptionKey } from 'src/types';

export interface ButtonSelectProps<Options extends Readonly<SelectOption[]>>
    extends Omit<ButtonGroupProps, 'onChange' | 'children'> {
    readonly options: Options;
    readonly value?: Options[number]['key'] | null;
    onChange: (value: Options[number]['key'], option: Options[number]) => void;
    small?: boolean;
    defaultButtonProps?: Partial<ButtonProps>;
}

export function ButtonSelect<Options extends Readonly<SelectOption[]>>({
    options,
    value,
    onChange,
    small,
    defaultButtonProps,
    ...rest
}: ButtonSelectProps<Options>) {
    return (
        <ButtonGroup {...rest}>
            {options.map(opt => (
                <Button
                    key={String(opt.key)}
                    {...defaultButtonProps}
                    intent={opt.key === value ? (opt.intent ? opt.intent : 'primary') : 'none'}
                    onClick={() => onChange(opt.key, opt)}
                    small={small}
                    icon={opt.icon}
                >
                    {opt.label || opt.key}
                </Button>
            ))}
        </ButtonGroup>
    );
}

export interface ButtonSelectFieldProps<Options extends Readonly<SelectOption[]>>
    extends MakeFieldProps<ButtonSelectProps<Options>> {}

export const ButtonSelectField = observer(function ButtonSelectField<
    Options extends Readonly<SelectOption[]>,
>({ field: f, ...rest }: ButtonSelectFieldProps<Options>) {
    const field = useOrGetField(f);

    return <ButtonSelect {...rest} value={field.value} onChange={field.onChange} />;
});

export const ButtonSelectMultiField: React.FC<{
    field: string | Field<SelectOptionKey[]>;
    options: Array<SelectOption & { disabled?: boolean }>;
    small?: boolean;
}> = ({ field: f, options, small, ...rest }) => {
    const field = useOrGetField(f);
    const value = field.value || [];

    return (
        <ButtonGroup className='mt0' {...rest}>
            {options.map(opt => {
                const selected = value.includes(opt.key);

                return (
                    <Button
                        key={String(opt.key)}
                        intent={selected ? 'primary' : 'none'}
                        disabled={opt.disabled}
                        onClick={() =>
                            field.onChange(
                                selected ? _.without(value, opt.key) : [...value, opt.key],
                            )
                        }
                        small={small}
                        icon={opt.icon}
                    >
                        {opt.label || opt.key}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};
