import _ from 'lodash';

type DocOrIdT = { _id: string } | string;

const MONGO_ID_REG = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;

export function isMongoId(str: string): boolean {
    return !!(str && MONGO_ID_REG.test(String(str)));
}

function ensureId(docOrId: null | undefined): null;
function ensureId(docOrId: DocOrIdT): string;
function ensureId(docOrId: null | undefined | DocOrIdT): string | null {
    if (!docOrId) return null;
    if (typeof docOrId !== 'object') {
        const str = String(docOrId);
        return isMongoId(str) ? str : null;
    }
    if ('_id' in docOrId) return String(docOrId._id);
    return null;
}

export default ensureId;

/**
 * Converts a list of documents (or _ids) to _ids. Filters out null values
 */
export function ensureIdList(list: null | undefined | (DocOrIdT | undefined | null)[]): string[] {
    return _.filter(_.map(list, ensureId)) as unknown as string[];
}
