const imported_stylus_components = require('.cache/react-style-loader/src/components/Search/SearchSelect.styl');
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { Select2 } from '@blueprintjs/select';
import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import BaseSearch from './BaseSearch';
import { useOrGetField } from '../Form/hooks';

import type { SelectProps } from '@blueprintjs/select';
import type { ButtonProps } from '@blueprintjs/core';
import type { BaseSearchProps, BaseType, SearchOption } from './BaseSearch';
import type { SelectOptionKey } from 'src/types';
import type { MakeFieldProps } from '../Form/types';

export interface SearchSelectProps
    extends BaseSearchProps<SelectOptionKey>,
        Partial<SelectProps<SelectOptionKey>> {
    maxLabelWidth?: number;
    /** Custom renderer for the button target. Gets all the component props + the selectedOption */
    renderTarget?: (
        props: SearchSelectProps & { selectedOption: SearchOption | null },
    ) => React.ReactNode;
    defaultButtonHandlerProps?: ButtonProps;
}

export class SearchSelect extends BaseSearch<SelectOptionKey, SearchSelectProps> {
    static defaultProps: Partial<SearchSelectProps> = {
        renderTarget: ({ defaultButtonHandlerProps, selectedOption, disabled, maxLabelWidth }) => (
            <Button
                {...defaultButtonHandlerProps}
                {...selectedOption?.buttonProps}
                disabled={disabled}
                text={
                    <Label
                        data-ellipsis={!!maxLabelWidth}
                        style={maxLabelWidth ? { maxWidth: maxLabelWidth } : undefined}
                    >
                        {selectedOption?.label || defaultButtonHandlerProps?.text || '<select>'}
                    </Label>
                }
                rightIcon='double-caret-vertical'
            />
        ),
    };

    readonly SelectComponent = Select2;

    isOptionSelected: BaseType<'isOptionSelected'> = option => this.props.value === option.key;

    handleClear: BaseType<'handleClear'> = () => this.props.onChange(null);

    onItemSelect: BaseType<'onItemSelect'> = option => this.props.onChange(option.key, option);

    createGetItems: BaseType<'createGetItems'> = () =>
        memoizeOne((options: SearchOption[], inputValue) => {
            const isNonEmptyArrayOfSearchOption = (value: unknown): value is SearchOption[] => {
                return Array.isArray(value) && value.length > 0;
            };
            const items = (isNonEmptyArrayOfSearchOption(options) ? options : []).map(
                (opt, index) => ({
                    ...opt,
                    search: String(opt.label || '')
                        .trim()
                        .toLowerCase(),
                    index,
                }),
            );

            if (inputValue != null && inputValue !== '' && !_.find(items, { key: inputValue })) {
                // @ts-ignore
                items.push({
                    key: inputValue,
                    label: `(custom) ${inputValue}`,
                    isNew: true,
                    buttonProps: {
                        intent: this.props.allowCreate ? 'none' : 'danger',
                    },
                });
            }

            return {
                items,
            };
        });

    getComponentProps() {
        const { value, renderTarget } = this.props;
        const selectedOption = _.find(this.getItems().items, { key: value });

        return {
            ...super.getComponentProps(),
            children: renderTarget!({
                ...this.props,
                selectedOption,
            }),
        };
    }
}

export default SearchSelect;

export interface SearchSelectFieldProps extends MakeFieldProps<SearchSelectProps> {}

export const SearchSelectField: React.FC<SearchSelectFieldProps> = observer(
    ({ field: f, ...props }) => {
        const field = useOrGetField(f);

        return <SearchSelect {...(props as any)} value={field.value} onChange={field.onChange} />;
    },
);

const Label = imported_stylus_components.Label;