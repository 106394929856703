const imported_stylus_components = require('.cache/react-style-loader/src/components/Text.styl');
import { type Intent } from '@blueprintjs/core';
import { type ClassAndStyle } from 'src/types';

export interface TextProps extends ClassAndStyle {
    type?: (typeof TextComponentTypes)[number];
    tag?: string;
    children: React.ReactNode;
    inline?: boolean;
    intent?: Intent;
    ellipsis?: boolean | number | string;
    muted?: boolean;
    monospace?: boolean;
    fill?: boolean;
}

export const Text: React.FC<TextProps> = ({
    tag,
    type = 'inherit',
    inline,
    intent,
    ellipsis,
    muted,
    fill,
    monospace,
    className,
    style,
    ...rest
}) => {
    const Tag: any = getDefaultTag(type);

    return (
        <Tag
            {...rest}
            data-inline={inline}
            data-type={type}
            data-intent={intent}
            data-ellipsis={!!ellipsis}
            data-muted={muted}
            data-fill={fill}
            data-monospace={monospace}
            className={Root.className + ' ' + (className || '')}
            style={
                ellipsis && typeof ellipsis !== 'boolean' ? { ...style, maxWidth: ellipsis } : style
            }
        />
    );
};

function getDefaultTag(type: (typeof TextComponentTypes)[number]): string {
    if (type === 'inherit') return 'span';
    if (/^[hH][1-6]/.test(type)) return type.toLowerCase();

    return 'div';
}

export const TextComponentTypes = [
    'title',
    'subtitle',
    'standard',
    'small',
    'tiny',
    'inherit',
] as const;

const Root = imported_stylus_components.Root;