import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';

export interface ImageProcessingContextType {
    queue: Array<ImageProcessingQueueItem>;
    update: (
        updater:
            | Partial<ImageProcessingContextType>
            | ((draft: ImageProcessingContextType) => void),
    ) => void;
}

export interface ImageProcessingQueueItem {
    imageId: string;
    priority: number;
}

const ImageProcessingContext = React.createContext<ImageProcessingContextType>(null as any);

export default ImageProcessingContext;

export const useImageProcessingContext = (): ImageProcessingContextType =>
    useContext(ImageProcessingContext);

export const ImageProcessingContextProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [value, setValue] = useState<ImageProcessingContextType>({
        queue: [],
        update: () => {},
    });

    useEffect(() => {
        void (async () => {
            setValue({
                queue: value?.queue,
                update: updater => {
                    setValue(val => {
                        return produce(val, draft => {
                            if (typeof updater === 'function') updater(draft!);
                            else Object.assign(draft!, updater);
                        });
                    });
                },
            });
        })();
    }, [value?.queue]);

    if (!value) return null;

    return (
        <ImageProcessingContext.Provider value={value}>{children}</ImageProcessingContext.Provider>
    );
};
