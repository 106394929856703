import { joinPaths } from '@pi/path-utils';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Types from '../../Type';

import type { BaseExpression, ParentContext, UiConfig, Expression } from 'src/types';

export interface ObjectNodeT extends BaseExpression {
    $type: 'ObjectNode';
    value: { [key: string]: Expression };
}

export default class ObjectNode extends ValueNode<ObjectNodeT> {
    static TYPE = Types.RestObject({ argType: { type: 'ValueNode' } });

    static uiConfig: UiConfig = {
        ...config.presets.default,
        style: {
            ...config.presets.default.style,
            background: '#FFC940',
        },
        description: 'Evaluates to an object with the specified keys and values',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: {
                key1: { $type: 'EvaluationNode', value: '' },
                key2: { $type: 'EvaluationNode', value: '' },
            },
        };
    }

    async execute({ targetPath, sourcePath }: ParentContext) {
        const result = this.context.get(targetPath) || {};
        for (const [key, expr] of Object.entries(this.expression.value)) {
            const keyExpression = key?.includes('$') ? Primitive.evaluation(key) : key;
            const parsedKey = await this.context.evaluate(keyExpression, { sourcePath });
            result[parsedKey] = await this.context.evaluateLog(key, expr, {
                sourcePath,
                targetPath: joinPaths(targetPath, parsedKey),
                isOutput: true,
            });
        }
        return result;
    }
}
