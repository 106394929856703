import Cookies from 'js-cookie';

import LS from './LS';
import setupApiClient from './setupApiClient';
import setupTransformerCompiler from './setupTransformerCompiler';
import setupTransformerEditor from './setupTransformerEditor';

export default function setJwt(jwt: string): void {
    LS.set({ jwt });
    Cookies.set('x-jwt', jwt, { expires: 30 });
    setupApiClient(jwt, 'admin-v2-client');
    setupTransformerCompiler();
    void setupTransformerEditor();
}
