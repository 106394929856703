import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, Expression, ParentContext } from 'src/types';

export interface IfNodeT extends BaseExpression {
    $type: 'IfNode';
    cond: Expression;
    then?: Expression;
    else?: Expression;
}

export default class IfNode extends ValueNode<IfNodeT> {
    static TYPE = Type.Object(
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'then', label: 'Then' },
        { key: 'else', label: 'Else' },
    );

    static uiConfig = {
        ...config.presets.block,
        description:
            'Evaluates "cond" if the "if" clause is truthy and optionally "else" if it\'s not',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            cond: Primitive.evaluation(''),
            then: Primitive.evaluation(''),
            else: Primitive.null(),
        };
    }

    async execute({ sourcePath }: ParentContext) {
        if (await this.context.evaluateLog('condition', this.expression.cond, { sourcePath })) {
            if (!this.expression.then) return null;
            return await this.context.evaluateLog('then', this.expression.then, { sourcePath });
        }
        if (!this.expression.else) return null;
        return await this.context.evaluateLog('else', this.expression.else, { sourcePath });
    }
}
