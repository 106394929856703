import type { SyntheticEvent } from 'react';

/**
 * A generic way to check if a thing is an Event
 *
 * This accounts for production builds where class names are mangled
 */
export function isDomEvent(v: any): v is Event & { target?: HTMLElement } {
    if (v == null || typeof v !== 'object') return false;
    if (v instanceof Event) return true;
    if (v.constructor.name === 'SyntheticBaseEvent') return true;
    if ((v as SyntheticEvent).nativeEvent && v.nativeEvent instanceof Event) return true;
    return false;
}
