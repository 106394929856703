import { joinPathComponents, joinPaths, PathComponent, toPathString } from '@pi/path-utils';
import produce from 'immer';
import _ from 'lodash';

type ArrayOrObject = {} | any[];
type Position = 'before' | 'after';

export default function moveNode(
    data: ArrayOrObject,
    fromPath: string,
    toPath: string,
    position: Position,
): { fromParent: ArrayOrObject, toParent: ArrayOrObject, fromParentPath: string, toParentPath: string } {
    if (!fromPath) throw new Error('Empty/Missing fromPath');
    if (!toPath) throw new Error('Empty/Missing toPath');

    const hashedToPath = hashPath(toPath);
    const hashedFromPath = hashPath(fromPath);

    if (hashedFromPath === hashedToPath) {
        throw new Error('fromPath === toPath, does not make sense');
    }

    if (hashedPathStartsWith(hashedFromPath, hashedToPath)) {
        throw new Error('Cannot insert into itself');
    }

    const toIndexOffset = position === 'before' ? 0 : 1;
    const fromPathComponents = _.toPath(fromPath);
    const fromParentPath = toPathString(fromPathComponents.slice(0, -1));
    let fromParent = safeGet(data, fromParentPath);
    const toPathComponents = _.toPath(toPath);
    let toParentPath = toPathString(toPathComponents.slice(0, -1));

    if (fromParentPath && !_.has(data, fromParentPath)) {
        throw new Error('fromPath does not exist in data');
    }

    if (toParentPath && !_.has(data, toParentPath)) {
        throw new Error('toPath does not exist in data');
    }

    // special case when moving into the same parent
    if (toParentPath === fromParentPath) {
        if (Array.isArray(fromParent)) {
            const fromIndex = parseInt(_.last(fromPathComponents)!, 10);
            const toIndex = parseInt(_.last(toPathComponents)!, 10);
            const result = moveWithinSameArray(fromParent, fromIndex, toIndex + toIndexOffset);
            return {
                fromParent: result,
                fromParentPath,
                toParent: result,
                toParentPath,
            };
        } else if (_.isPlainObject(fromParent)) {
            const entries = Object.entries(fromParent);
            const fromKey = _.last(fromPathComponents);
            const toKey = _.last(toPathComponents);
            const fromIndex = entries.findIndex(pair => pair[0] === fromKey);
            const toIndex = entries.findIndex(pair => pair[0] === toKey);
            const result = Object.fromEntries(
                moveWithinSameArray(entries, fromIndex, toIndex + toIndexOffset)
            );
            return {
                fromParent: result,
                fromParentPath,
                toParent: result,
                toParentPath,
            };
        } else {
            throw new Error('sameParent: Invalid fromParent type');
        }
    }

    let child: any;
    let childKey;

    // remove child from original location
    if (Array.isArray(fromParent)) {
        const index = parseInt(_.last(fromPathComponents)!, 10);
        fromParent = [...fromParent];
        [child] = fromParent.splice(index, 1);

        // edge-case: when toPath and fromPath have the same parent AND that parent is an array,
        // we need to decrement the shared index by 1 since we remove an element from the path
        // Example:
        //  moveNode(['a', ['b', 'c']], '0', '1.1', 'before')
        // In the above example, toPath = '1.1' points to ['b', 'c'] but after removing 'a'
        // the new toPath should be '0.1' since the array indices have all changed
        if (
            // only do this if the fromParentPath is before the toParentPath
            index < parseInt(toPathComponents[fromPathComponents.length - 1], 10) &&
            hashedPathStartsWith(hashPath(fromParentPath), hashPath(toParentPath))
        ) {
            const toParentPathComponents = toPathComponents.slice(0, -1);

            toParentPathComponents[fromPathComponents.length - 1] = String(
                parseInt(toParentPathComponents[fromPathComponents.length - 1], 10) - 1
            );
            toParentPath = toPathString(toParentPathComponents);
        }
    } else if (_.isPlainObject(fromParent)) {
        const key = _.last(fromPathComponents)!;
        fromParent = { ...fromParent };
        child = fromParent[key];
        childKey = key;
        delete fromParent[key];
    } else {
        throw new Error('Invalid fromParent type');
    }

    data = produce(data, draft => safeSet(draft, fromParentPath, fromParent));

    const addResult = addItemToParent({
        data,
        path: joinPaths(toParentPath, _.last(toPathComponents)!),
        position,
        child,
        childKey,
    });

    return {
        fromParent,
        fromParentPath,
        toParent: addResult.parent,
        toParentPath: addResult.parentPath,
    };
}

type HashedPath = string;

// this hashing makes sure we use a single standard separator between all path components
// we need this to make sure we don't insert an object into itself and not worry about whether the
// separators are `[]` or `.`
const hashPath = (path: string): HashedPath => _.toPath(path).join(pathSeparator);
const pathSeparator = '🐷'; // oink

const hashedPathStartsWith = (parent: HashedPath, child: HashedPath) =>
    parent === '' ||
    parent === undefined ||
    parent === child ||
    child.startsWith(parent + pathSeparator);

const safeGet = (obj: any, path: string | string[] | undefined) =>
    path === '' || path === undefined || path.length === 0
        ? obj
        : _.get(obj, path);

const safeSet = (obj: any, path: string | string[] | undefined, value: any) =>
    path === '' || path === undefined || path.length === 0
        ? value
        : _.set(obj, path, value);

/**
 * Moves an element in an array from one position to another
 * The `toIndex` will insert the element **before** the given index
 * Therefore if you want to insert at the end of the list, set `toIndex = list.length`
 */
export function moveWithinSameArray (arr: any[], fromIndex: number, toIndex: number) {
    arr = [...arr];
    const [removed] = arr.splice(fromIndex, 1);
    // we're removing from the start so the target index is going to be smaller by 1
    if (fromIndex < toIndex) toIndex -= 1;
    arr.splice(toIndex, 0, removed);
    return arr;
}

export function addItemToParent({ data, path, position, child, childKey }: {
    data: any;
    path: string;
    position: Position;
    child: any;
    childKey?: string;
}): { parent: ArrayOrObject, parentPath: string } {
    const toPathComponents = _.toPath(path);
    let parent = safeGet(data, toPathComponents.slice(0, -1));
    const toIndexOffset = position === 'before' ? 0 : 1;

    if (Array.isArray(parent)) {
        parent = [...parent];
        const index = parseInt(_.last(toPathComponents)!, 10);
        parent.splice(index + toIndexOffset, 0, child);
    } else if (_.isPlainObject(parent)) {
        const entries = Object.entries(parent);
        const toKey = _.last(toPathComponents)!;
        const index = _.findIndex(entries, pair => pair[0] === toKey);
        childKey = childKey == null
            ? `rand_${_.random(1e3)}`
            : Reflect.has(parent, childKey)
                ? `${childKey}_${_.random(1e3)}`
                : childKey;
        entries.splice(index + toIndexOffset, 0, [childKey, child]);
        parent = Object.fromEntries(entries);
    } else {
        console.warn(path, parent);
        throw new Error('Invalid parent type');
    }

    return {
        parent,
        parentPath: joinPathComponents(...toPathComponents.slice(0, -1)),
    };
}
