import _ from 'lodash';

import PrimitiveTypes from './utils/Primitive';

import type { BaseExpression, Expression, ExternalSuggestions, InternalSuggestions } from './types';

export type ExtendedArg = {
    key?: string;
    label?: string;
    /** @deprecated */
    inputType?: 'boolean' | 'json' | 'text' | 'string' | 'number';
    type?:
        | 'ValueNode'
        | 'EvaluationNode'
        | 'BooleanNode'
        | 'JSONNode'
        | 'StringNode'
        | 'NumericNode'
        | 'ObjectNode';
    suggestions?:
        | 'off'
        | 'array'
        | 'primitive'
        | 'number'
        | 'string'
        | 'docId'
        | InternalSuggestions
        | ExternalSuggestions
        | readonly string[]
        | ReadonlyArray<{ readonly key: string; readonly label: string }>;
    /**
     * If enabled, the UI will only show a select and disable the Text Input option for this node
     */
    forceSuggestion?: boolean | 'optional';
    onChange?: (config: {
        value: any;
        parentPath: string;
        formValue: any;
        getSuggestions: (path: string, argumentType?: ExtendedArg, contextCache?: any) => any;
    }) => void;
};
type Arg = string | ExtendedArg;

export interface NodeTypeSignature {
    type:
        | 'Complex'
        | 'Evaluation'
        | 'NTuple'
        | 'Object'
        | 'Primitive'
        | 'RestArray'
        | 'RestObject'
        | 'Text';
    getDefault: () => any;
    transform: (input: any) => any;
}

export interface EvaluationSignature extends NodeTypeSignature {
    args: ExtendedArg[];
}

export interface NTupleSignature extends NodeTypeSignature {
    args: ExtendedArg[];
}

export interface ObjectSignature extends NodeTypeSignature {
    args: ExtendedArg[];
}

export interface PrimitiveSignature extends NodeTypeSignature {
    args: [ExtendedArg];
}

export interface RestArraySignature extends NodeTypeSignature {
    argType?: ExtendedArg;
    defaultType?: ExtendedArg;
}

export interface RestObjectSignature extends NodeTypeSignature {
    argType?: ExtendedArg;
}

/**
 * @deprecated
 */
interface TextSignature extends NodeTypeSignature {}

const toExtendedArg = (item: string | ExtendedArg, extra?: Partial<ExtendedArg>): ExtendedArg =>
    typeof item === 'string' ? { key: item, ...extra } : item;

const toExtendedArgList = (
    args: Array<string | ExtendedArg>,
    extra?: Partial<ExtendedArg>,
): ExtendedArg[] => args.map(a => toExtendedArg(a, extra));

export const Type = {
    /**
     * Used by the UI to configure plain text nodes
     * @deprecated
     */
    Text: (): TextSignature => ({
        type: 'Text',
        getDefault: () => '',
        transform: _.identity,
    }),

    /**
     * Used by the UI to configure Evaluation nodes for math and variable evaluation
     */
    Evaluation: (...args: Arg[]): EvaluationSignature => ({
        type: 'Evaluation' as const,
        args: toExtendedArgList(args),
        getDefault: () => ({ value: '' }),
        transform: _.identity,
    }),

    /**
     * Handles primitive values such as numbers, booleans and strings
     */
    Primitive: (arg: Arg): PrimitiveSignature => ({
        type: 'Primitive',
        args: [toExtendedArg(arg)],
        getDefault: () => ({ value: undefined }),
        transform: _.identity,
    }),

    /**
     * Handles a fixed shape object: { a: 1, b: 2 }
     */
    Object: (...args: Arg[]): ObjectSignature => {
        const normalizedArgs = toExtendedArgList(args, { type: 'ValueNode' });
        return {
            type: 'Object' as const,
            args: normalizedArgs,
            getDefault: () => _.fromPairs(normalizedArgs.map(({ key }) => [key, null])),
            transform: _.identity,
        };
    },

    /**
     * Handles a fixed shape array: [1, 'foo', 321]
     */
    NTuple: (...args: Arg[]): NTupleSignature => {
        const normalizedArgs = toExtendedArgList(args);
        return {
            type: 'NTuple' as const,
            args: normalizedArgs,
            getDefault: () => ({ value: normalizedArgs.map(() => PrimitiveTypes.evaluation('')) }),
            transform: _.identity,
        };
    },

    /**
     * Handles a variable length array: [1, 2, 3, ...]
     */
    RestArray: ({
        defaultType,
        argType,
    }: { argType?: ExtendedArg; defaultType?: ExtendedArg } = {}): RestArraySignature => ({
        type: 'RestArray' as const,
        argType,
        defaultType,
        getDefault: () => ({ value: [] }),
        transform: (expr: BaseExpression & { value: any[] }) => ({
            ...expr,
            value: expr.value?.filter(expr => !expr?.$ui?.disabled) ?? [],
        }),
    }),

    /**
     * Handles a variable length object: { a: 1, b: 2, ... }
     */
    RestObject: ({ argType }: { argType?: ExtendedArg } = {}): RestObjectSignature => ({
        type: 'RestObject' as const,
        argType,
        getDefault: () => ({ value: {} }),
        transform: (expr: Expression) => ({
            ...expr,
            value: Object.fromEntries(
                Object.entries((expr as any).value || {}).filter(([, v]: any) => !v?.$ui?.disabled),
            ),
        }),
    }),
} as const;

export default Type;
