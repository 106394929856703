import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';
import { isNode } from 'browser-or-node';
import _ from 'lodash';

import { ApiModelClient } from './ApiModelClient';
import { API_TRACKING_HEADER, LEGACY_MODEL_NAMES, MODEL_NAMES } from './constants';
import { getSetupOptions, setupOnce } from './setup';

import type { ApiTrackingHeader, ModelName, SetupOptions } from './typeDefs';

type ApiTargets = 'legacy' | 'v2';
const instances: { [key in ApiTargets]?: ApiClient } = {};
const legacyModels = _.keyBy(LEGACY_MODEL_NAMES.map(_.toLower));

export interface ApiClientOptions extends Partial<SetupOptions> {
    target: ApiTargets;
    /** do not memoize it as a global client shared everywhere */
    local?: boolean;
    /**
     * Do not check for valid tokens before sending requests.
     * Only enable this if working with public routes (like /auth/login)
     */
    skipCredentialCheck?: boolean;
}

export class ApiClient {
    #axiosClient?: AxiosInstance;
    #defaultHeaders?: Record<string, string> = {};
    #tracking?: ApiTrackingHeader;

    constructor(private options: ApiClientOptions) {
        if (!options.target) throw new Error('[api-client] empty target provided');
        if (!options.local) instances[options.target] = instances[options.target] || this;
    }

    get client(): AxiosInstance {
        setupOnce({}); // setup once to pick up env vars

        const setupOptions = { ...getSetupOptions(), ...this.options };
        const [url, token] =
            this.options.target === 'v2'
                ? [setupOptions.apiV2Url, setupOptions.apiV2Token]
                : [setupOptions.apiLegacyUrl, setupOptions.apiLegacyToken];

        const credentialsError = (type: string) =>
            new Error(
                [
                    `[api-client:${this.options.target}] invalid ${type}. Either:`,
                    `- set the following ENV var: process.env.API_${this.options.target.toUpperCase()}_${type.toUpperCase()}`,
                    `- call setup() and provide a valid configuration for: api${_.upperFirst(
                        this.options.target,
                    )}${_.upperFirst(type)}`,
                ].join('\n'),
            );

        if (!url) throw credentialsError('url');
        if (!this.options.skipCredentialCheck && !token) throw credentialsError('token');
        if (this.#axiosClient) return this.#axiosClient;

        let httpsAgent: any = undefined;

        // In Node environment it's useful to ignore SSL issues
        if (isNode) {
            // requiring inline so it works in both browser and Node
            httpsAgent = new (require('https') as any).Agent({
                rejectUnauthorized: false,
            });
        }

        this.#axiosClient = axios.create({
            baseURL: url,
            httpsAgent,
            headers: {
                ...setupOptions.defaultHeaders,
                'Content-Type': 'application/json',
                JWT: token,
            },
        });

        return this.#axiosClient;
    }

    setDefaultHeaders(headers: Record<string, string>) {
        this.#defaultHeaders = headers;
    }

    setTracking(tracking: ApiTrackingHeader) {
        this.#tracking = tracking;
    }

    serializeParams(params: any) {
        if (!params || typeof params !== 'object') return undefined;

        params = { ...params };

        if (params.query != null && typeof params.query === 'object') {
            params.query = JSON.stringify(params.query);
        }

        return params;
    }

    getDefaultHeaders() {
        const headers = {
            ...this.#defaultHeaders,
        };

        const tracking = this.#tracking || getSetupOptions().tracking;
        if (tracking) headers[API_TRACKING_HEADER] = JSON.stringify(tracking);

        return headers;
    }

    async fetch(
        method: 'get' | 'post' | 'patch' | 'put' | 'delete',
        url: string,
        options: AxiosRequestConfig = {},
    ) {
        if (!/^(get|post|patch|delete|put)$/i.test(method)) {
            throw new Error(`Invalid method '${method}'`);
        }

        if (!url) throw new Error('Missing url');

        try {
            const { data } = await this.client.request({
                ...options,
                headers: {
                    ...this.getDefaultHeaders(),
                    ...options.headers,
                },
                params: this.serializeParams(options.params || {}),
                method,
                url,
            });

            return data;
        } catch (ex: any) {
            if (ex.response?.data) throw ex.response.data;
            if (ex.code) throw new Error(`${ex.code} for ${method} ${url}`);
            throw ex;
        }
    }

    get<R = any>(
        url: string,
        params?: AxiosRequestConfig['params'],
        options?: AxiosRequestConfig,
    ): Promise<R> {
        return this.fetch('get', url, { ...options, params });
    }

    post<R = any>(
        url: string,
        data: AxiosRequestConfig['data'],
        options?: AxiosRequestConfig,
    ): Promise<R> {
        return this.fetch('post', url, { ...options, data });
    }

    patch<R = any>(
        url: string,
        data: AxiosRequestConfig['data'],
        options?: AxiosRequestConfig,
    ): Promise<R> {
        return this.fetch('patch', url, { ...options, data });
    }

    delete<R = any>(url: string, options?: AxiosRequestConfig): Promise<R> {
        return this.fetch('delete', url, options);
    }

    /**
     * Returns an ApiModelProxy which is an API client that mimicks the mongoose model interface
     *
     * @example
     *  const Image = api.model<ImageT>('Image');
     *  await Image.find({ query: { ... }, select: 'name' });
     *  await Image.aggregate([ { $match: ... }, { $project: ... } ]);
     *  await Image.deleteMany({ query: ... });
     */
    model = _.memoize(
        <Name extends ModelName>(modelName: Name, { forceV2 = false } = {}) => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let instance: ApiClient = this;

            if (!forceV2) {
                const isLegacyModel = legacyModels[modelName.toLowerCase()];
                if (isLegacyModel) {
                    instance = instances.legacy || new ApiClient({ target: 'legacy' });
                }
            }

            return new ApiModelClient(instance, modelName);
        },
        (model, options) => `${model}-${options?.forceV2}`,
    );

    /**
     * Returns a map of all available models.
     *
     * If you don't need to specify the datatype, this is a more convenient method than `.model()`
     *
     * @example
     *  const { Company, Image, Pv2 } = api.getModels();
     *  await Pv2.find(...);
     */
    getModels({ forceV2 = false } = {}) {
        return Object.fromEntries(
            MODEL_NAMES.map(name => [name, this.model(name, { forceV2 })]),
        ) as { [key in ModelName]: ApiModelClient<key> };
    }
}
