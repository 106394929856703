import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, UiConfig } from 'src';

export interface BooleanNodeT extends BaseExpression {
    $type: 'BooleanNode';
    value: boolean;
}

export default class BooleanNode extends ValueNode<BooleanNodeT> {
    static TYPE = Type.Primitive({
        key: 'value',
    });

    static uiConfig: UiConfig = {
        ...config.presets.primitive,
        description: 'Simple true or false',
    };

    validate() {
        if (typeof this.expression.value !== 'boolean') return 'value must be true or false';
    }

    static getDefault() {
        return {
            ...super.getDefault(),
            value: true,
        };
    }

    async execute() {
        return this.expression.value;
    }
}
