//❗ Generated file via api-v2/scripts/create-graphql-fragments.ts

import { gql } from '@apollo/client';


const TagStructFragment = gql`

    fragment TagStructFragment on TagStruct {
        name
        reason
        data { _id } # Tag
        createdBy { _id } # User
        createdDate
    }
`;

export default TagStructFragment;