import _ from 'lodash';
import ensureArrayProp from 'src/utils/ensureArrayProp';

import ValueNode from '../../ValueNode';
import Type from '../../../Type';
import config from '../../../utils/config';

import type { BaseExpression, Expression, ParentContext } from '../../../types';

export interface MinNodeT extends BaseExpression {
    $type: 'MinNode';
    value: Expression | Expression[];
}

export default class MinNode extends ValueNode<MinNodeT> {
    static TYPE = Type.NTuple(
        { key: 'a', type: 'ValueNode', suggestions: 'number' },
        { key: 'b', type: 'ValueNode', suggestions: 'number' },
    );

    static uiConfig = {
        ...config.presets.comparison,
    };

    static migrate(expr: MinNodeT) {
        return ensureArrayProp('value', expr);
    }

    async execute({ targetPath }: ParentContext = {}) {
        const value = this.context.get(targetPath);

        const allValues = [value];
        for (const expression of this.expression.value as Expression[]) {
            allValues.push(await this.context.evaluate(expression));
        }

        return _.min(allValues);
    }
}
