import _ from 'lodash';
import { ModelNameEnum } from 'src/generated/graphql';

import type { DereferenceDocument } from 'src/types';

const disabledSymbol = Symbol('dereferenceDocument(false)');

export default function dereferenceDocument<T extends Record<string, any> = Record<string, any>>(
    doc: T,
): DereferenceDocument<T> {
    const seenMap = new Map<object, string>();
    seenMap.set(doc, '<ROOT>');

    const iteratorFn = (item: any, path: string): any => {
        // This also includes undefined
        if (item == null) return item;
        if (typeof item !== 'object') return item;
        if (!canDerefence(item)) return item;

        if (seenMap.has(item)) {
            // eslint-disable-next-line no-console
            console.error('duplicate entry: ', item);
            // eslint-disable-next-line no-console
            console.error('path 1: %s\n path 2: %s', seenMap.get(item), path);
            throw new Error('Cycles in data detected');
        }

        seenMap.set(item, path);

        if (Array.isArray(item)) return item.map((v, i) => iteratorFn(v, path + '.' + i));
        if ((!item.__typename || item.__typename in ModelNameEnum) && typeof item._id === 'string')
            return item._id;

        // remove fields added by Apollo
        if (item.__typename) item = _.omit({ ...item }, '__typename');

        return _.mapValues(item, (v, k) => iteratorFn(v, path + '.' + k));
    };

    if (doc == null) return doc;
    if (typeof doc !== 'object') return doc;
    if (!canDerefence(doc)) return doc;
    if (Array.isArray(doc)) return doc as any;

    // remove fields added by Apollo
    if (doc.__typename) doc = _.omit({ ...doc }, '__typename') as any;

    return _.mapValues(doc, (v, k) => iteratorFn(v, k)) as any;
}

/**
 * Disables dereferencing passed this point by setting the disabledSymbol flag on the input
 */
export function doNotDereference<T extends Record<string, any> | any[]>(item: T): T {
    Object.assign(item, { [disabledSymbol]: true });
    return item;
}

export function canDerefence(item: any): boolean {
    if (!item) return false;
    if (typeof item !== 'object') return false;
    if (item[disabledSymbol]) return false;
    if (item instanceof File) return false;
    return true;
}
