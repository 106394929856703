const imported_stylus_components = require('.cache/react-style-loader/src/components/Breakpoint.styl');
import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';
import type { QueryResult } from '@apollo/client';
import produce from 'immer';
import { useWindowScroll } from 'src/hooks/useWindowScroll';

export interface BreakpointProps {
    onTrigger: () => void;
    triggerOnMount?: boolean;
    handleWindowScroll?: boolean;
    multiple?: boolean;
    throttle?: number;
    debug?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

export const Breakpoint: React.FC<BreakpointProps> = (props) => {
    const {
        triggerOnMount = true,
        multiple = false,
        debug = false,
        throttle = 100,
        onTrigger,
        children,
        handleWindowScroll,
        disabled,
        ...rest
    } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const [done, setDone] = useState(false);
    const triggeredRef = useRef(false);

    const callback = useCallback(() => {
        if (disabled) return;
        if (!ref.current || (!multiple && done)) return;

        const rect = ref.current.getBoundingClientRect();

        if (rect.top <= window.innerHeight) {
            if (!triggeredRef.current) {
                triggeredRef.current = true;
                setDone(true);
                onTrigger();
            }
        } else {
            triggeredRef.current = false;
        }
    }, [multiple, done, disabled]);

    useWindowScroll({ throttle, callback });

    useEffect(() => {
        if (triggerOnMount && !done && !triggeredRef.current) callback();
    }, [triggerOnMount]);

    return (
        <Root {...rest} ref={ref} data-debug={!!debug}>
            <Content>
                {children}
            </Content>
        </Root>
    );
}

export interface QueryFetchMoreBreakpointProps<
    Data,
    Variables extends { limit?: number | null; skip?: number | null },
> extends Omit<BreakpointProps, 'onTrigger'> {
    query: QueryResult<Data, Variables>;
    path: string;
}

export function QueryFetchMoreBreakpoint<
    Data,
    Variables extends { limit?: number | null; skip?: number | null },
> (
    {
        query,
        path,
        ...props
    }: QueryFetchMoreBreakpointProps<Data, Variables>
) {
    const done = useRef(false);
    const skip = useRef(query.variables?.skip || 0);
    const limit = useRef(query.variables?.limit || 20);

    const onTrigger = useCallback(() => {
        if (done.current) return;

        skip.current += limit.current;

        void query.fetchMore({
            variables: {
                ...query.variables,
                skip: skip.current,
                limit: limit.current,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                const currentValue = _.get(prevResult, path);
                const extraValue = _.get(fetchMoreResult, path);

                if (!Array.isArray(currentValue)) return prevResult;
                if (!Array.isArray(extraValue) || !extraValue.length) {
                    done.current = true;
                    return prevResult;
                }

                return produce(prevResult, draft => {
                    _.get(draft, path).push(...extraValue);
                });
            }
        })
    }, [query]);

    if (!query) return null;
    if (query.loading) return <Spinner />;

    return <Breakpoint triggerOnMount {...props} multiple onTrigger={onTrigger} />;
};

const Root = imported_stylus_components.Root;
const Content = imported_stylus_components.Content;