import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { StringNodeT } from './StringNode';
import type { Expression, BaseExpression, ParentContext } from 'src/types';

export interface StopProcessingNodeT extends BaseExpression {
    $type: 'StopProcessingNode';
    cond?: Expression;
    message: StringNodeT;
}

export default class StopProcessingNode extends ValueNode<StopProcessingNodeT> {
    static OUTPUT_PATH = 'processingErrors';

    static TYPE = Type.Object(
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'message', label: 'Message', type: 'StringNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.mturk,
        style: config.styles.danger,
        description: 'Stop processing tasks for this product request if conditions are met',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            cond: Primitive.evaluation(''),
            message: this.getDefaultFor('StringNode'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'message')) return 'missing "message"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        if (this.expression.cond) {
            if (!(await this.context.evaluate(this.expression.cond, { sourcePath }))) return null;
        }

        const message = await this.context.evaluate(this.expression.message, {
            sourcePath,
            isOutput: true,
        });

        const result = { message };

        await this.context.evaluate(
            {
                ...ValueNode.getDefaultFor('SetNode'),
                path: Primitive.string(StopProcessingNode.OUTPUT_PATH),
                value: {
                    ...ValueNode.getDefaultFor('PushNode'),
                    value: [result],
                },
            } as Expression,
            { sourcePath },
        );

        return result;
    }
}
