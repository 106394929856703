//❗ Generated file via api-v2/scripts/create-graphql-fragments.ts

import { gql } from '@apollo/client';
import DataRequirementFragment from './DataRequirementFragment';
import TagStructFragment from './TagStructFragment';


const TaskDefinitionFragment = gql`

    fragment TaskDefinitionFragment on TaskDefinition {
        _id
        enabled
        name
        type
        # Configuration specific to the task type + handler
        config
        # Optional Markdown description of what this task does
        description
        # If set, Tasker will give up waiting on the request after the given period and fail the task with a timeout error
        timeoutSeconds
        throttle {
            # Max number of requests to handle per second
            perSecond
            # Max number of tasks to have in a processing state in the same time
            maxProcessingAtOnce
        }
        # Transformation to run on task completion
        resultTransformation { _id } # TransformerExpression
        tags { ...TagStructFragment }
        mturk {
            HITTypeId
            qualificationName
            QualificationTypeId
        }
        # Defaulted to current date via trackingProps mongoose plugin
        createdDate
        # Defaulted to current date via trackingProps mongoose plugin.
        #
        # Gets overwritten after every "Document.save()", unless it has been manually changed.
        updatedDate
        # Defaulted to current "doc.context.user" via trackingProps mongoose plugin
        createdBy { _id } # User
        # Defaulted to current "Document.context.user" via trackingProps mongoose plugin.
        #
        # Gets overwritten after every "Document.save()", unless it has been manually changed.
        updatedBy { _id } # User
        dataRequirements { ...DataRequirementFragment } # DataRequirement
    }

    ${DataRequirementFragment}
    ${TagStructFragment}
`;

export default TaskDefinitionFragment;