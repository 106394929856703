const imported_stylus_components = require('.cache/react-style-loader/src/components/VirtualizedList.styl');
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { List } from 'react-virtualized';

import type { ListProps } from 'react-virtualized';
import type { DebouncedFunc } from 'lodash';
import type { MaybeArray, OmitIndex } from '@pi/types';

export interface VirtualizedListProps<Item>
    extends Omit<
        OmitIndex<ListProps>,
        'width' | 'height' | 'rowHeight' | 'rowCount' | 'rowRenderer'
    > {
    items: Item[];
    renderItem: (options: {
        item: Item;
        key: string;
        index: number;
        isDimensionsRender?: boolean;
        style: React.CSSProperties;
    }) => React.ReactNode;
    rowHeight?: number;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    listStyle?: React.CSSProperties;
    listClassName?: string;
    /** Provide a custom container if your items behave differently inside a plain div (default) */
    renderItemContainer?: (props: {
        key?: string;
        ref?: React.LegacyRef<HTMLDivElement>;
        children: MaybeArray<React.ReactNode>;
    }) => React.ReactNode;
}

/**
 * Provides a wrapper on top of react-virtualized/List that does a few helpful things, optimized for common use-cases
 * - it automatically detects container width height
 * - allows setting a max width + height
 * - automatically detects rowWidth
 * - provides a simpler API by passing the current item being rendered to renderItem()
 */
export function VirtualizedList<Item>({
    items,
    renderItem,
    width,
    height,
    maxHeight = 300,
    maxWidth,
    rowHeight,
    className,
    style,
    listClassName,
    listStyle,
    renderItemContainer = props => <div {...props} />,
    ...props
}: VirtualizedListProps<Item>) {
    const [finalWidth, setFinalWidth] = useState(width || 100);
    const [finalHeight, setFinalHeight] = useState(height || 100);
    const [itemHeight, setItemHeight] = useState(rowHeight || 20);
    const [rows, setRows] = useState<[start: number, end: number]>([0, 0]);
    const saveDimensions = useRef<DebouncedFunc<() => void>>();
    const setDimensions = useRef<(index: number, width: number, height: number) => void>();
    const maxItemWidth = useRef(0);
    const containerRef = useRef<HTMLDivElement>(undefined as any);

    useEffect(() => {
        maxItemWidth.current = 0;
    }, [items]);

    useEffect(() => {
        if (width && height) {
            saveDimensions.current?.cancel();
            saveDimensions.current = _.noop as any;
            setDimensions.current = _.noop;
            return;
        }

        saveDimensions.current = _.debounce(() => {
            if (!width) {
                let width = maxItemWidth.current;
                width = Math.max(width + 15, 100);
                if (containerRef.current) {
                    // fill container if it's bigger
                    width = Math.max(width, containerRef.current.getBoundingClientRect().width);
                }
                if (maxWidth) width = Math.min(width, maxWidth);
                setFinalWidth(width);
            }

            if (!height && itemHeight) {
                setFinalHeight(Math.min(items.length * itemHeight, maxHeight));
            }
        }, 10);

        setDimensions.current = (index: number, width: number, height: number) => {
            maxItemWidth.current = Math.max(maxItemWidth.current, width);
            if (height !== itemHeight) setItemHeight(itemHeight);
            saveDimensions.current!();
        };
    }, [...rows, itemHeight]);

    const rowRenderer = useCallback<ListProps['rowRenderer']>(
        ({ index, style }) => renderItem({ index, style, item: items[index], key: String(index) }),
        [items, renderItem],
    );

    const renderIndex = (index: number) =>
        renderItem({
            index,
            item: items[index],
            key: String(index),
            isDimensionsRender: true,
            style: {
                display: 'inline-block',
            },
        });

    return (
        <>
            <List
                {...props}
                rowCount={items.length}
                rowHeight={itemHeight}
                rowRenderer={rowRenderer}
                width={finalWidth}
                height={finalHeight}
                onRowsRendered={props => setRows([props.startIndex, props.stopIndex])}
                style={listStyle}
                className={listClassName}
            />

            <Hidden>
                {renderItemContainer({
                    ref: containerRef,
                    children: _.range(rows[0], rows[1]).map(index => (
                        <div
                            key={rows.join('.') + '.' + index}
                            ref={elem => {
                                if (!elem) return;
                                const { width, height } = elem.children[0].getBoundingClientRect();
                                setDimensions.current!(index, width, height);
                            }}
                        >
                            {renderIndex(index)}
                        </div>
                    )),
                })}
            </Hidden>
        </>
    );
}

const Hidden = imported_stylus_components.Hidden;