//❗ Generated file via api-v2/scripts/create-graphql-fragments.ts

import { gql } from '@apollo/client';


const DataRequirementFragment = gql`

    fragment DataRequirementFragment on DataRequirement {

        ... on DataRequirementModel {
            targetModel
            # Optional fields to select. If left empty, everything will be selected
            selectFields
            # Optional fields to populate
            populateFields
            # Variable name
            name
            # Make this a list of entries
            isArray
            optional
            kind
        }

        ... on DataRequirementModelId {
            targetModel
            # Variable name
            name
            # Make this a list of entries
            isArray
            optional
            kind
        }

        ... on DataRequirementPrimitive {
            primitiveType
            # Variable name
            name
            # Make this a list of entries
            isArray
            optional
            kind
        }
    }
`;

export default DataRequirementFragment;