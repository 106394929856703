/* eslint-disable no-console */

const old = console.error.bind(console);

const ignore = [
    'Warning: You are importing createRoot from "react-dom" which is not supported. You should instead import it from "react-dom/client".',
];

console.error = (...args: any[]) => {
    if (ignore.includes(args[0])) return;
    return old(...args);
};

export {};
