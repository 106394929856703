import { createContext, useContext } from 'react';
import type { FormStore } from './FormStore';

export interface FormContextType<Value extends Record<string, any> = Record<string, any>, ValidValue extends Record<string, any> = Value> {
    form: FormStore<Value, ValidValue>;
}

export const FormContext = createContext<FormContextType>(null as any);

/**
 * Returns the FormStore for the given context
 *
 * Throws an error if context does not exist (there is a `useFormContextSafe()` hook that does not throw errors, though it's not usually needed)
 *
 * @see FormStore
 */
export function useFormContext<Value extends Record<string, any> = Record<string, any>, ValidValue extends Record<string, any> = Value>() {
    const context = useContext(FormContext) as FormContextType<Value, ValidValue>;
    if (!context) {
        throw new Error('Component must be inside <Form /> component to access the form context');
    }
    return context;
}

/**
 * Returns the FormStore for the given context
 *
 * @see FormStore
 */
export function useFormContextSafe<Value extends Record<string, any> = Record<string, any>, ValidValue extends Record<string, any> = Value>() {
    return useContext(FormContext) as FormContextType<Value, ValidValue> | null;
}
