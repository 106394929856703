(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define("transformer-compiler", ["lodash"], factory);
	else if(typeof exports === 'object')
		exports["transformer-compiler"] = factory(require("lodash"));
	else
		root["transformer-compiler"] = factory(root["lodash"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_lodash__) => {
return 