import textBlock from '@pi/text-block';
import _ from 'lodash';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, ParentContext, Expression, PrimitiveValue } from '../../types';
import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';
import type { NumericNodeT } from './NumericNode';

export interface ParsingNodeT extends BaseExpression {
    $type: 'ParsingNode';
    input: Expression | PrimitiveValue;
    data: SearchItem[];
    nameKey?: StringNodeT | NumericNodeT;
    searchKey?: StringNodeT | NumericNodeT;
    caseInsensitive?: BooleanNodeT;
    oneMatchPerItem?: BooleanNodeT;
}

type SearchItem = {
    [key in string | number]: string | string[];
};

export default class ParsingNode extends ValueNode<Required<ParsingNodeT>> {
    static TYPE = Type.Object(
        { key: 'input', type: 'StringNode', suggestions: 'string' },
        { key: 'data', type: 'ValueNode', suggestions: 'off' },
        { key: 'nameKey', type: 'StringNode', suggestions: 'off' },
        { key: 'searchKey', type: 'StringNode', suggestions: 'off' },
        { key: 'caseInsensitive', type: 'BooleanNode', suggestions: 'off' },
        { key: 'oneMatchPerItem', type: 'BooleanNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.default,
        group: 'Text',
        description: textBlock`
            Finds all matches in a given text.
            Use-case: We want to parse all allergens from a statement, and we have multiple aliases for each allergen name

            \`\`\`
            input = 'Contains milk and BRAZIL nuts'
            data = [
                { name: 'Tree Nuts', search: ['nuts', 'brazil nuts'] },
                { name: 'Milk & Dairy', search: ['milk', 'dairy'] },
            ]
            \`\`\`

            The output would be:

            \`\`\`
            ['Tree Nuts', 'Milk & Dairy']
            \`\`\`

            #### Options:

            - **input**: Text to search on
            - **data**: Table for each entry to search
            - **nameKey**: The name of each entry that will be returned when matched
            - **searchKey**: An array of all terms to search for a given entry
            - **caseInsensitive**: Ignore text casing
            - **oneMatchPerItem**: Only return a single match per entry even if multiple would work
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            data: [
                { name: 'Milk & Dairy', search: ['milk', 'dairy', 'sour cream'] },
                { name: 'Gluten', search: ['gluten', 'corn'] },
            ],
            nameKey: {
                $type: 'StringNode',
                value: 'name',
            },
            searchKey: {
                $type: 'StringNode',
                value: 'search',
            },
            caseInsensitive: this.getDefaultFor('BooleanNode'),
            oneMatchPerItem: this.getDefaultFor('BooleanNode'),
        };
    }

    validate() {
        if (this.expression.nameKey == null) return 'Missing nameKey';
        if (this.expression.searchKey == null) return 'Missing searchKey';
    }

    async execute({ sourcePath }: ParentContext) {
        const nameKey = await this.context.evaluate(this.expression.nameKey);
        const searchKey = await this.context.evaluate(this.expression.searchKey);
        const caseInsensitive = await this.context.evaluate(this.expression.caseInsensitive);
        const oneMatchPerItem = await this.context.evaluate(this.expression.oneMatchPerItem);

        let input = await this.context.evaluate(this.expression.input, { sourcePath });

        if (!input || typeof input !== 'string') return [];

        let data: SearchItem[] = await this.context.evaluate(
            this.expression.data as unknown as Expression,
            { sourcePath },
        );
        if (!Array.isArray(data)) return [];

        data = data
            .filter(item => Reflect.has(item, nameKey) && Reflect.has(item, searchKey))
            .map(item => ({
                ...item,
                [searchKey]: _.castArray(item[searchKey]).filter(
                    search => typeof search === 'string',
                ),
            }));

        if (caseInsensitive) {
            // lowercase input + data instead of passing "i" regexp
            input = input.toLocaleLowerCase();
            data.forEach(item => {
                item[searchKey] = _.uniq(
                    (item[searchKey] as string[]).map(search => search.toLowerCase()),
                );
            });
        }

        if (oneMatchPerItem) {
            data.forEach(item => {
                (item[searchKey] as string[]).sort((a: string, b: string) => b.length - a.length);
            });
        }

        const result: any[] = [];

        for (const item of data) {
            for (const pattern of item[searchKey]) {
                const reg = new RegExp(pattern);
                if (!reg.test(input)) continue;
                result.push(item[nameKey]);
                if (oneMatchPerItem) break;
            }
        }

        return result;
    }
}
