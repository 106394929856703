import _ from 'lodash';
import DefaultTypeRenderer from './DefaultTypeRenderer';
import type { TypeRendererIterateOptions } from '.';

export default class NTupleType extends DefaultTypeRenderer {
    static iterate({ shape, next, expression, path }: TypeRendererIterateOptions<'NTuple'>) {
        shape.args.forEach((argumentType, index) => {
            next({
                expression: (expression as any).value[index],
                path: [...path, 'value', index],
                parentPath: path,
                isLast: index === shape.args.length - 1,
                key: argumentType.key!,
                canMove: false,
                argumentType,
            });
        });
    }
}
