import _ from 'lodash';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { NumericNodeT } from './NumericNode';
import type { BaseExpression, Expression, PrimitiveValue } from '../../types';

export interface FlattenNodeT extends BaseExpression {
    $type: 'FlattenNode';
    input: Expression | PrimitiveValue;
    depth: NumericNodeT | PrimitiveValue;
}

export default class FlattenNode extends ValueNode<FlattenNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode', suggestions: 'array' },
        { key: 'depth', type: 'NumericNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.list,
        description: 'Converts a multi-dimensional array into a single-dimensional array',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            depth: {
                $type: 'NumericNode',
                value: 1,
            },
        };
    }

    async execute() {
        const input = await this.context.evaluate(this.expression.input);
        const depth = await this.context.evaluate(this.expression.depth);
        if (!Array.isArray(input)) return null;
        return _.flattenDepth(input, depth);
    }
}
