/* eslint-disable no-console */
let supportsPassive = false;
try {
    const opts = Object.defineProperty({}, 'passive', {
        get() {
            supportsPassive = true;
        },
    });
    // @ts-ignore
    window.addEventListener('test', null, opts);
// eslint-disable-next-line no-empty
} catch (e) { }

/**
 * Passive event listeners are non-blocking DOM listeners that can't be .preventDefault()
 * Browsers do not wait for them to complete before moving on (thus the non-blocking part)
 * They are really useful for high throughput events like onScroll or onMouseMove when you need to do some logic and you want the event handler to not jank the screen
 *
 * https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
 *
 * @return A method that will unbind the listener
 */
export function addPassiveDOMListener (
    target: HTMLElement | Window,
    event: keyof HTMLElementEventMap,
    handler: (event: Event) => void,
    capture = false,
) {
    const options = supportsPassive ? { capture, passive: true } : capture;

    if (target == null) {
        console.error(new Error('addPassiveDOMListener given null target'));
        return () => console.error('addPassiveDOMListener cannot remove listener for null target');
    }

    target.addEventListener(event, handler, options);
    return () => target.removeEventListener(event, handler, options);
}
