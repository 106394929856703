import ValueNode from '../../ValueNode';
import Type from '../../../Type';
import config from '../../../utils/config';

import type { BaseExpression, ParentContext, Expression } from '../../../types';

export interface NotEqualsNodeT extends BaseExpression {
    $type: 'NotEqualsNode';
    value: [Expression, Expression];
}

export default class NotEqualsNode extends ValueNode<NotEqualsNodeT> {
    static TYPE = Type.NTuple(
        { key: 'a', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'b', type: 'ValueNode', suggestions: 'primitive' },
    );

    static uiConfig = {
        ...config.presets.comparison,
    };

    async execute({ sourcePath }: ParentContext) {
        const source = await this.context.evaluate(this.expression.value[0], { sourcePath });
        const target = await this.context.evaluate(this.expression.value[1], { sourcePath });
        return source !== target;
    }
}
