import { iterateExpression } from '@pi/transformer-compiler';
import { useEffect, useMemo, useState } from 'react';
import {
    TaskDefinitionDocument,
    TaskDefinitionTypeEnum,
    TransformerExpressionFindDocument,
} from 'src/generated/graphql';
import apolloClient from 'src/startup/apollo';
import ensureId from 'src/utils/ensureId';

import type {
    TaskDefinition,
    TaskDefinitionQuery,
    TransformerExpression,
    TransformerExpressionFindQuery,
} from 'src/generated/graphql';
import type { BaseExpression, Expression } from '@pi/transformer-compiler';

export interface TaskTreeNode {
    /** used for cyclic refrences */
    id: string;
    task: Pick<TaskDefinition, '_id' | 'name' | 'type' | 'enabled'>;
    /** if this is a cyclic tree, reference to it's parent's TreeNode['id'] */
    cyclic?: string;
    /** whether this tasks was created in a loop (so multiple instances of it can be created) */
    many?: boolean;
    /** whether this task was created in an IF block (so it might not always be created) */
    optional?: boolean;
    /** how many times does this task show up */
    count: number;
    stage: 'action' | 'resultTransformation';
    children: TaskTreeNode[];
    dependencies: TaskTreeNode[];
}

export default function useTaskTree(root: TaskDefinition['_id']): {
    loading: boolean;
    root?: TaskTreeNode;
    nodes?: Record<string, TaskTreeNode>;
} {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<{
        root: TaskTreeNode;
        nodes: Record<string, TaskTreeNode>;
    }>();

    useEffect(() => {
        void (async () => {
            if (!root) return setLoading(false);
            setLoading(true);
            setResult(await fetchTaskTree(root));
            setLoading(false);
        })();
    }, [root]);

    return useMemo(() => ({ loading, ...result }), [loading, result]);
}

async function fetchTaskTree(root: TaskDefinition['_id']): Promise<{
    root: TaskTreeNode;
    nodes: Record<string, TaskTreeNode>;
}> {
    const nodes: Record<string, TaskTreeNode> = {};
    const taskNodeMap: Record<string, TaskTreeNode> = {};
    let idCounter = 0;
    const id = () => 'id_' + ++idCounter;

    const fetchTaskDefinition = (id: string) =>
        apolloClient
            .query<NonNullable<TaskDefinitionQuery>>({
                query: TaskDefinitionDocument,
                variables: { id },
            })
            .then(result => result.data.TaskDefinition! as TaskDefinition);

    const fetchTransformations = (ids: string[]) =>
        apolloClient
            .query<NonNullable<TransformerExpressionFindQuery>>({
                query: TransformerExpressionFindDocument,
                variables: {
                    query: {
                        _id: { $in: ids },
                    },
                },
            })
            .then(
                result =>
                    result.data.TransformerExpression_find!.results! as TransformerExpression[],
            );

    let rootNode: TaskTreeNode;

    const iterate = async (
        taskDefinitionId: string,
        parents: Record<string, TaskTreeNode>,
    ): Promise<TaskTreeNode> => {
        const existing = parents[taskDefinitionId];

        if (parents[taskDefinitionId]) {
            return {
                id: id(),
                children: [],
                stage: existing.stage,
                cyclic: existing.id,
                task: existing.task,
                count: 0,
                dependencies: [],
            };
        }

        if (taskNodeMap[taskDefinitionId]) return taskNodeMap[taskDefinitionId];

        const taskDef = await fetchTaskDefinition(taskDefinitionId);
        const node: TaskTreeNode = {
            id: id(),
            stage: 'action',
            task: taskDef,
            children: [],
            dependencies: [],
            count: 0,
        };
        rootNode ||= node;
        nodes[node.id] = node;

        const transformations = {
            action: [] as TransformerExpression[],
            resultTransformation: [] as TransformerExpression[],
        };

        taskNodeMap[node.task._id] = node;

        if (taskDef.resultTransformation) {
            transformations.resultTransformation.push(
                ...(await fetchTransformations([ensureId(taskDef.resultTransformation)])),
            );
        }

        if (taskDef.type === TaskDefinitionTypeEnum.Transformer && taskDef.config.transformation) {
            transformations.action.push(
                ...(await fetchTransformations([taskDef.config.transformation])),
            );
        }

        for (const [stage, list] of Object.tsEntries(transformations)) {
            for (const te of list) {
                for (const { dependencies, ...child } of getTransformationTreeNodes(te.value)) {
                    const itChild = await iterate(child.taskDefId, {
                        ...parents,
                        [node.task._id]: node,
                    });

                    node.children.push({
                        ...itChild,
                        ...child,
                        stage,
                        dependencies: dependencies.map(id => taskNodeMap[id]),
                    });
                }
            }
        }

        return node;
    };

    await iterate(root, {});

    return {
        root: rootNode!,
        nodes,
    };
}

function getTransformationTreeNodes(expression: Expression) {
    const nodes: Array<
        Pick<TaskTreeNode, 'many' | 'optional' | 'count'> & {
            taskDefId: string;
            dependencies: string[];
        }
    > = [];
    const parents = new Map<BaseExpression, BaseExpression[]>();
    const taskCounter: Record<string, number> = {};
    const groups: Record<string, string[]> = {};

    const isConditionalNode = (type: string) =>
        (['IfNode', 'SwitchNode'] satisfies Array<Expression['$type']>).includes(type as any);

    const isLoopNode = (type: string) =>
        (
            ['MapNode', 'EachNode', 'FilterNode', 'FunctionDefineNode'] satisfies Array<
                Expression['$type']
            >
        ).includes(type as any);

    iterateExpression({
        expression,
        mapper(node, _nodeClass, parentNode) {
            if (parentNode) {
                parents.set(node, [...parents.get(parentNode)!, parentNode]);
            } else {
                parents.set(node, []); // root
            }

            if (node.$type === 'CreateTaskNode') {
                let many = false;
                let optional = false;

                for (const { $type } of parents.get(node)!) {
                    many ||= isLoopNode($type);
                    optional ||= isConditionalNode($type);
                }

                const createNode = node as BaseExpression & { [K: string]: any };
                const taskDefId = createNode.taskDefinitionId?.value || createNode.taskDefinitionId;
                const dependencies = createNode.taskDependencies?.value;
                const groupName = createNode.taskGroup?.value;

                if (groupName) {
                    groups[groupName] = groups[groupName] || [];
                    groups[groupName].push(taskDefId);
                }

                if (taskCounter[taskDefId]) ++taskCounter[taskDefId];
                else {
                    taskCounter[taskDefId] = 1;
                    nodes.push({
                        many,
                        optional,
                        taskDefId,
                        count: 1,
                        dependencies: groups[dependencies] || [],
                    });
                }
            }

            return node;
        },
    });

    nodes.forEach(node => {
        node.count = taskCounter[node.taskDefId];
    });

    return nodes;
}
