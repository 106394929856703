import _ from 'lodash';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { Expression, BaseExpression, ParentContext } from '../../types';

export interface UnionNodeT extends BaseExpression {
    $type: 'UnionNode';
    value: Expression | Expression[];
}

export default class UnionNode extends ValueNode<UnionNodeT> {
    static TYPE = Type.RestArray({ argType: { type: 'ValueNode' } });

    static uiConfig = {
        ...config.presets.list,
        description: 'Combines arrays and values into a single array',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [this.getDefaultFor('EvaluationNode'), this.getDefaultFor('EvaluationNode')],
        };
    }

    async execute({ targetPath, sourcePath }: ParentContext = {}) {
        const expressions = _.castArray(this.expression.value) as Expression[];
        let value = this.context.get(targetPath);

        if (!Array.isArray(value)) value = [];

        for (const expression of expressions) {
            const newValue = await this.context.evaluate(expression, { sourcePath });
            if (newValue != null && newValue !== '' && !value.includes(newValue))
                value.push(newValue);
        }

        return [].concat(...value);
    }
}
