import _ from 'lodash';
import { joinPaths, getObjectPaths, normalizePath } from '@pi/path-utils';
import { Parser } from 'expr-eval';
import textBlock from '@pi/text-block';

import nodes from './nodes';
import ValueNode from './nodes/ValueNode';
import parserFunctions from './utils/parserFunctions';
import { isObject } from './utils/performanceFunctions';
import TransformerError from './utils/TransformerError';

import type { Expression, ParentContext, PrimitiveValue, InternalSuggestionsMap } from './types';
import type Transformation from './Transformation';

/** Matches strings wrapped in {} */
export const MATH_REG = /\{?\{([^}]+)\}\}?/g;

/** Matches strings wrapped in {} plus leading/trailing whitespace */
export const FULL_MATH_REG = /^\s*\{+[^}]+\}+\s*$/;
export const FULL_MATH_REPLACE_REG = /(^\{+)|(\}+$)/g;

/** Matches strings that start with $ and have alphanumeric, dots, or square brackets */
export const SUBSTITUTE_REG = /\$+(?:_*[a-z]+(?:\w+|\.|\[[^\]]+\])*)/gi;

/** Matches strings that look like a function: i.e. fnNameString(arg1, arg2) */
export const FUNC_REG = /^\w+\(([^)]*)\)$/;

type ContextOnCompleteEventHandler = {
    /**
     * If defined, only schedules a callback once for the given id
     * Useful in nodes where the operation they run does not depend on the number of nodes in the transformation
     */
    id?: string;
    /**
     * Callback that gets called once the whole expression has finished executing
     * Receives the final result (of the entire transformation):
     *  - the result object can be mutated
     *  - if the handler returns a value, that value will be used as the new result
     *  - handlers are run in the order in which they were registered, the output from one handler being passed to the next
     */
    handler: (result: any) => any;
};

export interface CacheValue {
    previewAttributeVisited: Record<string, boolean>;
    functionCalls: Record<string, number>;
    csvSheets: Record<string, { path: string; columns: string[] }>;
    taskGroups: string[];
}

const microsecondTime: () => number =
    typeof process !== 'undefined' && typeof process.hrtime !== 'undefined'
        ? () => Number(process.hrtime.bigint() / BigInt(1000))
        : // @ts-ignore
        (typeof performance as any) !== 'undefined'
        ? // @ts-ignore
          () => (performance as any).now() * 1000
        : () => 0;

// const microsecondTime = () => Date.now() * 1000;

let execCounter = 0;

export default class Context {
    result: any = {};
    variables: { [key: string]: any } = {
        INPUT: this.rootValue,
    };
    functions: { [key: string]: { name: string; args: string[]; body: Expression } } = {};
    inputLookups = new Set<string>();
    id: string;
    createdIds: Array<{ model: string; hash: string }> = [];
    private onCompleteHandlers: ContextOnCompleteEventHandler[] = [];

    constructor(public rootValue: any, public transformation: Transformation) {
        this.id = String(++execCounter);
    }

    get debug(): boolean {
        return !!this.transformation?.options?.debug;
    }

    set(path: string, value: any): Context {
        _.set(this.result, path, value);
        return this;
    }

    get(path?: string, defaultValue?: any): any {
        if (!path) return null;
        return _.get(this.result, path, defaultValue);
    }

    /**
     * Simple cache that is shared across all nodes during execution
     * It's meant for situations where multiple instances of nodes (or multiple node types) need to access the same value.
     * If a needs access to the cache, first define it in the CacheValue type
     *
     * Initially added for Pv2PreviewAttributeNode
     */
    cache: {
        value: CacheValue;
        set: <K extends keyof CacheValue>(key: K, value: CacheValue[K]) => CacheValue[K];
        get: <K extends keyof CacheValue>(key: K) => CacheValue[K];
        assign: <K extends keyof CacheValue>(key: K, prop: string, value: any) => CacheValue[K];
    } = {
        value: {
            previewAttributeVisited: {},
            functionCalls: {},
            csvSheets: {},
            taskGroups: [],
        },
        set: (key, value) => {
            this.cache.value[key] = value;
            return this.cache.get(key);
        },
        get: key => this.cache.value[key],
        assign: (key, prop, value) => {
            this.cache.value[key] = this.cache.value[key] || {};
            (this.cache.value[key] as any)[prop] = value;
            return this.cache.get(key);
        },
    };

    async variableBlock({
        path,
        variables,
        callback,
    }: {
        path: string;
        variables: { [key: string]: any };
        callback: (path: string) => Promise<any>;
    }): Promise<any> {
        let result;
        const oldVars = { ...this.variables };
        this.variables = { ...this.variables, ...variables };
        if (callback) result = await callback(path);

        for (const key of Object.keys(this.variables)) {
            /**
             * edge-case: recursively creating the same variable - e.g. via a recursive variable block
             * we want to revert those values back to their state before the block
             *
             * i.e. we want to support this use-case:
             *
             *  EACH [1, 2, 3] as item
             *      EACH [a, b] as item
             *          SET result $$item
             *      SET result $$item
             *
             * In the above example, we want "result" to be 3 (the last value in the outer loop) and not "b"
             */
            if (Reflect.has(variables, key)) {
                this.variables[key] = oldVars[key];
                /**
                 * only remove keys that were created via the variables prop
                 * allow variable updates that happened during the variable block to persist
                 * do not allow variables inside the variable block to leak outside
                 */
            } else if (!Reflect.has(oldVars, key)) delete this.variables[key];
        }

        return result;
    }

    getInternalSuggestions(): InternalSuggestionsMap {
        return {
            'internal:CsvSheetName': Object.keys(this.cache.get('csvSheets')),
            'internal:FunctionName': [
                ..._.map(this.functions, v => ({
                    key: v.name,
                    label: v.name + '(' + v.args.join(', ') + ')',
                    props: {
                        icon: 'function',
                    },
                })),
                ...Object.keys(parserFunctions)
                    .sort()
                    .map(key => ({
                        key: 'internal:' + key,
                        label: `internal:${key}()`,
                        props: {
                            icon: 'globe-network',
                        },
                    })),
            ],
            'internal:TaskGroups': this.cache
                .get('taskGroups')
                .slice()
                .sort()
                .map(name => ({
                    key: name,
                    label: name,
                })),
        };
    }

    toValue(item: any): any {
        if (item == null || item === 'n/a') return null;
        return item;
    }

    getInputValue(
        path: string,
        isOutput?: ParentContext['isOutput'],
        mapped?: ParentContext['mapped'],
    ): any {
        const value = this.toValue(_.get(this.rootValue, path));
        this.inputLookups.add(normalizePath(path));
        if (isOutput && typeof value !== 'string') {
            getObjectPaths(value).forEach(p => this.inputLookups.add(joinPaths(path, p)));
        }
        if (mapped && mapped.length > 0 && value && value.map) {
            const used = value.map((v: string) => {
                return _.flatten(mapped).includes(v) ? v : null;
            });
            getObjectPaths(used, { ignoreNull: true }).forEach(p =>
                this.inputLookups.add(joinPaths(path, p)),
            );
        }
        return value;
    }

    getVariableValue(path: string, sourcePath?: string): any {
        const value = this.toValue(_.get(this.variables, path));

        if (sourcePath) {
            this.inputLookups.add(normalizePath(sourcePath));
            const pathArr = _.toPath(path);
            if (pathArr.length > 1) {
                this.inputLookups.add(joinPaths(sourcePath, ...pathArr.slice(1)));
            }
        }

        return value;
    }

    expressionStack: { value: Expression | PrimitiveValue; meta?: string | number }[] = [];

    async evaluateLog(
        name: string | number,
        ...args: Parameters<Context['_evaluate']>
    ): Promise<any> {
        this.expressionStackPush(name);
        const result = await this.evaluate(...args);
        this.expressionStackPop();
        return result;
    }

    setLastStackEntryMeta(meta: string | number): void {
        _.last(this.expressionStack)!.meta = meta;
    }

    private expressionStackPush(expr: any, meta?: string | number) {
        this.expressionStack.push({ value: expr, meta });

        if (!this.debug) return;

        if (expr?.$type) {
            if (expr.__debug?.id === this.id) {
                expr.__debug.lastStartTime = microsecondTime();
            } else {
                Object.defineProperty(expr, '__debug', {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: {
                        id: this.id,
                        lastStartTime: microsecondTime(),
                        calls: [],
                    },
                });
            }
        }
    }

    private expressionStackPop() {
        const { value: expr } = this.expressionStack.pop()!;

        if (!this.debug) return;

        const debug = (expr as any)?.__debug;

        if (debug) {
            const endTime = microsecondTime();
            const duration = endTime - debug.lastStartTime;
            debug.calls.push(duration);
            debug.duration = (debug.duration || 0) + duration;
        }
    }

    public printDebug(): void {
        if (!this.debug) return;

        const iterate = (expr: any, depth: number, parentDuration: number): void => {
            if (expr == null || typeof expr !== 'object') return;

            if (Array.isArray(expr))
                return expr.forEach(child => iterate(child, depth + 1, parentDuration));

            let group;
            const debug = expr.__debug;
            if (debug) {
                const msTime =
                    debug.duration < 5
                        ? Math.round(debug.duration / 100) / 10
                        : Math.round(debug.duration / 1000);
                group = _.filter([
                    parentDuration > 0
                        ? debug.duration < parentDuration * 0.25
                            ? '⚪ '
                            : debug.duration < parentDuration * 0.65
                            ? '🟡 '
                            : '🔴 '
                        : '',
                    expr.$type,
                    ` — ${msTime}ms`,
                    debug.calls.length > 1 && ` | ${debug.calls.length} calls`,
                    expr.$comment && ` | ${expr.$comment.split('\n')[0]}`,
                ]).join('');
                delete expr.__debug;
                // eslint-disable-next-line no-console
                console[depth > 1 ? 'groupCollapsed' : 'group'](group);
            }

            _.forEach(expr, child => iterate(child, depth + 1, debug?.duration ?? parentDuration));

            // eslint-disable-next-line no-console
            if (group) console.groupEnd();
        };

        iterate(this.transformation.expression, 0, 0);
    }

    async evaluate(...args: Parameters<Context['_evaluate']>): Promise<any> {
        try {
            this.expressionStackPush(args[0]);
            const result = await this._evaluate(...args);
            this.expressionStackPop();

            return result;
        } catch (ex: any) {
            throw new TransformerError({ error: ex, context: this });
        }
    }

    private async _evaluate(
        expression: Expression | PrimitiveValue | null | undefined,
        parentContext?: ParentContext,
    ) {
        if (expression == null) return null;
        parentContext = parentContext || {};

        if (typeof expression === 'string') {
            if (expression[0] === '$') return this.evaluateVariable(expression, parentContext!);

            if (FULL_MATH_REG.test(expression)) {
                return this.evaluateMath(
                    expression.trim().replace(FULL_MATH_REPLACE_REG, ''),
                    parentContext!,
                );
            }

            // Handle template variables in texts
            return expression.replace(MATH_REG, (_match, name) =>
                String(this.evaluateMath(name, parentContext!)),
            );
        } else if (isObject(expression)) {
            if (!(expression as Expression).$type) return expression;
            return this.execute({ expression, parentContext });
        }

        return this.toValue(expression);
    }

    async execute({
        isRoot,
        expression,
        parentContext,
    }: {
        /**
         * Set once by the calling Transformation to denote the root execution
         */
        isRoot?: boolean;
        expression: Expression | PrimitiveValue;
        parentContext?: ParentContext;
    }): Promise<any> {
        if (isRoot) this.expressionStackPush(expression);

        const NodeType: any = nodes[(expression as Expression).$type as keyof typeof nodes];
        if (!NodeType)
            throw new Error(`Invalid expression.$type "${(expression as Expression).$type}"`);

        if (isRoot && !parentContext) {
            parentContext = { sourcePath: '' };
        }

        const node = new NodeType(expression, this);
        const nodeResult = await node.execute(parentContext || ({} as any));
        const isValue = node instanceof ValueNode;

        // Edge-case: for nodes that can be used as both value and block nodes (e.g. IfNode, Pv2AttributeNode)
        // we need to figure out which to use based on result
        // This is not ideal but works for now
        if (isRoot) {
            this.expressionStackPop();
            if (this.debug) this.printDebug();
            // prefer this.result if it has changed
            return this.complete(
                isValue ? (_.isEmpty(this.result) ? nodeResult : this.result) : this.result,
            );
        }

        if (isValue) return nodeResult;

        // don't return anything if not at the root level to prevent cyclic references
        return undefined;
    }

    /**
     * Converts "$foo" or "$$bar" to a global or local variable
     */
    evaluateVariable(str: string, parentContext: ParentContext): any {
        // handle plain strings
        return str[0] !== '$'
            ? str
            : // handle inline-variables "$$foo"
            str[1] === '$'
            ? this.getVariableValue(str.slice(2), parentContext.sourcePath)
            : // handle input references "$name"
              this.getInputValue(str.slice(1), parentContext.isOutput, parentContext.mapped);
    }

    evaluateMath(math: string | number, parentContext: ParentContext): number {
        const { text, vars } = substituteVars(String(math));

        try {
            const parserVars = _.mapValues(vars, path =>
                this.evaluateVariable(path, parentContext),
            );
            parserVars.null = null;
            return parser.evaluate(text, parserVars);
        } catch (ex: any) {
            ex.message = textBlock`
                Evaluating:
                    - raw: "${math}"
                    - converted: "${text}"

                ${ex.message}
            `;
            throw ex;
        }
    }

    /**
     * Register to run a callback when the entire transformation is finished successfully
     * Useful for nodes that want to perform some operations on the entire data at the end
     * Initially added for Pv2PreviewAttributeNode
     */
    onComplete(handler: ContextOnCompleteEventHandler): void {
        if (handler.id && this.onCompleteHandlers.find(h => h.id === handler.id)) return;
        this.onCompleteHandlers.push(handler);
    }

    /**
     * Runs the onComplete handlers on the final result before returning it
     */
    complete(result: any): any {
        for (const { handler } of this.onCompleteHandlers) {
            const handlerResult = handler(result);
            if (handlerResult !== undefined) result = handlerResult;
        }

        return result;
    }

    /**
     * When nodes need to specify an id for a resource that it will create
     */
    createId(model: string): string {
        const hash =
            '#' + model + '_id_' + (this.createdIds.filter(x => x.model === model).length + 1);
        this.createdIds.push({ model, hash });
        return hash;
    }

    isIdHash(value: string, model = '[a-zA-Z]+'): boolean {
        return !!(value && new RegExp(`^#${model}_id_\\d+$`).test(value));
    }

    addTaskerAction(data: TaskerAction): void {
        this.result.TASKER_ACTIONS = this.result.TASKER_ACTIONS || [];
        const finalData = _.pickBy(data, v => v != null && v !== '');
        if (data.kind === 'createTask') {
            if (finalData.taskDependencies) {
                finalData.taskDependencies = (this.result.TASKER_ACTIONS as TaskerAction[])
                    .filter(
                        a => a.kind === 'createTask' && a.taskGroup === finalData.taskDependencies,
                    )
                    .map(a => a.localId);
            }
        }
        this.result.TASKER_ACTIONS.push(finalData);
    }

    addAttribute(attribute: { type: string; [key: string]: any }): {
        [p: string]: any;
        type: string;
    } {
        if (typeof attribute?.type !== 'string') {
            throw new Error('Attribute must have string "type" field');
        }

        if (!Array.isArray(this.result.attributes)) this.result.attributes = [];

        this.result.attributes.push(attribute);

        return attribute;
    }
}

interface TaskerAction {
    kind: 'createPv2' | 'updatePv2' | 'createTask';
    [K: string]: any;
}

/**
 * The math parsing library we use is pretty strict with variable names, so we're replacing all var paths
 * with safe names for evaluation
 *
 * @example
 *  substituteVars('$test + $$foo12 + $test.subtest.1 - $$foo['bar'].10 / 10')
 *  result = {
 *      text: 'var_1 + var_2 + var_3 - var_4 / 10',
 *      vars: {
 *          var_1: '$test',
 *          var_2: '$$foo12',
 *          var_3: '$test.subtest.1',
 *          var_4: "$$foo['bar'].10"
 *      }
 *  }
 */
const substituteVars = (math: string) => {
    const vars: { [key: string]: string } = {};
    let counter = 0;
    const text = math.replace(SUBSTITUTE_REG, name => {
        const varName = `var_${++counter}`;
        vars[varName] = name;
        return varName;
    });
    return { text, vars };
};

const parser = new Parser({
    operators: {
        ceil: false,
        floor: false,
        max: false,
        min: false,
        round: false,
    },
});

Object.assign(parser.functions, parserFunctions);
