import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, UiConfig } from 'src/types';

export interface NumericNodeT extends BaseExpression {
    $type: 'NumericNode';
    value: number;
}

export default class NumericNode extends ValueNode<NumericNodeT> {
    static TYPE = Type.Primitive({
        key: 'value',
        suggestions: 'number',
    });

    static uiConfig: UiConfig = {
        ...config.presets.primitive,
        description: 'Evaluates to a number',
    };

    validate() {
        const { value } = this.expression;
        if (typeof value !== 'number' && value !== null) return 'value must be a number or null';
    }

    static getDefault() {
        return {
            ...super.getDefault(),
            value: 1,
        };
    }

    async execute() {
        return this.expression.value;
    }
}
