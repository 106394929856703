// eslint-disable-next-line no-restricted-imports
import { setup } from '@pi/api-client';
import memoizeOne from 'memoize-one';
import { getEnvVariables, type EnvVars } from 'src/utils/envConfig';

function setupApiClient(jwt: string, name: string, env: EnvVars = getEnvVariables()) {
    setup({
        apiLegacyToken: jwt,
        apiLegacyUrl: env.apiLegacyUrl,
        apiV2Token: jwt,
        apiV2Url: env.apiV2Url,
        tracking: {
            type: 'browser',
            name,
            host: location.hostname,
        },
    });
}

export default memoizeOne(setupApiClient);
