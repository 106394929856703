import SimpleLruCache, { SimpleLruCacheOptions } from 'simple-lru-cache';

export interface LruCacheOptions extends SimpleLruCacheOptions {
    hitOnGet?: boolean;
}

export default class LruCache extends SimpleLruCache {
    options: Required<LruCacheOptions>;

    /**
     * Creates an equivalent to lodash _.memoize but using the LruCache under the hood
     */
    static memoize = <T extends any[], R>(
        create: (...args: T) => R,
        hash: (...args: T) => string,
        options?: LruCacheOptions,
    ) => {
        const cache = new LruCache(options);

        const result = (...args: T): R => {
            const key = hash(...args);
            return cache.getOrCreate(key, () => create(...args));
        };

        result.cache = cache;

        return result;
    }

    constructor(options?: LruCacheOptions) {
        options = {
            maxSize: 10e3,
            hitOnGet: true,
            ...options,
        };

        super(options);

        this.options = options as Required<LruCacheOptions>;
    }

    has(key: string) { return Reflect.has(this.cache, key); }

    get(key: string) { return super.get(key, this.options.hitOnGet); }

    getOrCreate(key: string, create: () => any) {
        if (!this.has(key)) this.set(key, create(), true);

        return this.get(key);
    }
}
