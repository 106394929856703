import textBlock from '@pi/text-block';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Node from '../Node';
import Types from '../../Type';

import type { StringNodeT } from '../value/StringNode';
import type { Expression, BaseExpression, ParentContext } from 'src/types';

export interface Pv2RemoveAttributesNodeT extends BaseExpression {
    $type: 'Pv2RemoveAttributesNode';
    value: StringNodeT[];
}

export default class Pv2RemoveAttributesNode extends Node<Pv2RemoveAttributesNodeT> {
    static OUTPUT_PATH = '__pv2RemoveAttributes';

    static TYPE = Types.RestArray({
        argType: { type: 'ValueNode' },
        defaultType: { type: 'ValueNode', suggestions: 'off' },
    });

    static uiConfig = {
        ...config.presets.pv2,
        style: config.styles.danger,
        description: textBlock`
            Remove all Pv2 attributes that match the given selectors

            This should always be the first expression at the top level and not nested under any conditional block
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [ValueNode.getDefaultFor('StringNode'), ValueNode.getDefaultFor('StringNode')],
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const result: string[] = [];
        for (const expr of this.expression.value) {
            result.push(await this.context.evaluate(expr, { sourcePath }));
        }

        this.context.onComplete({
            id: this.constructor.name,
            handler: result => {
                result[Pv2RemoveAttributesNode.OUTPUT_PATH].sort();
            },
        });

        await this.context.evaluate(
            {
                ...Node.getDefaultFor('SetNode'),
                path: Primitive.string(Pv2RemoveAttributesNode.OUTPUT_PATH),
                value: {
                    ...Node.getDefaultFor('AddToSetNode'),
                    value: result.filter(x => x).flat(),
                },
            } as Expression,
            { sourcePath },
        );
    }
}
