import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import Type from 'src/Type';
import config from 'src/utils/config';

import Node from '../Node';

import type { BaseExpression, ParentContext, ValueExpression } from 'src/types';
import type { StringNodeT } from '../value/StringNode';

export interface VariableNodeT extends BaseExpression {
    $type: 'VariableNode';
    name: StringNodeT;
    value: ValueExpression;
}

export default class VariableNode extends Node<VariableNodeT> {
    static TYPE = Type.Object(
        { key: 'name', label: 'Name', type: 'StringNode', suggestions: 'off' },
        { key: 'value', label: 'Value', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.default,
        style: {
            ...config.presets.default.style,
            background: '#DB2C6F',
            color: 'white',
        },
        description: textBlock`
            Define a variable to be available globally via \`"$$name"\`

            ❗ You have to watch out for variable clashes with nodes that also create variables (like Each Node)

            I recomment prefixing all variables with \`var_\` to avoid name clashes
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            name: Primitive.string('var_my_var_name'),
            value: Primitive.evaluation('2 + 2 - 1'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'name')) return 'missing "name"';
        if (!Reflect.has(this.expression, 'value')) return 'missing "value"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const name = await this.context.evaluate(this.expression.name);
        this.context.setLastStackEntryMeta(name);
        const value = await this.context.evaluate(this.expression.value, { sourcePath });

        this.context.variables[name] = value;
    }
}
