import { Callout } from '@blueprintjs/core';
import Ajv from 'ajv';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AjvError, getSchemaPath } from './AjvError';
import ErrorBox from './ErrorBox';
import { type MakeFieldProps, useOrGetField } from './Form';
import {
    MonacoEditor,
    getJsonHighlightStart,
    type MonacoEditorProps,
    type MonacoHighlight,
} from './MonacoEditor';

import type { editor } from 'monaco-editor';
import type { ErrorObject } from 'ajv';

export interface JsonEditorProps extends Omit<MonacoEditorProps, 'value' | 'onChange' | 'onBlur'> {
    error?: string;
    value: Record<string, any> | any[];
    onChange?: (value: any) => void;
    validate?: (value: any) => string | undefined | null;
    jsonSchema?: Record<string, any> | null | undefined;
    onError?: (valid: boolean) => void;
    monacoStyle?: React.CSSProperties;
    readOnly?: boolean;
    minimal?: boolean;
}

type ErrorFormat =
    | { type: 'string'; value: string }
    | { type: 'jsonSchema'; value: ErrorObject[] }
    | null;

export const JsonEditor: React.FC<JsonEditorProps> = ({
    style,
    className,
    error: propsError,
    value,
    onChange,
    validate,
    jsonSchema,
    onError,
    readOnly,
    minimal,
    monacoStyle,
    ...props
}) => {
    const [error, _setError] = useState<ErrorFormat>(null);
    const [valueText, setValueText] = useState('');
    const setError = useCallback(
        (err: ErrorFormat | null) => {
            _setError(err);
            onError?.(!!err?.value);
        },
        [onError],
    );

    const onBlur = useCallback(
        (newValue: string) => {
            let json: any;
            try {
                json = JSON.parse(newValue);
            } catch (ex) {
                setError({
                    type: 'string',
                    value: 'Invalid JSON syntax. Check red error markers in editor',
                });
                return;
            }

            setValueText(JSON.stringify(json, null, 4));

            if (jsonSchema) {
                const ajv = new Ajv();
                const validator = ajv.compile(jsonSchema);
                if (!validator(json)) {
                    setError({ type: 'jsonSchema', value: validator.errors! });
                    return;
                }
            }

            if (validate) {
                const validationError = validate(newValue);
                if (validationError) {
                    setError({ type: 'string', value: validationError });
                    return;
                }
            }

            onChange?.(json);
            setError(null);
        },
        [jsonSchema, validate, onChange, setError],
    );

    useEffect(() => setValueText(JSON.stringify(value, null, 4)), [value]);

    const highlights = useMemo<MonacoHighlight[]>(() => {
        if (error?.type !== 'jsonSchema') return [];

        const errorHighlights = error.value
            .map(({ instancePath }) => {
                const start = getJsonHighlightStart(value, getSchemaPath(instancePath));

                if (start == null) return null as any;

                return {
                    style: 'red',
                    start,
                    end: start,
                    line: true,
                    margin: true,
                } as const;
            })
            .filter(x => x);

        return [...errorHighlights, ...(props.highlights || [])];
    }, [error?.type, error?.value, props.highlights, value]);

    const options = useMemo(
        (): editor.IStandaloneEditorConstructionOptions => ({
            formatOnPaste: true,
            readOnly,
            ...(minimal
                ? {
                      lineNumbers: 'off',
                      glyphMargin: false,
                      lineDecorationsWidth: 0,
                      lineNumbersMinChars: 0,
                  }
                : {}),
            ...props.options,
        }),
        [props.options, readOnly, minimal],
    );

    return (
        <div style={style} className={className}>
            {propsError && <ErrorBox error={propsError} />}
            {error?.type === 'string' && <ErrorBox error={error.value} />}
            {error?.type === 'jsonSchema' && (
                <Callout intent='danger'>
                    {error.value.map((error, index) => (
                        <AjvError key={index} error={error} />
                    ))}
                </Callout>
            )}
            <MonacoEditor
                minHeight={400}
                maxHeight={800}
                {...props}
                options={options}
                value={valueText}
                language='json'
                onBlur={onBlur}
                highlights={highlights}
                style={monacoStyle}
            />
        </div>
    );
};

export interface JsonEditorFieldProps extends MakeFieldProps<JsonEditorProps> {}

export const JsonEditorField: React.FC<JsonEditorFieldProps> = observer(({ field, ...props }) => {
    const f = useOrGetField(field);

    return (
        <JsonEditor
            {...props}
            value={f.value == null ? {} : (toJS(f.value) as any)}
            onChange={f.onChange}
        />
    );
});
