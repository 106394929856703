import Primitive from 'src/utils/Primitive';

import Node from '../Node';
import Type from '../../Type';
import config from '../../utils/config';

import type { StringNodeT } from '../value/StringNode';
import type { BooleanNodeT } from '../value/BooleanNode';
import type { BaseExpression, Expression, ParentContext, PrimitiveValue } from 'src/types';

export interface SetNodeT extends BaseExpression {
    $type: 'SetNode';
    path: StringNodeT;
    ifNotNull?: BooleanNodeT;
    value: Expression | Expression[] | PrimitiveValue;
}

export default class SetNode extends Node<SetNodeT> {
    static TYPE = Type.Object(
        { key: 'path', type: 'StringNode', suggestions: 'primitive' },
        {
            key: 'ifNotNull',
            type: 'BooleanNode',
            suggestions: 'off',
            label: 'If exists',
        },
        { key: 'value', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.block,
        description: 'Assigns to the output object the value "value" at "path"',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            path: super.getDefaultFor('StringNode'),
            ifNotNull: Primitive.boolean(false),
            value: this.getDefaultFor('EvaluationNode'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'path')) return 'missing "path"';
        if (!Reflect.has(this.expression, 'value')) return 'missing "value"';
    }

    async execute({ sourcePath }: ParentContext) {
        const targetPath = await this.context.evaluate(this.expression.path, { sourcePath });
        const ifNotNull =
            this.expression.ifNotNull && (await this.context.evaluate(this.expression.ifNotNull));
        const value = await this.context.evaluate(this.expression.value as Expression, {
            targetPath,
            sourcePath,
            isOutput: true,
        });
        if (!ifNotNull || value != null) {
            this.context.set(
                await this.context.evaluate(targetPath, { targetPath, sourcePath }),
                value,
            );
        }
    }
}
