const imported_stylus_components = require('.cache/react-style-loader/src/components/MonacoEditor.styl');
import React from 'react';
import textBlock from '@pi/text-block';
import Editor from '@monaco-editor/react';
import _ from 'lodash';
import memoizeOne from 'memoize-one';

import { UiContext } from './UiContext';

import type { EditorProps, Monaco, OnChange, OnMount } from '@monaco-editor/react';
import type { editor, IDisposable } from 'monaco-editor';
import type { Field } from './Form';

const DEFAULT_OPTIONS: Partial<editor.IEditorOptions> = {
    scrollBeyondLastLine: false,
    // wordWrap: 'on',
    minimap: { enabled: false },
    suggest: {
        showKeywords: false,
        showModules: false,
        showVariables: false,
    },
    // quickSuggestions: {
    //     other: false,
    // },
};

export type MonacoEditorProps = EditorProps & {
    onBlur?: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent, value: string | undefined) => void;
    style?: React.CSSProperties;
    minHeight?: number;
    maxHeight?: number;
    /**
     * A list of variables that are available in the editor for autocomplete suggestions
     */
    suggestionVariables?: string | Record<string, MonacoSuggestionVariable>;
    highlights?: Array<MonacoHighlight>;
};

export interface MonacoHighlight {
    style: 'green' | 'red' | 'blue';
    start: number;
    end: number;
    margin?: boolean;
    line?: boolean;
}

export interface MonacoSuggestionVariable {
    comment?: string;
    value: NonNullable<any>;
}

export class MonacoEditor extends React.PureComponent<MonacoEditorProps> {
    static contextType = UiContext;
    context!: React.ContextType<typeof UiContext>;
    editor!: editor.IStandaloneCodeEditor;
    monaco!: any;
    lastValue?: string;
    lastChangeValue?: string;
    cleanupSuggestions?: () => any;
    lastDecorators: any[] = [];
    isUnmounted = false;

    static defaultProps = {
        minHeight: 48,
        maxHeight: 480,
    };

    state = {
        height: this.props.minHeight || 48,
    };

    componentDidUpdate() {
        if (this.isUnmounted) return;
        const { highlights } = this.props;
        const { editor, monaco } = this;

        if (!editor || !monaco) return;

        if (!highlights?.length) {
            if (this.lastDecorators?.length) {
                this.lastDecorators = editor.deltaDecorations(this.lastDecorators, []);
            }
            return;
        }

        const newDecorators = highlights.map(({ start, end, style, margin, line }) => ({
            range: new monaco.Range(start, 1, end, 1),
            options: {
                isWholeLine: line,
                className: line ? 'monaco-decorator-' + style : undefined,
                marginClassName: margin ? 'monaco-decorator-' + style : undefined,
            },
        }));

        this.lastDecorators = editor.deltaDecorations(this.lastDecorators, newDecorators);
    }

    handleChange: OnChange = (value, ev) => {
        if (this.isUnmounted) return;
        this.lastValue = value;
        this.props.onChange?.(value, ev);
    };

    handleMount: OnMount = (editor, monaco) => {
        const { onMount, onKeyDown, onBlur, height, minHeight, maxHeight, suggestionVariables } =
            this.props;

        this.editor = editor;
        this.monaco = monaco;

        onMount?.(editor, monaco);

        // enable auto-resize if a fixed height is not provided
        if (!height && minHeight && maxHeight) {
            const updateHeight = () => {
                const contentHeight = _.clamp(editor.getContentHeight(), minHeight, maxHeight);
                const container = editor.getContainerDomNode();
                editor.layout({ height: contentHeight, width: container.offsetWidth });
                this.setState({ height });
            };
            editor.onDidContentSizeChange(updateHeight);
            updateHeight();
        } else this.setState({ height: this.props.height });

        this.updateEvents(this.editor, onKeyDown, onBlur);

        addMonacoSuggestions(monaco);

        if (suggestionVariables) {
            const serialize = (value: any) =>
                JSON.stringify(value, (_k, v) => (typeof v === 'function' ? v.toString() : v));

            const content =
                typeof suggestionVariables === 'string'
                    ? suggestionVariables
                    : _.map(
                          suggestionVariables,
                          ({ comment, value }, name) =>
                              textBlock`
                                /** ${comment} */
                                const ${name} = ${serialize(value)};
                            `,
                      ).join('\n');
            const cleanJS = monaco.languages.typescript.javascriptDefaults.addExtraLib(content);
            // const cleanTS = monaco.languages.typescript.typescriptDefaults.addExtraLib(content);
            this.cleanupSuggestions = () => {
                cleanJS.dispose();
                // cleanTS.dispose();
            };
        }
    };

    componentWillUnmount() {
        this.cleanupSuggestions?.();
        this.isUnmounted = true;
    }

    _onBlurDisposable?: IDisposable;
    _onKeyDownDisposable?: IDisposable;

    updateEvents = memoizeOne(
        (editor: editor.IStandaloneCodeEditor, onKeyDown: any, onBlur: any) => {
            this._onBlurDisposable?.dispose();
            this._onKeyDownDisposable?.dispose();

            this._onKeyDownDisposable = editor?.onKeyDown((e: { browserEvent: KeyboardEvent }) => {
                const event = e.browserEvent;

                if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    onBlur?.(
                        this.lastValue === undefined
                            ? this.props.value || ''
                            : this.lastValue || '',
                    );
                    return;
                }

                onKeyDown?.(event, this.lastValue);
            });

            if (onBlur) {
                this._onBlurDisposable = editor?.onDidBlurEditorText(() =>
                    onBlur(
                        this.lastValue == null
                            ? this.props.value == null
                                ? this.props.defaultValue || ''
                                : this.props.value
                            : this.lastValue,
                    ),
                );
            }
        },
    );

    render() {
        const { className, style, options, onBlur, onKeyDown, ...props } = this.props;
        const { height } = this.state;
        const { darkMode } = this.context;

        // why is this needed: https://github.com/suren-atoyan/monaco-react/issues/114
        if ('value' in props) {
            if (props.value == null) props.value = '';
            else props.value = String(props.value);
        }

        this.updateEvents(this.editor, onKeyDown, onBlur);

        if (this.isUnmounted) return null;

        return (
            <Root className={className} style={{ ...style, height }}>
                <Editor
                    defaultLanguage='json'
                    options={options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS}
                    height={height /* must be before ...props */}
                    theme={darkMode ? 'vs-dark' : undefined}
                    {...props}
                    onChange={this.handleChange}
                    onMount={this.handleMount}
                />
            </Root>
        );
    }
}

const addMonacoSuggestions = _.once((monaco: Monaco) => {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        noLib: true,
        allowNonTsExtensions: true,
    });
});

export interface MonacoEditorFieldProps
    extends Omit<MonacoEditorProps, 'value' | 'defaultValue' | 'onChange' | 'onBlur'> {
    field: Field<string>;
    changeOnBlur?: boolean;
}

export const MonacoEditorField: React.FC<MonacoEditorFieldProps> = ({
    field,
    changeOnBlur,
    ...props
}) => (
    <MonacoEditor
        minHeight={160}
        maxHeight={500}
        {...props}
        value={field.value == null ? '' : field.value}
        defaultValue={field.value == null ? '' : field.value}
        onChange={changeOnBlur ? undefined : field.onChange}
        onBlur={changeOnBlur ? field.onChange : undefined}
    />
);

/**
 * Assumes the JSON is encoded with JSON.stringify(json, null, 4)
 */
export function getJsonHighlightStart(json: Record<string, any>, pathStr: string): number | null {
    const path = _.toPath(pathStr);
    if (!path.length) return null;

    json = JSON.parse(JSON.stringify(json));
    const tag = '>🥞>TAG<🥞<';
    _.set(json, path, tag);
    const index = JSON.stringify(json, null, 4)
        .split('\n')
        .findIndex(x => x.includes(tag));

    if (index === -1) return null;

    return index + 1;
}

const Root = imported_stylus_components.Root;