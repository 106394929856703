import React, { useEffect, useRef } from 'react';
import { Spinner } from '@blueprintjs/core';
import { DateInput2 } from '@blueprintjs/datetime2';
import { observer } from 'mobx-react';
import { type MakeFieldProps, useOrGetField } from 'src/components/Form';
import _ from 'lodash';

import type { DateInput2Props } from '@blueprintjs/datetime2';

export interface DateInputProps<Value = string>
    extends Omit<DateInput2Props, 'onChange' | 'onBlur' | 'value' | 'defaultValue'> {
    value?: Value | null;
    defaultValue?: Value | null;
    onChange: (value: Value) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    format?: (value: Value | null | undefined) => string;
    parse?: (str: string) => Value;
    multiline?: boolean;
    changeOnBlur?: boolean;
    changeOnEnter?: boolean;
    minLength?: number;
    maxLength?: number;
    placeholder?: string;
    error?: string;
    loading?: boolean;
    style?: React.CSSProperties;
}

/**
 * Extension of @blueprint InputGroup and TextArea and switches between the two based on if multiline=true
 * Adds a couple of extra commonly-used features like changeOnBlur and disabling autocomplete by default
 */
export function DateInput<Value = string>(props: DateInputProps<Value>) {
    const { onChange, changeOnBlur, loading, format, parse, ...restProps } = props;
    const valueParser = parse || (_.identity as unknown as NonNullable<typeof parse>);
    const valueFormatter = format || (_.identity as unknown as NonNullable<typeof format>);
    const changeValue = (v: any) => onChange?.(valueParser(v));
    const inputRef = useRef<HTMLInputElement>();
    const prevValue = useRef<any>();

    const baseProps: DateInputProps<any> = {
        ...(restProps as any),
    };

    if (changeOnBlur) {
        baseProps.defaultValue = baseProps.value;
        delete baseProps.value;
        baseProps.onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value !== props.defaultValue) changeValue(event);
            if (props.onBlur) props.onBlur(event);
        };
    } else {
        Object.assign(baseProps, {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => changeValue(event),
        });
    }

    if (Reflect.has(baseProps, 'value')) baseProps.value = valueFormatter(baseProps.value);
    if (Reflect.has(baseProps, 'defaultValue')) {
        baseProps.defaultValue = valueFormatter(baseProps.defaultValue);
    }

    useEffect(() => {
        if (!changeOnBlur || !inputRef.current) return;
        if (baseProps.defaultValue === prevValue.current) return;
        inputRef.current.value = baseProps.defaultValue;
        prevValue.current = baseProps.defaultValue;
    }, [changeOnBlur, baseProps.defaultValue]);

    if (loading) {
        baseProps.rightElement = <Spinner size={16} />;
    }

    return <DateInput2 {...(baseProps as any)} inputRef={inputRef} />;
}
export interface DateInputFieldProps<Value = string>
    extends MakeFieldProps<DateInputProps<Value>> {}

export const DateInputField = observer(function DateInputField<Value = string>({
    field,
    ...props
}: DateInputFieldProps<Value>) {
    const f = useOrGetField(field);
    return <DateInput<Value> {...props} {...f.props} />;
});
