import { useEffect, useMemo } from 'react';

import { FormContext } from './FormContext';
import { FormStore } from './FormStore';

import type { FormProps } from './types';

/**
 * Main form component that provides the react context for all other fields
 *
 * @example
 *  import { Form, useField } from '@pi/ui';
 *
 *  const MyComponent = () =>
 *      <Form
 *          initialValue={{ foo: 'bar', nested: { baz: 123 } }}
 *          onSubmit={values => console.log(values)}
 *          container
 *      >
 *          <SomeInput name='foo' />
 *          <SomeInput name='nested.baz' />
 *          <button type='submit'>submit</button>
 *      </Form>
 *
 *  const SomeInput: React.FC<{ name: string }> = observer(({ name }) => {
 *      const field = useField(name);
 *
 *      return <input type='text' {...field.props} />
 *  })
 */
export function Form<
    Value extends Record<string, any>,
    ValidValue extends Record<string, any> = Value,
>({ children, container, style, className, ...options }: FormProps<Value, ValidValue>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const form = useMemo(() => new FormStore<Value, ValidValue>(options), [options?.initialValue]);

    useEffect(() => {
        form.options.onSubmit = options.onSubmit;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.onSubmit]);

    useEffect(() => {
        form.directUpdate(() => {
            form.isContainer = !!container;
        });
    }, [form, container]);

    const content = <FormContext.Provider value={{ form: form as any }} children={children} />;

    if (container) {
        const Tag: any = container === true ? 'form' : container;
        const props: any = { style, className };
        if (Tag === 'form') {
            props.onSubmit = (e: any) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit();
            };
        }
        return <Tag {...props}>{content}</Tag>;
    }

    return content;
}
