import textBlock from '@pi/text-block';
import _ from 'lodash';
import iterateExpressionItems from 'src/utils/iterateExpressionItems';
import Primitive from 'src/utils/Primitive';
import ellipsis from 'src/utils/ellipsis';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';
import type { BaseExpression, Expression, PrimitiveValue } from 'src/types';

export interface MapNodeT extends BaseExpression {
    $type: 'MapNode';
    input: Expression | PrimitiveValue | any[] | Record<string, any>;
    as: StringNodeT;
    flatten: BooleanNodeT;
    output: Expression;
}

/**
 * Usage:
    input = { list: ['a', 'b', 'c'] }
    {
        $each: {
            input: '$list',
            as: 'item',
            flatten: true,
            output: {
                myFilteredList: {
                    $push: '$$item',
                },
            },
        },
    }
 */
export default class MapNode extends ValueNode<MapNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode', suggestions: 'array' },
        { key: 'as', type: 'StringNode', suggestions: 'off' },
        { key: 'flatten', type: 'BooleanNode', suggestions: 'off' },
        { key: 'output', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            Returns "output" for each item in "input".

            Inside "output" the value of each iterable item is put in a variable defined by "as" and can be referenced by using the value of "as" with two dollar signs proceeding it (e.g. $$item)
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            flatten: Primitive.boolean(false),
            as: Primitive.string('item'),
            output: this.getDefaultFor('EvaluationNode'),
        };
    }

    async execute() {
        const itemName = await this.context.evaluate(this.expression.as);
        const flatten = await this.context.evaluate(this.expression.flatten);

        const result: any[] = [];

        await iterateExpressionItems({
            items: this.expression.input,
            itemName,
            context: this.context,
            callback: async ({ sourcePath, key, value }) => {
                this.context.setLastStackEntryMeta(
                    `index: ${key}, ${itemName}: ${ellipsis(20, value)}`,
                );
                const output = await this.context.evaluate(this.expression.output, { sourcePath });
                if (output) result.push(output);
            },
        });

        return flatten ? _.flatten(result) : result;
    }
}
