import _ from 'lodash';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, Expression } from 'src/types';

export interface PushNodeT extends BaseExpression {
    $type: 'PushNode';
    target: string;
    value: Expression;
}

export default class PushNode extends ValueNode<PushNodeT> {
    static TYPE = Type.Object(
        { key: 'target', label: 'Target', type: 'EvaluationNode' },
        { key: 'value', label: 'Value', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.list,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            target: Primitive.string(''),
            value: Primitive.evaluation(''),
        };
    }

    async execute() {
        const value = await this.context.evaluateLog('value', this.expression.value);
        if (value == null) return;

        let target: any[] = await this.context.evaluateLog('target', this.expression.target);
        if (!Array.isArray(target)) target = [];

        for (const entry of _.castArray(value)) {
            if (entry == null) continue;
            target.push(entry);
        }

        return target;
    }
}
