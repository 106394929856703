export { default as Node } from './Node';
export { default as ValueNode } from './ValueNode';

import AddToSetNode from './value/AddToSetNode';
import AllNode from './container/AllNode';
import AndNode from './value/AndNode';
import ArrayNode from './value/ArrayNode';
import BooleanNode from './value/BooleanNode';
import ConcatNode from './value/ConcatNode';
import CreateHITNode from './value/CreateHITNode';
import CreatePv2Node from './container/CreatePv2Node';
import CreateTaskNode from './value/CreateTaskNode';
import CsvRowNode from './container/CsvRowNode';
import CsvSheetNode from './container/CsvSheetNode';
import DocumentNode from './value/DocumentNode';
import EachNode from './container/EachNode';
import EqualsNode from './value/comparison/EqualsNode';
import EvaluationNode from './value/EvaluationNode';
import FilterNode from './value/FilterNode';
import FindNode from './value/FindNode';
import FlattenNode from './value/FlattenNode';
import FormulaResourceNode from './value/FormulaResourceNode';
import FunctionCallNode from './value/FunctionCallNode';
import FunctionDefineNode from './container/FunctionDefineNode';
import GetNode from './value/GetNode';
import GreaterThanEqualsNode from './value/comparison/GreaterThanEqualsNode';
import GreaterThanNode from './value/comparison/GreaterThanNode';
import IfNode from './container/IfNode';
import JoinNode from './value/JoinNode';
import JSONNode from './value/JSONNode';
import LessThanEqualsNode from './value/comparison/LessThanEqualsNode';
import LessThanNode from './value/comparison/LessThanNode';
import MapNode from './value/MapNode';
import MappingNode from './value/MappingNode';
import MathNode from './value/MathNode';
import MaxNode from './value/comparison/MaxNode';
import MinNode from './value/comparison/MinNode';
import NotEqualsNode from './value/comparison/NotEqualsNode';
import NumericNode from './value/NumericNode';
import ObjectNode from './value/ObjectNode';
import OrNode from './value/OrNode';
import OutputNode from './container/OutputNode';
import ParsingNode from './value/ParsingNode';
import PushNode from './value/PushNode';
import Pv2AttributeMatcherNode from './value/Pv2AttributeMatcherNode';
import Pv2AttributeNode from './value/Pv2AttributeNode';
import Pv2AttributeSchemaNode from './value/Pv2AttributeSchemaNode';
import Pv2PreviewAttributeNode from './container/Pv2PreviewAttributeNode';
import Pv2RemoveAttributesNode from './container/Pv2RemoveAttributesNode';
import RegexNode from './value/RegexNode';
import ResourceNode from './value/ResourceNode';
import SetNode from './container/SetNode';
import StopProcessingNode from './value/StopProcessingNode';
import StringNode from './value/StringNode';
import SwitchNode from './value/SwitchNode';
import TableNode from './value/TableNode';
import TaskIdNode from './value/TaskIdNode';
import UnionNode from './value/UnionNode';
import UpdatePv2Node from './container/UpdatePv2Node';
import ValueBlockNode from './value/ValueBlockNode';
import VariableNode from './container/VariableNode';

// NOTE: removing this generic type annotation will cause TS to be really slow
export const nodes: Record<string, any> = {
    AddToSetNode,
    AllNode,
    AndNode,
    ArrayNode,
    BooleanNode,
    ConcatNode,
    CreateHITNode,
    CreatePv2Node,
    CreateTaskNode,
    CsvRowNode,
    CsvSheetNode,
    DocumentNode,
    EachNode,
    EqualsNode,
    EvaluationNode,
    FilterNode,
    FindNode,
    FlattenNode,
    FormulaResourceNode,
    FunctionCallNode,
    FunctionDefineNode,
    GetNode,
    GreaterThanEqualsNode,
    GreaterThanNode,
    IfNode,
    JoinNode,
    JSONNode,
    LessThanEqualsNode,
    LessThanNode,
    MapNode,
    MappingNode,
    MathNode,
    MaxNode,
    MinNode,
    NotEqualsNode,
    NumericNode,
    ObjectNode,
    OrNode,
    OutputNode,
    ParsingNode,
    PushNode,
    Pv2AttributeMatcherNode,
    Pv2AttributeNode,
    Pv2AttributeSchemaNode,
    Pv2PreviewAttributeNode,
    Pv2RemoveAttributesNode,
    RegexNode,
    ResourceNode,
    SetNode,
    StopProcessingNode,
    StringNode,
    SwitchNode,
    TableNode,
    TaskIdNode,
    UnionNode,
    UpdatePv2Node,
    ValueBlockNode,
    VariableNode,
} as const;

for (const [name, node] of Object.entries(nodes)) {
    if (!node) throw new Error(`Node "${name}" does not have an export`);

    if (!node.TYPE) throw new Error(`Node "${name}" missing static TYPE`);

    if (!node.uiConfig) throw new Error(`Node "${name}" missing static uiConfig`);

    // if (!Node.isPrototypeOf(node) && !ValueNode.isPrototypeOf(node)) {
    //     throw new Error(`Node "${name}" is not an Node`);
    // }
}

export default nodes;
