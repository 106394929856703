import React, { useState } from 'react';
import { JSONCoverage } from '@pi/ui';
import { Button, ButtonGroup, Callout } from '@blueprintjs/core';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { Field, Fields } from '@pi/react-form';
import { ScrollContent } from './Layout';
import ExecuteError from './ExecuteError';
import { JsonEditorField, NumericInputField, TextInputField } from './LegacyReactForm';
import { Tooltip2 } from '@blueprintjs/popover2';
import getDefaultEditorProps from 'src/utils/getDefaultEditorProps';

const NO_FILTER = '';

const InputField: React.FC = () => {
    const [useOldView, setUseOldView] = useState(false);

    if (useOldView) return <OldInputField toggleView={() => setUseOldView(false)} />;

    return <>
        {/* <Actions
            list={[
                {
                    label: 'Use coverage view',
                    content: <Button
                        icon='mugshot'
                        small
                        onClick={() => setUseOldView(true)}
                    />
                }
            ]}
        /> */}
        <Field
            name='data'
            component={JsonEditorField}
            {...getDefaultEditorProps()}
        />
    </>
}

export default InputField;

class OldInputField extends React.PureComponent<{ toggleView: () => void }> {
    render() {
        const actions = _.filter([
            {
                label: 'Use text editor view',
                content: <Button
                    icon='mugshot'
                    onClick={() => this.props.toggleView()}
                />
            },
            {
                label: 'Toggle edit mode',
                content: <Field
                    name='inputConfig.edit'
                    component={({ input }) =>
                        <Button
                            intent={input.value ? 'primary' : 'none'}
                            icon='edit'
                            onClick={() => input.onChange(!input.value)}
                        />
                    }
                />,
            },
            {
                label: 'Sort keys ascending',
                content: <Field
                    name='inputConfig.sortKeys'
                    component={({ input }) =>
                        <Button
                            intent={input.value ? 'primary': 'none'}
                            icon='sort-alphabetical'
                            onClick={() => input.onChange(!input.value)}
                        />
                    }
                />,
            },
            {
                label: 'Filter keys',
                content: <Field
                    name='inputConfig.filter'
                    component={({ input }) =>
                        <Button
                            intent={input.value == NO_FILTER ? 'none' : 'primary'}
                            icon='filter'
                            onClick={() => input.onChange(input.value == NO_FILTER ? null : NO_FILTER )}
                        />
                    }
                />,
            },
            {
                label: 'Enable coverage (disable if the app is slow, especially for large inputs)',
                content: <Field
                    name='inputConfig.coverage'
                    component={({ input }) =>
                        <Button
                            intent={input.value ? 'primary' : 'none'}
                            icon='exclude-row'
                            onClick={() => input.onChange(!input.value)}
                        />
                    }
                />,
            },
            {
                label: 'Include hidden nodes in coverage',
                content: <Field
                    name='inputConfig.includeHidden'
                    component={({ input }) =>
                        <Button
                            intent={input.value ? 'primary' : 'none'}
                            icon='eye-off'
                            onClick={() => input.onChange(!input.value)}
                        />
                    }
                />,
            },
            {
                label: 'Collapse Depth (0 = show all)',
                content: <Field
                    name='inputConfig.collapseDepth'
                    component={NumericInputField}
                    leftIcon='diagram-tree'
                    short
                />,
            },
            {
                label: 'Font Size (default = 1)',
                content: <Field
                    name='inputConfig.fontSize'
                    component={NumericInputField}
                    min={0.6}
                    max={1.4}
                    step={0.1}
                    leftIcon='font'
                    short
                />,
            },
        ]).map((item, i) => ({
            ...item,
            content: <React.Fragment key={i}>{item.content}</React.Fragment>,
        }));

        return <div>
            <Actions list={actions} />

            <Fields
                names={{
                    data: 'data',
                    coverage: 'output.coverage',
                    inputConfig: 'inputConfig',
                }}
                component={CoverageWrapper}
                style={{ padding: '0 .5rem' }}
            />
        </div>
    }
}

interface Action { label?: string; content: React.ReactNode }

const Actions: React.FC<{ list: Action[] }> = ({ list }) =>
    <ButtonGroup style={{ padding: '0 .5rem' }}>
        {list.map((item, index) =>
            item.label
                ? <Tooltip2 key={index} content={item.label}>
                    {item.content}
                </Tooltip2>
                : item.content
        )}
    </ButtonGroup>

const CoverageWrapper: React.FC<any> = ({ data, coverage, inputConfig, ...rest }) => {
    const [error, setError] = React.useState<any>(null);
    inputConfig = inputConfig.input.value || {};

    const getFilteredData = React.useCallback(
        memoizeOne((data, filter) => {
            if (filter === NO_FILTER) return data;

            const paths: string[][] = (filter || '')
                .trim()
                .split(/\s*,\s*/)
                .filter((x: any) => x)
                .map(_.toPath);

            if (!paths.length) return data;

            const result = {};

            for (const p of paths) {
                const v = _.get(data, p);
                if (v != null) _.set(result, p, v);
            }

            return result;
        }),
        []
    );

    if (coverage.input.value?.error) return <ExecuteError error={coverage.input.value.error} />;

    return <>
        {inputConfig.filter !== NO_FILTER &&
            <div className='row mt05' style={{ alignSelf: 'stretch' }}>
                <div style={{ flex: 0, whiteSpace: 'nowrap' }}>Filter keys:</div>
                <Field
                    name='inputConfig.filter'
                    component={TextInputField}
                    placeholder='nutritionMap,ingredientList'
                    autoFocus
                    small
                    fill
                />
            </div>
        }

        <div style={{ minHeight: '1rem' }} />

        {error &&
            <Callout intent='danger' children={String(error)} className='mb1' />
        }

        <ScrollContent>
            <JSONCoverage
                {...rest}
                key={inputConfig.fontSize /* force rerender */}
                lazyRender
                data={inputConfig.edit
                    ? data.input.value
                    : getFilteredData(data.input.value, inputConfig.filter)
                }
                coverage={coverage.input.value || {}}
                onChange={inputConfig.edit
                    ? value => {
                        setError(null);
                        data.input.onChange(value);
                    }
                    : null
                }
                onError={inputConfig.edit ? setError as any : null}
                collapseDepth={inputConfig.collapseDepth}
                sortKeys={inputConfig.sortKeys}
                style={{ fontSize: inputConfig.fontSize + 'rem' }}
            />
        </ScrollContent>
    </>
}
