const imported_stylus_components = require('.cache/react-style-loader/src/pages/TaskDefinition/components/TaskTree.styl');
import { Button, Callout, Menu, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import LinkMenuItem from 'src/components/LinkMenuItem';
import TaskTypeIcon from 'src/pages/Tasks/components/TaskTypeIcon';
import linkTo from 'src/utils/linkTo';
import { useAppContext } from 'src/components/AppContext';
import { type ClassAndStyle } from '@pi/ui';

import useTaskTree from '../hooks/useTaskTree';

import type { TaskTreeNode } from '../hooks/useTaskTree';
import type { Intent } from '@blueprintjs/core';

export interface TaskTreeProps extends ClassAndStyle {
    taskId: string;
}

const TaskTree: React.FC<TaskTreeProps> = ({ taskId, ...rest }) => {
    const { loading, root } = useTaskTree(taskId);
    const { update, pinnedTaskDefinition } = useAppContext();

    if (loading) return <div>loading ...</div>;
    if (!root) return null;

    const nodes = flattenTreeNode(root);

    if (nodes.length <= 1) return null;

    const isPinned = pinnedTaskDefinition === taskId;

    return (
        <Root {...rest} icon='layout-hierarchy' title='Task Tree'>
            <Menu>
                {nodes.map(({ node, indent }) => (
                    <LinkMenuItem
                        key={node.id}
                        to={linkTo.taskDefinition.edit.url({ taskId: node.task._id })}
                        text={`${node.task.name} ${node.count > 1 ? `[${node.count}]` : ''}`}
                        style={{ marginLeft: indent * 1.5 + 'rem' }}
                        icon={<TaskTypeIcon type={node.task.type} enabled={node.task.enabled} />}
                        labelElement={
                            <Tags>
                                <NodeTags node={node} />
                            </Tags>
                        }
                    />
                ))}
            </Menu>
            <Button
                text={isPinned ? 'Unpin Task' : 'Pin Task'}
                rightIcon={isPinned ? 'unpin' : 'pin'}
                intent='primary'
                small
                minimal
                style={{
                    position: 'absolute',
                    top: '.75rem',
                    right: '.65rem',
                }}
                onClick={() =>
                    update({
                        pinnedTaskDefinition: isPinned ? null : taskId,
                    })
                }
            />
        </Root>
    );
};

export default TaskTree;

function flattenTreeNode(root: TaskTreeNode) {
    const result: Array<{ indent: number; node: TaskTreeNode }> = [];

    const iterate = (node: TaskTreeNode, indent: number) => {
        result.push({
            node,
            indent,
        });

        node.children.forEach(child => iterate(child, indent + 1));
    };

    iterate(root, 0);

    return result;
}

export const tagRenderers = {
    many: {
        intent: 'none',
        minimal: true,
        check: node => !!node.many,
        tooltip:
            'This task type is created inside a loop (such as "Each") and can have any number of instances generated based on the underlying input',
    },
    optional: {
        intent: 'warning',
        check: node => !!node.optional,
        tooltip:
            'This task type is created inside a conditional statement (such as "If" or "Filter"). This means it\'s not a guarantee it will be created, dependening on the underlying input',
    },
    disabled: {
        intent: 'none',
        check: node => !node.task.enabled,
        tooltip: (
            <>
                This task has been disabled
                <br />
                <br />
                Go to the task definition page and switch it's "Enabled" toggle to allow tasks of
                this kind to be processed
            </>
        ),
    },
    cyclic: {
        intent: 'danger',
        check: node => !!node.cyclic,
        tooltip: (
            <>
                This task type eventually creates another copy of itself, so it can potentially
                cycle indefinitely
                <br />
                <br />
                It is strongly encouraged to avoid having cycles in your task graph
            </>
        ),
    },
} satisfies Record<
    string,
    {
        tooltip: React.ReactNode;
        minimal?: boolean;
        intent: Intent;
        check: (node: TaskTreeNode) => boolean;
    }
>;

export const NodeTags: React.FC<{
    node: TaskTreeNode;
    exclude?: Array<keyof typeof tagRenderers>;
    include?: Array<keyof typeof tagRenderers>;
}> = ({ node, include, exclude = [] }) => {
    return (
        <>
            {Object.tsKeys(tagRenderers).map(key => {
                const { check, intent, tooltip, minimal } = tagRenderers[key] as any;

                if ((include && !include.includes(key)) || exclude.includes(key) || !check(node))
                    return null;

                return (
                    <Tooltip2 content={tooltip} key={key}>
                        <Tag intent={intent} minimal={minimal}>
                            {key}
                        </Tag>
                    </Tooltip2>
                );
            })}
        </>
    );
};

const Root = imported_stylus_components.Root.withComponent(Callout);
const Tags = imported_stylus_components.Tags;