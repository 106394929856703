export default function findPaths(obj: object, val: string, path: string[] = []): string[] {
    return Object.entries(obj).flatMap(([key, value]) => {
        const newPath = [...path, key];
        if (value === val) {
            return [newPath.join('.')];
        } else if (typeof value === 'object' && value !== null) {
            return findPaths(value, val, newPath);
        } else {
            return [];
        }
    });
}
