const imported_stylus_components = require('.cache/react-style-loader/src/components/ErrorBox.styl');
import { Button, Callout, Collapse, type IconName } from '@blueprintjs/core';
import { useState } from 'react';

import type { ClassAndStyle } from '../types';

export interface ErrorBoxProps extends ClassAndStyle {
    error: any;
    intent?: 'danger' | 'warning';
    title?: string;
    icon?: IconName | null;
    logError?: boolean;
}

export const ErrorBox: React.FC<ErrorBoxProps> = ({
    error,
    intent = 'danger',
    logError,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);

    if (error == null) return error;

    if (logError) {
        // eslint-disable-next-line no-console
        console.warn(error);
    }

    let stack: string | undefined;
    let message: any = undefined;

    if (error && typeof error === 'object') {
        stack = String(error.stack || '');
        message = String(error.message || error);
    } else {
        stack = '';
        message = String(error);
    }

    return (
        <Callout {...props} intent={intent}>
            <ErrorMessage>
                {stack && (
                    <Button
                        icon={isOpen ? 'chevron-down' : 'chevron-right'}
                        onClick={() => setIsOpen(!isOpen)}
                        className='mr1'
                        small
                        intent='danger'
                    />
                )}
                {message}
            </ErrorMessage>
            {stack && (
                <Collapse isOpen={isOpen}>
                    <ErrorMessage>{stack}</ErrorMessage>
                </Collapse>
            )}
        </Callout>
    );
};

export default ErrorBox;

const ErrorMessage = imported_stylus_components.ErrorMessage;