const imported_stylus_components = require('.cache/react-style-loader/src/components/Select.styl');
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import memoizeOne from 'memoize-one';

import OptionType from 'src/propTypes/SelectOptionType';

import type { SelectOption, SelectOptionKey } from 'src/types';

interface SelectOptionWithId extends SelectOption {
    id: string;
}

export type SelectProps = {
    value?: SelectOptionKey;
    onChange: (value: SelectOptionKey, option?: SelectOption) => any;
    /**
     * List of valid select options
     * String options will be converted into { key: value, label: value }
     */
    options: string[] | SelectOption[];
    /**
     * Label for the entire Select component, left aligned
     */
    label?: string | JSX.Element;
    /**
     * Disable groups even though they are part of options
     */
    noGroups?: boolean;
    /**
     * The label for the "no option selected" option.
     * Defaults to "[ select ]"
     * Also the label that shows up when the value is not found in options
     */
    noOptionLabel?: boolean;
}

export class Select extends React.PureComponent<SelectProps & { [key: string]: any }> {
    static propTypes = {
        value: PropTypes.string,
        label: PropTypes.node,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                OptionType,
            ]),
        ).isRequired,
        noGroups: PropTypes.bool,
        noOptionLabel: PropTypes.string.isRequired,
    };

    static defaultProps = {
        noOptionLabel: '[ select ]',
    };

    handleChange = ({ target: { value } }: any) => {
        const match = _.find(this.getOptions(), { id: value }) as SelectOption;
        if (match) this.props.onChange(match.key, match);
    };

    getOptionsMemoized = memoizeOne((value, noOptionLabel, options: SelectProps['options']): SelectOptionWithId[] => {
        // @ts-ignore
        const finalOptions: SelectOptionWithId[] = options.map(item =>
            typeof item === 'string'
                ? { key: item, label: item, group: null }
                : { ...item, group: item.group == null ? null : item.group }
        );

        if (!_.find(finalOptions, { key: value })) {
            finalOptions.unshift({ id: value, key: value, label: noOptionLabel, group: undefined });
            value = null;
        }

        for (const [index, opt] of finalOptions.entries()) opt.id = String(index);

        return finalOptions;
    });

    getOptions() {
        return this.getOptionsMemoized(
            this.props.value,
            this.props.noOptionLabel,
            this.props.options
        );
    }

    getGroups = memoizeOne((noGroups, finalOptions: SelectOptionWithId[]) => {
        let groups = [{ label: null, list: finalOptions }];

        if (!noGroups && finalOptions.some(opt => opt.group)) {
            const map = new Map();
            let order = [];
            for (const opt of finalOptions) {
                map.set(opt.group, [...(map.get(opt.group) || []), opt]);
                order.push(opt.group);
            }
            order = _.uniq(order);
            order = [null, ..._.sortBy(_.filter(order))];
            groups = (order as any[]).map(label => ({ label, list: map.get(label) || [] }));
        }

        return groups;
    });

    render() {
        const {
            value,
            onChange,
            label,
            noGroups,
            noOptionLabel,
            options,
            disabled,
            ...rest
        } = this.props;
        const finalOptions = this.getOptions();
        const groups = this.getGroups(noGroups, finalOptions);

        return (
            <Root tabIndex={0} {...rest}>
                {label && <Label>{label}</Label>}
                <Input
                    tabIndex={-1}
                    onChange={this.handleChange}
                    disabled={disabled}
                    value={_.get(_.find(finalOptions, { key: value }), 'id', '')}
                >
                    {groups.map(({ label, list }) => {
                        const optList = list.map(({ key, label, id }) =>
                            <option key={id} value={id}>{label}</option>
                        );

                        return label
                            ? <optgroup key={label} label={label as any}>{optList}</optgroup>
                            : <React.Fragment key={label}>{optList}</React.Fragment>;
                    })}
                </Input>
            </Root>
        );
    }
}

export default Select;

const Root = imported_stylus_components.Root.withComponent('label');
const Label = imported_stylus_components.Label;
const Input = imported_stylus_components.Input.withComponent('select');