import _ from 'lodash';
import type { SelectMenuOption } from '@pi/ui';
import type { PathComponent } from '@pi/path-utils';
import type { ValuesType } from 'utility-types';
import type { nodes, Type, BaseExpression, ExtendedArg, Expression } from '@pi/transformer-compiler';

import DefaultTypeRenderer from './DefaultTypeRenderer';
import NTupleType from './NTupleType';
import ObjectType from './ObjectType';
import RestArrayType from './RestArrayType';
import RestObjectType from './RestObjectType';
import EvaluationType from './EvaluationType';
import PrimitiveType from './PrimitiveType';
import type { CompiledExpression, IterateExpressionCallback, NodeState } from '../Tree/getExpressionCompiler';
import type { TransformerContextData } from '../TransformerContext';

export type NodeSelectOptionCreator = (expectedType: typeof Node | null) => Array<{ key: string | null; label: string; group?: string }>;

const Renderers = {
    Default: DefaultTypeRenderer,
    NTuple: NTupleType,
    Object: ObjectType,
    RestArray: RestArrayType,
    RestObject: RestObjectType,
    Evaluation: EvaluationType,
    Primitive: PrimitiveType,
} as const;

const TypeRenderers: Record<keyof typeof Renderers, typeof DefaultTypeRenderer> = Renderers;

export type NodeTypes = keyof typeof nodes;

/**
 * Main configuration object that's passed to each <TreeNodeRenderer /> component an subsequently
 *  to that component's TypeRenderer
 *
 * Any props passed to the renderChild() method are defined in here
 */
export type TypeRendererConfig = {
    expression: BaseExpression & { [key: string]: any };
    node: NodeState;
    onChange: (val: any) => void;
    onUiChange: (diff: Partial<BaseExpression['$ui']>) => void;
    /**
     * Returns suggestions for the given supported fields
     *
     * The 3rd argument - the transformation context cache - can only be provided by the TransformerEditor
     *  after it has first evaluated the expression + input.
     *  It is the context value and not the entire cache because evaluation usually happens in a worker and instances can't be easily transferred over
     */
    getSuggestions: (
        path: string,
        argumentType?: ExtendedArg,
        contextCache?: TransformerContextData,
    ) => readonly string[] | readonly SelectMenuOption[];
    labelSuffix?: string;
    getRenderer: (path: string) => AnyTypeRendererInstance;
    moveCopy: CompiledExpression['moveCopy'];
    onPaste: (targetPath: string, position: 'before' | 'after') => void;
    getFormValue: () => any;
    getNodeSelectOptions: NodeSelectOptionCreator;
};

export type AnyTypeRendererInstance = InstanceType<ValuesType<typeof TypeRenderers>>;

export type ExpressionNode = BaseExpression | string | null | undefined;

export type TypeRendererIterateOptions<T extends keyof typeof Type> = {
    shape: ReturnType<typeof Type[T]>;
    next: IterateExpressionCallback;
    expression: ExpressionNode;
    path: PathComponent[];
};

export default TypeRenderers;
