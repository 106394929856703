import type { JsonEditorProps } from '@pi/ui';

export default function getDefaultEditorProps<Props extends Partial<JsonEditorProps>>(
    props?: Props,
    extend?: Props,
): NonNullable<Props> {
    return {
        height: '100%',
        ...props,
        ...extend,
        minimal: true,
        style: {
            ...props?.style,
            ...extend?.style,
            width: '100%',
            height: '100%',
        },
        monacoStyle: {
            border: 0,
            height: '100%',
            ...props?.monacoStyle,
            ...extend?.monacoStyle,
        },
    } as any;
}
