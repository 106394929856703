import { ButtonGroup, Callout, FormGroup, HTMLTable, Spinner } from '@blueprintjs/core';
import {
    ErrorBox,
    FormGroupField,
    NumericInputField,
    SelectMenuField,
    useField,
    useFieldValue,
    useMultiSelectField,
} from '@pi/ui';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FormAutocompleteField } from 'src/components/autocomplete/FormAutocomplete';
import { useImageAnnotatorInfoQuery, useTransformerExpressionQuery } from 'src/generated/graphql';
import isHumanTask from 'src/utils/isHumanTask';
import linkTo from 'src/utils/linkTo';
import { MultiSelect2 } from '@blueprintjs/select';

import type { Check, Field } from '@pi/ui';
import type { TaskDefinition } from 'src/generated/graphql';

const TaskHandlerConfig: React.FC = observer(() => {
    const isNew = !useFieldValue('_id');
    const typeField = useField<keyof typeof typeData>('type');
    const configField = useField('config');
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            // needed to not reset initial state
            initialized.current = true;
            return;
        }

        configField.onChange(null);
    }, [typeField.value, initialized, configField]);

    const ConfigComponent = typeField.value ? typeData[typeField.value].component : null;

    return (
        <>
            <FormGroupField label='Task Type' field={typeField}>
                <ButtonGroup>
                    <SelectMenuField
                        field={typeField}
                        selectedIntent='primary'
                        options={Object.tsEntries(typeData).map(([key, v]) => ({
                            ...v,
                            props: {
                                icon: isHumanTask(key) ? 'person' : 'desktop',
                            },
                            key,
                        }))}
                    />
                </ButtonGroup>
            </FormGroupField>

            {ConfigComponent && <ConfigComponent configField={configField} isNew={isNew} />}
        </>
    );
});

export default TaskHandlerConfig;

const FormConfigurator: HandlerComponent = observer(({ configField: field }) => {
    return (
        <>
            <FormGroupField field={field.field('formId')} label='Form ID'>
                <FormAutocompleteField field={field.field('formId')} large />
            </FormGroupField>
        </>
    );
});

const MturkFormConfigurator: HandlerComponent = observer(props => {
    const { configField: field } = props;
    const mturk = useFieldValue<TaskDefinition['mturk']>('mturk');

    return (
        <>
            <FormConfigurator {...props} />
            <FormGroupField
                field={field.field('majorityScore')}
                label='majorityScore'
                labelInfo='(minumum number of workers needed for consensus)'
            >
                <NumericInputField field={field.field('majorityScore')} step={1} min={1} max={5} />
            </FormGroupField>
            <FormGroupField
                field={field.field('maxAllowedAssignments')}
                label='maxAllowedAssignments'
                labelInfo='(max number of assigments for consensus)'
            >
                <NumericInputField
                    field={field.field('maxAllowedAssignments')}
                    step={1}
                    min={1}
                    max={10}
                />
            </FormGroupField>
            {mturk?.HITTypeId && (
                <HTMLTable compact striped className='mb1'>
                    <tbody>
                        <tr>
                            <td>HITTypeId</td>
                            <td>
                                <code>{mturk.HITTypeId}</code>
                            </td>
                        </tr>
                        <tr>
                            <td>QualificationTypeId</td>
                            <td>
                                <code>{mturk.QualificationTypeId}</code>
                            </td>
                        </tr>
                        <tr>
                            <td>qualificationName</td>
                            <td>
                                <code>{mturk.qualificationName}</code>
                            </td>
                        </tr>
                    </tbody>
                </HTMLTable>
            )}
        </>
    );
});

const AnnotatorConfigurator: HandlerComponent = observer(({ configField: field }) => {
    const { loading, data } = useImageAnnotatorInfoQuery();
    const { multiSelectProps } = useMultiSelectField({
        options:
            data?.ImageAnnotator_info.annotations
                .filter(ann => ann.ref_name !== 'search text')
                .map(ann => ({
                    key: ann.ref_name,
                    label: ann.display_name,
                    props: {
                        label: (ann.prerequisites || []).join(', '),
                    },
                })) ?? [],
        field: field.field('annotations'),
        allowCreate: true,
    });

    if (loading || !data?.ImageAnnotator_info) return <Spinner size={24} />;

    return (
        <>
            <FormGroupField
                field={field.field('annotations')}
                label='Annotations'
                labelInfo='(returns all by default)'
                subLabel='Select an exisiting annotation type or enter in any text to search'
            >
                <MultiSelect2 {...multiSelectProps} />
            </FormGroupField>
        </>
    );
});

const TransformerConfigurator: HandlerComponent<{ transformation?: string }> = observer(
    ({ isNew, configField }) => {
        const { data, loading, error } = useTransformerExpressionQuery({
            skip: isNew || !configField.value?.transformation,
            variables: {
                id: configField.value?.transformation as any,
            },
        });
        const expr = data?.TransformerExpression;

        if (isNew) {
            return (
                <Callout intent='warning' className='mb1'>
                    Transformation will be created after initial save
                </Callout>
            );
        }

        if (loading) {
            return (
                <Callout intent='none' className='row'>
                    <Spinner size={16} />
                    <div>Fetching Transformation</div>
                </Callout>
            );
        }

        if (error) return <ErrorBox error={error} />;

        if (!expr) {
            return <Callout intent='danger'>Something ain't right chief</Callout>;
        }

        return (
            <>
                <FormGroup label='Transformation'>
                    <Link to={linkTo.transformer.edit.url({ expressionId: expr._id })}>
                        <Callout icon='playbook' intent='primary'>
                            {expr.name} v{expr.version}
                        </Callout>
                    </Link>
                </FormGroup>
            </>
        );
    },
);

type HandlerComponent<Config = Record<string, any>> = React.FC<{
    configField: Field<Config>;
    isNew: boolean;
}>;

export const typeData: Record<
    TaskDefinition['type'],
    {
        label: string;
        component: HandlerComponent;
        validate?: (check: Check, value: Partial<TaskDefinition>) => void;
        isHumanTask: boolean;
    }
> = {
    tasker_test: {
        label: 'Tasker Test',
        isHumanTask: false,
        component: () => <div>todo: test</div>,
    },
    form_internal: {
        label: 'Internal Form',
        isHumanTask: true,
        component: FormConfigurator,
        validate: check => check('config.formId').required(),
    },
    form_mturk: {
        label: 'MTurk Form',
        isHumanTask: true,
        component: MturkFormConfigurator,
        validate: (check, value) => {
            check('config.formId').required();

            if (!value.config) return;
            const majorityScore = value.config.majorityScore;
            if (majorityScore != null) {
                const { isValid } = check('config.majorityScore')
                    .valid(v => v >= 1, 'Must be at least 1')
                    .valid(v => v <= 5, 'Must be at most 5');
                if (isValid && value.config.maxAllowedAssignments != null) {
                    check('config.maxAllowedAssignments')
                        .valid(v => v >= majorityScore, 'Must be at least equal to majorityScore')
                        .valid(v => v <= 10, 'Must be at most 10');
                }
            }
        },
    },
    image_annotator: {
        label: 'Image Annotator',
        isHumanTask: false,
        component: AnnotatorConfigurator,
    },
    transformer: {
        label: 'Transformer',
        isHumanTask: false,
        component: TransformerConfigurator,
    },
};
