import React from 'react';
import _ from 'lodash';

import type { IconName } from '@blueprintjs/core';
import type { User } from 'src/generated/graphql';
import type { Mutable } from '@pi/types';

export const routes = addDefaults({
    component: null,
    children: {
        home: {
            name: 'Home',
            icon: 'home',
            component: React.lazy(() => import('src/pages/Home')),
        },
        builder: {
            name: 'Builder',
            icon: 'build',
            component: React.lazy(() => import('src/pages/Builder/pages/FormulaList')),
            children: {
                batchCreate: {
                    name: 'Run Batch',
                    icon: 'function',
                    component: React.lazy(() => import('src/pages/Builder/pages/BatchCreate')),
                    query: ['formula'] as const,
                },
                batchView: {
                    name: 'Batch View',
                    hideFromNav: true,
                    params: ['batchId'] as const,
                    component: React.lazy(() => import('src/pages/Builder/pages/BatchView')),
                },
                formulas: {
                    name: 'Formulas',
                    icon: 'search',
                    component: React.lazy(() => import('src/pages/Builder/pages/FormulaList')),
                },
                formulaEdit: {
                    name: 'Formula Edit',
                    hideFromNav: true,
                    params: ['formulaId'] as const,
                    component: React.lazy(() => import('src/pages/Builder/pages/FormulaEdit')),
                },
                formulaNew: {
                    name: 'Formula Create',
                    icon: 'add-to-artifact',
                    component: React.lazy(() => import('src/pages/Builder/pages/FormulaCreate')),
                },
                resources: {
                    name: 'Resources',
                    icon: 'search',
                    component: React.lazy(
                        () => import('src/pages/Builder/pages/FormulaResourceList'),
                    ),
                },
                resourceEdit: {
                    name: 'Resource Edit',
                    hideFromNav: true,
                    params: ['formulaResourceId'] as const,
                    component: React.lazy(
                        () => import('src/pages/Builder/pages/FormulaResourceEdit'),
                    ),
                },
                resourceNew: {
                    name: 'Resource Create',
                    hideFromNav: true,
                    component: React.lazy(
                        () => import('src/pages/Builder/pages/FormulaResourceEdit'),
                    ),
                },
            },
        },
        data: {
            name: 'Data > Edit',
            icon: 'database',
            component: React.lazy(() => import('src/pages/Data/pages/Edit')),
            query: ['model', 'id'] as const,
        },
        form: {
            name: 'Form Builder',
            icon: 'form',
            component: React.lazy(() => import('src/pages/FormBuilder/pages/List')),
            children: {
                new: {
                    name: 'Create',
                    icon: 'new-layer',
                    component: React.lazy(() => import('src/pages/FormBuilder/pages/Create')),
                },
                list: {
                    name: 'List Forms',
                    icon: 'search',
                    component: React.lazy(() => import('src/pages/FormBuilder/pages/List')),
                },
                edit: {
                    name: 'Edit',
                    icon: 'edit',
                    params: ['formId'] as const,
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/FormBuilder/pages/Edit')),
                },
                instructions: {
                    name: 'Instructions',
                    icon: 'info-sign',
                    query: ['formId'],
                    component: React.lazy(() => import('src/pages/FormBuilder/pages/Instructions')),
                },
                instructionsEdit: {
                    name: 'Instructions',
                    icon: 'info-sign',
                    hideFromNav: true,
                    params: ['formInstructionId'] as const,
                    query: ['formId'],
                    component: React.lazy(() => import('src/pages/FormBuilder/pages/Instructions')),
                },
                mturkPreview: {
                    query: ['taskId'] as const,
                    name: 'Preview',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Mturk/AppMturk')),
                },
                mturkPreviewViewer: {
                    name: 'MTurk Preview',
                    icon: 'panel-stats',
                    component: React.lazy(() => import('src/pages/Mturk/MturkTaskPreview')),
                },
            },
        },
        imageProcessing: {
            name: 'Image Processing',
            icon: 'media',
            component: React.lazy(() => import('src/pages/ImageProcessing/pages/DownloadImages')),
            isActive: user => user.isAdmin || user.imageProcessing?.enabled,
            children: {
                manage: {
                    name: 'Manage Images',
                    icon: 'edit',
                    hideFromNav: false,
                    isActive: user => user.isAdmin,
                    component: React.lazy(
                        () => import('src/pages/ImageProcessing/pages/ManageImages'),
                    ),
                },
                download: {
                    name: 'Download Images',
                    icon: 'download',
                    hideFromNav: false,
                    isActive: user => user.isAdmin || user.imageProcessing?.enabled,
                    component: React.lazy(
                        () => import('src/pages/ImageProcessing/pages/DownloadImages'),
                    ),
                },
                upload: {
                    name: 'Upload Images',
                    icon: 'upload',
                    hideFromNav: false,
                    isActive: user => user.isAdmin || user.imageProcessing?.enabled,
                    component: React.lazy(
                        () => import('src/pages/ImageProcessing/pages/UploadImages'),
                    ),
                },
            },
        },
        ingredientManagement: {
            name: 'Ingredient Management',
            icon: 'shopping-cart',
            component: React.lazy(
                () => import('src/pages/IngredientManagement/pages/IngredientResearchPage'),
            ),
            isActive: user => user.isAdmin,
            children: {
                newIngredientReview: {
                    name: 'New Ingredient Review',
                    icon: 'series-search',
                    hideFromNav: false,
                    isActive: user => user.isAdmin,
                    component: React.lazy(
                        () =>
                            import('src/pages/IngredientManagement/pages/NewIngredientReviewPage'),
                    ),
                },
                reviewedIngredients: {
                    name: 'Ingredient Research',
                    icon: 'eye-on',
                    component: React.lazy(
                        () => import('src/pages/IngredientManagement/pages/IngredientResearchPage'),
                    ),
                },
                singleIngredientReview: {
                    params: ['ingredientId'] as const,
                    name: 'Ingredient Review',
                    icon: 'shopping-cart',
                    hideFromNav: true,
                    component: React.lazy(
                        () =>
                            import(
                                'src/pages/IngredientManagement/pages/IngredientReviewStatusPage'
                            ),
                    ),
                },
                pv2sByIngredient: {
                    name: 'Pv2s By Ingredient',
                    icon: 'eye-on',
                    component: React.lazy(
                        () => import('src/pages/IngredientManagement/pages/Pv2sByIngredient'),
                    ),
                },
            },
        },
        pv2: {
            name: 'PV2',
            icon: 'barcode',
            children: {
                attributes: {
                    name: 'Attributes',
                    icon: 'layout-balloon',
                    children: {
                        create: {
                            name: 'Create',
                            icon: 'add',
                            component: React.lazy(
                                () => import('src/pages/Pv2/Attributes/pages/Create'),
                            ),
                        },
                        edit: {
                            hideFromNav: true,
                            name: 'Edit',
                            icon: 'edit',
                            params: ['id'] as const,
                            component: React.lazy(
                                () => import('src/pages/Pv2/Attributes/pages/Edit'),
                            ),
                        },
                        stats: {
                            name: 'Stats',
                            icon: 'grouped-bar-chart',
                            component: React.lazy(
                                () => import('src/pages/Pv2/Attributes/pages/Stats'),
                            ),
                        },
                    },
                    component: React.lazy(() => import('src/pages/Pv2/Attributes/index')),
                },
            },
            component: React.lazy(() => import('src/pages/Pv2/index')),
        },
        scripts: {
            name: 'Run Scripts',
            icon: 'document',
            hideFromNav: false,
            component: React.lazy(() => import('src/pages/Scripts/pages/ScriptsPage')),
            children: {
                run: {
                    params: ['key'] as const,
                    name: 'run',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Scripts/pages/ScriptPage')),
                },
                runs: {
                    params: ['scriptKey'] as const,
                    name: 'logs',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Scripts/pages/ScriptRuns')),
                },
                logs: {
                    hideFromNav: true,
                    name: 'ScriptLogContainer',
                    params: ['runId', 'scriptKey', 'userId'] as const,
                    component: React.lazy(() => import('src/pages/Scripts/pages/ScriptLogs')),
                },
            },
        },
        services: {
            name: 'Services',
            icon: 'antenna',
            component: React.lazy(() => import('src/pages/Services')),
        },
        taskDefinition: {
            // group: 'Tasker',
            icon: 'layout-hierarchy',
            name: 'Task Definition',
            component: React.lazy(() => import('src/pages/TaskDefinition/pages/List')),
            children: {
                new: {
                    name: 'Create',
                    icon: 'add-to-artifact',
                    component: React.lazy(() => import('src/pages/TaskDefinition/pages/Create')),
                },
                edit: {
                    name: 'Edit',
                    icon: 'edit',
                    params: ['taskId'] as const,
                    component: React.lazy(() => import('src/pages/TaskDefinition/pages/Edit')),
                    hideFromNav: true,
                },
                list: {
                    name: 'Search',
                    icon: 'search',
                    component: React.lazy(() => import('src/pages/TaskDefinition/pages/List')),
                },
            },
        },
        task: {
            // group: 'Tasker',
            icon: 'new-grid-item',
            name: 'Task',
            component: React.lazy(() => import('src/pages/Tasks/pages/List')),
            children: {
                new: {
                    name: 'Create',
                    icon: 'add-to-artifact',
                    component: React.lazy(() => import('src/pages/Tasks/pages/Create')),
                },
                newWithId: {
                    name: 'Create',
                    icon: 'add-to-artifact',
                    params: ['taskDefinitionId'] as const,
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Tasks/pages/Create')),
                },
                list: {
                    name: 'Search',
                    icon: 'search',
                    component: React.lazy(() => import('src/pages/Tasks/pages/List')),
                },
                view: {
                    hideFromNav: true,
                    name: 'View',
                    params: ['taskId'] as const,
                    component: React.lazy(() => import('src/pages/Tasks/pages/View')),
                },
            },
        },
        queue: {
            // group: 'Tasker',
            name: 'Task Queue',
            icon: 'sort-asc',
            component: React.lazy(() => import('src/pages/Tasks/pages/Queue')),
            isActive: user => user.isAdmin || !!user.tasker?.enabled,
            children: {
                view: {
                    name: 'Handle Task',
                    icon: 'blank',
                    params: ['taskId'] as const,
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Tasks/pages/ProcessFormTask')),
                },
            },
        },
        transformer: {
            name: 'Transformer',
            icon: 'playbook',
            component: React.lazy(() => import('src/pages/Transformer/pages/List')),
            children: {
                edit: {
                    params: ['expressionId'] as const,
                    query: ['taskDependencies', 'task'] as const,
                    name: 'Edit',
                    icon: 'edit',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Transformer/pages/Edit')),
                },
                new: {
                    name: 'Create',
                    icon: 'add-to-artifact',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Transformer/pages/Create')),
                },
                resources: {
                    name: 'Resources',
                    icon: 'search',
                    hideFromNav: false,
                    component: React.lazy(() => import('src/pages/Transformer/pages/Resources')),
                },
            },
        },
        users: {
            name: 'Users',
            icon: 'user',
            component: React.lazy(() => import('src/pages/Users/pages/List')),
            children: {
                edit: {
                    params: ['userId'] as const,
                    name: 'Edit',
                    icon: 'edit',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Users/pages/Edit')),
                },
                list: {
                    name: 'Users',
                    icon: 'user',
                    component: React.lazy(() => import('src/pages/Users/pages/List')),
                },
                mturk: {
                    name: 'MturkWorker',
                    icon: 'person',
                    component: React.lazy(() => import('src/pages/Users/pages/MturkWorkerList')),
                },
                mturkEdit: {
                    params: ['mturkWorkerId'] as const,
                    name: 'Edit',
                    icon: 'edit',
                    hideFromNav: true,
                    component: React.lazy(() => import('src/pages/Users/pages/MturkWorkerEdit')),
                },
            },
        },
        config: {
            name: 'Configurations',
            icon: 'code-block',
            component: React.lazy(() => import('src/pages/Configurations')),
        },
    },
});

export default routes;

function addDefaults<Root extends RouteDefinitionInput>(root: Root): MakeRoute<Root> {
    const defaultIsActive = (user: User) => !!user.isAdmin;

    const it = (route: Mutable<RouteDefinitionInput>, isActive: (user: User) => boolean): any => {
        let routeIsActive = route.isActive || isActive;
        let childIsActive = route.isActive || routeIsActive;

        if (route === root) {
            routeIsActive = () => true;
            childIsActive = defaultIsActive;
        }

        return {
            ...route,
            isActive: routeIsActive,
            children: route.children
                ? _.mapValues(route.children, child => it(child, childIsActive))
                : undefined,
        };
    };

    return it(root, defaultIsActive);
}

type MakeRoute<R extends RouteDefinitionInput> = R & {
    isActive: RouteDefinition['isActive'];
    children: R['children'] extends Record<string, RouteDefinitionInput>
        ? {
              [K in keyof R['children']]: MakeRoute<R['children'][K]>;
          }
        : undefined;
};

export interface RouteDefinition extends RouteDefinitionInput {
    readonly isActive: NonNullable<RouteDefinitionInput['isActive']>;
    readonly children?: Record<string, RouteDefinition>;
}

interface RouteDefinitionInput {
    readonly icon?: IconName;
    readonly name?: string;
    readonly component: React.LazyExoticComponent<any> | null;
    readonly hideFromNav?: boolean;
    readonly group?: string;
    readonly params?: readonly string[];
    readonly query?: readonly string[];
    readonly children?: Record<string, RouteDefinitionInput>;
    /**
     * by default, every route will only be available to internal admin users
     *
     * is a route has children, they will inherit the parent's permissions unless overwritten
     */
    readonly isActive?: (user: User) => boolean;
}
