import { MenuItem } from '@blueprintjs/core';
import { useLinkClickHandler } from 'react-router-dom';

import type { LinkProps } from 'react-router-dom';
import type { MenuItemProps } from '@blueprintjs/core';
import type { ClassAndStyle } from '@pi/ui';

export interface LinkButtonProps
    extends Omit<MenuItemProps, 'onClick' | 'href' | 'text'>,
        ClassAndStyle {
    to: string;
    text: React.ReactNode;
    linkProps?: Omit<LinkProps, 'to'>;
}

const LinkMenuItem: React.FC<LinkButtonProps> = ({ to, linkProps, ...buttonProps }) => {
    const onClick = useLinkClickHandler(to, linkProps);

    return <MenuItem {...buttonProps} onClick={onClick as any} />;
};

export default LinkMenuItem;
