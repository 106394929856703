
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DataRequirement": [
      "DataRequirementModel",
      "DataRequirementModelId",
      "DataRequirementPrimitive"
    ],
    "DataRequirementInterface": [
      "DataRequirementModel",
      "DataRequirementModelId",
      "DataRequirementPrimitive"
    ],
    "IEditableField": [
      "CheckboxEditableField",
      "ImageListEditableField",
      "NumberEditableField",
      "SelectEditableField",
      "StringEditableField"
    ],
    "ISearchField": [
      "AutocompleteSearchField",
      "DateSearchField",
      "EqualitySearchField",
      "NestedSearchField",
      "NumericalSearchField",
      "SelectSearchField",
      "StringSearchField"
    ],
    "Pv2EditableField": [
      "CheckboxEditableField",
      "ImageListEditableField",
      "NumberEditableField",
      "SelectEditableField",
      "StringEditableField"
    ],
    "Pv2SearchField": [
      "AutocompleteSearchField",
      "DateSearchField",
      "EqualitySearchField",
      "NestedSearchField",
      "NumericalSearchField",
      "SelectSearchField",
      "StringSearchField"
    ],
    "TaskAction": [
      "TaskActionCreatePv2",
      "TaskActionCreateTask",
      "TaskActionUpdatePv2"
    ],
    "TaskActionInterface": [
      "TaskActionCreatePv2",
      "TaskActionCreateTask",
      "TaskActionUpdatePv2"
    ]
  }
};
      export default result;
    