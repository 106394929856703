import _ from 'lodash';
import textBlock from '@pi/text-block';
import addAttributeDefaults from 'src/utils/addAttributeDefaults';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, ParentContext, Expression } from '../../types';
import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';

export interface Pv2AttributeNodeT extends BaseExpression {
    $type: 'Pv2AttributeNode';
    type: StringNodeT;
    pushToAttributes?: BooleanNodeT;
    key?: StringNodeT;
    parent?: StringNodeT;
    if?: Expression;
    source?: StringNodeT;
    value: Expression;
}

export default class Pv2AttributeNode extends ValueNode<Pv2AttributeNodeT> {
    static TYPE = Type.Object(
        { key: 'type', type: 'StringNode', suggestions: 'off' },
        { key: 'pushToAttributes', label: 'Push to attributes', type: 'BooleanNode' },
        { key: 'if', type: 'ValueNode' },
        { key: 'key', type: 'StringNode' },
        { key: 'parent', type: 'StringNode' },
        { key: 'source', type: 'StringNode' },
        { key: 'value', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.pv2,
        description: textBlock`
            Create a Pv2 Attribute.

            By default the created attribute is automatically added to ".attributes" if "Push to attributes" is true
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            key: null,
            parent: null,
            type: this.getDefaultFor('StringNode'),
            pushToAttributes: this.getDefaultFor('BooleanNode'),
            value: super.getDefaultFor('ObjectNode'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'type')) return 'missing "type"';
        if (!Reflect.has(this.expression, 'value')) return 'missing "value"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        if (
            this.expression.if &&
            !(await this.context.evaluate(this.expression.if, { sourcePath }))
        ) {
            return null;
        }

        const result = addAttributeDefaults({
            type: await this.context.evaluate(this.expression.type, { sourcePath }),
            value: await this.context.evaluate(this.expression.value, {
                sourcePath,
                isOutput: true,
            }),
            key:
                this.expression.key &&
                (await this.context.evaluate(this.expression.key, { sourcePath, isOutput: true })),
            parent:
                this.expression.parent &&
                (await this.context.evaluate(this.expression.parent, {
                    sourcePath,
                    isOutput: true,
                })),
            source:
                this.expression.source &&
                (await this.context.evaluate(this.expression.source, { sourcePath })),
        });

        if (await this.context.evaluate(this.expression.pushToAttributes)) {
            this.context.addAttribute(result);
        }

        return result;
    }
}
