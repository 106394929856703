import Type from 'src/Type';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import Node from '../Node';

import type { Expression } from 'src/types';

export interface UpdatePv2NodeT {
    $type: 'UpdatePv2Node';
    id: Expression;
    removeAttributes: Expression;
    removeAttributeKey: Expression;
    data: Expression;
}

export default class UpdatePv2Node extends Node<UpdatePv2NodeT> {
    static TYPE = Type.Object(
        { key: 'id', label: 'Pv2._id', type: 'StringNode', suggestions: 'string' },
        { key: 'removeAttributes', label: 'Remove Attributes', type: 'EvaluationNode' },
        {
            key: 'removeAttributeKey',
            label: 'Key for Attribute Removal',
            type: 'StringNode',
            suggestions: 'string',
        },
        { key: 'data', label: 'Data', type: 'ObjectNode' },
    );

    static uiConfig = {
        ...config.presets.tasker,
        description:
            'Update an existing Pv2. Passed `attributes` will be **append** to the existing Pv2.attributes, use the `removeAttributes` option to help with that',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            id: this.getDefaultFor('StringNode'),
            removeAttributes: {
                ...this.getDefaultFor('ArrayNode'),
                value: [this.getDefaultFor('StringNode')],
            },
            removeAttributeKey: this.getDefaultFor('StringNode'),
            data: {
                ...this.getDefaultFor('ObjectNode'),
                value: {
                    attributes: {
                        ...this.getDefaultFor('ArrayNode'),
                        value: [Primitive.evaluation('')],
                    },
                },
            },
        };
    }

    async execute() {
        const id = await this.context.evaluateLog('id', this.expression.id);
        const removeAttributes = await this.context.evaluateLog(
            'removeAttributes',
            this.expression.removeAttributes,
        );
        const removeAttributeKey = await this.context.evaluateLog(
            'removeAttributeKey',
            this.expression.removeAttributeKey,
        );
        const data = await this.context.evaluateLog('data', this.expression.data);

        if (data?.name) this.context.setLastStackEntryMeta(data.name);
        else if (id) this.context.setLastStackEntryMeta(id);

        if (!data || typeof data !== 'object') throw new Error('Pv2 must be an object');

        this.context.addTaskerAction({
            kind: 'updatePv2',
            pv2: id,
            removeAttributes,
            removeAttributeKey,
            data,
        });
    }
}
