import { gql } from '@apollo/client';
import { useOrGetField } from '@pi/ui';
import { observer } from 'mobx-react';

import QueryAutocomplete, {
    createGetOptions,
    modelAutocompleteGetVariables,
} from './QueryAutocomplete';

import type { ModelAutocompleteProps, ModelFieldAutoCompleteProps } from './QueryAutocomplete';
import type { FormFindQuery, FormFindQueryVariables } from 'src/generated/graphql';

const QUERY = gql`
    query FormFind($query: JSON!, $sort: String) {
        Form_find(query: $query, sort: $sort) {
            results {
                _id
                name
            }
        }
    }
`;

const FormAutocomplete: React.FC<ModelAutocompleteProps> = props => (
    <QueryAutocomplete<FormFindQuery, FormFindQueryVariables>
        {...props}
        gqlQuery={QUERY}
        getVariables={modelAutocompleteGetVariables}
        getOptions={createGetOptions('Form_find')}
    />
);

export default FormAutocomplete;

export const FormAutocompleteField: React.FC<ModelFieldAutoCompleteProps> = observer(
    ({ field, ...props }) => {
        const f = useOrGetField(field);

        return <FormAutocomplete {...props} value={f.value} onChange={f.onChange} />;
    },
);
