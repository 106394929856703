const imported_stylus_components = require('.cache/react-style-loader/src/components/RenderCounter.styl');
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import type { HTMLDivProps } from 'src/types';

export interface RenderCounterProps extends HTMLDivProps {
    label?: string | null;
    /**
     * Force enable/disable this particular instance, regardless of the global RenderCounter.ENABLED status
     */
    enabled?: boolean;
    /**
     * How long to show the highlight after a render (in ms).
     * Defaults to `1000` ms
     */
    blinkTime?: number;
}

export class RenderCounter extends React.Component<RenderCounterProps> {
    static propTypes = {
        label: PropTypes.string,
        enabled: PropTypes.bool,
        blinkTime: PropTypes.number.isRequired,
    };

    static defaultProps = {
        blinkTime: 1000,
    };

    rootRef = React.createRef<HTMLDivElement>();
    counter = 0;
    timeout: any;

    componentDidMount() {
        this.blink();
    }

    componentDidUpdate() {
        this.blink();
    }

    blink() {
        if (!this.rootRef.current) return;

        this.rootRef.current.dataset.active = '';
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            if (!this.rootRef.current) return;
            delete this.rootRef.current.dataset.active;
        }, this.props.blinkTime);
    }

    render() {
        const { label, enabled, blinkTime, ...rest } = this.props;

        if (enabled === false) return null;

        return (
            <Root {...rest} ref={this.rootRef}>
                {label}
                {++this.counter}
            </Root>
        );
    }
}

export default RenderCounter;

const Root = imported_stylus_components.Root;