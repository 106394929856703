const imported_stylus_components = require('.cache/react-style-loader/src/components/TypeRenderers/DefaultTypeRenderer.styl');
import React from 'react';
import { Button, ButtonGroup, type ButtonProps, Icon, Tooltip } from '@blueprintjs/core';
import { markdownToReact, Select, type SelectProps } from '@pi/ui';
import { joinPaths } from '@pi/path-utils';
import _ from 'lodash';
import { Field } from '@pi/react-form';
import textBlock from '@pi/text-block';
import { nodes, Node, ValueNode, Primitive } from '@pi/transformer-compiler';
import LruCache from 'src/utils/LruCache';

import { ContentEditableInputField } from '../ContentEditableInput';
import TreeNode from '../Tree/TreeNode';
import { stripNodeString } from './createGetNodeSelectOptions';

import type { NodeTypes, TypeRendererConfig, TypeRendererIterateOptions } from '.';
import type { ValuesType } from 'utility-types';

export type ChildConfig = {
    preSelectLabel?: React.ReactNode;
    keyOverride?: string | null;
    labelSuffix?: string | null;
    actions?: Action[];
};

export type Action = {
    key: ValuesType<typeof ACTION_ORDER>;
    enabled: boolean;
    showWhenDisabled?: boolean;
    render: (overrides?: ButtonProps) => React.ReactNode;
};

/**
 * Order of all actions EVERYWHERE
 * When creating a new action, add its key in here first so types update
 * This list is used to order actions so they show up consistenly everywhere
 */
const ACTION_ORDER = [
    'debug',
    'comment',
    'disable',
    'add',
    'add-top',
    'add-bottom',
    'copy',
    'move',
    'description',
    'remove',
] as const;

const ACTION_ORDER_MAP = Object.fromEntries(ACTION_ORDER.map((key, index) => [key, index + 1]));

export default class DefaultTypeRenderer {
    config: TypeRendererConfig;
    childConfig: ChildConfig;
    collapsible = true;

    /** @override */
    static iterate(_options: TypeRendererIterateOptions<any>) {
        throw new Error(`Must override ${this.name}.iterate`);
    }

    constructor(config: TypeRendererConfig) {
        this.config = config;
        const { getRenderer, node } = this.config;
        this.childConfig = getRenderer(node.parentPath!)?.getChildConfig(this.config) || {};
        const originalGetActions = this.getActions.bind(this);
        this.getActions = (): Action[] => {
            const score = (action: Action) => ACTION_ORDER_MAP[action.key] || Infinity;
            return Array.from(originalGetActions()).sort((a, b) => score(a) - score(b));
        };
    }

    get isDisabled() {
        const { node, expression } = this.config;
        return !!(node.canDisable && expression?.$ui?.disabled);
    }

    get canCollapse() {
        return !!this.config.node.nodeClass;
    }
    // get isCollapsed() { return !!(this.canCollapse && this.config.expression?.$ui?.collapsed); }
    get isCollapsed() {
        return !!(this.canCollapse && this.config.node.isCollapsed);
    }

    get moveType(): 'move' | 'copy' | null {
        const { move, copy } = this.config.expression?.$ui || {};
        return move ? 'move' : copy ? 'copy' : null;
    }
    get isChildOfAMovingParent() {
        return !this.moveType && this.config.node.isParentMoving;
    }

    getLabelText({
        labelSuffix,
        keyOverride,
        nodeName,
    }: { keyOverride?: string; labelSuffix?: string; nodeName?: string } = {}) {
        const { node } = this.config;
        nodeName = nodeName === undefined ? stripNodeString(node.nodeClass?.name || '') : nodeName;
        const key =
            keyOverride !== undefined
                ? keyOverride
                : 'keyOverride' in this.childConfig
                ? this.childConfig.keyOverride
                : node.argumentType?.label || node.key;

        return _.filter([
            key,
            key ? '→' : '',
            labelSuffix || this.childConfig.labelSuffix,
            nodeName,
        ]).join(' ');
    }

    /**
     * Main way for a prent renderer to communicate to a child renderer.
     * A child will call parent.getChildConfig(child.config) and use the returned configuration
     * Look at RestObject for an example, it needs to tell its children to render a special preSelectLabel
     *
     * The reason this is done this way is because all nodes are rendered as a single flat list,
     *  which means there's no direct way for a parent to communicate to its child.
     */
    getChildConfig(_childConfig: TypeRendererConfig): ChildConfig {
        return {};
    }

    /**
     * Root render method that has to return a <TreeNode />
     */
    render(): React.ReactNode {
        const { node } = this.config;

        if (node.isHidden) return null;

        const renderNode = (props?: any) => {
            const result = (
                <StyledTreeNode
                    indents={node.indents}
                    background={node.background || '#CED9E0'}
                    color={node.color || 'black'}
                    label={this.renderLabel()}
                    children={this.renderContent()}
                    preLabel={this.renderPreLabel()}
                    disabled={this.isDisabled}
                    {...props}
                    data-path={node.path}
                    data-move={this.moveType || this.isChildOfAMovingParent}
                />
            );

            // return <div className='row'>
            //     <RenderCounter
            //         label={null}
            //         enabled
            //         style={{ minWidth: '2rem', textAlign: 'right' }}
            //     />
            //     {React.cloneElement(result, { style: { flex: 1 } })}
            // </div>;

            return result;
        };

        return renderNode();
    }

    renderPreLabel() {
        const { node, expression } = this.config;

        return (
            <>
                {!this.isDisabled && !this.isCollapsed && expression?.$comment && (
                    <Field name={joinPaths(node.path, '$comment')} component={Comment} />
                )}
                {this.renderActions()}
            </>
        );
    }

    renderLabel(children?: React.ReactNode): React.ReactNode {
        const { node, onUiChange } = this.config;

        const buttonProps = {
            tabIndex: -1,
            style: {
                marginLeft: '-0.5rem',
                marginTop: '-0.5rem',
                marginBottom: '-0.5rem',
                outline: 'none',
            },
        };

        const label = (
            <LabelContent>
                {this.isDisabled
                    ? this.getActions()
                          .find(x => x.key === 'disable')!
                          .render(buttonProps as any)
                    : this.collapsible && (
                          <Tooltip
                              hoverOpenDelay={500}
                              content={
                                  this.isCollapsed ? 'Show node contents' : 'Hide node contents'
                              }
                          >
                              <Button
                                  small
                                  minimal
                                  icon={
                                      <Icon
                                          icon={this.isCollapsed ? 'chevron-right' : 'chevron-down'}
                                          color={node.color}
                                      />
                                  }
                                  onClick={() => onUiChange({ collapsed: !this.isCollapsed })}
                                  {...buttonProps}
                              />
                          </Tooltip>
                      )}
                {this.renderNodeSelect()}
                {children}
            </LabelContent>
        );

        return label;
    }

    renderNodeSelect(): React.ReactNode {
        const { node, onChange, getRenderer, expression } = this.config;
        const { argumentType } = node;
        const wrapper = (child: React.ReactNode) => child;

        const { preSelectLabel } = node.parentPath
            ? getRenderer(node.parentPath).getChildConfig(this.config)
            : ({} as any);

        // TODO: Allow disabling node selector for options with fixedType set?
        // if (argumentType?.fixedType) return wrapper(this.getLabelText());
        const expectedType: any = argumentType?.type ? _.get(nodeTypes, argumentType.type) : null;

        return wrapper(
            <NodeSelectContainer>
                {preSelectLabel}
                <NodeSelect
                    value={expression == null ? null : (node.nodeClass?.name as any)}
                    onChange={onChange}
                    children={this.getLabelText()}
                    disabled={this.isDisabled}
                    options={this.config.getNodeSelectOptions(expectedType)}
                />
            </NodeSelectContainer>,
        );
    }

    renderContent(): React.ReactNode {
        const { node, expression, moveCopy } = this.config;

        return (
            <>
                {(this.isDisabled || this.isCollapsed) && expression?.$comment && (
                    <LabelComment data-inline>
                        {markdownToReact(expression.$comment.trim().split('\n')[0])}
                    </LabelComment>
                )}

                {!!moveCopy && (node.canBeMovedTo || this.moveType) && (
                    <MoveActions vertical>
                        {this.moveType ? (
                            this.getActions()
                                .find(x => x.key === this.moveType)
                                ?.render({
                                    icon: 'disable',
                                    minimal: false,
                                })
                        ) : (
                            <>
                                <Tooltip
                                    content={`Paste above this ${stripNodeString(
                                        node.nodeClass?.name,
                                    )} node`}
                                >
                                    <HalfButton
                                        icon='chevron-up'
                                        small
                                        onClick={() => this.config.onPaste(node.path, 'before')}
                                    />
                                </Tooltip>
                                <Tooltip
                                    content={`Paste below this ${stripNodeString(
                                        node.nodeClass?.name,
                                    )} node`}
                                >
                                    <HalfButton
                                        icon='chevron-down'
                                        small
                                        onClick={() => this.config.onPaste(node.path, 'after')}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </MoveActions>
                )}
            </>
        );
    }

    renderActions(): React.ReactNode {
        const { isDisabled } = this;
        const actions = this.getActions().filter(
            action => action.enabled && (!isDisabled || action.showWhenDisabled),
        );

        if (!actions.length) return null;

        return (
            <ActionsRow>
                {actions.map(({ key, render }) => React.cloneElement(render() as any, { key }))}
            </ActionsRow>
        );
    }

    renderActionButton({
        tooltip,
        style,
        ...buttonProps
    }: { tooltip?: React.ReactNode } & ButtonProps & {
            style?: React.CSSProperties;
        }): React.ReactNode {
        const btn = (
            <Button
                minimal
                tabIndex={-1}
                {...buttonProps}
                small
                style={{ ...style, outline: 'none' }}
            />
        );

        if (tooltip) {
            const content = (
                <TooltipContent>
                    {typeof tooltip === 'string' ? renderActionTooltipText(tooltip) : tooltip}
                </TooltipContent>
            );
            return <Tooltip content={content}>{btn}</Tooltip>;
        }

        return btn;
    }

    getActions(): Action[] {
        const { node } = this.config;

        return [
            {
                key: 'debug',
                enabled: false,
                render: () =>
                    this.renderActionButton({
                        icon: 'download',
                        intent: 'warning',
                        // eslint-disable-next-line no-console
                        onClick: () => console.log(this.config),
                    }),
            },
            ...(this.childConfig.actions || []),
            {
                key: 'description',
                enabled: !!node.nodeClass?.uiConfig.description,
                render: () =>
                    this.renderActionButton({
                        tooltip: markdownToReact(node.nodeClass!.uiConfig.description!, {
                            style: { maxWidth: '25rem' },
                        }),
                        icon: 'info-sign',
                    }),
            },
            {
                key: 'comment',
                enabled: !!(!this.isDisabled && node.nodeClass),
                render: () => (
                    <Field
                        name={joinPaths(node.path, '$comment')}
                        // @ts-ignore
                        component={({ input }) =>
                            this.renderActionButton({
                                tooltip: input.value ? 'Remove comment' : 'Add Comment',
                                icon: 'comment',
                                intent: input.value ? 'danger' : 'none',
                                onClick: () =>
                                    input.onChange(input.value ? undefined : '🙊 Some Comment! 🙊'),
                            })
                        }
                    />
                ),
            },
            {
                key: 'disable',
                enabled: !!(node.canDisable && node.nodeClass),
                showWhenDisabled: true,
                render: overrides => (
                    <Field
                        name={joinPaths(node.path, '$ui.disabled')}
                        // @ts-ignore
                        component={({ input }) =>
                            this.renderActionButton({
                                ...overrides,
                                tooltip: input.value
                                    ? 'Enable node'
                                    : textBlock`
                                        Disable Node

                                        This will cause the node to not evaluate at all, it will be completely ignored.

                                        It's particularly useful when trying to focus on a certain part of the transformation and you don't want other nodes to be confusing
                                    `,
                                icon: 'eye-off',
                                intent: input.value ? 'primary' : 'none',
                                onClick: () => input.onChange(!input.value),
                            })
                        }
                    />
                ),
            },
            {
                key: 'move',
                enabled: node.canMove,
                showWhenDisabled: true,
                render: overrides => (
                    <Field
                        name={joinPaths(node.path, '$ui.move')}
                        // @ts-ignore
                        component={({ input }) =>
                            this.renderActionButton({
                                tooltip: input.value
                                    ? 'Cancel move operation'
                                    : 'Move this expression somewhere else',
                                icon: 'move',
                                intent: input.value ? 'danger' : 'none',
                                ...overrides,
                                onClick: () => input.onChange(!input.value),
                            })
                        }
                    />
                ),
            },
            {
                key: 'copy',
                enabled: node.canMove, // @TODO: set this to true after moving the textfield to proper nodes
                showWhenDisabled: true,
                render: overrides => (
                    <Field
                        name={joinPaths(node.path, '$ui.copy')}
                        // @ts-ignore
                        component={({ input }) =>
                            this.renderActionButton({
                                tooltip: input.value
                                    ? 'Remove this node from clipboard'
                                    : 'Add this node to the clipboard',
                                icon: 'clipboard',
                                intent: input.value ? 'warning' : 'none',
                                ...overrides,
                                onClick: () => input.onChange(!input.value),
                            })
                        }
                    />
                ),
            },
        ];
    }
}

const nodeTypes = { Node, ValueNode, ...nodes };

/**
 * Action tooltips will almost never change so it's good to cache them
 */
const renderActionTooltipText = LruCache.memoize(
    (text: string) => markdownToReact(text),
    text => text,
    { maxSize: 1000 },
);

export const NodeSelect: React.FC<{
    value: NodeTypes;
    onChange: (...args: any[]) => any;
    options: SelectProps['options'];
    [key: string]: any;
}> = ({ value, onChange, options, children, ...rest }) => (
    <SelectContainer>
        {children}
        <Select
            tabIndex='-1'
            {...rest}
            value={value}
            options={options}
            onChange={
                ((value: NodeTypes) => {
                    if (value != null) return onChange((nodes[value] as any).getDefault());

                    return onChange(Primitive.evaluation('null'));
                }) as any
            }
        />
    </SelectContainer>
);

class Comment extends React.Component<any> {
    state = {
        focused: false,
    };

    render() {
        const { input, meta, ...rest } = this.props;
        const { focused } = this.state;

        if (focused) {
            return (
                <ContentEditableInputField
                    input={input}
                    meta={meta}
                    onBlur={() => this.setState({ focused: false })}
                    autoFocus
                    className={LabelComment.className}
                />
            );
        }

        return (
            <LabelComment {...rest} onClick={() => this.setState({ focused: true })}>
                {markdownToReact(input.value)}
            </LabelComment>
        );
    }
}

const LabelComment = imported_stylus_components.LabelComment;
const MoveActions = imported_stylus_components.MoveActions.withComponent(ButtonGroup);
const StyledTreeNode = imported_stylus_components.StyledTreeNode.withComponent(TreeNode);
const HalfButton = imported_stylus_components.HalfButton.withComponent(Button);
const ActionsRow = imported_stylus_components.ActionsRow;
const LabelContent = imported_stylus_components.LabelContent;
const NodeSelectContainer = imported_stylus_components.NodeSelectContainer;
const SelectContainer = imported_stylus_components.SelectContainer;
const TooltipContent = imported_stylus_components.TooltipContent;