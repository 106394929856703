const imported_stylus_components = require('.cache/react-style-loader/src/components/Autocomplete.styl');
import { Button, Menu, MenuItem, type MenuProps } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import TextInput from './TextInput';

import type { TextInputProps } from './TextInput';
import type { Popover2Props } from '@blueprintjs/popover2';
import type { HTMLDivProps, MenuItemOption } from 'src/types';
import type { MaybePromise } from '@pi/types';

export interface AutocompleteProps<Option extends MenuItemOption = MenuItemOption>
    extends HTMLDivProps {
    loading?: boolean;
    onSearch: (text: string) => MaybePromise<Option[]>;
    onChange: (key: Option['key'], item: Option) => void;
    /** @defaults 250 */
    debounce?: number;
    large?: boolean;
    triggerOnFocus?: boolean;
    inputProps?: Partial<TextInputProps>;
    popoverProps?: Partial<Popover2Props>;
    menuProps?: Partial<MenuProps>;
    clearable?: boolean;
}

interface AutocompleteState<Option extends MenuItemOption> {
    text: string;
    options: Option[];
    showMenu: boolean;
    highlight: number;
}

export class Autocomplete<Option extends MenuItemOption> extends React.PureComponent<
    AutocompleteProps<Option>,
    AutocompleteState<Option>
> {
    state: AutocompleteState<Option> = {
        text: '',
        options: [],
        showMenu: false,
        highlight: 0,
    };

    lastSearch: string | null = null;

    handleSearchDebounced = _.debounce(async (text: string) => {
        if (this.lastSearch === text) return;
        this.lastSearch = text;
        const options = await this.props.onSearch(text);
        if (this.lastSearch !== text) return; // another search was made in the meantime
        this.setState({
            options,
            showMenu: true,
            highlight: 0,
        });
    }, this.props.debounce ?? 250);

    onInput = (text: string) => {
        this.setState({ text });
        void this.handleSearchDebounced(text);
    };

    move(diff: 1 | -1) {
        const { options, highlight } = this.state;

        this.setState({
            showMenu: true,
            highlight: (options.length + highlight + diff) % options.length,
        });
    }

    onKeyDown: TextInputProps['onKeyDown'] = event => {
        const { options, showMenu, highlight } = this.state;
        const { onChange } = this.props;

        switch (event.key) {
            case 'Enter':
                if (showMenu) {
                    onChange(options[highlight].key, options[highlight]);
                    this.setState({ showMenu: false });
                }
                break;
            case 'ArrowDown':
                this.move(+1);
                break;
            case 'ArrowUp':
                this.move(-1);
                break;
            case 'Escape':
                if (showMenu) this.setState({ showMenu: false });
                break;
            case 'Tab':
                if (showMenu) this.setState({ showMenu: false });
                // allow key event to still propagate and change focus
                return;
            default:
                return;
        }

        event.preventDefault();
        event.stopPropagation();
    };

    onFocus = () => {
        if (!this.props.triggerOnFocus) return;

        this.onInput(this.state.text);
        setTimeout(() => {
            this.setState({ showMenu: true });
        }, 100); // increased delay when opening on focus to avoid click event causing dropdown to flicker out
    };

    hideMenu = () => this.setState({ showMenu: false });

    renderMenu() {
        const { large, onChange, menuProps } = this.props;
        const { options, highlight } = this.state;

        if (!options?.length) {
            return (
                <Menu large={large} {...menuProps}>
                    <MenuItem text='no results' disabled />
                </Menu>
            );
        }

        return (
            <Menu large={large} {...menuProps}>
                {options.map((opt, index) => (
                    <MenuItem
                        {...opt.props}
                        key={String(opt.key)}
                        text={opt.label}
                        active={highlight === index}
                        onClick={() => onChange(opt.key, opt)}
                        ref={
                            highlight !== index
                                ? null
                                : ref => {
                                      if (ref) {
                                          const node = ReactDOM.findDOMNode(ref) as Element;
                                          node.scrollIntoView({
                                              behavior: 'auto',
                                              block: 'nearest',
                                          });
                                      }
                                  }
                        }
                    />
                ))}
            </Menu>
        );
    }

    render() {
        const {
            loading,
            inputProps,
            onChange,
            onSearch,
            debounce,
            large,
            popoverProps,
            triggerOnFocus,
            menuProps,
            clearable,
            ...rest
        } = this.props;
        const { showMenu, text } = this.state;

        return (
            <Root {...rest}>
                <Popover2
                    canEscapeKeyClose
                    autoFocus={false}
                    position='bottom-left'
                    minimal
                    fill
                    matchTargetWidth
                    {...popoverProps}
                    isOpen={showMenu}
                    onClose={this.hideMenu}
                    content={this.renderMenu()}
                >
                    <TextInput
                        placeholder='Search ...'
                        fill
                        large={large}
                        {...inputProps}
                        value={text}
                        onChange={this.onInput}
                        loading={loading}
                        onKeyDown={this.onKeyDown}
                        onFocus={triggerOnFocus ? this.onFocus : undefined}
                        rightElement={
                            <Button
                                minimal
                                small
                                icon='cross'
                                onClick={() => onChange(null, {} as any)}
                            />
                        }
                    />
                </Popover2>
            </Root>
        );
    }
}

const Root = imported_stylus_components.Root;