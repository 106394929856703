import _ from 'lodash';
import * as pathUtils from '@pi/path-utils';
import textBlock from '@pi/text-block';
import Type from 'src/Type';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';
import { isObject } from 'src/utils/performanceFunctions';

import ValueNode from '../ValueNode';

import type { BooleanNodeT } from './BooleanNode';
import type { Expression, PrimitiveValue } from 'src/types';

export type TableNodeT = {
    $type: 'TableNode';
    input: Expression | PrimitiveValue;
    outputAsList: BooleanNodeT;
    flattenOutput: BooleanNodeT;
    keyMapper: Expression | PrimitiveValue;
    valueMapper: Expression | PrimitiveValue;
};
export default class TableNode extends ValueNode<TableNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode' },
        { key: 'flattenOutput', type: 'BooleanNode' },
        { key: 'outputAsList', type: 'BooleanNode' },
        { key: 'keyMapper', type: 'ValueNode' },
        { key: 'valueMapper', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            Transforms the data in a Table Field located at "path" and returns it.

            If "flattenOutput" is true, data for nested rows will appear on the root of the output object instead of within the row data it\'s nested under.

            The "keyMapper" allows you to redefine the top level keys used in the table. A $$key variable is defined inside the scope of "keyMapper" representing the key to be mapped.

            The "valueMapper" allows you to redefine the shape of rows. A $$row variable is defined inside the scope of "valueMapper" representing the row data.
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            flattenOutput: Primitive.boolean(false),
            outputAsList: Primitive.boolean(false),
            keyMapper: null,
            valueMapper: null,
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'input')) return 'missing "input"';
    }

    async execute() {
        const table = await this.context.evaluate(this.expression.input, { isOutput: true });
        if (!_.get(table, 'structure') || !_.get(table, 'columns')) return {};
        const inputIsString = this.expression.input && typeof this.expression.input === 'string';
        const inputPath = inputIsString
            ? this.expression.input
            : _.get(this, 'expression.input.value');
        const outputAsList = await this.context.evaluate(this.expression.outputAsList);
        const flattenOutput = await this.context.evaluate(this.expression.flattenOutput);
        const childrenKey = await this.getChildrenKey();

        let inputData = _.cloneDeep(table.structure);

        if (flattenOutput) {
            const flattenedInputData: any[] = [];
            const it = (array: any[]) => {
                array.forEach(item => {
                    flattenedInputData.push(item);
                    if (item.children) {
                        it(item.children);
                        delete item.children;
                    }
                });
            };
            it(inputData);
            inputData = flattenedInputData;
        }

        const outputData: { [key: string]: [] } = {};
        for (let i = 0; i < inputData.length; i++) {
            const data = inputData[i];
            const row = await this.mapRow({
                data,
                path: pathUtils.joinPaths(
                    String(inputPath).replace(/^\$\$?/, ''),
                    'structure',
                    i,
                    'value',
                ),
                columns: table.columns,
                outputAsList,
            });
            const key = await this.mapKey({
                key: _.get(data, 'value[0].value'),
            });
            outputData[key] = _.cloneDeep(row);
        }

        if (!outputAsList) {
            return outputData;
        }

        const output: any[] = [];
        const it = async (out: any[], object: { [key: string]: any }) => {
            for (const item of Object.values(object)) {
                out.push(item);
                if (item[childrenKey]) {
                    item[childrenKey] = await it([], item[childrenKey]);
                }
            }
            return out;
        };

        return it(output, outputData);
    }

    async getChildrenKey() {
        const inputIsString = this.expression.input && typeof this.expression.input === 'string';
        const inputPath = inputIsString
            ? this.expression.input
            : _.get(this, 'expression.input.value');
        return await this.mapKey({
            key: 'children',
            path: inputIsString ? pathUtils.joinPaths(String(inputPath).replace(/^\$\$?/, '')) : '',
        });
    }

    async mapKey({ key, path }: { key: string; path?: string }) {
        const { keyMapper } = this.expression;
        let output = key;
        if (!_.isEmpty(keyMapper)) {
            await this.context.variableBlock({
                path: path || '',
                variables: { key },
                callback: async sourcePath => {
                    const mappedKey = await this.context.evaluate(keyMapper, { sourcePath });
                    if (mappedKey) output = mappedKey;
                },
            });
        }

        return output;
    }

    async mapRow({
        data: { value, children },
        path,
        columns,
        outputAsList,
    }: {
        data: { value: any; children?: any[] };
        path: string;
        columns: any[];
        outputAsList: boolean;
    }) {
        const { valueMapper } = this.expression;

        let output: any = {};
        const firstColumnValue = columns[0].value || 'id';
        let childrenKey: string | null = await this.getChildrenKey();

        // map keys
        for (let index = 0; index < value.length; index++) {
            const key = await this.mapKey({
                key: index === 0 ? firstColumnValue : columns[index].value,
            });

            output[key] = value[index].value;
        }

        // map value
        if (!_.isEmpty(valueMapper)) {
            // provide the children as row.children to the mapper
            let row = output;
            if (isObject(output) && children?.length) {
                row = { ...output, children };
            }

            output = await this.context.variableBlock({
                path,
                variables: { row, children },
                callback: async sourcePath => this.context.evaluate(valueMapper, { sourcePath }),
            });

            // detect if the children are used in the output and remap them
            if (isObject(output) && children?.length) {
                childrenKey = null;
                for (const key in output) {
                    if (output[key] === children) {
                        childrenKey = key;
                        break;
                    }
                }
            }
        }

        // map children
        if (childrenKey && isObject(output)) {
            if (children?.length) {
                output[childrenKey] = {};
                let addChild = (key: string, child: any) => (output[childrenKey!][key] = child);

                if (outputAsList) {
                    output[childrenKey] = [];
                    addChild = (key, child) => output[childrenKey!].push(child);
                }

                for (const child of children) {
                    const row = await this.mapRow({
                        data: child,
                        columns,
                        path,
                        outputAsList,
                    });
                    const key = await this.mapKey({
                        key: _.get(child, 'value[0].value'),
                    });
                    addChild(key, row);
                }
            }

            if (output[childrenKey] == null) delete output[childrenKey];
        }

        return output;
    }
}
