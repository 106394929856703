import { Expression, nodes } from '@pi/transformer-compiler';
import _ from 'lodash';
import LruCache from 'src/utils/LruCache';
import type { NodeSelectOptionCreator } from '.';

export const getNodeName = _.memoize(key => _.startCase(stripNodeString(key)).replace(/\s+(\d+)/g, '$1'));
export const stripNodeString = _.memoize(key =>
    key != null ? String(key).replace(/Node/, '') : '❗Invalid Node❗'
);
const nodeList = Object.values(nodes);

const NULL_OPTION = { key: null, label: 'null' };

export default function createGetNodeSelectOptions(availableNodes: undefined | null | Expression['$type'][]) {
    const availableMap = availableNodes ? _.keyBy(availableNodes) : null;

    const creator: NodeSelectOptionCreator = expectedType => {
        const expectedName = expectedType?.name;
        // For anything other than ValueNode or Node limit options to these few
        if (expectedName && !['ValueNode', 'Node'].includes(expectedName!)) {
            return _.filter([
                { key: expectedName, label: getNodeName(expectedName) },
                { key: 'EvaluationNode', label: getNodeName('EvaluationNode') },
                NULL_OPTION,
            ]);
        }
        const validChildNodes = nodeList
            .filter(nodeClass => !availableMap || availableMap[nodeClass.name])
            .filter(nodeClass =>
                !expectedType || Object.prototype.isPrototypeOf.call(expectedType, nodeClass)
            )
            .map(({ name, uiConfig: { group } }) => ({
                key: name,
                group,
                label: getNodeName(name),
            }));

        return _.filter([
            NULL_OPTION,
            ...validChildNodes,
        ]);
    };

    const getNodeSelectOptions = LruCache.memoize(
        creator,
        expectedType => expectedType?.name || 'none',
        { maxSize: 100 },
    );

    return getNodeSelectOptions;
}
