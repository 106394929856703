import Node from './Node';

import type { BaseExpression, ParentContext } from '../types';

export default class ValueNode<T extends BaseExpression> extends Node<T> {
    /* @override */
    async execute(_config: ParentContext): Promise<any> {
        throw new Error('override me');
    }
}
