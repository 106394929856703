const imported_stylus_components = require('.cache/react-style-loader/src/components/CheckboxListField.styl');
import { Switch } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import type { ClassAndStyle, SelectMenuOption } from 'src/types';
import { Field, useOrGetField } from './Form';

export interface CheckboxListFieldProps<Option extends SelectMenuOption> extends ClassAndStyle {
    options: Option[];
    field: string | Field<Option['key'][]>;
}

export const CheckboxListField = observer(
    function CheckboxListField<Option extends SelectMenuOption, >(
        { field: f, options, ...props }: CheckboxListFieldProps<Option>
    ) {
        const field = useOrGetField<Option['key'][]>(f);

        return <Root {...props}>
            {options.map(opt => {
                const index = field.value.indexOf(opt.key);

                return <div key={String(opt.key)}>
                    <Switch
                        label={opt.label}
                        checked={index > -1}
                        inline
                        onChange={() => {
                            field.update(value => {
                                if (index > -1) value.splice(index, 1);
                                else value.push(opt.key);
                            });
                        }}
                    />
                </div>;
            })}
        </Root>;
    }
);

const Root = imported_stylus_components.Root;