import DbDraftCache from './DbDraftCache';

import type { DraftStoreName } from './db';

const dbCaches: { [K in DraftStoreName]: DbDraftCache<K> } = {
    formDrafts: new DbDraftCache('formDrafts'),
    transformerDrafts: new DbDraftCache('transformerDrafts'),
};

export default dbCaches;
