import Type from 'src/Type';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';

import type { BaseExpression, MongoId } from '../../types';

export interface ResourceNodeT extends BaseExpression {
    $type: 'ResourceNode';
    id: MongoId;
}

export default class ResourceNode extends ValueNode<ResourceNodeT> {
    static TYPE = Type.Object({
        key: 'id',
        type: 'StringNode',
        suggestions: 'external:Resource',
        forceSuggestion: true,
    });

    static uiConfig = {
        ...config.presets.default,
        style: {
            background: '#48AFF0',
            color: 'white',
        },
        description: 'Outputs the value of a resource defined in the admin UI',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            id: this.getDefaultFor('StringNode'),
        };
    }

    async execute() {
        const id = await await this.context.evaluate(this.expression.id);
        if (!id) return null;
        const data = await this.context.transformation.options.fetchResource(id);
        if (!data) throw new Error(`Resource not found "${id}"`);
        return data;
    }
}
