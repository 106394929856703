import _ from 'lodash';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';
import type { BaseExpression, ParentContext, Expression, PrimitiveValue } from '../../types';

export interface MappingNodeT extends BaseExpression {
    $type: 'MappingNode';
    input: Expression | PrimitiveValue;
    data: Expression | Expression[] | PrimitiveValue[] | { [key: string]: any };
    itemKey?: StringNodeT;
    dataKey: StringNodeT;
    dataValue?: StringNodeT;
    keepMissing?: BooleanNodeT;
}

/**
 * Usage:
    input = { foo: ['a', 'b', 'c'] }

    {
        $mapping: {
            input: '$foo',
            itemKey: undefined,
            dataKey: 'key',
            data: [
                { key: 'a', foo: 123, bar: 300 },
                { key: 'c', bar: 5 },
            ],
        },
    }
 */
export default class MappingNode extends ValueNode<MappingNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode' },
        { key: 'itemKey', type: 'StringNode', suggestions: 'off' },
        { key: 'dataKey', type: 'StringNode', suggestions: 'off' },
        { key: 'dataValue', type: 'StringNode', suggestions: 'off' },
        { key: 'data', type: 'ValueNode' },
        { key: 'keepMissing', type: 'BooleanNode' },
    );

    static uiConfig = {
        ...config.presets.list,
        description:
            'Takes items within "input" and matches them to items within "data" where "itemKey" and "dataKey" match',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            itemKey: this.getDefaultFor('StringNode'),
            dataKey: {
                $type: 'StringNode',
                value: 'key',
            },
            dataValue: this.getDefaultFor('StringNode'),
            data: this.getDefaultFor('JSONNode'),
            keepMissing: {
                $type: 'BooleanNode',
                value: false,
            },
        };
    }

    validate() {
        const { data, dataKey } = this.expression;
        if (!dataKey) return 'Missing dataKey';
        if (typeof dataKey !== 'string' && !dataKey.value) return 'dataKey is empty';
        if (!data) return 'Missing data';
    }

    async execute({ sourcePath }: ParentContext) {
        const {
            dataKey: dataKeyExpression,
            itemKey: itemKeyExpression,
            dataValue: dataValueExpression,
            keepMissing: keepMissingExpression,
        } = this.expression;
        const dataKey = await this.context.evaluate(dataKeyExpression);
        const itemKey = itemKeyExpression && (await this.context.evaluate(itemKeyExpression));
        const dataValue = dataValueExpression && (await this.context.evaluate(dataValueExpression));
        const keepMissing =
            keepMissingExpression && (await this.context.evaluate(keepMissingExpression));
        const data = await this.context.evaluate(this.expression.data as Expression, {
            sourcePath,
        });
        const input = await this.context.evaluate(this.expression.input, {
            sourcePath,
            mapped: data.map((x: any) => x[dataKey]),
        });
        const mapping: { [key: string]: any } = {};
        for (const value of data as any[]) {
            const key = value[dataKey];

            if (Array.isArray(key)) {
                for (const childKey of key) {
                    mapping[childKey] = value;
                }
            } else {
                mapping[key] = value;
            }
        }

        const mapper = (item: any) => {
            const prop = itemKey ? _.get(item, itemKey) : item;
            const result = mapping[prop];

            if (result == null) {
                if (!keepMissing) return null;

                return dataValue ? prop : { [dataKey]: prop, _isMissing: true };
            }

            return dataValue ? result[dataValue] : result;
        };

        if (Array.isArray(input)) {
            return input.map(mapper).filter(item => item != null);
        }

        return mapper(input);
    }
}
