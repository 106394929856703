import React, { useCallback } from 'react';
import { HTMLTable, type HTMLTableProps } from '@blueprintjs/core';
import { arrayRemove } from 'react-movable';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { type Field, useOrGetField } from './Form';
import { DragHandle, List, type ListProps, RemoveButton } from './List';

export interface TableListProps<Item = any>
    extends Omit<ListProps<Item>, 'renderItemContainer' | 'renderList'> {
    tableProps?: HTMLTableProps;
    renderHeader?: () => React.ReactNode;
}

export function TableList<Item = any>({
    removable,
    sortable,
    renderItem,
    tableProps,
    onChange,
    renderHeader,
    value,
    ...props
}: TableListProps<Item>) {
    const noopRenderItem = useCallback(() => null, []);

    const renderList: NonNullable<ListProps['renderList']> = useCallback(
        ({ children, props }) => (
            <HTMLTable {...tableProps}>
                {renderHeader && React.cloneElement(renderHeader() as any, { key: 'header' })}
                <tbody {...props}>{children}</tbody>
            </HTMLTable>
        ),
        [renderHeader, tableProps],
    );

    const renderItemContainer: NonNullable<ListProps['renderItemContainer']> = useCallback(
        props => (
            <tr {...props.props} tabIndex={-1}>
                {sortable && (
                    <td>
                        <DragHandle />
                    </td>
                )}
                {renderItem(props)}
                {removable && (
                    <td>
                        <RemoveButton
                            onClick={() => {
                                if (props.index == null) return;
                                onChange(arrayRemove(value, props.index));
                            }}
                        />
                    </td>
                )}
            </tr>
        ),
        [onChange, removable, renderItem, sortable, value],
    );

    return (
        <List
            {...props}
            value={value}
            onChange={onChange}
            sortable={sortable}
            removable={removable}
            renderItem={noopRenderItem}
            renderList={renderList}
            renderItemContainer={renderItemContainer}
        />
    );
}

export default TableList;

export interface TableListFieldProps<Item>
    extends Omit<TableListProps<Item>, 'value' | 'onChange'> {
    field: string | Field<Item[]>;
}

export const TableListField = observer(function TableListField<Item = any>({
    field: f,
    ...props
}: TableListFieldProps<Item>) {
    const field = useOrGetField(f);
    const onChange: TableListProps['onChange'] = useCallback(
        value => field.onChange(value),
        [field],
    );

    return <TableList {...props} value={field.value} onChange={onChange} />;
});
