import textBlock from '@pi/text-block';

import type { Expression } from 'src/types';
import type Context from 'src/Context';

export default class TransformerError extends Error {
    originalError!: Error;
    expressionStack: Context['expressionStack'] = [];
    name = 'TransformerError';

    constructor({ context, error }: { context: Context; error: Error }) {
        super();
        if (error instanceof TransformerError) return error;

        this.originalError = error;

        this.expressionStack = [...context.expressionStack];

        const path = this.expressionStack.map(({ value, meta }) => {
            let name = String((value as Expression)?.$type ?? value);
            name = name.replace(/Node$/, '').trim();
            name = name || '??';
            if (meta) name += ` [${meta}]`;
            return name;
        });

        this.message = textBlock`
            In: ${path.join(' > ')}
            -----------------------------------
            ${error.message}
        `;
    }
}
