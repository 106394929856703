import React from 'react';

export = function createComponent({
    name,
    baseClass,
    baseTag,
}: {
    name: string;
    baseClass: string;
    baseTag?: any;
}) {
    if (!baseTag) throw new Error(`createComponent(${name}): baseTag not provided. This should never happen`);

    const cls = [baseTag.className, baseClass].filter(x => x).join(' ');

    const result = React.forwardRef(({ Tag = baseTag, className, children, ...rest }: any, ref) => {
        for (const key in rest) {
            if (key.startsWith('data-') && typeof rest[key] === 'boolean') {
                if (rest[key]) rest[key] = '';
                else delete rest[key];
            }
        }

        return React.createElement(
            Tag,
            {
                ...rest,
                className: `${Tag.className || ''} ${className || ''} ${cls}`.trimStart(),
                ref
            },
            children,
        );
    });

    return Object.assign(result, {
        displayName: `${name}(${baseTag.displayName || baseTag})`,
        withComponent: (baseTag: any) => createComponent({ name, baseClass: cls, baseTag }),
        className: baseClass,
    });
}
