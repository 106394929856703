const imported_stylus_components = require('.cache/react-style-loader/src/components/TransformerBuilder.styl');
import React from 'react';
import { Button, ButtonGroup, Checkbox, Classes, Dialog, Tag, Tooltip } from '@blueprintjs/core';
import { Form, FormEvent, Field, type FieldMeta, type FieldInput } from '@pi/react-form';
import _ from 'lodash';
import { downloadJSON, TextInput } from '@pi/ui';
import { iterateExpression, type Expression } from '@pi/transformer-compiler';

import Tree, { type TreeContextType } from './Tree';
import { Column, ColumnTitle, ScrollContent } from './Layout';
import { NumericInputField } from './LegacyReactForm';

import type { TypeRendererConfig } from './TypeRenderers';
// import { Tooltip } from '@blueprintjs/popover2';

export interface TransformerBuilderProps {
    value: Expression;
    onChange?: (value: Expression) => void;
    getSuggestions?: TypeRendererConfig['getSuggestions'];
    title?: React.ReactNode;
    noHeader?: boolean;
    /** Only show the selected subset of nodes */
    includeNodes?: Expression['$type'][];
    argumentChangeHandlers?: TreeContextType['argumentChangeHandlers'];
}

/** @deprecated */
export interface TB_FieldProps extends Omit<TransformerBuilderProps, 'value' | 'onChange'> {
    input: FieldInput<Expression>;
    meta: FieldMeta;
}

const MIGRATED_SYMBOL = Symbol('TransformerMigrated');

export class TransformerBuilder extends React.PureComponent<TransformerBuilderProps> {
    static defaultProps: Partial<TransformerBuilderProps> = {
        getSuggestions: () => [],
    };

    lastExpressions: Expression[] = [];
    lastInitialValues?: any;

    /**
     * The point of this is to change the lastInitialValues ONLY IF the expression has changed
     */
    setInitialValues = (expression: Expression) => {
        if (this.lastExpressions?.includes(expression)) return;
        this.lastExpressions = [expression, this.props.value];
        this.lastInitialValues = { expression };
    };

    render() {
        const { value: expression, onChange, ...rest } = this.props;

        this.setInitialValues(expression);

        return (
            <Form initialValues={this.lastInitialValues}>
                <Field {...rest} name='expression' component={TransformerBuilderField} />

                {onChange && (
                    <FormEvent
                        on='post:update'
                        callback={({ getFormValue }) => {
                            const { expression } = getFormValue();
                            if (this.lastExpressions.includes(expression)) return;
                            this.lastExpressions = [expression, this.props.value];
                            onChange(expression);
                        }}
                    />
                )}
            </Form>
        );
    }
}

export default TransformerBuilder;

export class TransformerBuilderField extends React.PureComponent<TB_FieldProps> {
    props!: Required<TB_FieldProps>;

    static defaultProps: Partial<TB_FieldProps> = {
        getSuggestions: () => [],
    };

    state = {
        dialog: 'null',
        fileName: `transformation_${new Date().toString().split(' ').slice(1, 3).join('-')}`,
        uiFields: ['disabled'],
    };

    history: Expression[];
    initialized = false;

    constructor(props: TB_FieldProps) {
        super(props);

        // does not need to be part of state for perf reasons
        this.history = [props.input.value];
    }

    handleDrop = (event: DragEvent) => {
        event.preventDefault();
        if (!event.dataTransfer || !event.dataTransfer.files) return;
        const [file] = event.dataTransfer.files;
        if (!file) return;
        const reader = new FileReader();

        reader.onload = ({ target }) => {
            if (!target?.result) return;
            try {
                const transformation = JSON.parse(target.result as string);
                this.props.input.onChange(transformation);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        };

        reader.readAsText(file);
    };

    handleDownload = () => {
        const { fileName, uiFields } = this.state;

        const expression = iterateExpression({
            expression: this.props.input.value,
            mapper: (node, nodeClass) => {
                if (!nodeClass || !node?.$ui) return node;
                if (!uiFields.length) return _.omit(node, '$ui');
                return {
                    ...node,
                    $ui: _.pick(node.$ui, uiFields),
                };
            },
        });

        downloadJSON(`${fileName}.json`, expression, { pretty: true });
        this.setState({ dialog: null });
    };

    preventEvent = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const {
            input,
            meta,
            getSuggestions,
            title,
            noHeader,
            includeNodes,
            argumentChangeHandlers,
            ...rest
        } = this.props;
        const { dialog } = this.state;

        // perform migration once on init
        if (!this.initialized) {
            return (
                <FormEvent
                    on='init'
                    callback={({ context, getFormValue }) => {
                        const expression = _.get(getFormValue(), input.name);

                        if (!expression[MIGRATED_SYMBOL]) {
                            const migratedValue = iterateExpression({
                                expression,
                                mapper: (node, nodeClass) => nodeClass.migrate(node as any) as any,
                            });
                            migratedValue[MIGRATED_SYMBOL] = true;
                            context.changeField(input.name, migratedValue);
                            this.initialized = true;
                        } else {
                            this.initialized = true;
                            this.forceUpdate(); // no change triggered otherwise
                        }
                    }}
                />
            );
        }

        if (input.value && input.value !== _.last(this.history)) {
            this.history = _.filter([...this.history, input.value]).slice(-51); // keep the last 50 history states
        }

        const content = (
            <Tree
                getSuggestions={getSuggestions}
                includeNodes={includeNodes}
                argumentChangeHandlers={argumentChangeHandlers}
            />
        );

        if (noHeader) return content;

        return (
            <Column {...rest} onDrop={this.handleDrop as any} onDragOver={this.preventEvent as any}>
                <ColumnTitle>{title || 'Transformation'}</ColumnTitle>

                <ButtonGroup>
                    <Button
                        text={`Undo (${this.history.length - 1})`}
                        disabled={this.history.length <= 1}
                        icon='undo'
                        onClick={() => {
                            this.history.pop();
                            input.onChange(this.history.pop());
                        }}
                    />
                    <Button
                        text='Download'
                        icon='import'
                        onClick={() => this.setState({ dialog: 'download' })}
                    />
                    <Tooltip hoverOpenDelay={500} content='Default depth to collapse after'>
                        <Field
                            name='builderConfig.defaultCollapseDepth'
                            component={NumericInputField}
                            leftIcon='minimize'
                            min={1}
                            short
                        />
                    </Tooltip>
                    <Field
                        name='builderConfig.debug'
                        component={({ input }) => (
                            <Tooltip content='Toggle performance debugging to console'>
                                <Button
                                    icon='stopwatch'
                                    onClick={() => input.onChange(!input.value)}
                                    intent={input.value ? 'primary' : undefined}
                                />
                            </Tooltip>
                        )}
                    />
                </ButtonGroup>

                <Root style={{ paddingTop: '.75rem', paddingBottom: '5rem' }}>{content}</Root>

                <Dialog
                    isOpen={dialog === 'download'}
                    canEscapeKeyClose
                    canOutsideClickClose
                    usePortal
                    title='Download Transformation'
                    icon='import'
                    onClose={() => this.setState({ dialog: null })}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <DialogField label='File name'>
                            <TextInput
                                value={this.state.fileName}
                                onChange={fileName => this.setState({ fileName })}
                                rightElement={<Tag>.json</Tag>}
                            />
                        </DialogField>
                        <DialogField
                            label={
                                <>
                                    <div>Include disabled state</div>
                                    <Button small disabled icon='eye-off' />
                                </>
                            }
                        >
                            <Checkbox
                                checked={this.state.uiFields.includes('disabled')}
                                onChange={({ target: { checked } }: any) =>
                                    this.setState({
                                        uiFields: [
                                            ...this.state.uiFields.filter(x => x !== 'disabled'),
                                            ...(checked ? ['disabled'] : []),
                                        ],
                                    })
                                }
                            />
                        </DialogField>
                        <DialogField
                            label={
                                <>
                                    <div>Include collapsed state</div>
                                    <Button small disabled icon='chevron-right' />
                                </>
                            }
                        >
                            <Checkbox
                                checked={this.state.uiFields.includes('collapsed')}
                                onChange={({ target: { checked } }: any) =>
                                    this.setState({
                                        uiFields: [
                                            ...this.state.uiFields.filter(x => x !== 'collapsed'),
                                            ...(checked ? ['collapsed'] : []),
                                        ],
                                    })
                                }
                            />
                        </DialogField>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                text='Download'
                                intent='primary'
                                icon='import'
                                onClick={this.handleDownload}
                            />
                        </div>
                    </div>
                </Dialog>
            </Column>
        );
    }
}

const DialogField: React.FC<{ label: React.ReactNode; children: React.ReactNode }> = ({
    label,
    children,
}) => (
    <DialogFieldContainer>
        <DialogFieldLabel>{label}</DialogFieldLabel>
        {children}
    </DialogFieldContainer>
);

const Root = imported_stylus_components.Root.withComponent(ScrollContent);
const DialogFieldContainer = imported_stylus_components.DialogFieldContainer;
const DialogFieldLabel = imported_stylus_components.DialogFieldLabel;