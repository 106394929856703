const imported_stylus_components = require('.cache/react-style-loader/src/components/List.styl');
import React, { useCallback } from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { List as RMList, arrayMove, arrayRemove } from 'react-movable';
import { observer } from 'mobx-react';

import { useOrGetField } from './Form';
import { type ClassAndStyle } from '..';

import type { Field } from './Form';
import type { IProps } from 'react-movable';
import type { ButtonProps } from '@blueprintjs/core';

export interface ListProps<Item = any>
    extends ClassAndStyle,
        Omit<Partial<IProps<Item>>, 'values' | 'onChange' | 'renderItem' | 'renderList'> {
    readonly value: Item[];
    onChange: (newValue: Item[]) => void;
    /**
     * Called when adding a new item to the list via the "add more" button
     */
    getAddMore?: () => Item;
    addMoreDisabled?: boolean;
    renderAddMore?: (props: { addMore: (item: Item) => void }) => React.ReactNode;
    /**
     * Main method that renders each item in the `value`
     * Called once for every element in `value`
     */
    renderItem: NonNullable<IProps<Item>['renderItem']>;
    renderItemContainer?: IProps<Item>['renderItem'];
    renderList?: IProps<Item>['renderList'];
    removable?: boolean;
    sortable?: boolean;
    plain?: boolean;
}

export class List<Item = any> extends React.PureComponent<ListProps<Item>> {
    onChange: IProps<Item>['onChange'] = ({ oldIndex, newIndex }) => {
        const { value, onChange } = this.props;

        return onChange(arrayMove(value, oldIndex, newIndex));
    };

    renderList: IProps<Item>['renderList'] = args => {
        const { renderList } = this.props;

        if (renderList) return renderList(args);

        return <ListContainer {...args.props}>{args.children}</ListContainer>;
    };

    renderItem: IProps<Item>['renderItem'] = options => {
        const { renderItemContainer, renderItem, removable, onChange, value, sortable } =
            this.props;

        const { props: baseItemProps, isDragged, isOutOfBounds, isSelected, index } = options;
        const itemProps = {
            ...baseItemProps,
            'data-dragged': isDragged,
            'data-index': index,
            'data-out-of-bounds': isOutOfBounds,
            'data-selected': isSelected,
        };
        options = { ...options, props: itemProps };
        if (renderItemContainer) return renderItemContainer(options) as any;

        return (
            <Item {...options.props} tabIndex={-1}>
                {sortable && <DragHandle />}
                <ItemInner>{renderItem(options)}</ItemInner>
                {removable && index != null && (
                    <RemoveButton onClick={() => onChange(arrayRemove(value, index))} />
                )}
            </Item>
        );
    };

    renderRoot = (list: React.ReactNode) => {
        const {
            getAddMore,
            addMoreDisabled,
            renderAddMore,
            value,
            onChange,
            style,
            className,
            plain,
        } = this.props;

        const addMore = (item: Item) => onChange([...(value || []), item]);

        return (
            <Root style={style} className={className} data-plain={plain}>
                {list}
                {(getAddMore || renderAddMore) &&
                    (renderAddMore ? (
                        renderAddMore({ addMore })
                    ) : (
                        <Button
                            intent='primary'
                            icon='plus'
                            text='Add More'
                            type='button'
                            className='mt05'
                            disabled={addMoreDisabled}
                            onClick={() => addMore(getAddMore!())}
                        />
                    ))}
            </Root>
        );
    };

    render() {
        const { removable, sortable, value, getAddMore, addMoreDisabled, ...props } = this.props;

        if (sortable) {
            return this.renderRoot(
                <RMList
                    lockVertically
                    {...props}
                    values={value || []}
                    onChange={this.onChange}
                    renderItem={this.renderItem}
                    renderList={this.renderList}
                />,
            );
        }

        return this.renderRoot(
            this.renderList({
                isDragged: false,
                props: {
                    ref: null as any,
                },
                children: (value || []).map((value, index) =>
                    this.renderItem({
                        value,
                        index,
                        isDragged: false,
                        isOutOfBounds: false,
                        isSelected: false,
                        props: {},
                    }),
                ),
            }),
        );
    }
}

export interface ListFieldProps<Item> extends Omit<ListProps<Item>, 'value' | 'onChange'> {
    field: string | Field<Item[]>;
}

export const ListField = observer(function ListField<Item = any>({
    field: f,
    ...props
}: ListFieldProps<Item>) {
    const field = useOrGetField(f);
    const onChange: ListProps['onChange'] = useCallback(value => field.onChange(value), [field]);

    return <List {...props} value={field.value} onChange={onChange} />;
});

export const DragHandle = () => (
    <DragHandleIcon icon='drag-handle-vertical' data-movable-handle tabIndex={-1} />
);

export const RemoveButton: React.FC<ButtonProps> = props => (
    <Button intent='danger' icon='trash' minimal small tabIndex={-1} {...props} />
);

const Root = imported_stylus_components.Root;
const ListContainer = imported_stylus_components.ListContainer;
const ItemInner = imported_stylus_components.ItemInner;
const Item = imported_stylus_components.Item;
const DragHandleIcon = imported_stylus_components.DragHandleIcon.withComponent(Icon);