import React from 'react';
import { Callout } from '@blueprintjs/core';

export default function ExecuteError({ error }: { error: Error | string }) {
    return (
        <Callout intent='danger' title='Error Occurred' style={{ paddingTop: '10px' }}>
            <pre
                style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowX: 'auto',
                }}
            >
                {typeof error === 'string' ? error : String(error.message || error.stack || error)}
            </pre>
        </Callout>
    );
}
