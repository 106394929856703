import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';

import Type from '../../Type';
import config from '../../utils/config';
import ValueNode from '../ValueNode';

import type { BaseExpression, ParentContext, Expression } from 'src/types';

export interface OrNodeT extends BaseExpression {
    $type: 'OrNode';
    value: Expression[];
}

export default class OrNode extends ValueNode<OrNodeT> {
    static TYPE = Type.RestArray();

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            Returns \`true\` if at least one child expressions is truthy

            ### Example

            \`\`\`
            Or
                - Equals
                    - $name
                    - "Apple"
                - Equals
                    - $brand
                    - "365 Everyday Value"
            \`\`\`

            Will return \`true\` if **either** the "name" field is "Apple" **or** the brand is "365"
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [Primitive.evaluation(''), Primitive.evaluation('')],
        };
    }

    async execute({ sourcePath }: ParentContext) {
        for (const [index, expr] of this.expression.value.entries()) {
            if (await this.context.evaluateLog(index, expr, { sourcePath })) return true;
        }

        return false;
    }
}
