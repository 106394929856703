const imported_stylus_components = require('.cache/react-style-loader/src/pages/TaskDefinition/components/PinnedTaskDefinition.styl');
import { useAppContext } from 'src/components/AppContext';
import { Button, MenuItem, Portal } from '@blueprintjs/core';
import { useToggleState } from '@pi/ui';

import TaskTree from './TaskTree';
import TaskPipeline from './TaskPipeline';

export interface PinnedTaskDefinitionProps {}

const PinnedTaskDefinition: React.FC<PinnedTaskDefinitionProps> = () => {
    const { pinnedTaskDefinition } = useAppContext();
    const collapsed = useToggleState();

    if (!pinnedTaskDefinition) return null;

    if (collapsed.value) {
        return (
            <MenuItem text='Show Pinned Task' icon='layout-hierarchy' onClick={collapsed.toggle} />
        );
    }

    return (
        <Portal>
            <Root>
                <TaskTree
                    taskId={pinnedTaskDefinition}
                    style={{ width: '20rem', flex: '0 0 auto' }}
                />
                <TaskPipeline taskId={pinnedTaskDefinition} style={{ flex: '1 1 0' }} />
                <Button
                    intent='primary'
                    icon={collapsed.value ? 'maximize' : 'minimize'}
                    text={collapsed.value ? 'Expand' : 'Collapse'}
                    onClick={collapsed.toggle}
                    style={{
                        position: 'absolute',
                        top: '.5rem',
                        right: '.5rem',
                        zIndex: 'var(--z-index-popup)',
                    }}
                />
            </Root>
        </Portal>
    );
};

export default PinnedTaskDefinition;

const Root = imported_stylus_components.Root;