const imported_stylus_components = require('.cache/react-style-loader/src/components/JSONRenderer.styl');
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import memoizeOne from 'memoize-one';

import type { HTMLDivProps } from 'src/types';
import { addPassiveDOMListener } from 'src/utils/addPassiveListener';

/**
 * A component used to render every row in a JSONRenderer
 */
export type JSONRowRenderer = React.ComponentType<
    {
        data: JsonRow;
        /** Total number of rows */
        total: number;
        /** Render line numbers at the start of every row. Useful for debugging */
        lineNumbers: boolean;
    }
    & HTMLDivProps
>;

export interface JSONRendererProps extends Omit<HTMLDivProps, 'onError'> {
    data: any;
    /**
     * When enabled, the input data will be passed through `JSON.parse(JSON.stringify(data))` to
     * ensure it's proper JSON.
     * This has some consequences like removing undefined-s from the output, throwing an exception if the JSON is cyclical.
     * It also has a performance impact, especially with large inputs
     */
    cleanData?: boolean;
    /**
     * Sort object keys when rendering
     */
    sortKeys?: boolean;
    /**
     * Passing this will cause the JSONRenderer to become editable
     */
    onChange?: (data: any) => void;
    /**
     * If `onChange` is enabled, this handler will be called whenever the inputted JSON is not valid
     */
    onError?: (error: Error) => void;
    /**
     * You can use a custom renderer to add extra features.
     * E.g. JSONCoverage overrides the renderer to add coverage highlighting
     */
    RowRenderer?: JSONRowRenderer;
    /**
     * Only render rows that are visible in the viewport and update it as it scrolls
     *
     * *NOTE:* This should work paired with edit mode (via `onChange`), lazy rendering is disabled during focus
     */
    lazyRender?: boolean;
    /**
     * A positive integer depth value after which to stop showing rows
     * Useful for getting an overview of the data in large JSONs
     */
    collapseDepth?: number;
    /**
     * Render line numbers at the start of every row
     * Useful for debugging
     */
    lineNumbers?: boolean;
}

export type JsonRow = {
    /**
     * An unique string that is used as the react component key prop
     */
    id: string;
    index: number;
    path: (string | number)[];
    /**
     * All the primitive types (from `typeof item`) and 2 custom types: null and syntax
     */
    type: 'null' | 'syntax' | 'string' | 'boolean' | 'number' | 'object';
    value: any;
    depth: number;
    key?: string;
    comma?: boolean;
    indent?: number;
};

export class DefaultRowRenderer extends React.Component<React.ComponentProps<JSONRowRenderer>> {
    render () {
        const {
            data: { type, value, key, comma, depth },
            total,
            lineNumbers,
            ...rest
        } = this.props;
        let prefix;

        if (key) prefix = <ObjectKey>{`"${key}": `}</ObjectKey>;

        return <Item {...rest}>
            {lineNumbers &&
                String(this.props.data.index).padStart(Math.ceil(Math.log10(total))) + ' | '
            }
            {depth > 0 && '    '.repeat(depth)}
            {prefix}
            <span data-type={type}>{type === 'string' ? `"${value}"` : String(value)}</span>
            {comma && ','}
        </Item>;
    }
}

/**
 * Renders a plain JS object as JSON using react components
 * The given object is flattened and rendered row-by-row.
 *  This allows for some optimizations like not rendering rows that are outside of the viewport
 */
export class JSONRenderer extends React.PureComponent<JSONRendererProps> {
    static propTypes = {
        data: PropTypes.any,
        cleanData: PropTypes.bool,
        sortKeys: PropTypes.bool,
        RowRenderer: PropTypes.elementType.isRequired,
    };

    static defaultProps = {
        cleanData: false,
        sortKeys: false,
        RowRenderer: DefaultRowRenderer,
    };

    static getDerivedStateFromProps(nextProps: JSONRendererProps, state: any) {
        if (state._data === nextProps.data && state._lazyRender === nextProps.lazyRender) return null;

        return {
            // change the root key every time data changes to force re-render and prevent weird edit behaviors with keys potentially not changing
            key: Math.random(),
            // data or lazyRender change should re-initialze
            initialized: false,
            // cache props in state to compare next time since there's no more componentWillReceiveProps
            _data: nextProps.data,
            _lazyRender: nextProps.lazyRender,
        };
    }

    state = {
        key: null,
        focused: false,
        // -- lazyRender state --
        initialized: false,
        lineHeight: 0,
        startIndex: 0,
        endIndex: 0,
    };

    _removeScrollListener?: ReturnType<typeof addPassiveDOMListener>;
    _rootElem?: HTMLDivElement;

    componentWillUnmount(){
        this._removeScrollListener?.();
    }

    baseGetData = memoizeOne((data, cleanData, sortKeys, collapseDepth) => {
        if (cleanData) {
            data = (
                data === null
                ? 'null'
                : data === undefined
                ? 'undefined'
                : JSON.parse(JSON.stringify(data))
            );
        }

        let flatData = flattenJson(data, { sortKeys });
        if (collapseDepth) flatData = flatData.filter(row => row.depth <= collapseDepth);

        return {
            flatData,
            maxDepth: flatData.reduce((max, row) => row.depth > max ? row.depth : max, 0),
        };
    });

    getData() {
        const { data, cleanData, onChange, sortKeys, collapseDepth } = this.props;
        const { focused } = this.state;
        return this.baseGetData(
            data,
            cleanData || !!onChange,
            sortKeys,
            // disable collapsedDepth when focused (in edit mode) to prevent invalid JSON
            focused ? undefined : collapseDepth,
        );
    }

    setVisibleRange = (startIndex: number, endIndex: number) => {
        startIndex = Math.round(startIndex);
        endIndex = Math.round(endIndex);

        if (startIndex !== this.state.startIndex || endIndex !== this.state.endIndex) {
            this.setState({ startIndex, endIndex });
        }
    };

    updateVisible = () => {
        if (!this._rootElem) return this.setVisibleRange(0, 0);

        const { lineHeight } = this.state;
        const { flatData } = this.getData();
        const windowInnerHeight = window.innerHeight;
        const toRenderCount = Math.round(windowInnerHeight / lineHeight);
        const { top, bottom } = this._rootElem.getBoundingClientRect();
        const bufferCount = Math.round(toRenderCount * 0.5); // how many extra to render before/after

        // do not render anything when out of bounds
        if (top > windowInnerHeight || bottom < 0) return this.setVisibleRange(0, 0);

        let startIndex: number, endIndex: number;

        if (top > 0) {
            startIndex = 0;
            // toRenderCount -= top / lineHeight;
        } else startIndex = top * -1 / lineHeight;

        startIndex = _.clamp(startIndex - bufferCount, 0, flatData.length);
        endIndex = _.clamp(startIndex + toRenderCount + bufferCount * 2, startIndex, flatData.length);

        this.setVisibleRange(startIndex, endIndex);
    };

    updateVisibleThrottled = _.throttle(this.updateVisible, 16);
    updateVisibleOnInit = _.once(this.updateVisible);

    setRootRef = (elem: HTMLDivElement) => {
        if (this._rootElem && (!elem || elem === this._rootElem)) return;
        this._rootElem = elem;
        this.updateVisibleOnInit();
        this._removeScrollListener?.();
        const listeners = getScrollParents(this._rootElem).map(parent =>
            addPassiveDOMListener(
                parent,
                'scroll',
                this.updateVisibleThrottled
            )
        );
        this._removeScrollListener = () => {
            listeners.forEach(unbind => unbind());
        };
    };

    render() {
        const {
            data,
            cleanData,
            sortKeys,
            onChange,
            RowRenderer,
            onError,
            lazyRender,
            collapseDepth,
            lineNumbers,
            ...rest
        } = this.props as Required<JSONRendererProps>;
        const { key, initialized, lineHeight, startIndex, endIndex, focused } = this.state;
        const rootProps = {
            ...rest,
            key,
        };
        const { flatData } = this.getData();

        if (onChange) {
            Object.assign(rootProps, {
                contentEditable: 'plaintext-only',
                suppressContentEditableWarning: true,
                spellCheck: false,
                onFocus: () => this.setState({ focused: true }),
                onBlur: ({ target: { innerText } }: React.ChangeEvent<HTMLDivElement>) => {
                    this.setState({ focused: false });
                    try {
                        const newValue = JSON.parse(innerText);
                        onChange(newValue);
                    } catch (e: any) {
                        if (onError) onError(e);
                    }
                },
            });
        }

        const renderContent = (rowList: JsonRow[]) =>
            <Root {...rootProps}>
                {rowList.map(row =>
                    <RowRenderer
                        key={row.id}
                        data={row}
                        total={flatData.length}
                        lineNumbers={lineNumbers}
                    />
                )}
            </Root>;

        // No point optimizing for small datasets
        if (!lazyRender || focused || flatData.length < 100) {
            return renderContent(flatData);
        }

        if (!initialized) {
            return <div
                {...rest}
                style={{ ...rest.style, padding: 0, visibility: 'hidden' }}
                children='Test'
                ref={elem => {
                    if (!elem) return;
                    this.setState({ initialized: true, lineHeight: elem.offsetHeight });
                }}
            />;
        }

        Object.assign(rootProps, {
            style: {
                ...rest.style,
                height: 'unset',
                minHeight: this.state.lineHeight * flatData.length,
                paddingTop: lineHeight * startIndex,
            },
            ref: this.setRootRef,
        });

        return renderContent(
            initialized
                ? flatData.slice(startIndex, endIndex)
                : []
        );
    }
}

export default JSONRenderer;

function getScrollParents(node: HTMLElement) {
    const result: (HTMLElement | Window)[] = [];

    let current: HTMLElement | null = node;
    while (current) {
        // using the bellow condition is not robust enough
        // it fails to pick up scroll parents when the children is smaller than the parent height
        // for our purposes we want all parents that have a potential scroll and just getting all parents
        // is an easy way to achieve it
        // if (current.scrollHeight > current.clientHeight) {
        result.push(current);
        current = current.parentElement;
    }

    if (!result.includes(window)) result.unshift(window);

    return result;
}

const SLASH_REG = /\\/g;
const NEWLINE_REG = /\n/g;
const QUOTE_REG = /"/g;

/**
 * Converts a deeply nested JSON object into a flat list of JsonRow types
 * This is the main datatype used by the renderers
 */
const flattenJson = (obj: any, { sortKeys } = { sortKeys: false }) => {
    const result: JsonRow[] = [];

    const iterate = (
        item: any,
        path: JsonRow['path'],
        key?: JsonRow['key'],
        comma?: JsonRow['comma'],
    ) => {
        let itemType: JsonRow['type'] = 'null';
        let rowCount = 0;
        const add = (type: JsonRow['type'], value: JsonRow['value']) => {
            // escape common parsing corner-cases
            if (type === 'string') {
                value = value.replace(SLASH_REG, '\\\\');
                value = value.replace(NEWLINE_REG, '\\n');
                value = value.replace(QUOTE_REG, '\\"');
            }

            const row: JsonRow = {
                id: path.join('.') + '_' + rowCount,
                index: result.length,
                path,
                type,
                value,
                depth: path.length,
            };

            if (rowCount === 0) row.key = key;

            // all cases for when to add a comma (if it exists) at the end of a row
            if (rowCount > 0 || type !== 'syntax' || (itemType === 'object' && _.isEmpty(item))) {
                row.comma = comma;
            }

            result.push(row);
            ++rowCount;
        };

        if (item === null) return add('null', 'null');
        if (item === undefined) return add('null', 'undefined');

        if (Array.isArray(item)) {
            itemType = 'object';
            if (!item.length) return add('syntax', '[]');
            add('syntax', '[');
            item.forEach((child, index) => {
                iterate(child, [...path, index], undefined, index < item.length - 1);
            });
            add('syntax', ']');
            return;
        }

        itemType = typeof item as JsonRow['type'];

        if (itemType === 'object') {
            if (_.isEmpty(item)) return add('syntax', '{}');
            add('syntax', '{');
            const pairs = Object.entries(item);
            if (sortKeys) pairs.sort((a, b) => a[0].localeCompare(b[0]));
            for (const [key, value] of pairs) {
                iterate(value, [...path, key], key, key !== _.last(pairs)?.[0]);
            }
            add('syntax', '}');
            return;
        }

        add(itemType, item);
    };

    iterate(obj, []);

    return result;
};

const Root = imported_stylus_components.Root;
const Item = imported_stylus_components.Item;
const ObjectKey = imported_stylus_components.ObjectKey;