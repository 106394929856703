const imported_stylus_components = require('.cache/react-style-loader/src/components/ContentEditableInput.styl');
import React from 'react';
import type { FieldInput, FieldMeta } from '@pi/react-form';
import type { Assign } from 'utility-types';
import type { HTMLDivProps } from '@pi/ui';

type CEI_Props = Assign<HTMLDivProps, {
    value: string;
    onChange: (val: string) => void;
    autoFocus?: boolean;
}>;

type CEI_FieldProps = Assign<Omit<CEI_Props, 'value' | 'onChange'>, {
    input: FieldInput<string>;
    meta: FieldMeta;
}>;



class ContentEditableInput extends React.Component<CEI_Props> {
    handleChange = (event: any) => {
        const { onChange, onBlur, value } = this.props;
        const newValue = event.target.innerText.trim();

        if (newValue !== value) onChange(newValue);

        onBlur && onBlur(event);
    };

    render() {
        const { value, onChange, onBlur, autoFocus, ...rest } = this.props;

        const props: any = {
            ...rest,
            spellCheck: 'false',
            contentEditable: 'plaintext-only',
            suppressContentEditableWarning: true,
            onBlur: this.handleChange,
            'data-highlight': value && (value[0] === '$' || value.includes('{$')),
            children: value,
        };

        if (autoFocus) {
            props.ref = (elem: HTMLDivElement) => elem?.focus();
        }

        return <Root {...props} />;
    }
}

export default ContentEditableInput;

export const ContentEditableInputField: React.FC<CEI_FieldProps> = ({ input, meta, ...props }) =>
    <ContentEditableInput
        {...props}
        value={input.value}
        onChange={input.onChange}
    />;

const Root = imported_stylus_components.Root;