import { openDB } from 'idb';

import type { DBSchema, IDBPDatabase } from 'idb';
import type { Expression } from '@pi/transformer-compiler';
import type { OmitIndex } from '@pi/types';
import type { FormInput } from 'src/generated/graphql';
import type { DbDraftEntry } from './DbDraftCache';

export interface DBShape extends DBSchema {
    formDrafts: DbDraftEntry<FormInput>;
    transformerDrafts: DbDraftEntry<Expression>;
}

export type DraftStoreName = keyof OmitIndex<DBShape> & `${string}Drafts`;

let db: IDBPDatabase<DBShape>;

export default function getDb(): IDBPDatabase<DBShape> {
    return db;
}

export async function startDb(): Promise<IDBPDatabase<DBShape>> {
    db = await openDB<DBShape>('admin-db', 1, {
        upgrade(db) {
            initDbDraftCache(db, 'formDrafts');
            initDbDraftCache(db, 'transformerDrafts');
        },
    });

    return db;
}

export function deleteKeys(store: keyof OmitIndex<DBShape>, keys: any[]): Promise<void> {
    if (!keys.length) return Promise.resolve();
    const transaction = db.transaction(store, 'readwrite');
    for (const key of keys) {
        void transaction.objectStore(store).delete(key);
    }
    return transaction.done;
}

function initDbDraftCache(dbInstance: typeof db, storeName: DraftStoreName): void {
    const store = dbInstance.createObjectStore(storeName, { keyPath: 'hash' });
    store.createIndex('hash', 'hash');
    store.createIndex('id', 'id');
    store.createIndex('date', 'date');
}
