import iterateExpressionItems from 'src/utils/iterateExpressionItems';
import ellipsis from 'src/utils/ellipsis';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { Expression, BaseExpression } from 'src/types';
import type { StringNodeT } from './StringNode';

export interface FilterNodeT extends BaseExpression {
    $type: 'FilterNode';
    input: Expression;
    as: StringNodeT;
    cond: Expression;
}

export default class FilterNode extends ValueNode<FilterNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode', suggestions: 'array' },
        { key: 'as', type: 'StringNode', suggestions: 'off' },
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'primitive' },
    );

    static uiConfig = {
        ...config.presets.list,
        description:
            'Filter an array based on a given condition, use $$item to reference array items in the condition',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            as: {
                $type: 'StringNode',
                value: 'item',
            },
            cond: {
                ...this.getDefaultFor('EqualsNode'),
                value: [
                    { $type: 'EvaluationNode', value: '$$item' },
                    this.getDefaultFor('EvaluationNode'),
                ],
            },
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'input')) return 'missing "type"';
        if (!Reflect.has(this.expression, 'as')) return 'missing "as"';
        if (!Reflect.has(this.expression, 'cond')) return 'missing "cond"';
    }

    async execute() {
        const items = this.expression.input;
        const itemName = await this.context.evaluate(this.expression.as);
        const output: any[] = [];
        await iterateExpressionItems({
            items,
            itemName,
            context: this.context,
            callback: async ({ sourcePath, value, key }) => {
                this.context.setLastStackEntryMeta(
                    `index: ${key}, ${itemName}: ${ellipsis(20, value)}`,
                );
                if (!(await this.context.evaluate(this.expression.cond, { sourcePath }))) return;
                output.push(value);
            },
        });
        return output;
    }
}
