const imported_stylus_components = require('.cache/react-style-loader/src/components/Dialog.styl');
import { Dialog as BlueprintDialog, Classes, Button } from '@blueprintjs/core';

import { Form, type FormProps } from './Form';
import { useUiContext } from './UiContext';

import type { DialogProps as BlueprintDialogProps } from '@blueprintjs/core';

export interface DialogProps extends BlueprintDialogProps {
    actions?: React.ReactNode;
    /** Optionally wrap the content in a <Form /> with the given props */
    form?: Omit<FormProps<any, any>, 'children'> & { key?: string };
    noCloseButton?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
    children,
    onClose,
    actions,
    form,
    noCloseButton,
    ...props
}) => {
    const { darkMode } = useUiContext();

    let content = (
        <>
            {children && <div className={Classes.DIALOG_BODY}>{children}</div>}

            <div className={Classes.DIALOG_FOOTER}>
                <Actions className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {!noCloseButton && <Button text='Cancel' intent='none' onClick={onClose} />}
                    {actions}
                </Actions>
            </div>
        </>
    );

    if (form) content = <Form {...form}>{content}</Form>;

    return (
        <BlueprintDialog
            canEscapeKeyClose
            canOutsideClickClose
            lazy
            hasBackdrop
            {...props}
            onClose={onClose}
            className={`${StyledDialog.className} ${darkMode && 'bp4-dark'}`}
        >
            {content}
        </BlueprintDialog>
    );
};

const StyledDialog = imported_stylus_components.StyledDialog;
const Actions = imported_stylus_components.Actions;