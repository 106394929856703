export function downloadURL(data: string, fileName: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = formatFileName(fileName);
    document.body.appendChild(a);
    a.style.display = 'none';
    a.click();
    a.remove();
}

export function downloadBlob(fileName: string, mimeType: string, data: string | Blob) {
    let blob: Blob;
    if (typeof data === 'string') blob = new Blob([data], { type: mimeType });
    else blob = data;
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName);
    setTimeout(() => window.URL.revokeObjectURL(url), 1000);
}

export function downloadJSON(fileName: string, data: any, { pretty = false } = {}) {
    downloadBlob(fileName, 'application/json', JSON.stringify(data, null, pretty ? 4 : undefined));
}

export function formatFileName(name: string, { spaces = true } = {}) {
    name = name
        .replace(/[^a-z0-9!@#$%^&*()_+\-=[\]{};'",.<>?|]/gi, ' ')
        .replace(/\s+/g, ' ')
        .replace(/\s*-\s*/g, '-');
    if (!spaces) name = name.replace(/\s+/g, '_');
    return name;
}
