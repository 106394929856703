import { observer } from 'mobx-react';
import { Switch } from '@blueprintjs/core';
import { useCallback } from 'react';

import { type MakeFieldProps, useOrGetField } from './Form';

import type { SwitchProps } from '@blueprintjs/core';

export interface SwitchFieldProps
    extends MakeFieldProps<
        Omit<SwitchProps, 'checked' | 'value' | 'onChange'> & { value?: boolean }
    > {
    onChange?: (value: boolean) => void;
}

export const SwitchField: React.FC<SwitchFieldProps> = observer(({ field, onChange, ...props }) => {
    const f = useOrGetField(field);
    const handleChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.checked;
            onChange?.(value);
            f.onChange(value);
        },
        [f, onChange],
    );

    return <Switch {...props} checked={!!f.value} onChange={handleChange} />;
});
