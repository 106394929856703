import _ from 'lodash';
import { TransformerEditor } from '@pi/transformer';
import TransformerWorker from 'src/workers/Transformer/TransformerWorker';
import { ValueNode } from '@pi/transformer-compiler';
import apolloClient from 'src/startup/apollo';
import { gql } from '@apollo/client';
import {
    DataRequirementKindEnum,
    DataRequirementPrimitivePrimitiveTypeEnum,
    ModelNameEnum,
} from 'src/generated/graphql';
import DataRequirementFragment from 'src/generated/fragments/DataRequirementFragment';
import TaskDefinitionFragment from 'src/generated/fragments/TaskDefinitionFragment';

import type { Expression, InternalSuggestions } from '@pi/transformer-compiler';
import type {
    DataRequirement,
    DataRequirementPrimitive,
    Form,
    FormFindQuery,
    FormulaResource,
    FormulaResourceFindQuery,
    Pv2AttributeDefinition,
    TaskDefinitionFindQuery,
    TransformerResource,
    TransformerResourceFindQuery,
} from 'src/generated/graphql';
import type { FindQueryItemType } from 'src/types';
import type { TransformerBuilderProps, TransformerEditorProps } from '@pi/transformer';
import type { SelectMenuOption } from '@pi/ui';

const worker = new TransformerWorker();
interface OptionWithItem<T> extends SelectMenuOption {
    item: T;
}
export const resourceOptions: {
    FormulaResource: Array<OptionWithItem<FindQueryItemType<FormulaResourceFindQuery>>>;
    TransformerResource: Array<OptionWithItem<FindQueryItemType<TransformerResourceFindQuery>>>;
    TaskDefinition: Array<OptionWithItem<FindQueryItemType<TaskDefinitionFindQuery>>>;
    Form: Record<string, { dataRequirements: Form['dataRequirements'] }>;
} = {
    FormulaResource: [],
    TransformerResource: [],
    TaskDefinition: [],
    Form: {},
};
let attributes: SelectMenuOption[] = [];

export default async function setupTransformerEditor(): Promise<void> {
    TransformerEditor.setGlobalOptions({
        argumentChangeHandlers,
        contextExecute: worker.contextExecute.bind(worker),
        // checkCoverage: worker.checkCoverage.bind(worker),
        checkCoverage: async () => ({ covered: [], error: null, notCovered: [], warn: [] }),
        getSuggestions: defaultGetSuggestions,
    });

    await Promise.all([
        apolloClient
            .query<NonNullable<FormFindQuery>>({
                variables: { limit: 10e3 },
                query: FORM_FIND_QUERY,
            })
            .then(({ data }) => {
                resourceOptions.Form = Object.fromEntries(
                    data.Form_find!.results.map(item => [item._id, item]),
                );
            }),
        apolloClient
            .query<NonNullable<TaskDefinitionFindQuery>>({
                variables: { limit: 10e3 },
                query: TASK_DEFINITION_FIND_QUERY,
            })
            .then(({ data }) => {
                resourceOptions.TaskDefinition = data.TaskDefinition_find!.results.map(td => ({
                    key: td._id,
                    label: td.name,
                    item: td,
                }));
            }),
        apolloClient
            .query({
                variables: { limit: 10e3 },
                query: FORMULA_RESOURCE_FIND_QUERY,
            })
            .then(({ data }) => {
                resourceOptions.FormulaResource = data.FormulaResource_find.results.map(
                    (doc: FormulaResource) => ({
                        key: doc._id,
                        label: doc.name,
                        item: doc,
                    }),
                );
            }),
        apolloClient
            .query({
                variables: { limit: 10e3 },
                query: TRANSFORMER_RESOURCE_FIND_QUERY,
            })
            .then(({ data }) => {
                resourceOptions.TransformerResource = data.TransformerResource_find.results.map(
                    (doc: TransformerResource) => ({
                        key: doc._id,
                        label: doc.name,
                        item: doc,
                    }),
                );
            }),
        apolloClient
            .query({
                variables: { limit: 10e3 },
                query: PV2_ATTRIBUTE_DEFINITION_FIND_QUERY,
            })
            .then(({ data }) => {
                attributes = data.Pv2AttributeDefinition_find.results.map(
                    (doc: Pv2AttributeDefinition) => ({
                        key: doc._id,
                        label: doc.name,
                        item: doc,
                        schema: doc.valueSchema,
                    }),
                );
            }),
    ]);
}

const TRANSFORMER_RESOURCE_FIND_QUERY = gql`
    query TransformerResource_find($limit: Int) {
        TransformerResource_find(sort: "name", limit: $limit) {
            results {
                _id
                name
                value
            }
        }
    }
`;

const FORM_FIND_QUERY = gql`
    query Form_find($limit: Int) {
        Form_find(sort: "name", limit: $limit) {
            results {
                _id
                name
                dataRequirements {
                    ...DataRequirementFragment
                }
            }
        }
    }

    ${DataRequirementFragment}
`;

const TASK_DEFINITION_FIND_QUERY = gql`
    query TaskDefinition_find($limit: Int) {
        TaskDefinition_find(sort: "name", limit: $limit) {
            results {
                ...TaskDefinitionFragment
            }
        }
    }

    ${TaskDefinitionFragment}
`;

const FORMULA_RESOURCE_FIND_QUERY = gql`
    query FormulaResource_find($limit: Int) {
        FormulaResource_find(sort: "name", limit: $limit) {
            results {
                _id
                name
                value
            }
        }
    }
`;

const PV2_ATTRIBUTE_DEFINITION_FIND_QUERY = gql`
    query Pv2AttributeDefinition_find($limit: Int) {
        Pv2AttributeDefinition_find(sort: "name", limit: $limit) {
            results {
                _id
                name
                types
                valueSchema
            }
        }
    }
`;

const argumentChangeHandlers: TransformerBuilderProps['argumentChangeHandlers'] = {
    CreateTaskNode: {
        taskDefinitionId: ({ newValue, context, node }) => {
            const data = _.find(resourceOptions.TaskDefinition, { key: newValue.value })!;
            if (!data) return;

            context.getForm().changeField(node.parentPath! + '.config', {
                $type: 'ObjectNode',
                value: Object.fromEntries(
                    data.item.dataRequirements.map(req => [req.name, dataRequirementToNode(req)]),
                ),
            });
        },
    },
};

function dataRequirementToNode(req: DataRequirement): Expression {
    if (req.isArray) {
        return {
            ...ValueNode.getDefaultFor('ArrayNode'),
            value: [
                dataRequirementToNode({ ...req, isArray: false }),
                dataRequirementToNode({ ...req, isArray: false }),
            ],
        };
    }

    switch (req.kind!) {
        case DataRequirementKindEnum.Model:
            return ValueNode.getDefaultFor('StringNode');

        case DataRequirementKindEnum.ModelId:
            return ValueNode.getDefaultFor('StringNode');

        case DataRequirementKindEnum.Primitive: {
            const r = req as DataRequirementPrimitive;

            switch (r.primitiveType!) {
                case DataRequirementPrimitivePrimitiveTypeEnum.Boolean:
                    return ValueNode.getDefaultFor('BooleanNode');
                case DataRequirementPrimitivePrimitiveTypeEnum.Number:
                    return ValueNode.getDefaultFor('NumericNode');
                case DataRequirementPrimitivePrimitiveTypeEnum.String:
                    return ValueNode.getDefaultFor('StringNode');
                case DataRequirementPrimitivePrimitiveTypeEnum.Object:
                    return ValueNode.getDefaultFor('ObjectNode');
            }

            throw new Error(`primitiveType="${r.primitiveType}" not handled`);
        }
    }

    throw new Error(`DataRequirement.kind="${(req as any).kind}" not handled`);
}

export const defaultGetSuggestions: TransformerEditorProps['getSuggestions'] = (
    _path,
    arg,
    context,
) => {
    if (!arg?.suggestions) return [];

    const { suggestions } = arg;

    if (Array.isArray(suggestions)) return suggestions;

    const internalSugg = suggestions as InternalSuggestions;
    if (context?.suggestions?.[internalSugg])
        return context.suggestions[internalSugg] ?? ([] as any);

    switch (suggestions) {
        case 'off':
            return [];
        case 'docId':
            return [];
        case 'external:Tasks':
            return resourceOptions.TaskDefinition;
        case 'external:DatabaseModels':
            return Object.values(ModelNameEnum);
        case 'external:Resource':
            return resourceOptions.TransformerResource;
        case 'external:FormulaResource':
            return resourceOptions.FormulaResource;
        case 'external:AttributeSchemas':
            return attributes;
        default:
            return [];
    }
};
