const imported_stylus_components = require('.cache/react-style-loader/src/components/FieldTableRow.styl');
import { Tooltip2 } from '@blueprintjs/popover2';
import { observer } from 'mobx-react';
import React from 'react';

import { useOrGetField } from './Form';

import type { Field } from './Form';
import type { ClassAndStyle } from 'src/types';

export interface FieldTableRowProps extends ClassAndStyle {
    field: string | Field<any>;
    label: React.ReactNode;
    children: React.ReactNode;
    labelInfo?: React.ReactNode;
    subLabel?: React.ReactNode;
    tooltip?: string | JSX.Element | null;
}

/**
 * Mimicks Blueprint's <FormGroup /> component but in a table layout form
 *
 * Adds <Field /> support by changing the intent and showing an error when relevant
 */
export const FieldTableRow: React.FC<FieldTableRowProps> = observer(
    ({ field: f, label, children, labelInfo, subLabel, tooltip, ...rest }) => {
        const {
            meta: { valid, error },
        } = useOrGetField(f);

        let labelElem = (
            <LabelContainer data-tooltip={!!tooltip}>
                <Label>
                    {label} {!valid && <span style={{ color: 'var(--error-text-color)' }}>*</span>}
                </Label>
                {labelInfo && <LabelInfo>{labelInfo}</LabelInfo>}
            </LabelContainer>
        );

        if (tooltip) {
            labelElem = <Tooltip2 content={tooltip}>{labelElem}</Tooltip2>;
        }

        return (
            <tr {...rest} data-error={!valid}>
                <td style={{ verticalAlign: 'middle' }}>{labelElem}</td>
                <td style={{ verticalAlign: 'middle' }}>
                    {children}
                    {error && <ErrorMessage>* {error}</ErrorMessage>}
                    {subLabel && <SubLabel>{subLabel}</SubLabel>}
                </td>
            </tr>
        );
    },
);

const LabelContainer = imported_stylus_components.LabelContainer;
const Label = imported_stylus_components.Label;
const LabelInfo = imported_stylus_components.LabelInfo;
const SubLabel = imported_stylus_components.SubLabel;
const ErrorMessage = imported_stylus_components.ErrorMessage;