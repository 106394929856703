import { createRoot } from 'react-dom/client';
import { Toaster } from '@blueprintjs/core';

// This entire file should just be:
//  const GlobalToaster = Toaster.create();
//  export defautl GlobalToaster;
//
// but that prints the warning "ReactDOM.render is no longer supported .."
// until the below issue is resolved we need to use this hack
//  https://github.com/palantir/blueprint/issues/5212#issuecomment-1124544078

export let GlobalToast: Toaster;

let toasterRoot = document.getElementById('toaster');

if (!toasterRoot) {
    toasterRoot = document.createElement('div');
    toasterRoot.setAttribute('id', 'toaster');
    document.body.appendChild(toasterRoot);
}

createRoot(toasterRoot).render(
    <Toaster
        canEscapeKeyClear
        ref={instance => {
            GlobalToast = instance!;
        }}
    />,
);
