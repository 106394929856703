import { observer } from 'mobx-react';

import { type MakeFieldProps, useOrGetField } from './Form';
import TextInput, { type TextInputProps } from './TextInput';

export interface StringArrayTextareaProps extends TextInputProps<string[]> {}

const StringArrayTextarea: React.FC<StringArrayTextareaProps> = props => {
    return (
        <TextInput
            multiline
            changeOnBlur
            format={stringArrayFormat}
            parse={stringArrayParse}
            {...props}
        />
    );
};

export default StringArrayTextarea;

export interface StringArrayTextareaFieldProps extends MakeFieldProps<StringArrayTextareaProps> {}

export const StringArrayTextareaField = observer(function StringArrayTextareaField({
    field,
    ...props
}: StringArrayTextareaFieldProps) {
    const f = useOrGetField(field);
    return (
        <StringArrayTextarea
            {...props}
            {...f.props}
            intent={f.meta.valid ? props.intent : 'danger'}
        />
    );
});

const stringArrayFormat = (value: any) => (Array.isArray(value) ? value.join('\n') : '');

const stringArrayParse = (str: string | null) =>
    (str || '')
        .split('\n')
        .map(line => line.trim())
        .filter(line => line);
