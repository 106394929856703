import _ from 'lodash';
import produce from 'immer';
import { Primitive, Type, ValueNode } from '@pi/transformer-compiler';

import DefaultTypeRenderer, { Action, ChildConfig } from './DefaultTypeRenderer';
import type { TypeRendererConfig, TypeRendererIterateOptions } from '.';

export default class RestArrayType extends DefaultTypeRenderer {
    static iterate({ next, expression, path, shape }: TypeRendererIterateOptions<'RestArray'>) {
        _.forEach((expression as any).value, (child, index: number) => {
            next({
                expression: child,
                path: [...path, 'value', index],
                parentPath: path,
                isLast: index === (expression as any).value.length - 1,
                key: null,
                canDisable: true,
                canMove: true,
                argumentType: (shape.defaultType || shape.argType) as any,
            });
        });
    }

    private getChangeHandler(producer: (arr: any[]) => void) {
        return () => {
            // Why not just use this.config?
            // Because actions are rendered once and then cached, they only update if the node itself changes
            // When a sibling changes, it won't cause all its other siblings to update - that's intentional
            // The side-effect of that is that the "expression" is not guaranteed to be up to date in an event handler
            const { onChange, expression } = this.config.getRenderer(this.config.node.path).config;
            onChange({
                ...expression,
                value: produce(expression.value, producer),
            });
        };
    }

    getChildConfig(childConfig: TypeRendererConfig): ChildConfig {
        const { expression } = this.config;
        const index = expression.value.indexOf(childConfig.expression);
        const value = expression.value[index];

        const actions: Action[] = [
            {
                key: 'remove',
                enabled: true,
                showWhenDisabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'trash',
                        tooltip: `Remove this entry`,
                        onClick: this.getChangeHandler(arr => { arr.splice(index, 1); }),
                    }),
            },
            {
                key: 'add-top',
                enabled: true,
                showWhenDisabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add-row-top',
                        tooltip: `Add a new entry above`,
                        onClick: this.getChangeHandler(arr => { arr.splice(index, 1, Primitive.evaluation(''), value); }),
                    }),
            },
            {
                key: 'add-bottom',
                enabled: true,
                showWhenDisabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add-row-bottom',
                        tooltip: `Add a new entry below`,
                        onClick: this.getChangeHandler(arr => { arr.splice(index, 1, value, Primitive.evaluation('')); }),
                    }),
            },
        ];

        return {
            actions,
        };
    }

    getActions(): Action[] {
        return [
            ...super.getActions(),
            {
                key: 'add',
                enabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add',
                        tooltip: 'Add a new entry at the end',
                        onClick: this.getChangeHandler(arr => {
                            const shape = this.config.node.nodeClass!.TYPE as ReturnType<typeof Type['RestArray']>;
                            if (shape?.defaultType?.type) {
                                arr.push(ValueNode.getDefaultFor(shape.defaultType.type));
                            }
                            else arr.push(Primitive.evaluation(''));
                        }),
                    }),
            },
        ];
    }
}
