import { useLazyQuery } from '@apollo/client';
import { Tag } from '@blueprintjs/core';
import { Autocomplete } from '@pi/ui';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import isMongoId from 'src/utils/isMongoId';

import type { AutocompleteProps, Field, MenuItemOption } from '@pi/ui';
import type { DocumentNode, QueryResult } from '@apollo/client';

export interface QueryAutocompleteProps<
    Item extends AnyDocument = AnyDocument,
    Query extends Record<string, any> = Record<string, any>,
    Variables extends Record<string, any> = Record<string, any>,
> extends Omit<AutocompleteProps, 'onChange' | 'value' | 'onSearch'> {
    baseQuery?: Record<string, any>;
    gqlQuery: DocumentNode;
    getOptions: (result: QueryResult<Query, Variables>) => ItemOption<Item>[];
    getVariables: (text: string) => Variables;
    onChange: (id: string, option: ItemOption<Item>) => void;
    value?: string | null;
    getSelectedLabel?: (option: ItemOption<Item>) => React.ReactNode;
}

type AnyDocument = {
    _id: string;
    [key: string]: any;
};

interface ItemOption<Item> extends MenuItemOption {
    data: Item;
}

export default function QueryAutocomplete<
    Query extends Record<string, any>,
    Variables extends Record<string, any>,
    Item extends AnyDocument = AnyDocument,
>({
    gqlQuery,
    getOptions,
    getVariables,
    onChange,
    value,
    baseQuery,
    getSelectedLabel = option => option.label,
    ...props
}: QueryAutocompleteProps<Item, Query, Variables>): JSX.Element {
    const [callQuery, queryResult] = useLazyQuery<Query, Variables>(gqlQuery);
    const { loading, error, data } = queryResult;
    const selected = useMemo(
        () => (data ? getOptions(queryResult).find(option => option.key === value) : undefined),
        [data, getOptions, queryResult, value],
    );

    // eslint-disable-next-line no-console
    useEffect(() => error && console.error(error), [error]);

    useEffect(() => {
        if (!loading && !selected && isMongoId(value)) {
            void callQuery({
                variables: getVariables(value!),
            });
        }
    }, [selected, loading, value, baseQuery, callQuery, getVariables]);

    const onSearch = useCallback(
        (text: string) => {
            const vars = getVariables(text);
            const variables = baseQuery
                ? { ...vars, query: { ...baseQuery, ...vars.query } }
                : vars;
            return callQuery({ variables }).then(getOptions);
        },
        [callQuery, getOptions, getVariables, baseQuery],
    );

    return (
        <Autocomplete
            triggerOnFocus
            placeholder={selected?.label}
            loading={loading}
            {...props}
            onChange={useCallback(
                (key: any, option: any) => onChange(String(key), option),
                [onChange],
            )}
            onSearch={onSearch}
            inputProps={{
                ...props.inputProps,
                leftElement:
                    !loading && selected ? (
                        <Tag intent='primary'>{getSelectedLabel(selected)}</Tag>
                    ) : undefined,
            }}
        />
    );
}

export interface ModelAutocompleteProps<Item extends AnyDocument = AnyDocument>
    extends Omit<QueryAutocompleteProps<Item>, 'gqlQuery' | 'getVariables' | 'getOptions'> {}

export interface ModelFieldAutoCompleteProps
    extends Omit<ModelAutocompleteProps, 'onChange' | 'value'> {
    field: string | Field<any>;
}

export function modelAutocompleteGetVariables(text: string): {
    query: Record<string, any>;
    sort: string;
} {
    const sort = 'name';
    text = text?.trim() || '';
    if (!text.length) return { sort, query: {} };
    if (text.length === 1) return { sort, query: { name: '~^' + text } };
    if (isMongoId(text)) return { sort, query: { _id: text } };
    return { sort, query: { name: '~' + text } };
}

export const createGetOptions = _.memoize(
    <Item extends AnyDocument, Name extends string>(name: Name) =>
        (result: {
            data?: { [K in Name]?: { results?: any[] } | null } | null;
        }): ItemOption<Item>[] => {
            if (!result.data?.[name]?.results?.length) return [];

            return result.data![name]!.results!.map(
                data =>
                    ({
                        key: data._id,
                        label: data.name,
                        data,
                    } satisfies ItemOption<Item>),
            );
        },
);
