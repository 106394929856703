import _ from 'lodash';
import { routes } from 'src/components/AppRouter/routes';

import type { RouteDefinition } from 'src/components/AppRouter/routes';
import type { MaybeArray } from 'src/types';

/**
 * Provides interface for navigating between Routes as defined in React router.
 *
 * Example usage:
 * linkTo.<childrenKeyField>.url(query?).
 *
 * The <childrenKeyField> can be repeated many times for nested entries. For example,
 * when accessing the User Edit url, it was used as `linkTo.users.edit.url`.
 *
 * An optional query can be provided to it for constructing url queries.
 */
const linkTo = makeLinkTo(routes);

export default linkTo;
function makeLinkTo<RC extends RouteDefinition>(root: RC): MapRoutes<RC> {
    const createUrl = (path: string[], query?: Record<string, any>) => {
        const url = '/' + path.join('/');

        if (_.isEmpty(query)) return url;

        const queryString = _.sortBy(Object.entries(query), 1)
            .filter(x => x[1] != null && x[1] !== 'null' && x[1] !== 'undefined')
            .map(([k, v]) => `${k}=${v}`)
            .join('&');

        return url + '?' + queryString;
    };

    const iterate = (rc: RouteDefinition, path: string[]) => {
        const base: any = {};

        if (rc.component) {
            if (rc.params?.length) {
                base.url = (config: Record<string, any>, query?: Record<string, any>) => {
                    const result = [...path];
                    for (const key of rc.params || []) {
                        if (!config[key]) throw new Error(`Missing ${key} param`);
                        result.push(config[key]);
                    }
                    return createUrl(result, query);
                };
            } else {
                base.url = (query?: Record<string, any>) => createUrl(path, query);
            }
        }

        return Object.assign(
            base,
            _.mapValues(rc.children || {}, (child, childPath): any =>
                iterate(child, [...path, childPath]),
            ),
        );
    };

    return iterate(root, []) as any;
}

type ParamsObj<Params extends readonly string[]> = {
    [K in Params[number]]: string;
};

type QueryObj<Query> = Query extends readonly string[]
    ? {
          [K in Query[number]]?: MaybeArray<string | number | boolean>;
      }
    : never;

type MapUrl<RC extends RouteDefinition> = RC extends {
    component: NonNullable<RouteDefinition['component']>;
    query?: infer Query;
    params?: infer Params;
}
    ? Params extends readonly string[]
        ? { url: (params: ParamsObj<Params>, query?: QueryObj<Query>) => string }
        : { url: (query?: QueryObj<Query>) => string }
    : object;

type MapChildren<RC extends RouteDefinition> = RC extends {
    children: NonNullable<RouteDefinition['children']>;
}
    ? {
          [K in keyof RC['children']]: MapRoutes<RC['children'][K]>;
      }
    : unknown;

type MapRoutes<RC extends RouteDefinition> = MapUrl<RC> & MapChildren<RC>;

export type RouteParams<LinkToUrlFn extends { url: (...args: any[]) => string }> =
    LinkToUrlFn extends { url: (params: infer Params, query?: any) => string }
        ? Params extends ParamsObj<any>
            ? Params
            : never
        : never;
