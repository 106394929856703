const imported_stylus_components = require('.cache/react-style-loader/src/components/LazyImage.styl');
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { type ClassAndStyle, type HTMLDivProps } from 'src/types';

interface LazyImageProps extends ClassAndStyle, HTMLDivProps {
    src?: string;
    showLoading?: boolean;
    fadeAnimation?: boolean;
    width: number | string;
    height: number | string;
}

interface LazyImageState {
    done: boolean;
    showDummy: boolean;
}

export class LazyImage extends PureComponent<LazyImageProps, LazyImageState> {
    static defaultProps = {
        showLoading: false,
        fadeAnimation: true,
    };

    getState = (): LazyImageState => ({
        done: false,
        showDummy: false,
    });

    state: LazyImageState = this.getState();

    _observer?: IntersectionObserver;

    componentWillUnmount() {
        this.clean();
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.done && nextProps.src !== this.props.src) {
            this.clean();
            this.setState(this.getState());
        }
    }

    clean() {
        if (this._observer) this._observer.disconnect();
    }

    handleRootRef = (elem: any) => {
        const { src } = this.props;
        const { done, showDummy } = this.state;

        if (!src || done || showDummy) return;

        elem = ReactDOM.findDOMNode(elem);
        if (!elem) return;

        if (!('IntersectionObserver' in window)) {
            this.setState({ done: true, showDummy: false });
            return;
        }

        const options = {
            root: null, // viewport
            threshold: 0.01,
        };

        try {
            this._observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                if (!intersectionRatio) return;
                this.clean();
                this.setState({ showDummy: false, done: true });
            }, options);
            this._observer.observe(elem);
        } catch (ex) {
            this.setState({ done: true, showDummy: false });
            return;
        }
    };

    handleDummyRef = (elem: any) => {
        elem = ReactDOM.findDOMNode(elem);
        if (!elem) return;
        const done = () =>
            this.setState({
                done: true,
                showDummy: false,
            });

        if (elem.complete) return done();

        elem.onload = done;
    };

    render() {
        const { src, showLoading, fadeAnimation, width, height, style, ...rest } = this.props;
        const { done, showDummy } = this.state;

        return (
            <Root {...rest} key={src} ref={this.handleRootRef} style={{ ...style, width, height }}>
                {showLoading && !done && <ImageLoader />}

                <Image
                    style={{
                        ...(done && src ? { backgroundImage: `url(${encodeURI(src)})` } : null),
                    }}
                    data-fade={fadeAnimation ? (done ? 'done' : 'loading') : undefined}
                />
                {!done && showDummy ? <DummyImage src={src} ref={this.handleDummyRef} /> : null}
            </Root>
        );
    }
}

const Root = imported_stylus_components.Root;
const Image = imported_stylus_components.Image;
const DummyImage = imported_stylus_components.DummyImage.withComponent('img');
const ImageLoader = imported_stylus_components.ImageLoader;