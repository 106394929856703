import { MenuItem2, type MenuItem2Props } from '@blueprintjs/popover2';
import { type MultiSelect2Props } from '@blueprintjs/select';
import React from 'react';
import { type SelectOptionKey } from 'src/types';

import { type Field, useOrGetField } from '../Form';

export type UseMultiSelectOption = {
    key: SelectOptionKey;
    label: string;
    props?: Partial<MenuItem2Props>;
};
type UseMultiSelectProps = {
    options: Array<UseMultiSelectOption>;
    onSelectedItemsChange?: (items: Array<string>) => void;
    selectedItems?: Array<string>;
    allowCreate?: boolean;
    initialState?: Array<string>;
};

type UseMultiSelectReturn = {
    value: Array<string>;
    multiSelectProps: MultiSelect2Props<UseMultiSelectOption>;
    setValue: React.Dispatch<React.SetStateAction<string[]>>;
};

export function useMultiSelect({
    options,
    onSelectedItemsChange,
    selectedItems: selectedItemsFromProps,
    allowCreate,
    initialState,
}: UseMultiSelectProps): UseMultiSelectReturn {
    const [value, setValue] = React.useState<Array<string>>(initialState || []);

    const handleItemSelect = (option: UseMultiSelectOption) => {
        if (selectedItemsFromProps && Array.isArray(selectedItemsFromProps)) {
            const newValue = getNewValueFromItemSelect(selectedItemsFromProps, option);
            onSelectedItemsChange?.(newValue);
        } else {
            setValue(currValue => {
                const newValue = getNewValueFromItemSelect(currValue, option);
                onSelectedItemsChange?.(newValue);
                return newValue;
            });
        }
    };

    const handleRemove = (option: UseMultiSelectOption) => {
        if (selectedItemsFromProps && Array.isArray(selectedItemsFromProps)) {
            const newValue = getNewValueFromItemRemove(selectedItemsFromProps, option);
            onSelectedItemsChange?.(newValue);
        } else {
            setValue(currValue => {
                const newValue = getNewValueFromItemRemove(currValue, option);
                onSelectedItemsChange?.(newValue);
                return newValue;
            });
        }
    };

    const handleClear = () => {
        onSelectedItemsChange?.([]);
        if (!(selectedItemsFromProps && Array.isArray(selectedItemsFromProps))) {
            setValue([]);
        }
    };

    const selectedItems = React.useMemo(() => {
        return value.map(key => {
            return options.find(option => option.key === key) ?? { key, label: key };
        });
    }, [options, value]);

    React.useEffect(() => {
        if (selectedItemsFromProps && Array.isArray(selectedItemsFromProps)) {
            setValue(selectedItemsFromProps);
        }
    }, [selectedItemsFromProps]);

    return {
        setValue,
        value,
        multiSelectProps: {
            itemsEqual: 'key',
            selectedItems,
            tagRenderer: item => item.label,
            items: options,
            itemRenderer: (item, itemProps) => {
                if (!itemProps.modifiers.matchesPredicate) return null;
                return (
                    <MenuItem2
                        key={item.key as any}
                        text={item.label}
                        active={itemProps.modifiers.active}
                        disabled={itemProps.modifiers.disabled}
                        onClick={itemProps.handleClick}
                        onFocus={itemProps.handleFocus}
                        intent={value.includes(item.key as any) ? 'primary' : 'none'}
                        icon={value.includes(item.key as any) ? 'tick' : 'blank'}
                        {...item?.props}
                    />
                );
            },
            onItemSelect: handleItemSelect,
            onRemove: handleRemove,
            onClear: handleClear,
            itemPredicate: (query, item) => {
                return item.label.toLowerCase().includes(query.toLowerCase());
            },
            resetOnSelect: true,
            createNewItemFromQuery: allowCreate
                ? query => ({ key: query, label: query })
                : undefined,
            createNewItemRenderer: !allowCreate
                ? undefined
                : (
                      query: string,
                      active: boolean,
                      handleClick: React.MouseEventHandler<HTMLElement>,
                  ) => {
                      return (
                          <MenuItem2
                              icon='add'
                              text={`Create "${query}"`}
                              active={active}
                              onClick={handleClick}
                              shouldDismissPopover={false}
                          />
                      );
                  },
        },
    };
}

const getNewValueFromItemSelect = (currValue: Array<string>, option: UseMultiSelectOption) => {
    let alreadySelected = false;
    let newValue = currValue.filter(currOption => {
        if (currOption === option.key) {
            alreadySelected = true;
            return false;
        }
        return true;
    });

    if (!alreadySelected) {
        newValue = [...currValue, option.key as string];
    }

    return newValue;
};

const getNewValueFromItemRemove = (currValue: Array<string>, option: UseMultiSelectOption) => {
    return currValue.filter(currOption => currOption !== option.key);
};

export function useMultiSelectField({
    field: fieldFromProps,
    ...rest
}: UseMultiSelectProps & {
    field: string | Field<Array<string> | undefined>;
}): UseMultiSelectReturn {
    const field = useOrGetField(fieldFromProps);

    return useMultiSelect({
        ...rest,
        onSelectedItemsChange: field.onChange,
        selectedItems: field.value,
    });
}

export default useMultiSelect;
