const imported_stylus_components = require('.cache/react-style-loader/src/components/EditableMarkdown.styl');
import { Button } from '@blueprintjs/core';
import React, { useState } from 'react';

import { Markdown } from './Markdown';
import TextInput from './TextInput';

import type { TextInputProps } from './TextInput';
import type { HTMLDivProps } from '@blueprintjs/core';
import { MakeFieldProps, useOrGetField } from './Form';
import { observer } from 'mobx-react';

export interface EditableMarkdownProps extends Omit<HTMLDivProps, 'onChange'> {
    value: string | null | undefined;
    onChange: (text: string) => void;
    inputProps?: Partial<TextInputProps>;
    editText?: { edit: string; show: string };
}

export const EditableMarkdown: React.FC<EditableMarkdownProps> = ({
    value,
    onChange,
    inputProps,
    editText = { edit: 'Edit', show: 'View' },
    ...rest
}) => {
    const [edit, setEdit] = useState(false);
    const [hover, setHover] = useState(false);


    return <Root {...rest} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Button
            intent={hover ? 'primary' : 'none'}
            text={hover ? (edit ? editText.show : editText.edit) : null}
            icon='edit'
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}
            small
            onClick={() => setEdit(!edit)}
        />
        {edit
            ? <TextInput
                placeholder='Markdown format'
                changeOnBlur
                autoFocus
                {...inputProps}
                multiline
                value={value || ''}
                onChange={val => onChange(val || '')}
            />
            : <MarkdownWrapper>
                <Markdown {...rest} text={value || inputProps?.placeholder || '_empty_'} />
            </MarkdownWrapper>
        }
    </Root>;
};

export interface EditableMarkdownFieldProps extends MakeFieldProps<EditableMarkdownProps> { }

export const EditableMarkdownField: React.FC<EditableMarkdownFieldProps> = observer(function EditableMarkdownField({
    field,
    ...props
}) {
    const f = useOrGetField(field);
    return <EditableMarkdown
        {...props}
        {...f.props}
        inputProps={{
            ...props.inputProps,
            intent: f.meta.valid ? props.inputProps?.intent : 'danger',
        }}
    />;
});

const Root = imported_stylus_components.Root;
const MarkdownWrapper = imported_stylus_components.MarkdownWrapper;