import React from 'react';
import { observer } from 'mobx-react';
import { type MakeFieldProps, useOrGetField } from 'src/components/Form';
import { DateRangeInput2, type DateRangeInput2Props } from '@blueprintjs/datetime2';

export interface DateRangeInputFieldProps extends MakeFieldProps<DateRangeInput2Props> {}

export const DateRangeInputField = observer(function DateRangeInputField({
    field,
    ...props
}: DateRangeInputFieldProps) {
    const f = useOrGetField(field);
    return <DateRangeInput2 {...props} {...f.props} />;
});
