/**
 * Convenience utility to create primitive nodes
 * Usage:
 *
 * import Primitive from 'src/utils/Primitive';
 *
 * Primitive.string('foo');
 */
import type { BooleanNodeT } from 'src/nodes/value/BooleanNode';
import type { JSONNodeT } from 'src/nodes/value/JSONNode';
import type { NumericNodeT } from 'src/nodes/value/NumericNode';
import type { StringNodeT } from 'src/nodes/value/StringNode';

const Primitive = {
    string: (value: string): StringNodeT => ({ $type: 'StringNode', value } as const),
    boolean: (value: boolean): BooleanNodeT => ({ $type: 'BooleanNode', value } as const),
    number: (value: number): NumericNodeT => ({ $type: 'NumericNode', value } as const),
    /** @deprecated */
    json: (value: any): JSONNodeT => ({ $type: 'JSONNode', value } as const),
    null: () => null,
    evaluation: (value: string) => ({ $type: 'EvaluationNode', value } as const),
};

export default Primitive;
