import _ from 'lodash';
import convertToNode from 'src/utils/convertToNode';
import { isObject } from 'src/utils/performanceFunctions';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { Expression, BaseExpression, ParentContext, PrimitiveValue } from '../../types';
import type { BooleanNodeT } from './BooleanNode';

export interface CreateHITNodeT extends BaseExpression {
    $type: 'CreateHITNode';
    cond: Expression;
    taskId: Expression | PrimitiveValue;
    hitConfig: Expression | PrimitiveValue;
    pushToDependencies: BooleanNodeT;
}

export default class CreateHITNode extends ValueNode<CreateHITNodeT> {
    static TYPE = Type.Object(
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'taskId', label: 'Task', type: 'StringNode', suggestions: 'docId' },
        { key: 'hitConfig', label: 'HIT Config', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'pushToDependencies', type: 'BooleanNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.mturk,
        description: 'Create an mturk HIT',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            cond: this.getDefaultFor('EqualsNode'),
            taskId: this.getDefaultFor('StringNode'),
            hitConfig: null,
            pushToDependencies: this.getDefaultFor('BooleanNode'),
        };
    }

    static migrate(expr: CreateHITNodeT) {
        return _.mapValues(expr, (value, key) => {
            return key === 'name' || key[0] === '$' ? value : convertToNode(value);
        });
    }

    validate() {
        if (!Reflect.has(this.expression, 'taskId')) return 'missing "taskId"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const taskId = await this.context.evaluateLog('taskId', this.expression.taskId, {
            sourcePath,
        });
        this.context.setLastStackEntryMeta(taskId);
        const cond = await this.context.evaluateLog('cond', this.expression.cond, { sourcePath });
        const pushToDependencies = await this.context.evaluateLog(
            'pushToDependencies',
            this.expression.pushToDependencies,
            { sourcePath },
        );
        if (!cond || !taskId) return null;

        const hitConfig = await this.context.evaluateLog('hitConfig', this.expression.hitConfig, {
            sourcePath,
            isOutput: true,
        });

        const result = {
            taskId,
            hitConfig: isObject(hitConfig) ? hitConfig : undefined,
        };

        if (pushToDependencies) {
            await this.context.evaluateLog(
                'pushToDependencies',
                {
                    ...ValueNode.getDefaultFor('SetNode'),
                    path: {
                        $type: 'StringNode',
                        value: 'dependencies',
                    },
                    value: {
                        ...ValueNode.getDefaultFor('PushNode'),
                        value: [result],
                    },
                } as Expression,
                { sourcePath },
            );
        }

        return result;
    }
}
