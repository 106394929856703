import textBlock from '@pi/text-block';
import iterateExpressionItems from 'src/utils/iterateExpressionItems';
import Primitive from 'src/utils/Primitive';

import Node from '../Node';
import Type from '../../Type';
import config from '../../utils/config';
import ellipsis from '../../utils/ellipsis';

import type { StringNodeT } from '../value/StringNode';
import type { BaseExpression, ContainerExpression, Expression, PrimitiveValue } from '../../types';

export interface EachNodeT extends BaseExpression {
    $type: 'EachNode';
    input: Expression | PrimitiveValue | any[] | Record<string, any>;
    as: StringNodeT;
    cond?: Expression;
    do: ContainerExpression | null;
}

/**
 * Usage:
    input = { list: ['a', 'b', 'c'] }
    {
        $each: {
            input: '$list',
            as: 'item',
            cond: { $neq: ['$$item', 'b'] },
            do: {
                $output: {
                    myFilteredList: {
                        $push: '$$item',
                    },
                },
            },
        },
    }
 */
export default class EachNode extends Node<EachNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode', suggestions: 'array' },
        { key: 'as', type: 'StringNode', suggestions: 'off' },
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'off' },
        { key: 'do', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            Evaluates "do" for each item in "input" if "cond" is true.

            Inside "do" the value of each iterable item is put in a variable defined by "as" and can be referenced by using the value of "as" with two dollar signs proceeding it (e.g. $$item)
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            as: Primitive.string('item'),
            cond: Primitive.null(),
            do: this.getDefaultFor('AllNode'),
        };
    }

    async execute() {
        const itemName = this.expression.as;

        await iterateExpressionItems({
            items: this.expression.input,
            itemName,
            context: this.context,
            callback: async ({ sourcePath, itemName, key, value }) => {
                this.context.setLastStackEntryMeta(
                    `index: ${key}, ${itemName}: ${ellipsis(20, value)}`,
                );
                if (this.expression.cond) {
                    if (!(await this.context.evaluateLog('cond', this.expression.cond))) return;
                }
                await this.context.evaluate(this.expression.do, { sourcePath });
            },
        });
    }
}
