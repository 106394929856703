import _ from 'lodash';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Types from '../../Type';

import type {
    BaseExpression,
    ParentContext,
    UiConfig,
    ValueExpression,
    PrimitiveValue,
} from '../../types';

export interface GetNodeT extends BaseExpression {
    $type: 'GetNode';
    input: ValueExpression;
    path: ValueExpression | PrimitiveValue;
}

export default class GetNode extends ValueNode<GetNodeT> {
    static TYPE = Types.Object(
        { key: 'input', label: 'Data', type: 'ValueNode' },
        { key: 'path', label: 'Path', type: 'StringNode' },
    );

    static uiConfig: UiConfig = {
        ...config.presets.block,
        description: 'Returns the value at the given path from an object',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: super.getDefaultFor('ResourceNode'),
            path: Primitive.string(''),
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const input = await this.context.evaluate(this.expression.input, { sourcePath });
        const path = await await this.context.evaluate(this.expression.path, { sourcePath });

        return _.get(input, path);
    }
}
