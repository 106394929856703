import _ from 'lodash';
import React from 'react';

import { markdownToReact } from '..';

import type { HTMLDivProps } from 'src/types';

export class Markdown extends React.PureComponent<{ text: string | null | undefined } & HTMLDivProps> {
    render() {
        const { text, ...props } = this.props;
        return markdownToReact(text, props);
    }
}
