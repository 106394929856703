import { FormStore } from '../FormStore';

/**
 * Creates a detached Field instance.
 *
 * Useful in the odd situation where a component accepts only a Field and you need to use it outside of a form and are too lazy to refactor it into a base non-Field component
 *
 * This is not efficient. It's not meant to be.
 */
export function mockField<T = any>(value: T, onChange?: (value: T) => void) {
    const form = new FormStore({
        initialValue: { value },
    });

    const field = form.field('value');

    field.listenOnChange(onChange || null);

    return field;
}
