const imported_stylus_components = require('.cache/react-style-loader/src/utils/markdownToReact.styl');
import _ from 'lodash';
import React from 'react';
import SimpleMarkdown from 'simple-markdown';

import type { DefaultRules, HtmlOutputRule, ParserRule, ReactOutputRule } from 'simple-markdown';

interface ExtendedRules extends DefaultRules {
    color: ParserRule & ReactOutputRule & HtmlOutputRule;
    img: ParserRule & ReactOutputRule & HtmlOutputRule;
    box: ParserRule & ReactOutputRule & HtmlOutputRule;
}

const rules: ExtendedRules = {
    ...SimpleMarkdown.defaultRules,
    img: {
        order: SimpleMarkdown.defaultRules.em.order - 0.5,

        match(source) {
            return /^<img([^>]+)\/>/m.exec(source);
        },

        parse([, content]) {
            return {
                config: Object.fromEntries(
                    (content.match(/([a-z]+)="([^"]+)"/g) ?? [])
                        .map(str => {
                            const [k, v] = str.split('=');
                            return [k, v.slice(1, -1)];
                        })
                ),
                content,
            };
        },

        react(node) {
            if (!node.config.src) throw new Error('<img /> tag needs src="" attribute');
            return <img {...node.config} key={node.config.src} />;
        },

        html(node) {
            if (!node.config.src) throw new Error('<img /> tag needs src="" attribute');
            return `<img ${node.content} />`
        },
    },
    link: {
        ...SimpleMarkdown.defaultRules.link,
        react: (...args) =>
            React.cloneElement(
                SimpleMarkdown.defaultRules.link.react(...args),
                { target: '_blank' }
            ),
    },
    color: {
        order: SimpleMarkdown.defaultRules.em.order - 0.5,

        match(source) {
            return /^<color=([^>]+)>([^<]+)<\/color>/m.exec(source);
        },

        parse([, color, content], parse, state) {
            return {
                color,
                content: parse(content, state),
            };
        },

        react(node, output) {
            const content = output(node.content);
            return <span key={String(content)} style={{ color: node.color }}>{content}</span>;
        },

        html(node, output) {
            return `<span style='color:${node.color}'>${output(node.content)}</span>`;
        },
    },
    box: {
        order: SimpleMarkdown.defaultRules.em.order - 0.5,

        match(source) {
            return /^<box>((?:[^<]|<(?!box>))+)<\/box>/m.exec(source);
        },

        parse(match, parse, state) {
            return {
                content: parse(match[1].trim(), state),
            };
        },

        react(node, output) {
            const content = output(node.content);
            return <Box key={String(content)}>{content}</Box>;
        },

        html(node, output) {
            return `<div class='${Box.className}'>${output(node.content)}</div>`;
        },
    },
};

const parser = SimpleMarkdown.parserFor(rules);
// @ts-ignore
const reactOutput = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react'));

const mdParser = (text: string) => reactOutput(parser(text));

/**
 * Converts a given MarkDown text to a React component
 * Extends SimpleMarkdown to provide some custom functionality like converting newlines to... actual new lines
 *
 * @param text  The MarkDown text to convert
 * @param props Any optional props to be passed to the top level container
 */
export function markdownToReact(text: string | null | undefined, props?: { [key: string]: any }): JSX.Element {
    // this should not happen under normal circumstances
    if (!text || !_.isString(text)) return null as any;

    return <Markdown {...props}>{mdParser(text)}</Markdown>;
}

export default markdownToReact;

const Markdown = imported_stylus_components.Markdown;
const Box = imported_stylus_components.Box;