import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import LS from 'src/utils/LS';
import { startDb } from 'src/utils/db';
import dbCaches from 'src/utils/dbCaches';

export interface AppContextType {
    db: Awaited<ReturnType<typeof startDb>>;
    darkMode: boolean;
    pinnedTaskDefinition: string | null;
    update: (updater: Partial<AppContextType> | ((draft: AppContextType) => void)) => void;
    setPageTitle: (path: Array<string | undefined | null | false>) => void;
}

const AppContext = React.createContext<AppContextType>(null as any);

export default AppContext;

export const useAppContext = (): AppContextType => useContext(AppContext);

export const AppContextProvider: React.FC<{ children: React.ReactNode; noDb?: boolean }> = ({
    children,
    noDb = false,
}) => {
    const [value, setValue] = useState<AppContextType>();

    useEffect(() => {
        void (async () => {
            // todo: handle user not granting DB access, then remove the "noDb" flag
            const db = noDb ? null : await startDb();
            setValue({
                darkMode: LS.get('darkMode')!,
                db: db as any,
                pinnedTaskDefinition: LS.get('pinnedTaskDefinition') || null,
                setPageTitle: path => {
                    document.title =
                        path
                            .filter(x => x)
                            .reverse()
                            .join(' ❮ ') + ' | PI Admin';
                },
                update: updater => {
                    setValue(val =>
                        produce(val, draft => {
                            if (typeof updater === 'function') updater(draft as any);
                            else Object.assign(draft!, updater);
                        }),
                    );
                },
            });

            if (db) {
                await dbCaches.formDrafts.deleteExpired();
                await dbCaches.transformerDrafts.deleteExpired();
            }
        })();
    }, [noDb]);

    useEffect(() => {
        LS.set(_.pick(value, ['darkMode', 'pinnedTaskDefinition']));
    }, [value]);

    if (!value) return null;

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
