type AnyObject = Record<string, any>;

export type LocalStorageOptions<T extends AnyObject> = {
    namespace: string;
    getDefaults?: () => Partial<T>;
    store?: AnyObject;
};

/**
 * LocalStorage interface that has 2 main features:
 * - it automatically stores JSON and returns parsed objects
 * - it is namespaced by default, i.e. all objects are serialized and stored in a single key in LocalStorage so it avoids clashes with other localStorage implementations
 */
export class LocalStorage<T extends AnyObject = AnyObject> {
    options: Required<LocalStorageOptions<T>>;

    constructor({ namespace, getDefaults, store }: LocalStorageOptions<T>) {
        this.options = {
            namespace,
            getDefaults: getDefaults || (() => ({})),
            store: store || global.localStorage,
        };
    }

    /**
     * Returns all keys stores in the store
     */
    getAll(): Partial<T> {
        try {
            return {
                ...this.options.getDefaults(),
                ...JSON.parse(this.options.store[this.options.namespace]),
            };
        } catch (ex) {
            return this.options.getDefaults();
        }
    }

    /**
     * Returns the value of a single or a default if not found
     */
    get<K extends keyof T, D extends T[K]>(key: K, defaultValue?: D): T[K] | undefined {
        const obj = this.getAll();
        return key in obj ? obj[key] : defaultValue;
    }

    /**
     * Resets all the given values in the store to the given object
     */
    setAll(obj: T) {
        this.options.store[this.options.namespace] = JSON.stringify(obj);
        this.triggerChange(obj);
    }

    /**
     * Save any number of keys in the store
     */
    set(diff: Partial<T>) {
        this.setAll({
            ...this.getAll(),
            ...diff,
        } as any);
    }

    delete(key: keyof T) {
        this.set({ [key]: undefined } as any);
    }

    /**
     * Deletes all the data in the store and restores defaults
     */
    reset() {
        const defaults = this.options.getDefaults();
        const empty = Object.fromEntries(
            Object.keys(this.getAll())
                .map(k => [k, undefined])
        );
        delete this.options.store[this.options.namespace];
        this.setAll({ ...empty, ...defaults } as any);
    }

    private onChangeEvents = new Set<(...args: any[]) => any>();

    /**
     * Listen to storage changes
     *
     * Receives the diff of changed values AFTER the change
     */
    onChange(callback: (diff: Partial<T>) => void) {
        this.onChangeEvents.add(callback);

        return () => this.onChangeEvents.delete(callback);
    }

    private triggerChange(diff: Partial<T>) {
        for (const cb of this.onChangeEvents) {
            cb(diff);
        }
    }
}

export default LocalStorage;
