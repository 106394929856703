import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import Node from '../Node';
import Types from '../../Type';

import type { Expression, BaseExpression, ParentContext } from '../../types';
import type { StringNodeT } from '../value/StringNode';
import type { ArrayNodeT } from '../value/ArrayNode';

export interface CsvSheetNodeT extends BaseExpression {
    $type: 'CsvSheetNode';
    name: StringNodeT;
    columns: ArrayNodeT;
}

export default class CsvSheetNode extends Node<CsvSheetNodeT> {
    static TYPE = Types.Object(
        { key: 'name', label: 'Name', type: 'StringNode', suggestions: 'off' },
        { key: 'columns', label: 'Columns', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.csv,
        description: '',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            name: Primitive.string('Sheet Name'),
            columns: {
                ...super.getDefaultFor('ArrayNode'),
                value: [
                    Primitive.string('col1'),
                    Primitive.string('col2'),
                    Primitive.string('col3'),
                ],
            },
        };
    }

    async execute({ sourcePath }: ParentContext) {
        let [name, columns] = await Promise.all([
            this.context.evaluate(this.expression.name, { sourcePath }),
            this.context.evaluate(this.expression.columns, { sourcePath }),
        ]);

        if (!name || typeof name !== 'string') {
            throw new Error('Missing CSV name');
        }

        if (this.context.cache.get('csvSheets')[name]) {
            throw new Error(`A CSV sheet with the name "${name}" already exists`);
        }

        if (!Array.isArray(columns)) {
            throw new Error(`CSV ${name} must have columns be an Array of Strings`);
        }

        columns = columns
            .flat()
            .filter(x => x && ['string', 'number', 'boolean'].includes(typeof x));
        const path = 'csv.' + name;
        this.context.cache.assign('csvSheets', name, { path, columns });

        await this.context.evaluate(
            {
                ...Node.getDefaultFor('SetNode'),
                path: Primitive.string(path),
                value: {
                    ...Node.getDefaultFor('ObjectNode'),
                    value: {
                        name: Primitive.string(name + '.csv'),
                        columns: {
                            ...Node.getDefaultFor('ArrayNode'),
                            value: columns.map(Primitive.string),
                        },
                        data: {
                            ...Node.getDefaultFor('ArrayNode'),
                            value: [],
                        },
                    },
                },
            } as Expression,
            { sourcePath },
        );
    }
}
