import { Transformation } from '@pi/transformer-compiler';
// eslint-disable-next-line no-restricted-imports
import { api } from '@pi/api-client';
import memoizeExpire from 'src/utils/memoizeExpire';

export default function setupTransformerCompiler(): void {
    Transformation.setGlobalOptions({
        fetchResource,
        fetchDocument,
        fetchAttributeSchemas,
    });
}

const fetchResource = memoizeExpire({
    timeout: 60e3,
    func: (id: string, isFormulaResource?: boolean) => {
        if (isFormulaResource) {
            return api
                .model('FormulaResource')
                .findById(id)
                .then(resource => JSON.parse(resource?.value || ''));
        }

        return api
            .model('TransformerResource')
            .findById(id, { virtuals: true })
            .then(resource => resource?.value);
    },
});

const fetchDocument = memoizeExpire({
    timeout: 60e3,
    resolver: (...args: any[]) => args.join('__'),
    func: (model: any, id: string) => api.model(model).findById(id),
});

const fetchAttributeSchemas = memoizeExpire({
    timeout: 60e3,
    resolver: () => 'constant',
    func: () =>
        api
            .model('Pv2AttributeDefinition')
            .find({ limit: 10000, select: ['types', '_valueSchema'] })
            .then(list =>
                list.map(attrb => ({
                    types: attrb.types,
                    schema: JSON.parse(attrb._valueSchema || ''),
                })),
            ),
});
