const imported_stylus_components = require('.cache/react-style-loader/src/components/TypeRenderers/PrimitiveType.styl');
import React from 'react';
import _ from 'lodash';
import { Button, Callout, Switch } from '@blueprintjs/core';
import { NumericInput } from '@pi/ui';
import DefaultTypeRenderer from './DefaultTypeRenderer';
import ContentEditableInput from '../ContentEditableInput';
import type { TypeRendererIterateOptions } from '.';
import TextWithSuggestions from '../TextWithSuggestions';

export default class PrimitiveType extends DefaultTypeRenderer {
    static iterate(_opts: TypeRendererIterateOptions<'Primitive'>) {
        // no op
    }

    collapsible = false;

    renderLabel() {
        const { node, expression, onChange: configOnChange, getSuggestions, getFormValue } = this.config;
        const { argumentType } = node;
        const nodeName = node?.nodeClass.name;
        if (expression == null) return 'invalid';
        let input;

        const onChange = (args: Record<string, any>) => {
            if (node?.argumentType?.onChange) {
                node.argumentType.onChange({
                    value: args.value,
                    parentPath: node.parentPath!,
                    formValue: getFormValue(),
                    getSuggestions,
                });
            }
            configOnChange(args);
        };

        switch (nodeName) {
            case 'NumericNode':
                input = <NumericInput
                    min={Number.NEGATIVE_INFINITY}
                    max={Number.POSITIVE_INFINITY}
                    value={expression.value}
                    onChange={value => onChange({
                        ...expression,
                        value,
                    })}
                    small
                    short
                    className='mb0 ml05 mr0'
                />
                break;
            case 'StringNode':
                input = <ContentEditableInput
                    value={String(expression.value)}
                    onChange={value => onChange({
                        ...expression,
                        value,
                    })}
                    className='ml05'
                />
                input = <TextWithSuggestions
                    value={String(expression.value)}
                    onChange={value => onChange({
                        ...expression,
                        value,
                    })}
                    path={node.path}
                    getSuggestions={getSuggestions}
                    argumentType={argumentType}
                    className='ml05'
                />
                break;
            case 'BooleanNode':
                input = <StyledSwitch
                    checked={expression.value}
                    innerLabel={String(expression.value)}
                    // large
                    onChange={(e: any) => onChange({
                        ...expression,
                        value: (e.target as HTMLInputElement).checked,
                    })}
                />
                break;
            case 'JSONNode':
                input = <JsonInput
                    value={expression.value}
                    onChange={value => onChange({
                        ...expression,
                        value,
                    })}
                />;
                break;
            default:
                throw new Error(`Node named "${nodeName}" not handled`);
        }

        return super.renderLabel(input);
    }
}

const JsonInput: React.FC<{ value: any; onChange: (v: any) => void; }> = ({ value, onChange, ...rest }) => {
    const [visible, setVisible] = React.useState(false);
    const [textValue, setTextValue] = React.useState('');
    const [error, setError] = React.useState<any>(null);

    React.useEffect(() => {
        setTextValue(JSON.stringify(value, null, 4));
    }, [value]);

    if (visible) {
        return <React.Fragment>
            <Button
                minimal
                small
                className='mx05'
                icon='eye-off'
                onClick={() => setVisible(false)}
            />

            <div className='ml05'>
                {error && <Callout intent='danger' className='my025'>{error}</Callout>}
                <ContentEditableInput
                    value={textValue}
                    className='mt025'
                    onChange={text => {
                        setTextValue(text);
                        try {
                            text = text.trim();
                            const json = text ? JSON.parse(text) : null;
                            setError(null);
                            onChange(json);
                        } catch (ex) {
                            setError('Invalid JSON, not saved');
                        }
                    }}
                />
            </div>
        </React.Fragment>;
    }

    return <Button
        intent='primary'
        text='Edit JSON'
        icon='eye-open'
        small
        minimal
        {...rest}
        onClick={() => setVisible(true)}
    />;
};

const StyledSwitch = imported_stylus_components.StyledSwitch.withComponent(Switch);