import _ from 'lodash';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, ParentContext, Expression } from '../../types';

export interface AddToSetNodeT extends BaseExpression {
    $type: 'AddToSetNode';
    value: Expression | Expression[];
}

export default class AddToSetNode extends ValueNode<AddToSetNodeT> {
    static TYPE = Type.RestArray({ argType: { type: 'ValueNode' } });

    static uiConfig = {
        ...config.presets.block,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [this.getDefaultFor('EvaluationNode')],
        };
    }

    static migrate(expr: BaseExpression): AddToSetNodeT {
        if (Array.isArray((expr as AddToSetNodeT).value)) return expr as AddToSetNodeT;
        return {
            ...(expr as AddToSetNodeT),
            value: _.filter(_.castArray((expr as AddToSetNodeT).value)),
        } as const;
    }

    async execute({ targetPath, sourcePath }: ParentContext = {}) {
        const expressions = _.castArray(this.expression.value);
        let value = this.context.get(targetPath);

        if (!Array.isArray(value)) value = [];

        for (const expression of expressions) {
            const newValue = await this.context.evaluate(expression, { sourcePath });
            if (newValue != null && newValue !== '' && !value.includes(newValue)) {
                value.push(newValue);
            }
        }

        return value;
    }
}
