import { joinPaths } from '@pi/path-utils';

import type { StringNodeT } from 'src/nodes/value/StringNode';
import type { Expression, PrimitiveValue } from 'src/types';

type ItemIterationOptions = {
    items: Expression | PrimitiveValue | any[] | Record<string, any>;
    itemName: StringNodeT;
    context: any;
    callback: (options: {
        sourcePath: string;
        key: string | number;
        itemName: string;
        value: any;
    }) => void;
};

async function iterateExpressionItems({
    items,
    itemName,
    context,
    callback,
}: ItemIterationOptions) {
    const name = await context.evaluate(itemName);
    const target = Array.isArray(items)
        ? items // Use-case for passing in an already evaluated list
        : await context.evaluate(items);

    if (target == null || typeof target !== 'object') return;

    const entries = Array.isArray(target) ? target.map((v, i) => [i, v]) : Object.entries(target);

    for (const [key, value] of entries) {
        const path =
            typeof items === 'string'
                ? joinPaths((items as string).replace(/^\$\$?/, ''), key)
                : '';
        await context.variableBlock({
            path,
            variables: {
                [name]: value,
                [`${name}_index`]: key,
                [`${name}_key`]: key,
                [`${name}_total`]: entries.length,
            },
            callback: (sourcePath: string) => callback({ sourcePath, key, value, itemName: name }),
        });
    }
}

export default iterateExpressionItems;
