const imported_stylus_components = require('.cache/react-style-loader/src/components/AppRouter/Nav.styl');
import _ from 'lodash';
import { Button, Icon, IconSize, Menu, MenuDivider, MenuItem, Switch } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowResize } from '@pi/ui';
import { useSessionQuery } from 'src/generated/graphql';
import PinnedTaskDefinition from 'src/pages/TaskDefinition/components/PinnedTaskDefinition';

import { useAppContext } from '../AppContext';
import routes from './routes';

import type { User } from 'src/generated/graphql';
import type { RouteDefinition } from './routes';

const Nav: React.FC = () => {
    const { darkMode, update } = useAppContext();
    const [small, setSmall] = useState(false);
    const session = useSessionQuery();

    useWindowResize({
        throttle: 10,
        direction: 'x',
        callback: () => setSmall(window.innerWidth < 70 * 16),
    });

    const user = session.data?.session?.user as User;

    if (!user) return null;

    return (
        <Root large>
            <RouteRenderer small={small} user={user} />
            <div style={{ flex: '1 1 0' }} />
            <PinnedTaskDefinition />
            <div className='bp4-menu-item hide-on-small'>
                <Icon icon='lightbulb' size={IconSize.LARGE} />
                <Switch
                    checked={darkMode}
                    onChange={() => update({ darkMode: !darkMode })}
                    alignIndicator='right'
                    label={'Lights ' + (darkMode ? 'off' : 'on')}
                    large
                    style={{ flex: '1 1 0' }}
                />
            </div>
            <Button
                icon='lightbulb'
                minimal
                className='show-on-small'
                onClick={() => update({ darkMode: !darkMode })}
            />
        </Root>
    );
};

export default Nav;

export const RouteRenderer: React.FC<{ small: boolean; user: User }> = ({ small, user }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const render = (route: RouteDefinition, path: string[]) => {
        // console.log(route.name, route.isActive, route.isActive(user));
        if (!route.isActive(user)) return null;

        const childRoutes = Object.entries(route.children || {})
            .map(([key, v]) => ({ ...v, key }))
            .filter(x => !x.hideFromNav);
        const groups = _.groupBy(childRoutes || [], 'group');

        const renderGroup = (
            group: string | null | undefined,
            children: typeof childRoutes,
        ): React.ReactNode => {
            if (!children.length) return null;
            const result = children.map(child => render(child, [...path, child.key]));
            if (path.length) {
                result.unshift(<MenuDivider key='div' className='show-on-small' />);
                result.unshift(
                    <MenuDivider key='title' title={route.name} className='show-on-small' />,
                );
            }
            if (!group || group === 'undefined' || group === 'null') return result;
            return (
                <React.Fragment key={group}>
                    <li
                        className='bp4-menu-header pt05 hide-on-small'
                        style={{ fontSize: '1.125rem' }}
                    >
                        {group}
                    </li>
                    {result}
                </React.Fragment>
            );
        };

        let children: React.ReactNode = null;

        if (_.size(groups) > 1) {
            children = Object.values(groups).map(list => renderGroup(list[0].group, list));
        } else if (_.size(groups) === 1) {
            children = renderGroup(null, childRoutes);
        }

        if (!route.component || route.hideFromNav) return children;

        const url = '/' + [...path, ...(route.params || [])].join('/');

        const regexpComponents = [...path, ...(route.params?.map(() => '[^/]*') ?? [])];

        const active = regexpComponents.every((_, i) =>
            new RegExp('^\\/' + regexpComponents.slice(0, i + 1).join('\\/') + '(\\/|$)').test(
                location.pathname,
            ),
        );

        return (
            <React.Fragment key={url}>
                <MenuItem
                    icon={route.icon}
                    text={small && path.length <= 1 ? null : route.name}
                    onClick={() => navigate(url)}
                    active={active}
                    data-hide-arrow={path.length === 1 && small}
                >
                    {children}
                </MenuItem>
            </React.Fragment>
        );
    };

    return <>{render(routes, [])}</>;
};

const Root = imported_stylus_components.Root.withComponent(Menu);