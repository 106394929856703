/**
 * Simple memoization function that supports expiring values after a given time
 * Works similarly lodash _.memoize(func, resolver), but expects the resolver to return a string
 */
export default function memoizeExpire<T extends (...args: any[]) => any>({
    func,
    resolver = (...args) => String(args[0]),
    timeout = 60e3,
}: {
    func: T;
    resolver?: (...args: Parameters<T>) => string;
    timeout?: number;
}): Memoized<T> {
    let cache: { [key: string]: { time: number; value: ReturnType<T> } } = {};

    const fn = (...args: any) => {
        const key = String(resolver(...args));

        if (!cache[key] || cache[key].time < Date.now() - timeout) {
            cache[key] = {
                time: Date.now(),
                value: func(...args),
            };
        }

        return cache[key].value;
    };

    return Object.assign(fn, {
        clearCache: () => {
            cache = {};
        },
    }) as any;
}

export type Memoized<T> = T & {
    /** removes all cache entries */
    clearCache: () => void;
};
