import textBlock from '@pi/text-block';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, ParentContext, Expression, PrimitiveValue } from '../../types';
import type { StringNodeT } from './StringNode';

export interface RegexNodeT extends BaseExpression {
    $type: 'RegexNode';
    value: Expression | PrimitiveValue;
    pattern: StringNodeT;
    flags?: StringNodeT;
    map?: Expression | PrimitiveValue;
}

export default class RegexNode extends ValueNode<RegexNodeT> {
    static TYPE = Type.Object(
        { key: 'value', type: 'StringNode', suggestions: 'string' },
        { key: 'pattern', type: 'StringNode', suggestions: 'off' },
        { key: 'flags', type: 'StringNode', suggestions: 'off' },
        { key: 'map', label: 'Map Result', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.default,
        group: 'Text',
        description: textBlock`
            Evaluates the regular expression in "pattern" against the data in "value"

            The resulting match is saved in the "$$match" variable that's available in the "Map Result" block
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: this.getDefaultFor('EvaluationNode'),
            pattern: this.getDefaultFor('StringNode'),
            flags: {
                $type: 'StringNode',
                value: 'i',
            } as StringNodeT,
            map: null,
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const { value, flags, map } = this.expression;
        let str: string = await this.context.evaluate(value, { sourcePath });
        const fl = flags && (await this.context.evaluate(flags, { sourcePath }));
        if (!str || typeof str === 'object') return null;
        str = String(str);
        const pattern = await this.context.evaluate(this.expression.pattern, { sourcePath });
        if (!pattern || typeof pattern !== 'string') return null;
        const regex = new RegExp(pattern, fl);
        const match = str.match(regex);

        if (map) {
            if (!match) return null;
            return await this.context.variableBlock({
                path: '',
                variables: {
                    match,
                },
                callback: () => this.context.evaluate(map, { sourcePath }),
            });
        }

        return match;
    }
}
