import { useCallback, useMemo, useState } from 'react';

export function useToggleState(initialValue = false) {
    const [value, setValue] = useState(initialValue);

    const toggle = useCallback(
        (value?: any) => {
            if (typeof value === 'boolean') setValue(!!value);
            else setValue(v => !v);
        },
        [setValue],
    );

    const toggleOn = useCallback(() => toggle(true), [toggle]);
    const toggleOff = useCallback(() => toggle(false), [toggle]);

    return useMemo(
        () => ({
            value,
            toggle,
            on: toggleOn,
            off: toggleOff,
        }),
        [value, toggle, toggleOn, toggleOff],
    );
}
