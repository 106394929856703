import { ApiClient } from './ApiClient';

export * from './ApiClient';
export * from './ApiModelClient';
export * from './constants';
export * from './modelTypes';
export * from './setup';
export * from './typeDefs';

export const api = new ApiClient({ target: 'v2' });
export const legacyApi = new ApiClient({ target: 'legacy' });

export const publicApi = new ApiClient({ target: 'v2', skipCredentialCheck: true });
export const publicLegacyApi = new ApiClient({ target: 'legacy', skipCredentialCheck: true });
