import _ from 'lodash';

import { API_V2_MODEL_NAMES } from './modelTypes';

export const API_TRACKING_HEADER = 'x-pi-tracking' as const;

/**
 * All model names exported from api (v1) repo
 */
export const API_V1_MODEL_NAMES = [
    'AuthCode',
    'Batch',
    'Brand',
    'Checklist',
    'Collection',
    'Company',
    'Config',
    'DeleteHistory',
    'DeTask',
    'DigitizationUpcScan',
    'DigitizationUpdate',
    'DigitizationUserStatus',
    'Domain',
    'Image',
    'Ingredient',
    'KrogerProduct',
    'Meal',
    'Media',
    'MturkAnswer',
    'MturkCannedReply',
    'MturkHIT',
    'MturkInstruction',
    'MturkMessage',
    'MturkTask',
    'MturkWorker',
    'PhotoEntryItem',
    'Place',
    'Product',
    'ProductEditRequest',
    'ProductExport',
    'ProductRequest',
    'ProductVariety',
    'Pv2',
    'Recipe',
    'Store',
    'Tag',
    'Taxonomy',
    'Tracking',
    'TransformerExpression',
    'TransformerResource',
    'UPCGroup',
    'User',
    'UserSearch',
    'WFMBanner',
    'WFMIXOne',
    'WFMPrice',
    'WFMProduct',
    'WFMSpice',
    'WFMStore',
    'WFMUser',
] as const;

/**
 * All model names from both api v1 and api-v2
 */
export const MODEL_NAMES = _.uniq([...API_V1_MODEL_NAMES, ...API_V2_MODEL_NAMES]).sort();

/**
 * These models will be proxied to api (v1)
 */
export const LEGACY_MODEL_NAMES = [
    ..._.difference(MODEL_NAMES, API_V2_MODEL_NAMES),
    // It's going to be a while until we'll be able to port the Product model over
    'Product',
] as const;

export const OP_FIND_PARAMS = [
    'limit',
    'populate',
    'query',
    'select',
    'skip',
    'sort',
    'virtuals',
] as const;

export const OP_FIND_POST_PARAMS = [...OP_FIND_PARAMS, 'queryListVariables'] as const;

export const OP_FIND_ONE_PARAMS = ['query', 'select', 'sort', 'populate', 'virtuals'] as const;

export const OP_COUNT_PARAMS = ['query'] as const;

export const OP_COUNT_POST_PARAMS = [...OP_COUNT_PARAMS, 'queryListVariables'] as const;

export const OP_DELETE_PARAMS = ['populate', 'query'] as const;

export const OP_DISTINCT_PARAMS = ['query', 'distinct'] as const;
