import textBlock from '@pi/text-block';
import Type from 'src/Type';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';

import type { Expression, PrimitiveValue } from 'src/types';

export interface CreateTaskNodeT {
    $type: 'CreateTaskNode';
    taskDefinitionId: Expression | PrimitiveValue;
    taskGroup: Expression;
    config: Expression;
    taskDependencies: Expression;
}

export default class CreateTaskNode extends ValueNode<CreateTaskNodeT> {
    static TYPE = Type.Object(
        {
            key: 'taskDefinitionId',
            label: 'Task',
            type: 'StringNode',
            suggestions: 'external:Tasks',
            forceSuggestion: true,
        },
        {
            key: 'taskGroup',
            label: 'Group',
            type: 'StringNode',
            suggestions: 'internal:TaskGroups',
        },
        {
            key: 'taskDependencies',
            label: 'Task Dependencies',
            type: 'EvaluationNode',
            suggestions: 'internal:TaskGroups',
            forceSuggestion: 'optional',
        },
        { key: 'config', label: 'Config', type: 'ObjectNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.tasker,
        description: textBlock`
            Schedule a new Task to run
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            taskDefinitionId: this.getDefaultFor('StringNode'),
            taskGroup: this.getDefaultFor('StringNode'),
            taskDependencies: this.getDefaultFor('StringNode'),
            config: this.getDefaultFor('ObjectNode'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'taskDefinitionId')) return 'missing "taskDefinitionId"';
    }

    async execute() {
        const taskDefinitionId = await this.context.evaluateLog(
            'taskDefinitionId',
            this.expression.taskDefinitionId,
        );
        this.context.setLastStackEntryMeta(taskDefinitionId);
        if (!taskDefinitionId) return;

        const taskGroup = String(
            await this.context.evaluateLog('taskGroup', this.expression.taskGroup),
        ).trim();
        const localId = this.context.createId('Task');

        if (taskGroup) {
            const groups = this.context.cache.get('taskGroups');
            if (!groups.includes(taskGroup)) groups.push(taskGroup);
        }

        const taskDependencies = await this.context.evaluateLog(
            'taskDependencies',
            this.expression.taskDependencies,
        );

        const config = await this.context.evaluateLog('config', this.expression.config);

        if (taskDependencies && taskGroup && taskDependencies === taskGroup) {
            throw new Error('Task cannot have as dependencies the group it is part of');
        }

        this.context.addTaskerAction({
            kind: 'createTask',
            localId,
            taskDefinitionId,
            taskDependencies,
            config,
            taskGroup,
        });

        return localId;
    }
}
