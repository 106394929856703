import { useEffect, useMemo } from 'react';
import _ from 'lodash';

import { useFormContext, useFormContextSafe } from './FormContext';

import type { Field } from './Field';
import type { FormChangeHandler } from './types';

/**
 * Returns a standard field bound to the given path
 *
 * **NOTE:** All fields are MobX observables so you'll need to use the observer() wrapper to get proper reactivity
 *
 * @see Field
 *
 * @example
 *  const MyComponent = observer(() => {
 *      const nameField = useField('name');
 *
 *      <input type='text' value={nameField.value} onChange={nameField.onChange} />
 *      // alternatively you can spread the "props" property
 *      <input type='text' {...nameField.props} />
 *  })
 */
export function useField<Type = any, Path extends string = string>(path: Path) {
    const { form } = useFormContext();
    return form.field<Path, Type>(path);
}

export function useOrGetField<Type = any>(idOrField: string | Field<Type>): Field<Type> {
    const context = useFormContextSafe();

    if (typeof idOrField === 'string') {
        if (!context) {
            throw new Error(
                'Must be inside a <Form /> component in order to use string field paths \n' +
                    'Either put this component somwhere inside a <Form /> or pass an object field directly',
            );
        }
        return context.form.field(idOrField);
    }

    return idOrField;
}

/**
 * Simple alternative to useField() when you only need the field's value and don't plan to mutate it
 */
export function useFieldValue<Type = any>(path: string | string[]) {
    const { form } = useFormContext();
    return form.get<Type>(path);
}

export function useFormErrors(): Record<string, string> {
    const { form } = useFormContext();
    return form.errors;
}

export function useFormStatus(): { valid: boolean; error?: string; numErrors: number } {
    const { form } = useFormContext();
    return useMemo(
        () => ({
            valid: form.meta.valid,
            error: form.meta.error,
            numErrors: form.meta.numErrors,
        }),
        [form.meta.error, form.meta.numErrors, form.meta.valid],
    );
}

/**
 * Register a change handler to be called whenever any data is changed in the form
 *
 * Change handlers run after the value has been updated
 *
 * This only works when using standard change handlers via `useField()` (i.e. it won't work if you just manually mutate `form.value` via mobx)
 *
 * @example
 *  const MyComponent = () => {
 *      useFormChangeEvent(({ form, changes }) => {
 *          console.log('%d changes happened:', changes.length, changes);
 *      });
 *  }
 */
export function useFormChangeEvent(handler: FormChangeHandler) {
    const { form } = useFormContext();
    useEffect(() => {
        form.changeHandlers.push(handler);
        return () => {
            form.changeHandlers = form.changeHandlers.filter(h => h !== handler);
        };
    }, [form, handler]);
}
