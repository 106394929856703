import _ from 'lodash';
import { getObjectPaths } from '@pi/path-utils';

import Context from './Context';
import iterateExpression from './utils/iterateExpression';

import type { Expression, MongoId, ParentContext } from './types';

export type TransformationExecuteOptions = {
    input?: any;
    context?: Context;
    parentContext?: ParentContext;
};

export interface TransformationStaticExecuteOptions extends TransformationExecuteOptions {
    expression: Expression;
    options?: TransformationOptions;
}

export type TransformationOptions = {
    /**
     * Enabling this will cause the transformer to keep the expression.$ui property around and use it internally
     * Most notably - for now - it will filter out expressions based on the $ui.disabled property
     *
     * This should be enabled in the Transfomer UI but disabled when running it in the backend
     */
    uiMode?: boolean;

    /**
     * A method used by ResourceNode/FormulaResourceNode to retrieve a TransformerResource/FormulaResource document
     * This needs to be passed - instead of being defined inline - since endpoints and libraries
     *  are different from web/node and different repos
     */
    fetchResource?: (
        id: MongoId,
        isFormulaResource?: boolean,
    ) => Promise<null | any[] | Record<string, any>>;

    /**
     * A method that can fetch any document from the database by id
     */
    fetchDocument?: (
        model: string,
        id: string,
    ) => Promise<null | { _id: string; [key: string]: any }>;

    /**
     * A method that can fetch any document from the database by id
     */
    fetchAttributeSchemas?: () => Promise<
        null | { types: string[]; schema: Record<string, any> }[]
    >;

    /**
     * Optional transformation name/id
     * It will be attached to any errors thrown to make debugging easier
     */
    name?: string;

    /**
     * Enable performance debugging of the transformation
     */
    debug?: boolean;
};

export class Transformation {
    expression: Expression;
    options: Required<TransformationOptions>;

    static version: string = require('../package.json').version;

    static globalOptions: Partial<TransformationOptions>;

    static setGlobalOptions = (options: Partial<TransformationOptions>) => {
        Transformation.globalOptions = options;
    };

    static async execute({ expression, options, ...rest }: TransformationStaticExecuteOptions) {
        return new Transformation(expression, options).execute(rest);
    }

    static async contextExecute({
        expression,
        options,
        ...rest
    }: TransformationStaticExecuteOptions) {
        return new Transformation(expression, options).contextExecute(rest);
    }

    static async checkCoverage({ input, expression, options }: TransformationStaticExecuteOptions) {
        return new Transformation(expression, options).checkCoverage(input);
    }

    constructor(expression: Expression, options?: TransformationOptions) {
        if (!expression) throw new Error('Missing expression');
        if (!expression.$type)
            throw new Error(
                `expression is missing $type field: "${JSON.stringify(expression, null, 4)}"`,
            );

        options = {
            uiMode: false,
            debug: false,
            ...Transformation.globalOptions,
            ...options,
        };

        if (!options?.fetchResource) throw new Error('options.fetchResource is required');

        if (!options?.fetchDocument) throw new Error('options.fetchDocument is required');

        if (!options?.fetchAttributeSchemas)
            throw new Error('options.fetchAttributeSchemas is required');

        if (!options?.uiMode) {
            expression = iterateExpression({
                expression: expression!,
                mapper: node => {
                    if (node?.$ui) return _.omit(node, ['$ui']);
                    return node;
                },
            });
        }

        this.expression = expression;
        this.options = options as Required<TransformationOptions>;
    }

    async contextExecute({ input }: { input?: any }) {
        const context = new Context(input, this);

        let result: any, error: Error | undefined;

        try {
            result = await context.execute({ expression: this.expression, isRoot: true });
        } catch (ex: any) {
            if (this.options.name && ex.message) {
                ex.message = `[${this.options.name}] ${ex.message}`;
            }
            error = ex;
        }

        return {
            context,
            result,
            error,
            suggestions: context.getInternalSuggestions(),
        };
    }

    async execute(...args: [TransformationExecuteOptions]) {
        const { result, error } = await this.contextExecute(...args);
        if (error) throw error;
        return result;
    }

    async checkCoverage(
        input: any,
    ): Promise<{ error: Error } | { covered: string[]; notCovered: string[]; warn: string[] }> {
        const {
            error,
            context: { inputLookups },
        } = await this.contextExecute({ input });
        if (error) return { error };
        const allPaths = getObjectPaths(input);
        const covered = [...inputLookups];
        const [warn, notCovered] = _.partition(_.difference(allPaths, covered), path => {
            const pathArr = _.toPath(path);
            return covered.find(lookup => {
                const lookupArr = _.toPath(lookup).slice(0, pathArr.length);
                return _.isEqual(pathArr, lookupArr);
            });
        });
        return { covered, notCovered, warn };
    }
}

export default Transformation;
