import textBlock from '@pi/text-block';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, ValueExpression } from '../../types';

export interface MathNodeT extends BaseExpression {
    $type: 'MathNode';
    operation: string;
    a: ValueExpression;
    b: ValueExpression;
}

const operations = [
    { key: '+', label: 'add (+)' },
    { key: '-', label: 'subtract (—)' },
    { key: '*', label: 'multiply (*)' },
    { key: '/', label: 'divide (/)' },
    { key: '>', label: '>' },
    { key: '>=', label: '>=' },
    { key: '<', label: '<' },
    { key: '<=', label: '<=' },
    { key: '==', label: '==' },
    { key: '!=', label: '!=' },
] as const;

export default class MathNode extends ValueNode<MathNodeT> {
    static TYPE = Type.Object(
        { key: 'operation', type: 'StringNode', suggestions: operations, forceSuggestion: true },
        { key: 'a', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'b', type: 'ValueNode', suggestions: 'primitive' },
    );

    static uiConfig = {
        ...config.presets.comparison,
        description: textBlock`
            Perform common math operations and comparissons
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            operation: Primitive.string('+'),
            a: super.getDefaultFor('EvaluationNode'),
            b: super.getDefaultFor('EvaluationNode'),
        };
    }

    async execute() {
        const operation: (typeof operations)[number]['key'] = await this.context.evaluate(
            this.expression.operation,
        );
        const a = await this.context.evaluate(this.expression.a);
        const b = await this.context.evaluate(this.expression.b);

        switch (operation) {
            case '+':
                return a + b;
            case '-':
                return a - b;
            case '*':
                return a * b;
            case '/':
                return a / b;
            case '>':
                return a > b;
            case '>=':
                return a >= b;
            case '<':
                return a < b;
            case '<=':
                return a <= b;
            case '==':
                return a == b;
            case '!=':
                return a != b;
            default:
                throw new Error(`Unknown operation "${operation}"`);
        }
    }
}
