import LS from 'src/utils/LS';
import { getEnvVariables } from 'src/utils/envConfig';

import type { Transformation, TransformationStaticExecuteOptions } from '@pi/transformer-compiler';

let idCounter = 0;

export default class TransformerWorker {
    worker = new Worker(
        new URL(
            './worker.ts',
            // @ts-ignore
            import.meta.url,
        ),
        {
            name: 'TransformerWorker',
        },
    );
    requests: Record<string, { id: string; resolve: (v: any) => any; reject: () => any }> = {};

    constructor() {
        this.worker.onmessage = ({ data: { id, type, ...data } }) => {
            if (!this.requests[id]) return;

            // generic worker error
            if (type === 'error') this.requests[id].resolve({ ...data, type: 'error' });
            // internal compiler error
            else if (data.error) {
                const error =
                    data.error instanceof Error
                        ? { message: data.error.message, stack: data.error.stack }
                        : data.error;
                this.requests[id].resolve({ ...data, error, type: 'error' });
            } else this.requests[id].resolve(data);

            delete this.requests[id];
        };
    }

    post(type: string, payload: any): Promise<any> {
        const id = String(++idCounter);

        return new Promise((resolve, reject) => {
            this.requests[id] = { id, resolve, reject };
            this.worker.postMessage({
                ...payload,
                env: getEnvVariables(),
                jwt: LS.get('jwt'),
                type,
                id,
            });
        });
    }

    checkCoverage(
        options: TransformationStaticExecuteOptions,
    ): ReturnType<(typeof Transformation)['checkCoverage']> {
        return this.post('checkCoverage', options);
    }

    contextExecute(
        options: TransformationStaticExecuteOptions,
    ): ReturnType<(typeof Transformation)['contextExecute']> {
        return this.post('contextExecute', options);
    }
}
