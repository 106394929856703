import React from 'react';
import _ from 'lodash';
import type { InternalSuggestionsMap } from '@pi/transformer-compiler';

export interface TransformerContextData {
    suggestions: Partial<InternalSuggestionsMap>,
}

export interface TransformerContextType extends TransformerContextData {
    update: (suggestions: InternalSuggestionsMap) => void;
}

const TransformerContext = React.createContext<TransformerContextType>({} as any);

export default TransformerContext;

export const TransformerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [ctx, setCtx] = React.useState<TransformerContextType>({
        suggestions: {},
        update: suggestions => {
            if (_.isEqual(suggestions, ctx.suggestions)) return;
            setCtx({ ...ctx, suggestions });
        },
    });

    return <TransformerContext.Provider value={ctx}>
        {children}
    </TransformerContext.Provider>;
}
