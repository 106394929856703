/* eslint-disable no-useless-escape */
/**
 * Given an attribute type and a list of attributeSchemas, return matching schema.
 */
const getSchema = (
    type: string,
    attributeSchemas: { types: string[]; schema: Record<string, any> }[],
): Record<string, any> | null => {
    const wildcard = '[a-zA-Z0-9-]+';
    const turnType2Regex = (t: string) =>
        '^' + t.replace(/\*\*/g, '.+').replace(/\*/g, wildcard) + '$';

    const parsedAttributeSchemas = attributeSchemas.map(attrbSch => ({
        types: attrbSch.types && attrbSch.types.map(turnType2Regex),
        attrbSch,
    }));

    return (
        parsedAttributeSchemas.find(
            atrbSch => atrbSch.types && atrbSch.types.find(t => new RegExp(t).exec(type)),
        )?.attrbSch?.schema || null
    );
};
export default getSchema;
