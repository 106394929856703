// Disables console message: Download the Apollo DevTools for a better development experience
import { getEnvVariables } from "src/utils/envConfig";

(window as any).__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = true;

import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import LS from 'src/utils/LS';
import cacheOptions from 'src/startup/apollo/cache-options';
import dereferenceDocument, { canDerefence } from 'src/utils/dereferenceDocument';
import _ from 'lodash';
import { Kind } from 'graphql';
// eslint-disable-next-line no-restricted-imports
import { API_TRACKING_HEADER, getSetupOptions } from '@pi/api-client';

import type { HttpOptions, ServerError } from '@apollo/client';
import type { Maybe } from 'src/generated/graphql';

export type OriginalUserData = { token: string; displayName: string } | null;

const env = getEnvVariables();
const API_URL = env.apiV2Url! + '/graphql';

const getToken = () => LS.get('jwt') || '';

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            jwt: getToken(),
            [API_TRACKING_HEADER]: JSON.stringify(getSetupOptions().tracking),
            ...headers,
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { noErrorAlert } = operation.getContext();

    if (!noErrorAlert) {
        if (graphQLErrors) {
            // eslint-disable-next-line no-console
            graphQLErrors.forEach(err => console.error(err));
        }

        if (networkError) {
            if ((networkError as ServerError).statusCode === 401) {
                LS.set({ jwt: '' });
            }
            // eslint-disable-next-line no-console
            console.error(networkError);
        }
    }
});

const getOperationOptions = (
    requestInit: RequestInit,
): {
    operationName?: string;
    query?: string;
} => {
    let operations: Maybe<string> = null;

    if (requestInit.body) {
        if (requestInit.body instanceof FormData) {
            const object: any = {};

            requestInit.body.forEach((value, key) => {
                object[key] = value;
            });

            operations = object.operations;
        } else {
            operations = requestInit.body.toString();
        }
    }

    if (!operations) return {};

    const { operationName, query } = JSON.parse(operations);

    return { operationName, query };
};

const separatedFetch: HttpOptions['fetch'] = (_str, init) => {
    let fetchUri: string = API_URL;

    if (init) {
        const { operationName, query } = getOperationOptions(init);

        // Add the operation + type name at the end of each graphql fetch operation for easier network inspect
        if (operationName && query) {
            try {
                const match = query.match(/query|mutation/);
                const suffix: string = match?.[0] ? `:${match[0]}` : '';

                fetchUri = `${API_URL}/${operationName}${suffix}`;
            } catch (ex) {
                // noop
            }
        }
    }

    return fetch(fetchUri, init);
};

const httpLink = createUploadLink({
    fetch: separatedFetch,
    headers: {
        jwt: getToken(),
    },
});

const dereferenceLink = new ApolloLink((operation, forward) => {
    const def = operation.query.definitions[0];

    /**
     * Automatically converts references to their _id values to match standard create/update mutations
     * You can opt out derefencing via the doNotDereference() method;
     */
    if (def?.kind === Kind.OPERATION_DEFINITION && def.operation === 'mutation') {
        if (canDerefence(operation.variables)) {
            operation.variables = _.mapValues(operation.variables, dereferenceDocument);
        }
    }

    return forward(operation);
});

const timeoutLink = new ApolloLinkTimeout(10 * 60e3);
const linkWithTimeout = timeoutLink.concat(httpLink) as any;
const link = from([authLink, errorLink, dereferenceLink, linkWithTimeout]);

const cache = new InMemoryCache({
    ...cacheOptions,
});

const apolloClient = new ApolloClient({
    cache,
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
        mutate: {
            fetchPolicy: 'network-only',
        },
    },
});

export default apolloClient;
