/**
 * Converts the given item to a string and truncates it value to at most "length" characters
 */
export default function ellipsis(length: number, item: any) {
    if (item == null) return String(item);

    const result = typeof item === 'object' ? JSON.stringify(item) : String(item);

    if (result.length < length) return result;

    return result.slice(0, length) + '…';
}
