import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { Suggest2 } from '@blueprintjs/select';

import BaseSearch from './BaseSearch';

import type { SuggestProps } from '@blueprintjs/select';
import type { BaseSearchProps, BaseType, SearchOption } from './BaseSearch';
import type { SelectOptionKey } from 'src/types';

export interface SearchSuggestProps
    extends BaseSearchProps<SelectOptionKey>,
        Partial<SuggestProps<SelectOptionKey>> {
    showSelected?: boolean;
}

export class SearchSuggest extends BaseSearch<SelectOptionKey, SearchSuggestProps> {
    readonly SelectComponent = Suggest2;

    isOptionSelected: BaseType<'isOptionSelected'> = option => this.props.value === option.key;

    handleClear: BaseType<'handleClear'> = () => this.props.onChange(null);

    onItemSelect: BaseType<'onItemSelect'> = option => this.props.onChange(option.key, option);

    createGetItems: BaseType<'createGetItems'> = () =>
        memoizeOne((options: SearchOption[], inputValue) => {
            const items = options.map((opt, index) => ({
                ...opt,
                search: (opt.label || '').trim().toLowerCase(),
                index,
            }));

            if (inputValue != null && !_.find(items, { key: inputValue })) {
                // @ts-ignore
                items.push({
                    key: inputValue,
                    label: `(custom) ${inputValue}`,
                    isNew: true,
                    buttonProps: {
                        intent: 'danger',
                    },
                });
            }

            return {
                items,
            };
        });

    inputValueRenderer = (item: SearchOption) => (this.props.showSelected ? item.label : '');
    getComponentProps() {
        return {
            ...super.getComponentProps(),
            inputValueRenderer: this.inputValueRenderer,
            selectedItem: this.props.selectedItem,
        };
    }
}

export default SearchSuggest;
