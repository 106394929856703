const imported_stylus_components = require('.cache/react-style-loader/src/components/NumericInput.styl');
import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { type MakeFieldProps, useOrGetField } from 'src/components/Form';

import TextInput from './TextInput';

import type { InputGroupProps2 } from '@blueprintjs/core';

export interface NumericInputProps
    extends Omit<InputGroupProps2, 'onChange' | 'value' | 'defaultValue'> {
    value?: number | null;
    defaultValue?: number | string | null;
    onChange: (value: number) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeOnBlur?: boolean;
    applyRounding?: boolean;
    min?: number;
    max?: number;
    step?: number;
    small?: boolean;
    noButtons?: boolean;
    /**
     * Sets the width of the input to the smallest value
     * Good for 1-2 digit values or 1 digit floats (i.e. 0 -> 100 or 0.0 -> 9.9)
     */
    short?: boolean;
    /**
     * Style is applied to the `<input />` component **and NOT** to the tag istself
     * This is a limitation of the underlying blueprint <InputGroup /> component
     * Use `className` to update container styles
     */
    style?: React.CSSProperties;
}

const ALL_ZERO_PRECISION = /\.0*$/;

/**
 * Reimplementation of blueprint's NumericInput with a few changes
 *
 * Q: Why not just use the default NumericInput?
 * A: It's plagued with several issues and really weird design choices
 *  - precision only goes to 3 decimal points
 *  - the component throws an exception in several instances (stepSize > minStepSize, min > max)
 */
export class NumericInput extends React.PureComponent<NumericInputProps> {
    static getDerivedStateFromProps(nextProps: any, nextState: any) {
        if (nextProps.value === nextState.propsValue) return null;

        return {
            value: defaultFormatter(nextProps.value),
            propsValue: nextProps.value,
        };
    }

    static defaultProps = {
        min: 0,
        step: 1,
    };

    state = {
        value: '',
    };

    get precision() {
        const { step } = this.props;
        return !step || step >= 1 ? 0 : Math.ceil(-1 * Math.log10(step));
    }

    parse(value: string | null): number | null {
        const { step, min, max, applyRounding } = this.props;

        if (value == null) return null;
        if (ALL_ZERO_PRECISION.test(value)) return null;

        let parsed = parseFloat(value);
        if (Number.isNaN(parsed)) return null;

        if (applyRounding && step) {
            parsed = _.round(Math.round(parsed / step) * step, this.precision);
        }

        return _.clamp(parsed, min || 0, max || Infinity);
    }

    change(text: string | null) {
        const { value, onChange } = this.props;

        if (text == null) text = '';
        this.setState(() => ({ value: text }));

        if (text) {
            const parsed = this.parse(text);
            this.setState({ value: parsed == null ? text : String(parsed) });
            if (parsed != null && parsed !== value) onChange(parsed);
        }
    }

    changeStep(direction: 1 | -1) {
        const { value, step, min } = this.props;
        const finalMin = min || 0;
        const finalStep = step || 1;
        const nextStep = String(value == null ? finalMin : value + finalStep);
        const prevStep = String(value == null ? finalMin : value - finalStep);
        this.change(direction > 0 ? nextStep : prevStep);
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let prevent = true;
        switch (event.key) {
            case 'ArrowUp':
                this.changeStep(+1);
                break;
            case 'ArrowDown':
                this.changeStep(-1);
                break;
            default:
                prevent = false;
        }

        if (prevent) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    onChange = (text: string | null) => {
        const { changeOnBlur } = this.props;
        if (!changeOnBlur) this.change(text);
    };

    onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onBlur } = this.props;
        let { value } = event.target;
        value = value.replace(ALL_ZERO_PRECISION, '');
        this.change(value);
        if (onBlur) onBlur(event);
    };

    render() {
        const {
            value,
            onChange,
            onBlur,
            min,
            max,
            step,
            rightElement,
            small,
            short,
            defaultValue,
            noButtons,
            disabled,
            className,
            applyRounding,
            ...rest
        } = this.props;

        return (
            <Root
                fill={false}
                {...rest}
                disabled={disabled}
                type='number'
                defaultValue={defaultValue != null ? String(defaultValue) : undefined}
                value={defaultValue != null && !value ? undefined : this.state.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                className={_.filter([short && 'short', small && 'small', className]).join(' ')}
                rightElement={
                    <Controls>
                        {rightElement}
                        {!noButtons && (
                            <ButtonGroup vertical>
                                <Button
                                    intent={rest.intent}
                                    icon='chevron-up'
                                    onClick={() => this.changeStep(+1)}
                                    disabled={
                                        disabled ||
                                        (max != null && value != null && value + (step || 1) > max)
                                    }
                                    tabIndex={-1}
                                />
                                <Button
                                    intent={rest.intent}
                                    icon='chevron-down'
                                    onClick={() => this.changeStep(-1)}
                                    disabled={
                                        disabled ||
                                        (min != null && value != null && value - (step || 1) < min)
                                    }
                                    tabIndex={-1}
                                />
                            </ButtonGroup>
                        )}
                    </Controls>
                }
            />
        );
    }
}

export default NumericInput;

const defaultFormatter = (value?: string | number | null): string => {
    if (value == null) value = '';
    else if (Number.isNaN(value)) value = '0';
    else if (value && (value as string)[0] === '.') value = '0' + value;

    return String(value);
};

export interface NumericInputFieldProps extends MakeFieldProps<NumericInputProps> {}

export const NumericInputField: React.FC<NumericInputFieldProps> = observer(
    function NumericInputField({ field, ...props }) {
        const f = useOrGetField(field);
        return (
            <NumericInput
                applyRounding={true}
                {...props}
                {...f.props}
                intent={f.meta.valid ? props.intent : 'danger'}
            />
        );
    },
);

const Root = imported_stylus_components.Root.withComponent(TextInput<string>);
const Controls = imported_stylus_components.Controls.withComponent(ButtonGroup);