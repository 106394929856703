import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import fuzzaldrin from 'fuzzaldrin-plus';
import { renderFilteredItems } from '@blueprintjs/select';
import _ from 'lodash';

import markdownToReact from '../../utils/markdownToReact';

import type { SelectProps, MultiSelectProps } from '@blueprintjs/select';
import type { MenuItemOption } from 'src/types';
import type { ButtonProps, MenuProps } from '@blueprintjs/core';

export interface BaseSearchProps<T> {
    value?: T;
    onChange: (key: T, option?: MenuItemOption) => void;
    options: MenuItemOption[];
    allowCreate?: boolean;
    /**
     * If using custom filtering (e.g. doing async query and creating options) you can disable the components filtering to not have 2 filter systems
     */
    disableFilter?: boolean;
    menuProps?: Partial<MenuProps>;
    /**
     * Because this autocomplete is so fucking shit and it's a shitshow to be virtualized ...
     * Here, there you go, limit the results to something that doesn't crash the UX
     *
     * @default 100
     */
    maxItems?: number;
    [key: string]: any;
}

export interface SearchOption extends MenuItemOption {
    /**
     * The part of the label to highlight
     */
    match?: string;
    isNew?: boolean;
    buttonProps?: ButtonProps;
}

export interface CompiledSearchOption extends SearchOption {
    search: string;
}

/**
 * This shouldn't be needed, but TS does not fully support types for abstract classes
 *
 * Until the following works, this workaround is needed:
 *
 *  abstract class Base {
 *      abstract someMethod: (input: string) => number;
 *  }
 *
 *  class Foo extends Base {
 *      someMethod = input => 123;
 *  }
 *
 * The above right now throws a type error that `Foo.someMethod` has "input" arg as "any", even though it extends the abstract Base class which has it defined
 *
 */
export type BaseType<Key extends keyof typeof BaseSearch['prototype']> = typeof BaseSearch['prototype'][Key];

export abstract class BaseSearch<K, T extends BaseSearchProps<K>> extends React.PureComponent<T> {
    abstract readonly SelectComponent: React.ComponentType<any>;

    abstract isOptionSelected: (option: SearchOption) => boolean;
    abstract handleClear: () => void;
    abstract onItemSelect: (option: SearchOption) => void;
    abstract createGetItems: () => ((options: SearchOption[], inputValue: K) => any);
    getItems: any;

    private trimmedResults = 0;

    constructor(props: T) {
        super(props);

        const baseGetItems = _.once(() => this.createGetItems());
        this.getItems = () => baseGetItems()(this.props.options, this.props.value as any);
    }

    private isCreateItemFirst(): boolean {
        return this.props.createNewItemPosition === 'first';
    }

    // copy-pasted from: https://github.com/palantir/blueprint/blob/develop/packages/select/src/components/query-list/queryList.tsx
    // added support for passing menuProps
    itemListRenderer: SelectProps<CompiledSearchOption>['itemListRenderer'] = (listProps) => {
        const { initialContent, noResults, menuProps } = this.props;

        // omit noResults if createNewItemFromQuery and createNewItemRenderer are both supplied, and query is not empty
        const createItemView = listProps.renderCreateItem();
        const maybeNoResults = createItemView != null ? null : noResults;
        const menuContent = renderFilteredItems(listProps, maybeNoResults, initialContent);
        if (menuContent == null && createItemView == null) {
            return null;
        }
        const createFirst = this.isCreateItemFirst();
        return (
            <Menu {...menuProps} ulRef={listProps.itemsParentRef}>
                {createFirst && createItemView}
                {menuContent}
                {!createFirst && createItemView}
                {!!this.trimmedResults &&
                    <MenuItem text={`${this.trimmedResults} results hidden`} intent='warning' />
                }
            </Menu>
        );
    };

    itemRenderer: SelectProps<CompiledSearchOption>['itemRenderer'] = (option, { handleClick, modifiers: { active } }) =>
        <MenuItem
            key={String(option.key)}
            text={option.match ? markdownToReact(option.match) : option.label}
            onClick={handleClick}
            active={active}
            intent={this.isOptionSelected(option) ? 'primary' : 'none'}
            {...option.props}
        />;

    itemPredicate: SelectProps<CompiledSearchOption>['itemPredicate'] = (query, option) => {
        if (this.props.disableFilter) return true;

        query = query.trim().toLowerCase();
        return option.search.includes(query);
    };

    itemListPredicate: SelectProps<CompiledSearchOption>['itemListPredicate'] = (query, items) => {
        const { disableFilter, maxItems = 100 } = this.props;

        const maybeTrim = (list: any[]) => {
            if (maxItems && list.length > maxItems) {
                this.trimmedResults = list.length - maxItems;
                return list.slice(0, maxItems);
            }
            this.trimmedResults = 0;
            return list;
        }

        if (disableFilter) return maybeTrim(items);

        query = query.trim().toLowerCase();
        if (!query) return maybeTrim(items);

        const results = items
            .map(option => ({
                score: fuzzaldrin.score(option.search, query),
                option,
            }))
            .filter(item => item.score > 0)
            .map(item => ({
                ...item.option,
                match: fuzzaldrin.wrap(
                    item.option.label,
                    query,
                    { wrap: { tagOpen: '**', tagClose: '**' } },
                ),
            }));

        return maybeTrim(results);
    };

    tagRenderer: MultiSelectProps<CompiledSearchOption>['tagRenderer'] = option => option.label;

    createNewItemFromQuery: SelectProps<SearchOption>['createNewItemFromQuery'] = query =>
        ({ key: query, label: query.trim() });

    createNewItemRenderer: SelectProps<CompiledSearchOption>['createNewItemRenderer'] = (query, active, onClick) =>
        <MenuItem
            text={markdownToReact(`**Create new entry:** ${query.trim()}`)}
            onClick={onClick}
            active={active}
            icon='insert'
            intent='warning'
        />;

    itemsEqual: SelectProps<CompiledSearchOption>['itemsEqual'] = (a, b) => a.key === b.key;

    getComponentProps() {
        const { value, onChange, options, allowCreate, menuProps, ...props } = this.props;

        return props;
    }

    render() {
        const { SelectComponent } = this;
        const { allowCreate } = this.props;

        return <SelectComponent
            {...this.getItems()}
            noResults={<MenuItem disabled text='No results.' />}
            resetOnSelect
            {...this.getComponentProps()}
            itemRenderer={this.itemRenderer}
            itemListRenderer={this.itemListRenderer}
            itemsEqual={this.itemsEqual}
            onItemSelect={this.onItemSelect}
            tagRenderer={this.tagRenderer}
            itemPredicate={this.itemPredicate}
            itemListPredicate={this.itemListPredicate}
            createNewItemFromQuery={allowCreate ? this.createNewItemFromQuery : null}
            createNewItemRenderer={allowCreate ? this.createNewItemRenderer : null}
        />;
    }
}

export default BaseSearch;
