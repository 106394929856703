import _ from 'lodash';
import { joinPaths } from '@pi/path-utils';

import type { Path } from '@pi/path-utils';

export type Check = ((path: string) => CurriedHandlers) & {
    [key in keyof BaseHandlers]: BaseHandlers[key] & CurriedHandlers;
};

export type ValidateFormFn<Values = any> = (args: { values: Values; check: Check }) => void;

export interface ValidatorContext {
    value: any;
    path: Path;
    absolutePath: string;
}

export type ErrorMsg = string;
export type MaybeErrorMsg = ErrorMsg | undefined;

export interface BaseHandlers {
    error: (path: Path, message?: string) => true;
    assert: (path: Path, message?: string) => boolean;
    required: (path: Path, message?: string) => boolean;
    /**
     * Check if the given value matches a provided RegExp
     */
    match: (path: Path, reg: RegExp, message?: string) => boolean;
    valid: (path: Path, validator: (value: any) => boolean, message?: string) => boolean;
    size: (path: Path, min?: number, max?: number, message?: string) => boolean;
    each: (path: Path, nestedValidator: (child: BaseHandlers) => void) => boolean;
}

export interface CurriedHandlers {
    error: (message?: string) => CurriedHandlers;
    assert: (message?: string) => CurriedHandlers;
    required: (message?: string) => CurriedHandlers;
    /**
     * Check if the given value matches a provided RegExp
     */
    match: (reg: RegExp, message?: string) => CurriedHandlers;
    valid: (validator: (value: any) => boolean, message?: string) => CurriedHandlers;
    size: (min?: number, max?: number, message?: string) => CurriedHandlers;
    each: (nestedValidator: (child: CurriedHandlers) => void) => CurriedHandlers;
    /** returns if the current curry chain is valid */
    isValid: boolean;
}

export interface ValidatorArgs {
    value: any;
    path?: string | null;
    errors?: Record<string, string>;
}

/**
 * 2 ways of using validation:
 * - the boring way:
 *  check.required('text') &&
 *      check.match('text', /foo/) &&
 *      check.whatever(...)
 *
 * - the curried way:
 *  check('text')
 *      .required()
 *      .match(/foo/)
 *      .whatever(...)
 */
export function applyValidation<Values = any>(formValue: Values, validator: ValidateFormFn<Values>) {
    const v = new Validator({ value: formValue });
    validator({ values: formValue, check: v.getCheck() });
    return v.errors;
}

export function getSingleValueValidationHandlers(value: any) {
    const v = new Validator({ value: { field: value } });
    return {
        check: v.curry('field') as unknown as Check,
        getError: () => v.errors.field,
    };
}

export class Validator {
    value: ValidatorArgs['value'];
    path: ValidatorArgs['path'];
    errors: Required<ValidatorArgs>['errors'];
    handlers: { [key: string]: any };

    constructor({ value, path = null, errors = {} }: ValidatorArgs) {
        this.value = value;
        this.path = path;
        this.errors = errors;
        this.handlers = this.getHandlers();
    }

    extend(props?: { [key: string]: any }) {
        return new Validator({
            ..._.pick(this, ['value', 'path', 'errors']),
            ...props,
        });
    }

    curry(path: string) {
        return new CurryValidator({
            value: path === '' ? this.value : _.get(this.value, path),
            path: joinPaths(this.path, path),
            errors: this.errors,
        });
    }

    getCheck(): Check {
        const check = (path: string) => this.curry(path);
        Object.assign(check, this.handlers);
        return <unknown>check as Check;
    }

    getHandlers() {
        const wrapValidator = (
            type: 'baseHandlers' | 'chainHandlers',
            callback: (opts: { validator: any; validatorName: string; result: any; context: ValidatorContext }) => any
        ) =>
            (validator: (...args: any[]) => any, validatorName: string) => {
                const fn = (path: Path, ...args: any[]) => {
                    const absolutePath = joinPaths(this.path, path);
                    const value = path ? _.get(this.value, path) : this.value;

                    // console.log('[%s] %s =', validatorName, absolutePath, value, ...args);

                    const context: ValidatorContext = {
                        value,
                        path,
                        absolutePath,
                    };

                    return callback({
                        validator,
                        validatorName,
                        context,
                        result: validator(context, ...args),
                    });
                };

                fn.type = type;

                return fn;
            };

        return {
            ..._.mapValues(
                this.baseHandlers,
                wrapValidator('baseHandlers', ({ result: err, context: { absolutePath } }) => {
                    if (err) this.errors[absolutePath] = err;
                    return !err;
                }),
            ),

            ..._.mapValues(
                this.chainHandlers,
                wrapValidator('chainHandlers', ({ result }) => result),
            ),
        };
    }

    /**
     * Validation handlers convention:
     *  - first argument validate always the context that contains { value, path, handlers, ... }
     *  - if invalid it returns an error message
     *  - a default error message should be the last argument
     */
    baseHandlers: { [key: string]: (ctx: ValidatorContext, ...args: any[]) => MaybeErrorMsg } = {
        error: (_ctx, message = 'invalid') => message,
        assert: ({ value }, message = 'invalid') => {
            if (!value) return message;
        },
        required: ({ value }, message = 'required') => {
            if (value == null || value === '') return message;
        },
        match: ({ value, path }, reg, message = 'invalid format') => {
            if (!this.handlers.required(path)) return message;
            if (!reg.test(value)) return message;
        },
        valid: ({ value }, validator, message = 'invalid') => {
            const result = validator(value);
            if (result == null || result === true) return;
            return result === false ? message : result;
        },
        size: ({ value }, min = null, max = null, message = null) => {
            const size = _.size(value);
            if (min != null && size < min) return message || `Too few items. At least ${min} required`;
            if (max != null && size > max) return message || `Too many items. At most ${max} required`;
        },
    };

    // These handlers don't return an error and usually apply some other kind of validation
    // e.g. field() returns a curried validation
    chainHandlers: { [key: string]: (ctx: ValidatorContext, ...args: any[]) => void } = {
        each: ({ value, path }, childValidator) => {
            if (!Array.isArray(value) && !_.isPlainObject(value)) return;

            _.forEach(value, (v, k) => {
                childValidator(
                    this.curry(joinPaths(path, k)),
                    v,
                    k
                );
            });
        },
        field: ({ value, path }, fieldPath) =>
            this.curry(joinPaths(path, fieldPath)),
    };
}

export class CurryValidator extends Validator {
    isValid: boolean;
    [key: string]: any;

    constructor({ isValid = true, ...props }: { isValid?: boolean } & ValidatorArgs) {
        super(props);
        this.isValid = isValid;

        const curryHandler = (fn: ((...args: any[]) => any) & { type: string }) =>
            (...args: any[]) => {
                if (fn.type === 'baseHandlers') {
                    this.isValid = this.isValid && fn(null, ...args);
                    return this.extend();
                }

                return fn(null, ...args);
            };

        for (const [handlerName, fn] of Object.entries(this.handlers)) {
            if (Reflect.has(this, handlerName)) {
                throw new Error(`Handler "${handlerName}" clashes with a class property`);
            }

            this[handlerName] = curryHandler(fn);
        }
    }

    extend(props?: object) {
        return new CurryValidator({
            ..._.pick(this, ['isValid', 'value', 'path', 'errors']),
            ...props,
        });
    }
}
