const imported_stylus_components = require('.cache/react-style-loader/src/components/Form/SubmitButton.styl');
import { Button, type ButtonProps } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useToggleState } from 'src/hooks/useToggleState';

import { useFormContext } from './FormContext';
import { Dialog, type DialogProps } from '../Dialog';
import { type FormStore } from './FormStore';

import type { ClassAndStyle } from 'src/types';

export interface SubmitButtonProps extends Omit<ButtonProps, 'onClick'>, ClassAndStyle {
    showErrorMessage?: boolean;
    errorLocation?: 'left' | 'right';
    renderConfirmDialog?: (props: {
        onConfirm: () => void;
        value: Record<string, any>;
    }) => Omit<DialogProps, 'isOpen' | 'onClose'>;
    onClick?: (form: FormStore<any>) => void;
}

/**
 * Helper component to render a submit button inside a <Form /> component, without having to create a child component
 *
 * @example
 *  <Form onSubmit={value => console.log(value)}>
 *      <TextInputField name='someText' />
 *      <SubmitButton />
 *  </Form>
 */
export const SubmitButton: React.FC<SubmitButtonProps> = observer(function SubmitButton({
    showErrorMessage = true,
    errorLocation = 'left',
    onClick: baseOnClick,
    renderConfirmDialog,
    ...baseProps
}) {
    const { form } = useFormContext();
    const openToggle = useToggleState();

    const onClick = useMemo(() => {
        if (renderConfirmDialog) return () => openToggle.on();
        if (form.isContainer) return undefined;
        if (!baseOnClick) return form.handleSubmit;

        return () => {
            baseOnClick(form);
            form.handleSubmit();
        };
    }, [baseOnClick, form, openToggle, renderConfirmDialog]);

    const props: ButtonProps & ClassAndStyle = {
        text: 'Submit',
        intent: 'primary',
        icon: 'floppy-disk',
        disabled: !form.meta.valid, // can be overwritten by props
        onClick,
        ...baseProps,
        type: 'submit',
    };

    if (!showErrorMessage) return <Button {...props} />;

    const { className, style, ...rest } = props;

    const err = form.meta.error && (
        <div style={{ color: 'var(--error-text-color)' }}>{form.meta.error}</div>
    );

    return (
        <Root className={className} style={style}>
            {errorLocation === 'left' && err}
            <Button {...rest} />
            {errorLocation === 'right' && err}

            {renderConfirmDialog && (
                <Dialog
                    {...renderConfirmDialog({ onConfirm: form.handleSubmit, value: form.value })}
                    isOpen={openToggle.value}
                    onClose={openToggle.off}
                />
            )}
        </Root>
    );
});

const Root = imported_stylus_components.Root;