import type { UiConfig } from '../types';

const styles: { [key: string]: UiConfig['style'] } = {
    default: { background: 'rgb(89, 222, 255)' },
    primitive: { background: '#CED9E0' },
    block: { color: '#000', background: 'rgb(0, 224, 139)' },
    list: { color: 'white', background: 'rgb(177, 88, 255)' },
    danger: { background: '#DB3737', color: 'white' },
    tasker: { background: '#634DBF', color: 'white' },
};

const presets = {
    default: {
        style: {},
    },
    primitive: {
        style: styles.primitive,
        // group: 'Basic',
    },
    function: {
        style: {
            background: '#2965CC',
            color: 'white',
        },
    },
    block: {
        style: styles.block,
        group: 'Blocks',
    },
    comparison: {
        style: styles.default,
        group: 'Comparisons',
    },
    list: {
        style: styles.list,
        group: 'Lists',
    },
    pv2: {
        style: styles.default,
        group: 'Pv2',
    },
    mturk: {
        style: styles.default,
        group: 'Mturk',
    },
    csv: {
        style: styles.default,
        group: 'CSV',
    },
    tasker: {
        style: styles.tasker,
        group: 'Tasker',
    },
} as const;

export default {
    styles,
    presets,
};
