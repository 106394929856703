
      import API from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/.pnpm/style-loader@3.3.1_webpack@5.75.0/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/cjs.js!../../../node_modules/.pnpm/stylus-loader@7.1.0_stylus@0.58.1_webpack@5.75.0/node_modules/stylus-loader/dist/cjs.js!../../../packages/react-style-loader/dist/src/stylus-global-imports-loader.js??ruleSet[1].rules[3].use[0]!./global.styl";
      
      

var options = {"attributes":{"data-type":"global"}};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/cjs.js!../../../node_modules/.pnpm/stylus-loader@7.1.0_stylus@0.58.1_webpack@5.75.0/node_modules/stylus-loader/dist/cjs.js!../../../packages/react-style-loader/dist/src/stylus-global-imports-loader.js??ruleSet[1].rules[3].use[0]!./global.styl";
       export default content && content.locals ? content.locals : undefined;
