import _ from 'lodash';
import Ajv from 'ajv';
import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import getSchema from 'src/utils/getSchema';
import addAttributeDefaults from 'src/utils/addAttributeDefaults';
import parseSubtypes from 'src/utils/parseSubtypes';

import ValueNode from '../ValueNode';
import Type, { type ExtendedArg } from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, Expression, ParentContext } from '../../types';
import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';

const translate = (schema: Record<string, any>) => {
    const addTransformerNode = (
        jsonSchema: Record<string, any>,
        transformerNode: Record<string, any>,
    ) => {
        switch (jsonSchema.type) {
            case 'number':
            case 'integer':
                transformerNode.$type = 'NumericNode';
                transformerNode.value = 1;
                break;
            case 'string':
                transformerNode.$type = 'StringNode';
                if (jsonSchema.enum?.length) transformerNode.value = jsonSchema.enum[0];
                else transformerNode.value = '';
                break;
            case 'boolean':
                transformerNode.$type = 'BooleanNode';
                transformerNode.value = true;
                break;
            case 'array': {
                transformerNode.$type = 'ArrayNode';
                const newNode = {};
                addTransformerNode(jsonSchema.items, newNode);
                const minItems = jsonSchema.minItems || 1;
                transformerNode.value = _.fill(Array(minItems), newNode);
                break;
            }
            case 'object': {
                transformerNode.$type = 'ObjectNode';
                const propertyKeys = Object.keys(jsonSchema.properties);
                transformerNode.value = {};
                for (const property of propertyKeys) {
                    const newNode = {};
                    addTransformerNode(jsonSchema.properties[property], newNode);
                    transformerNode.value[property] = newNode;
                }
                break;
            }
            default:
                break;
        }
    };

    const transformerNode = {};
    addTransformerNode(schema, transformerNode);

    return transformerNode;
};

const onChange: ExtendedArg['onChange'] = ({ value, parentPath, formValue, getSuggestions }) => {
    const parentNode = _.get(formValue, parentPath);
    const attributeSchemas = getSuggestions('', { suggestions: 'external:AttributeSchemas' });
    const schema = getSchema(value, attributeSchemas);

    if (schema) {
        parentNode.value = translate(schema);
    }
};

export class AjvError extends Error {
    name: string;
    attribute: string;
    errors: any[];

    constructor(attribute: string, errors: any[]) {
        super(`AjvError: ${JSON.stringify({ attribute, errors })}`);
        this.name = 'AjvError';
        this.attribute = attribute;
        this.errors = errors;
    }
}

export interface Pv2AttributeSchemaNodeT extends BaseExpression {
    $type: 'Pv2AttributeSchemaNode';
    type: StringNodeT;
    pushToAttributes?: BooleanNodeT;
    key?: StringNodeT;
    parent?: StringNodeT;
    if?: Expression;
    source?: StringNodeT;
    value: Expression;
}

export default class Pv2AttributeSchemaNode extends ValueNode<Pv2AttributeSchemaNodeT> {
    static TYPE = Type.Object(
        { key: 'type', type: 'StringNode', onChange },
        { key: 'pushToAttributes', label: 'Push to attributes', type: 'BooleanNode' },
        { key: 'if', type: 'ValueNode' },
        { key: 'key', type: 'StringNode' },
        { key: 'parent', type: 'StringNode' },
        { key: 'source', type: 'StringNode' },
        { key: 'value', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.pv2,
        description: textBlock`
            Create a Pv2 Attribute.

            By default the created attribute is automatically added to ".attributes" if "Push to attributes" is true
        `,
    };

    static getDefault(): any {
        return {
            ...super.getDefault(),
            key: Primitive.null(),
            parent: Primitive.null(),
            type: this.getDefaultFor('StringNode'),
            pushToAttributes: { ...this.getDefaultFor('BooleanNode'), value: false },
            value: super.getDefaultFor('ObjectNode'),
        };
    }

    validate(): string | undefined {
        if (!Reflect.has(this.expression, 'type')) return 'missing "type"';
        if (!Reflect.has(this.expression, 'value')) return 'missing "value"';
    }

    async execute({ sourcePath }: ParentContext = {}): Promise<any> {
        const ifResult = this.expression.if
            ? await this.context.evaluate(this.expression.if, { sourcePath })
            : null;
        // a `null` if value is valid since that's the default state
        if (ifResult !== null && !ifResult) return null;

        const result = addAttributeDefaults({
            type: await this.context.evaluate(this.expression.type, { sourcePath }),
            value: await this.context.evaluate(this.expression.value, {
                sourcePath,
                isOutput: true,
            }),
            key:
                this.expression.key &&
                (await this.context.evaluate(this.expression.key, { sourcePath, isOutput: true })),
            parent:
                this.expression.parent &&
                (await this.context.evaluate(this.expression.parent, {
                    sourcePath,
                    isOutput: true,
                })),
            source:
                this.expression.source &&
                (await this.context.evaluate(this.expression.source, { sourcePath })),
        });

        if (await this.context.evaluate(this.expression.pushToAttributes)) {
            this.context.addAttribute(result);
        }

        const attributeSchemas =
            (await this.context.transformation.options.fetchAttributeSchemas()) || [];
        const schema = getSchema(result.type, attributeSchemas);

        if (schema) {
            const ajv = new Ajv();
            const parsedJson = parseSubtypes({ valueSchema: schema });
            const validator = ajv.compile(parsedJson);

            if (!validator(result.value)) {
                throw new AjvError(result.type, validator.errors || []);
            }
        }

        return result;
    }
}
