const imported_stylus_components = require('.cache/react-style-loader/src/pages/Login.styl');
import { Button, Card, FormGroup, H1 } from '@blueprintjs/core';
import { ErrorBox, Form, TextInputField, useField } from '@pi/ui';
import { useCallback } from 'react';
import { namedOperations, useLoginMutation } from 'src/generated/graphql';
import setJwt from 'src/utils/setJwt';
import apolloClient from 'src/startup/apollo';

import type { LoginMutation } from 'src/generated/graphql';
import type { MutationResult } from '@apollo/client';

const LoginPage: React.FC = () => {
    const [login, loginData] = useLoginMutation({
        onCompleted: ({ login }) => {
            if (!login?.jwt) return;
            setJwt(login.jwt);
            // doing it here so we ensure the token is updated first
            void apolloClient.refetchQueries({
                include: [namedOperations.Query.session],
            });
        },
    });

    const onSubmit = useCallback(
        (variables: any) => {
            void login({ variables });
        },
        [login],
    );

    return (
        <Root>
            <Form container onSubmit={onSubmit} initialValue={{ email: '', password: '' }}>
                <Content login={loginData} />
            </Form>
        </Root>
    );
};

export default LoginPage;

const Content: React.FC<{ login: MutationResult<LoginMutation> }> = ({ login }) => (
    <Card elevation={2}>
        <H1 className='mb1'>👷‍♀️ Papers, please</H1>
        <FormGroup label='Email' labelInfo='(admin account)'>
            <TextInputField placeholder='judy@hopps.com' large field={useField('email')} />
        </FormGroup>

        <FormGroup label='Password'>
            <TextInputField
                placeholder='password'
                type='password'
                large
                field={useField('password')}
            />
        </FormGroup>

        <ErrorBox error={login.error} />

        <div style={{ flex: '1 1 0' }} />

        <Button
            type='submit'
            intent='primary'
            text='Beam me up, Scotty!'
            fill
            large
            loading={login.loading}
        />
    </Card>
);

const Root = imported_stylus_components.Root;