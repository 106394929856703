import _ from 'lodash';

import type { SetupOptions } from './typeDefs';

let setupOptions: SetupOptions = {} as any;

function sanitizeHTTPHeader(header: string) {
    return header.replace(/['’]/, '');
}

export function setup(options: Partial<SetupOptions>) {
    if (options.tracking && options.tracking.host) {
        options.tracking.host = sanitizeHTTPHeader(options.tracking.host);
    }

    setupOptions = {
        apiLegacyToken: process.env.API_LEGACY_TOKEN || '',
        apiLegacyUrl: process.env.API_LEGACY_URL || '',
        apiV2Token: process.env.API_V2_TOKEN || '',
        apiV2Url: process.env.API_V2_URL || '',
        ...(setupOptions as any),
        ...options,
    };
}

export const setupOnce = _.once(setup);

export function getSetupOptions() {
    return setupOptions;
}
