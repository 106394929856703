import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, UiConfig } from 'src/types';

export interface EvaluationNodeT extends BaseExpression {
    $type: 'EvaluationNode';
    value: string;
}

export default class EvaluationNode extends ValueNode<EvaluationNodeT> {
    static TYPE = Type.Evaluation({
        key: 'value',
    });

    static uiConfig: UiConfig = { ...config.presets.primitive };

    validate() {
        if (typeof this.expression.value !== 'string' && this.expression.value !== null)
            return 'value must be a string or null';
    }

    static getDefault() {
        return {
            ...super.getDefault(),
            value: '',
        };
    }

    async execute() {
        if (!this.expression.value) return null;
        return this.context.evaluate(`{${this.expression.value}}`);
    }
}
