import _ from 'lodash';
import nodes from 'src/nodes';

import { isObject } from './performanceFunctions';

import type { BaseExpression, Expression } from 'src/types';
import type { ValuesType } from 'utility-types';

type IterateOptions = {
    expression: Expression;
    mapper: (
        node: BaseExpression,
        nodeClass: ValuesType<typeof nodes>,
        parentNode: null | BaseExpression,
    ) => BaseExpression | null;
};

export function iterateExpression({ expression, mapper }: IterateOptions) {
    const it = (node: Expression, parentNode: BaseExpression | null): any => {
        if (Array.isArray(node)) return node.map(child => it(child, parentNode));

        if (!node?.$type) {
            // handles things like ObjectNode that can have deeply nested node children
            if (node && isObject(node))
                return _.mapValues(node, child => it(child as any, parentNode));
            return node;
        }

        const nodeClass = nodes[node.$type as keyof typeof nodes];
        if (!nodeClass) return node;

        const newExpr = mapper(node, nodeClass, parentNode);
        if (!newExpr?.$type) return newExpr;

        const mappedNodeClass = nodes[newExpr.$type as keyof typeof nodes];
        if (!mappedNodeClass) {
            // eslint-disable-next-line no-console
            console.warn(`Invalid new expression "${newExpr.$type}" from "${node.$type}"`);
            return newExpr;
        }

        return _.mapValues(
            newExpr,
            // This lazily looks for any value that has a $type and recurses over it
            // The proper way of doing it would be to have each node define an `static iterate` method
            //  and specify on how it is recursed... but ain't nobody got time for that
            newNode => it(newNode as any, newExpr),
        );
    };

    return it(expression, null);
}

export default iterateExpression;
