import React from 'react';
import _ from 'lodash';

import DefaultTypeRenderer, { Action, ChildConfig } from './DefaultTypeRenderer';
import ContentEditableInput from '../ContentEditableInput';
import type { TypeRendererConfig, TypeRendererIterateOptions } from '.';
import { Primitive } from '@pi/transformer-compiler';

type Entry = [key: string, value: any];

export default class RestObjectType extends DefaultTypeRenderer {
    static iterate({ next, expression, path, shape }: TypeRendererIterateOptions<'RestObject'>) {
        const entries = Object.entries((expression as any)?.value || {});
        entries.forEach(([key, child], index) => {
            next({
                expression: child as any,
                path: [...path, 'value', `["${key}"]`],
                parentPath: path,
                isLast: index === entries.length - 1,
                key,
                canDisable: true,
                canMove: true,
                argumentType: shape.argType,
            });
        });
    }

    private getChangeHandler(
        childKey: string,
        { before, self, after }: { before?: Entry, self?: Entry | null, after?: Entry },
    ) {
        return () => {
            // Why not just use this.config?
            // Because actions are rendered once and then cached, they only update if the node itself changes
            // When a sibling changes, it won't cause all its other siblings to update - that's intentional
            // The side-effect of that is that the "expression" is not guaranteed to be up to date in an event handler
            const { onChange, expression } = this.config.getRenderer(this.config.node.path).config;
            const value = expression.value?.[childKey];

            onChange({
                ...expression,
                value: _.fromPairs(
                    // doing it this convoluted way to preserve the key order
                    // it's nice to not have new keys jump to the end as key names change
                    Object.entries(expression.value)
                        .map(([k, v]) =>
                            k === childKey
                                ? [
                                    before,
                                    self === undefined ? [childKey, value] : self,
                                    after,
                                ] as any
                                : [[k, v]]
                        )
                        .flat()
                        .filter(x => x)
                ),
            });
        };
    }

    getChildConfig(childConfig: TypeRendererConfig): ChildConfig {
        const { expression } = this.config;
        const childKey = childConfig.node.key!;
        const value = expression.value?.[childKey];

        const preSelectLabel = <>
            <ContentEditableInput
                value={childConfig.node.key!}
                onChange={newKey => this.getChangeHandler(childKey, { self: [newKey, value] })()}
            />
            <span>=</span>
        </>;

        const actions = [
            {
                key: 'remove',
                enabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'trash',
                        tooltip: `Remove the "${childKey}" key`,
                        onClick: this.getChangeHandler(childKey, { self: null }),
                    }),
            },
            {
                key: 'add-top',
                enabled: true,
                showWhenDisabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add-row-top',
                        tooltip: `Add a new entry above "${childKey}"`,
                        onClick: this.getChangeHandler(
                            childKey,
                            { before: [`key_${_.random(1e3)}`, Primitive.string('')] },
                        ),
                    }),
            },
            {
                key: 'add-bottom',
                enabled: true,
                showWhenDisabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add-row-bottom',
                        tooltip: `Add a new entry below "${childKey}"`,
                        onClick: this.getChangeHandler(
                            childKey,
                            { after: [`key_${_.random(1e3)}`, Primitive.string('')] },
                        ),
                    }),
            },
        ] as Action[];

        return {
            preSelectLabel,
            keyOverride: null,
            actions,
        };
    }

    getActions(): Action[] {
        const { expression, onChange } = this.config;

        return [
            ...super.getActions(),
            {
                key: 'add',
                enabled: true,
                render: () =>
                    this.renderActionButton({
                        icon: 'add',
                        tooltip: 'Add a new entry at the end',
                        onClick: () =>
                            onChange({
                                ...expression,
                                value: {
                                    ...expression.value,
                                    [`key_${_.random(1e3)}`]: { $type: 'EvaluationNode', value: ''},
                                },
                            })
                    }),
            },
        ];
    }
}
