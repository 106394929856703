import Node from '../Node';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression } from 'src/types';

export interface OutputNodeT extends BaseExpression {
    $type: 'OutputNode';
    value: { [key: string]: any };
}

/**
 * @deprecated
 */
export default class OutputNode extends Node<OutputNodeT> {
    static TYPE = Type.RestObject();

    static uiConfig = {
        ...config.presets.block,
        description: 'Assigns to the output object each key and value',
    };

    async execute() {
        for (const [path, expr] of Object.entries(this.expression.value)) {
            const value = await this.context.evaluate(expr, { targetPath: path, isOutput: true });
            this.context.set(path, value);
        }
    }
}
