import _ from 'lodash';

import ValueNode from '../../ValueNode';
import Type from '../../../Type';
import config from '../../../utils/config';

import type { BaseExpression, Expression } from '../../../types';

export interface LessThanNodeT extends BaseExpression {
    $type: 'LessThanNode';
    value: [Expression, Expression];
}

export default class LessThanNode extends ValueNode<LessThanNodeT> {
    static TYPE = Type.NTuple(
        { key: 'a', type: 'ValueNode', suggestions: 'number' },
        { key: 'b', type: 'ValueNode', suggestions: 'number' },
    );

    static uiConfig = {
        ...config.presets.comparison,
    };

    async execute() {
        const source = await this.context.evaluate(this.expression.value[0]);
        const target = await this.context.evaluate(this.expression.value[1]);
        return _.isFinite(source) && _.isFinite(target) && source < target;
    }
}
