import textBlock from '@pi/text-block';
import iterateExpressionItems from 'src/utils/iterateExpressionItems';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';
import ellipsis from '../../utils/ellipsis';

import type { BaseExpression, Expression } from 'src';

export interface FindNodeT extends BaseExpression {
    $type: 'FindNode';
    input: Expression;
    as: Expression;
    cond: Expression;
    map?: Expression;
}

export default class FindNode extends ValueNode<FindNodeT> {
    static TYPE = Type.Object(
        { key: 'input', type: 'ValueNode', suggestions: 'array' },
        { key: 'as', type: 'StringNode', suggestions: 'off' },
        { key: 'cond', label: 'Condition', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'map', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.list,
        description: textBlock`
            Find a single element from a given array.
            Optionally map it to a different shape.
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: this.getDefaultFor('EvaluationNode'),
            as: {
                $type: 'StringNode',
                value: 'item',
            },
            cond: {
                ...this.getDefaultFor('EqualsNode'),
                value: [
                    {
                        $type: 'EvaluationNode',
                        value: '$$item',
                    },
                    this.getDefaultFor('EvaluationNode'),
                ],
            },
            map: null,
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'input')) return 'missing "type"';
        if (!Reflect.has(this.expression, 'as')) return 'missing "as"';
        if (!Reflect.has(this.expression, 'cond')) return 'missing "cond"';
    }

    async execute() {
        const items = this.expression.input;
        const itemName = await this.context.evaluate(this.expression.as);
        const target = await this.context.evaluate(items);

        if (!Array.isArray(target)) return null;

        let match: { value?: any } = {};

        await iterateExpressionItems({
            items,
            itemName,
            context: this.context,
            callback: async ({ sourcePath, value, key }) => {
                this.context.setLastStackEntryMeta(
                    `index: ${key}, ${itemName}: ${ellipsis(20, value)}`,
                );
                if (!(await this.context.evaluate(this.expression.cond, { sourcePath }))) return;
                match = { value };
                if (this.expression.map) {
                    match.value = await this.context.evaluate(this.expression.map, { sourcePath });
                }
            },
        });

        return match ? match.value : null;
    }
}
