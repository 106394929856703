import Type from 'src/Type';
import config from 'src/utils/config';

import Node from '../Node';

import type { Expression } from 'src/types';

export interface CreatePv2NodeT {
    $type: 'CreatePv2Node';
    data: Expression;
}

export default class CreatePv2Node extends Node<CreatePv2NodeT> {
    static TYPE = Type.Object({ key: 'data', label: 'Data', type: 'ObjectNode' });

    static uiConfig = {
        ...config.presets.tasker,
        description: 'Create an entirely new Pv2 document',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            data: {
                ...this.getDefaultFor('ObjectNode'),
                value: {
                    source: {
                        ...this.getDefaultFor('StringNode'),
                        value: 'tasker',
                    },
                    name: this.getDefaultFor('StringNode'),
                    brand: this.getDefaultFor('StringNode'),
                    attributes: {
                        ...this.getDefaultFor('ArrayNode'),
                        value: [this.getDefaultFor('Pv2AttributeSchemaNode')],
                    },
                },
            },
        };
    }

    async execute() {
        const data = await this.context.evaluateLog('data', this.expression.data);

        if (!data) return;

        if (data.name) this.context.setLastStackEntryMeta(data.name);

        if (typeof data !== 'object') throw new Error('Pv2 must be an object');

        this.context.addTaskerAction({
            kind: 'createPv2',
            data,
        });
    }
}
