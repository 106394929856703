const imported_stylus_components = require('.cache/react-style-loader/src/components/MultiProgress.styl');
import _ from 'lodash';
import React, { useMemo } from 'react';
import { type ClassAndStyle } from 'src/types';

export interface MultiProgressBarProps extends ClassAndStyle {
    label?: React.ReactNode;
    labelSize?: number; // rem
    total?: number;
    readonly ranges: ReadonlyArray<MultiProgressBarRange>;
    colors?: string[];
    noTotalLabel?: boolean;
    fill?: boolean;
    showEmpty?: boolean;
    height?: string | number;
    minBarWidth?: string | number;
}

export interface MultiProgressBarRange {
    label?: React.ReactNode;
    size: number;
}

export const MultiProgressBar = React.memo(function MultiProgress({
    label,
    labelSize,
    total,
    ranges,
    colors = [
        'var(--bp-blue3)',
        'var(--bp-orange3)',
        'var(--bp-green4)',
        'var(--bp-red3)',
        'var(--bp-gold3)',
        'var(--bp-violet3)',
    ],
    noTotalLabel,
    fill,
    showEmpty,
    height,
    minBarWidth,
    ...rest
}: MultiProgressBarProps) {
    const sum = _.sum(_.map(ranges, 'size'));
    const id = useMemo(() => _.uniqueId('bar-'), []);

    let max = _.sum(_.map(ranges, 'size'));
    if (total) max = Math.max(total, max);

    return (
        <Root {...rest} data-fill={fill} id={id}>
            <style>{`
                #${id} .${BarContainer.className} {
                    --height: ${height || '.5rem'};
                    --min-bar-width: ${minBarWidth || '.5rem'};
                }
            `}</style>
            {label ? (
                <Label style={{ width: labelSize ? `${labelSize}rem` : undefined }}>{label}</Label>
            ) : null}
            <BarContainer>
                {ranges.map(({ size, label }, index) =>
                    !showEmpty && size <= 0 ? null : (
                        <Bar
                            key={index}
                            tabIndex={-1}
                            style={{
                                width: `${(size / max) * 100}%`,
                                color: colors[index % colors.length],
                            }}
                        >
                            {label && (
                                <BarLabel>
                                    {label}: {size.toLocaleString()}
                                </BarLabel>
                            )}
                        </Bar>
                    ),
                )}
            </BarContainer>
            {noTotalLabel ? null : (
                <Label style={{ width: '5rem' }}>Total: {sum.toLocaleString()}</Label>
            )}
        </Root>
    );
});

const Root = imported_stylus_components.Root;
const BarContainer = imported_stylus_components.BarContainer;
const Label = imported_stylus_components.Label;
const Bar = imported_stylus_components.Bar;
const BarLabel = imported_stylus_components.BarLabel;