import _ from 'lodash';
import DefaultTypeRenderer from './DefaultTypeRenderer';
import type { TypeRendererIterateOptions } from '.';

export default class ObjectType extends DefaultTypeRenderer {
    static iterate({ shape, next, expression, path }: TypeRendererIterateOptions<'Object'>) {
        shape.args.forEach((argumentType: any) => {
            next({
                expression: (expression as any)[argumentType.key!],
                path: [...path, argumentType.key],
                parentPath: path,
                isLast: argumentType.key === _.last(shape.args)!.key,
                key: argumentType.key!,
                canMove: false,
                argumentType,
            });
        });
    }
}
