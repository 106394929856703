const imported_stylus_components = require('.cache/react-style-loader/src/components/AppRouter.styl');
import _ from 'lodash';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { ErrorBox } from '@pi/ui';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSessionQuery } from 'src/generated/graphql';
import LoginPage from 'src/pages/Login';

import Nav from './Nav';
import privateRoutes from './routes';
import { useAppContext } from '../AppContext';

import type { User } from 'src/generated/graphql';
import type { RouteDefinition } from './routes';

const AppRouter: React.FC = () => {
    const session = useSessionQuery();

    if (session.loading) return <Spinner />;

    if (session.error) return <ErrorBox error={session.error} />;

    if (!session.data?.session?.isAuthenticated || !session.data.session.user) {
        return (
            <BrowserRouter>
                <LoginPage />
            </BrowserRouter>
        );
    }

    const user = session.data.session.user;

    return (
        <BrowserRouter>
            <Nav />
            <PageContainer>
                <Suspense fallback={<Spinner />}>
                    <Routes>
                        {routeRenderer(privateRoutes, user as User)}

                        <Route
                            path='*'
                            element={
                                <NonIdealState
                                    icon='bug'
                                    title='No Page Found'
                                    className='mt2'
                                    description={
                                        <>
                                            <div>Hi {user.displayName},</div>
                                            <br />
                                            <div>
                                                You somehow landed on a page that does not exist
                                            </div>
                                            <br />
                                            <div>This is not supposed to happen</div>
                                            <pre>pathname: {window.location.pathname}</pre>
                                        </>
                                    }
                                />
                            }
                        />
                    </Routes>
                </Suspense>
            </PageContainer>
        </BrowserRouter>
    );
};

const routeRenderer = (root: RouteDefinition, user: User) => {
    const iterate = (
        route: RouteDefinition,
        path: string[],
        titlePath: string[],
    ): React.ReactNode => {
        if (!route.isActive(user)) return;

        const children = route.children
            ? _.map(route.children, (child, childPath) =>
                  iterate(child, [...path, childPath], [...titlePath, route.name!]),
              )
            : null;

        if (!route.component) return children;

        const url = '/' + [...path, ...(route.params || []).map(p => ':' + p)].join('/');

        const elem = (
            <Route
                key={url}
                path={url}
                element={<PageWrapper route={route} titlePath={titlePath} />}
            />
        );

        if (!children?.length) return elem;

        return (
            <React.Fragment key={url}>
                {elem}
                {children}
            </React.Fragment>
        );
    };

    return iterate(root, [], []);
};

const PageWrapper: React.FC<{ route: RouteDefinition; titlePath: string[] }> = ({
    route,
    titlePath,
}) => {
    const app = useAppContext();
    const Component = route.component!;
    app.setPageTitle([...titlePath, route.name]);
    return <Component />;
};

export default AppRouter;

const PageContainer = imported_stylus_components.PageContainer;