import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Types from '../../Type';

import type { Expression, BaseExpression, ParentContext } from '../../types';

export interface ArrayNodeT extends BaseExpression {
    $type: 'ArrayNode';
    value: Expression[];
}

export default class ArrayNode extends ValueNode<ArrayNodeT> {
    static TYPE = Types.RestArray({ argType: { type: 'ValueNode' } });

    static uiConfig = {
        ...config.presets.default,
        style: {
            ...config.presets.default.style,
            background: '#FFC940',
        },
        description: 'Evaluates to an array with the specified of the given elements',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [
                ValueNode.getDefaultFor('EvaluationNode'),
                ValueNode.getDefaultFor('EvaluationNode'),
            ],
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const result = [];
        for (const [index, expr] of (this.expression.value || []).entries()) {
            const item = await this.context.evaluateLog(index, expr, {
                sourcePath,
                // targetPath: joinPaths(targetPath, index),
                // isOutput: true,
            });
            if (item != null && item !== '') result.push(item);
        }
        return result;
    }
}
