const imported_stylus_components = require('.cache/react-style-loader/src/components/TextWithSuggestions.styl');
import React from 'react';
import { Button } from '@blueprintjs/core';
import type { ExtendedArg } from '@pi/transformer-compiler';
import { SearchOption, SearchSelect, SelectMenuOption } from '@pi/ui';

import ContentEditableInput from './ContentEditableInput';
import type { TypeRendererConfig } from './TypeRenderers';
import TransformerContext from './TransformerContext';

const TextWithSuggestions: React.FC<{
    path: string;
    value: any;
    onChange: (v: string) => void;
    getSuggestions: TypeRendererConfig['getSuggestions'];
    argumentType?: ExtendedArg;
    [key: string]: any;
}> = ({ value, onChange, getSuggestions, path, argumentType, ...rest }) => {
    const context = React.useContext(TransformerContext);

    const input = <ContentEditableInput
        {...rest}
        value={value}
        onChange={onChange}
        className='ml025'
    />;

    if (argumentType?.suggestions === 'off') return input;

    // hardcoded suggestions
    const suggestions = Array.isArray(argumentType?.suggestions)
        ? argumentType!.suggestions as string[]
        : getSuggestions?.(path, argumentType, context) || [];

    if (!Array.isArray(suggestions)) return input;
    if (!suggestions.length) return input;

    const options = [
        ...suggestions.map((option: string | SelectMenuOption) =>
            typeof option === 'string'
                ? { key: option, label: option }
                : option
        ),
    ];

    const allowTextInput = !!(options.length && !argumentType?.forceSuggestion);

    if (allowTextInput) {
        options.unshift({
            key: null,
            label: 'Text Input',
        });
    }

    if (argumentType?.forceSuggestion === 'optional') {
        options.unshift({
            key: null,
            label: 'n/a',
        });
    }

    const suggestionMatch = options.find(option => option.key === value);

    return <>
        <SearchSelect
            value={suggestionMatch ? value : null}
            options={options as SearchOption[]}
            onChange={val => onChange(val as any || '')}
            allowCreate={false}
            fill={false}
            renderTarget={() =>
                <Button
                    small
                    minimal
                    icon={(suggestionMatch as any)?.icon}
                    intent='primary'
                    rightIcon='chevron-down'
                    text={
                        suggestionMatch && value
                            ? <SelectHandle>{suggestionMatch.label || value}</SelectHandle>
                            : allowTextInput
                                ? ''
                                : value
                                    ? <SelectHandle data-invalid>invalid: {value}</SelectHandle>
                                    : argumentType?.forceSuggestion === 'optional'
                                        ? <SelectHandle>n/a</SelectHandle>
                                        : <SelectHandle data-invalid>select option</SelectHandle>
                    }
                />
            }
            className='ml025'
        />

        {!suggestionMatch && allowTextInput && input}
    </>;
}

export default TextWithSuggestions;

const SelectHandle = imported_stylus_components.SelectHandle;