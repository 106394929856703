const sleep = (time: number): Promise<void> =>
    new Promise(resolve => setTimeout(() => resolve(), time));

Promise.sleep = sleep;
Promise.prototype.sleep = function (time) {
    return this.then(result => sleep(time).then(() => result));
};

Promise.defer = <T>(): PromiseDefer<T> => {
    const result: { resolve?: any; reject?: any; promise?: any } = {};
    const promise = new Promise((resolve, reject) => {
        result.resolve = (value?: any) => {
            resolve(value);
            return result.promise;
        };
        result.reject = (err: any) => {
            reject(err);
            return result.promise;
        };
    });
    result.promise = promise;
    return <PromiseDefer<T>>result;
};

Promise.allObject = async object => {
    const result: any = {};
    for (const key in object) result[key] = null;

    await Promise.all(
        Object.entries(object).map(([key, promise]) =>
            Promise.resolve()
                .then(() => promise)
                .then(promiseResult => {
                    result[key] = promiseResult;
                }),
        ),
    );

    return result;
};
