const imported_stylus_components = require('.cache/react-style-loader/src/components/AjvError.styl');
import textBlock from '@pi/text-block';
import type { ErrorObject } from 'ajv';
import type { HTMLDivProps } from 'src/types';
import { Markdown } from '..';

type ErrorWithMessage = ErrorObject & Required<Pick<ErrorObject, 'message'>>;

export const AjvError: React.FC<HTMLDivProps & { error: ErrorObject }> = ({ error, ...props }) =>
    <AjvErrorRoot {...props}>
        <Markdown text={formatAjvError(error)} />
    </AjvErrorRoot>;

export function formatAjvError(error: ErrorObject) {
    const config = getErrorFormatter(error)

    return config.format(error as any, config);
}

export const getErrorFormatter = (_error: ErrorObject): Formatter & { instancePath: string; schemaPath: string } => {
    const error: ErrorWithMessage = {
        message: 'no message',
        ..._error,
    };

    for (const config of errorFormatters) {
        if (!config.message.test(error.message)) continue;

        const { getInstancePath, getSchemaPath } = config;
        const instancePath = getInstancePath(error);
        const schemaPath = getSchemaPath(error);

        return { ...config, instancePath, schemaPath };
    }

    return '' as never; // last formatter guaranteed to match
}

export const getSchemaPath = (str: string) =>
    str?.split('/').filter(x => x).join('.').replace(/^#/, '') || 'ROOT';

const baseErrorFormatters: Formatter[] = [
    {
        message: /^must NOT have additional properties/i,
        format: (error, { instancePath }) => `${error.message}: "${instancePath}"`,
        getInstancePath: error => getSchemaPath(error.instancePath) + '.' + error.params.additionalProperty,
    },
    {
        message: /^must have required property '/,
        format: error => error.message!,
    },
    {
        message: /.*/,
        format: (error, { instancePath, schemaPath }) =>
            textBlock`
                ${error.message}

                - path:     ${instancePath}
                - schema:   ${schemaPath}
            `,
    },
];

const errorFormatters: Required<Formatter>[] = baseErrorFormatters.map(config => ({
    getInstancePath: (error: ErrorObject) => getSchemaPath(error.instancePath),
    getSchemaPath: (error: ErrorObject) => getSchemaPath(error.schemaPath),
    ...config,
}) as any);

type Formatter = {
    message: RegExp;
    format: (error: ErrorWithMessage, config: { instancePath: string; schemaPath: string }) => string;
    getInstancePath?: (error: ErrorWithMessage) => string;
    getSchemaPath?: (error: ErrorWithMessage) => string;
};

const AjvErrorRoot = imported_stylus_components.AjvErrorRoot;