import { Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import isHumanTask from 'src/utils/isHumanTask';

import type { Tooltip2Props } from '@blueprintjs/popover2';
import type { IconName, Intent } from '@blueprintjs/core';
import type { TaskDefinition } from 'src/generated/graphql';

export interface TaskTypeIconProps extends Omit<Tooltip2Props, 'content' | 'intent'> {
    type: TaskDefinition['type'];
    enabled: boolean;
}

const TaskTypeIcon: React.FC<TaskTypeIconProps> = ({ type, enabled, ...props }) => {
    let intent: Intent = 'none';
    let { icon, message }: { icon: IconName; message: React.ReactNode } = isHumanTask(type)
        ? { icon: 'person', message: 'Human Task' }
        : { icon: 'desktop', message: 'Machine Task' };

    if (enabled != null) {
        if (enabled) {
            intent = 'primary';
            message = (
                <div>
                    {message}
                    <br />
                    TaskDefinition is enabled
                    <br />
                    Processing new tasks of this type
                </div>
            );
        } else {
            intent = 'danger';
            message = (
                <div>
                    TaskDefinition is disabled
                    <br />
                    No new Tasks will be processed of this type
                </div>
            );
        }
    }

    return (
        <Tooltip2 {...props} content={message as any} intent={intent}>
            <Icon intent={intent} icon={icon} />
        </Tooltip2>
    );
};

export default TaskTypeIcon;
