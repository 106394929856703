import React, { useEffect, useRef } from 'react';
import { TextArea, InputGroup, Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { type MakeFieldProps, useOrGetField } from 'src/components/Form';
import _ from 'lodash';

import type { InputGroupProps2 } from '@blueprintjs/core';

export interface TextInputProps<Value = string>
    extends Omit<InputGroupProps2, 'onChange' | 'onBlur' | 'value' | 'defaultValue'> {
    value?: Value | null;
    defaultValue?: Value | null;
    onChange: (
        value: Value,
        event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    ) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    format?: (value: Value | null | undefined) => string;
    parse?: (str: string) => Value;
    multiline?: boolean;
    changeOnBlur?: boolean;
    changeOnEnter?: boolean;
    minLength?: number;
    maxLength?: number;
    placeholder?: string;
    error?: string;
    loading?: boolean;
    style?: React.CSSProperties;
}

/**
 * Extension of @blueprint InputGroup and TextArea and switches between the two based on if multiline=true
 * Adds a couple of extra commonly-used features like changeOnBlur and disabling autocomplete by default
 */
export function TextInput<Value = string>(props: TextInputProps<Value>) {
    const {
        onChange,
        multiline,
        changeOnBlur,
        changeOnEnter = !!changeOnBlur,
        minLength = 0,
        maxLength = 0,
        loading,
        format,
        parse,
        ...restProps
    } = props;
    const valueParser = parse || (_.identity as unknown as NonNullable<typeof parse>);
    const valueFormatter = format || (_.identity as unknown as NonNullable<typeof format>);
    const changeValue = (v: any, event: any) => onChange?.(valueParser(v), event);
    const inputRef = useRef<HTMLInputElement>();
    const prevValue = useRef<any>();

    const baseProps: TextInputProps<any> = {
        fill: true,
        autoComplete: 'off-for-realsies',
        minLength,
        maxLength: maxLength > minLength ? maxLength : undefined,
        ...(restProps as any),
    };

    if (changeOnEnter) {
        baseProps.onKeyDown = event => {
            if (event.key === 'Enter' && (!multiline || event.metaKey || event.ctrlKey)) {
                event.preventDefault();
                event.stopPropagation();
                changeValue((event.target as any).value, event);
                return;
            }
            (restProps as any).onKeyDown?.(event);
        };
    }

    if (changeOnBlur) {
        baseProps.defaultValue = baseProps.value;
        delete baseProps.value;
        baseProps.onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value !== props.defaultValue) changeValue(value, event);
            if (props.onBlur) props.onBlur(event);
        };
    } else {
        Object.assign(baseProps, {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                changeValue(event.target.value, event),
        });
    }

    if (Reflect.has(baseProps, 'value')) baseProps.value = valueFormatter(baseProps.value);
    if (Reflect.has(baseProps, 'defaultValue')) {
        baseProps.defaultValue = valueFormatter(baseProps.defaultValue);
    }

    useEffect(() => {
        if (!changeOnBlur || !inputRef.current) return;
        if (baseProps.defaultValue === prevValue.current) return;
        inputRef.current.value = baseProps.defaultValue;
        prevValue.current = baseProps.defaultValue;
    }, [changeOnBlur, baseProps.defaultValue]);

    if (multiline) {
        return <TextArea {...(baseProps as any)} growVertically />;
    }

    if (loading) {
        baseProps.rightElement = <Spinner size={baseProps.large ? 20 : 16} />;
    }

    return <InputGroup {...(baseProps as any)} inputRef={inputRef} />;
}

export default TextInput;

export interface TextInputFieldProps<Value = string>
    extends MakeFieldProps<TextInputProps<Value>> {}

export const TextInputField = observer(function TextInputField<Value = string>({
    field,
    ...props
}: TextInputFieldProps<Value>) {
    const f = useOrGetField(field);
    return (
        <TextInput<Value> {...props} {...f.props} intent={f.meta.valid ? props.intent : 'danger'} />
    );
});
