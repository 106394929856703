import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import Type from 'src/Type';
import config from 'src/utils/config';

import Node from '../Node';

import type { ArrayNodeT } from 'src/nodes/value/ArrayNode';
import type { BaseExpression, ParentContext, ValueExpression } from 'src/types';
import type { StringNodeT } from 'src/nodes/value/StringNode';
import type { ValueBlockNodeT } from 'src/nodes/value/ValueBlockNode';

export interface FunctionDefineNodeT extends BaseExpression {
    $type: 'FunctionDefineNode';
    name: StringNodeT;
    args: ValueExpression;
    body: ValueExpression;
}

export default class FunctionDefineNode extends Node<FunctionDefineNodeT> {
    static TYPE = Type.Object(
        { key: 'name', label: 'Name', type: 'StringNode', suggestions: 'off' },
        { key: 'args', label: 'Argument Names', type: 'ValueNode', suggestions: 'off' },
        { key: 'body', label: 'Body', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.function,
        description: textBlock`
            Define your own Transformer Nodes!

            If you find yourself reusing a specific set of instructions, you can instead create a function

            Used in conjunction with Function Call Node
        `,
    };

    static getDefault(): FunctionDefineNodeT {
        return {
            ...(super.getDefault() as FunctionDefineNodeT),
            name: Primitive.string('my_sum_function'),
            args: {
                ...super.getDefaultFor('ArrayNode'),
                value: [Primitive.string('arg_a'), Primitive.string('arg_b')],
            } as ArrayNodeT,
            body: {
                ...super.getDefaultFor('ValueBlockNode'),
                value: [Primitive.evaluation('$$arg_a + $$arg_b')],
            } as ValueBlockNodeT,
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'name')) return 'missing "name"';
        if (!Reflect.has(this.expression, 'body')) return 'missing "body"';
        if (!Reflect.has(this.expression, 'args')) return 'missing "args"';
        if (!Array.isArray(this.expression.args) && this.expression.args?.$type !== 'ArrayNode') {
            return '"args" must be an Array';
        }
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const name = await this.context.evaluate(this.expression.name);
        this.context.setLastStackEntryMeta(name);
        if (this.context.functions[name]) throw new Error(`Cannot redefine function "${name}"`);
        this.context.functions[name] = {
            name,
            args: await this.context.evaluate(this.expression.args, { sourcePath }),
            body: this.expression.body,
        };
    }
}
