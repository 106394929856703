import _ from 'lodash';

export default function ensureArrayProp(prop: string, item: any) {
    if (item[prop] === undefined) return { ...item, [prop]: [] };
    if (Array.isArray(item[prop])) return item;
    return {
        ...item,
        [prop]: _.castArray(item[prop]),
    };
}
