import React, { type ReactNode, useContext, useEffect, useState } from 'react';

export interface UiContextType {
    darkMode: boolean;
    update: (diff: Partial<UiContextType>) => void;
}

export const UiContext = React.createContext<UiContextType>(null as any);

export const useUiContext = () => useContext(UiContext);

export const UiContextProvider = ({ children }: { children: ReactNode }) => {
    const [value, setValue] = useState<UiContextType>();

    useEffect(() => {
        setValue({
            darkMode: false,
            update: diff => setValue(prev => ({ ...(prev as UiContextType), ...diff })),
        });
    }, []);

    useEffect(() => {
        if (value?.darkMode) document.body.classList.add('bp4-dark');
        else document.body.classList.remove('bp4-dark');
    }, [value?.darkMode]);

    if (!value) return null;

    return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};
