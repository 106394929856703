import _ from 'lodash';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';
import ellipsis from 'src/utils/ellipsis';

import Node from '../Node';
import Types from '../../Type';

import type { BaseExpression, ParentContext } from '../../types';
import type { ObjectNodeT } from '../value/ObjectNode';
import type { StringNodeT } from '../value/StringNode';

export interface CsvRowNodeT extends BaseExpression {
    $type: 'CsvRowNode';
    sheet: StringNodeT;
    data: ObjectNodeT;
}

export default class CsvRowNode extends Node<CsvRowNodeT> {
    static TYPE = Types.Object(
        {
            key: 'sheet',
            label: 'Sheet Name',
            type: 'StringNode',
            suggestions: 'internal:CsvSheetName',
            forceSuggestion: true,
        },
        { key: 'data', label: 'Data', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.csv,
        description: '',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            sheet: Primitive.string(''),
            data: {
                ...super.getDefaultFor('ObjectNode'),
                value: {
                    col1: Primitive.string(''),
                },
            },
        } as any;
    }

    async execute({ sourcePath }: ParentContext) {
        const [sheet, data] = await Promise.all([
            this.context.evaluate(this.expression.sheet, { sourcePath }),
            this.context.evaluate(this.expression.data, { sourcePath }),
        ]);

        const sheetData = this.context.cache.get('csvSheets')[sheet];

        if (!sheetData) throw new Error(`CSV Sheet "${sheet}" does not exist`);

        const { path, columns } = sheetData;
        const columnMap = _.keyBy(columns);
        const value: Record<string, any> = {};
        const invalidKeys = [];
        const invalidValues: Array<{ key: string; value: any }> = [];

        for (const key in data || {}) {
            if (columnMap[key]) {
                const v = data[key];
                if (v != null) {
                    if (typeof v === 'object') invalidValues.push({ key, value: v });
                    else value[key] = v;
                }
            } else invalidKeys.push(key);
        }

        if (invalidKeys.length || invalidValues.length) {
            throw new Error(
                `CSV Sheet "${sheet}":\n` +
                    _.filter([
                        invalidKeys.length &&
                            `    - does not have columns ${invalidKeys.join('; ')}`,
                        invalidValues.length && '    - has invalid values for columns:',
                        ...invalidValues.map(
                            ({ value, key }) =>
                                `       - ${key}: [${typeof value}] ${ellipsis(30, value)}`,
                        ),
                    ]).join('\n'),
            );
        }

        await this.context.evaluate(
            {
                ...Node.getDefaultFor('SetNode'),
                path: Primitive.string(path + '.data'),
                value: {
                    ...Node.getDefaultFor('AddToSetNode'),
                    value: [value],
                },
            } as any,
            { sourcePath },
        );
    }
}
