import React from 'react';
import _ from 'lodash';
import { Menu, Button, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { observer } from 'mobx-react';

import type { Intent } from '@blueprintjs/core';
import type { MenuItemOption } from 'src/types';
import { MakeFieldProps, useOrGetField } from './Form';

export interface SelectMenuProps<Option extends MenuItemOption = MenuItemOption> {
    value?: Option['key'];
    onChange: (key: Option['key'], option?: Option) => any;
    options: Option[];
    /**
     * The option handle to show for when no option is selected (or for when `value` is not part of `options`)
     */
    emptyOption?: Option | null;
    /**
     * Props to pass to the Blueprint Button handle
     */
    buttonProps?: { [key: string]: any };
    /**
     * Function to get the label of the handle based on the selected option
     */
    getButtonText?: (option: Option) => React.ReactNode;
    disabled?: boolean;
    /**
     * Blueprint intent of the button handle for when an option is selected
     * Defaults to `primary`
     */
    selectedIntent?: Intent;
}

export class SelectMenu<Option extends MenuItemOption> extends React.PureComponent<SelectMenuProps<Option> & { [key: string]: any }> {
    static defaultProps: Partial<SelectMenuProps> = {
        emptyOption: {
            key: null,
            label: '[select]',
        },
        getButtonText: option => option.label || String(option.key),
    };

    render() {
        const {
            value,
            onChange,
            options,
            emptyOption,
            buttonProps,
            getButtonText,
            disabled,
            selectedIntent,
            intent: _intent,
            ...rest
        } = this.props;
        const finalValue = value == null || value === '' ? null : value;
        const finalOptions = Array.isArray(options)
            ? [...options]
            : [];

        if (emptyOption && !_.find(finalOptions, { key: emptyOption.key })) {
            finalOptions.unshift(emptyOption);
        }

        let match = _.find(finalOptions, { key: finalValue }) as Option;
        let intent = _intent;

        if (match) {
            if (selectedIntent && (!emptyOption || match.key !== emptyOption.key)) {
                intent = selectedIntent;
            }
        } else {
            intent = 'danger' as const;
            match = {
                key: finalValue,
                label: `Invalid option: ${finalValue}`,
            } as any;
        }

        return (
            <Popover2
                position='bottom'
                {...rest}
                disabled={disabled}
                canEscapeKeyClose
                shouldReturnFocusOnClose
                content={
                    <Menu style={{ maxHeight: 410, overflowY: 'auto' }}>
                        {finalOptions.map(item => (
                            <MenuItem
                                {...item.props}
                                key={String(item.key)}
                                text={item.label}
                                disabled={item.key === value}
                                onClick={() => onChange(item.key, item)}
                            />
                        ))}
                    </Menu>
                }
            >
                <Button
                    intent={intent}
                    text={getButtonText!(match)}
                    rightIcon='chevron-down'
                    disabled={disabled}
                    {...match.props as any}
                    {...buttonProps}
                />
            </Popover2>
        );
    }
}

export default SelectMenu;

export interface SelectMenuFieldProps extends MakeFieldProps<SelectMenuProps> { }

export const SelectMenuField: React.FC<SelectMenuFieldProps> = observer(({ field, ...props }) => {
    const f = useOrGetField(field);

    return <SelectMenu
        {...props}
        value={f.value}
        onChange={f.onChange}
    />;
});
