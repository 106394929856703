import _ from 'lodash';
import { joinPaths, type PathComponent } from '@pi/path-utils';
import {
    type BaseExpression,
    type Expression,
    type ExtendedArg,
    nodes,
    Primitive,
} from '@pi/transformer-compiler';
import LruCache from 'src/utils/LruCache';

import TypeRenderers, { type ExpressionNode } from '../TypeRenderers';

import type { ValuesType } from 'utility-types';

export interface CompiledExpression {
    moveCopy: null | { type: 'copy' | 'move'; path: string };
    nodes: NodeState[];
}

export interface NodeState {
    id: string;
    indents: NodeIndent[];
    color?: string;
    background?: string;
    nodeClass: ValuesType<typeof nodes> | null;
    path: string;
    parentPath: string | null;
    parentType: Expression['$type'] | null;
    key: string | null;
    isLast: boolean;
    canDisable?: boolean;
    isDisabled: boolean;
    isCollapsed: boolean;
    isHidden: boolean;
    isParentMoving: boolean;
    canMove: boolean;
    canBeMovedTo: boolean;
    argumentType?: ExtendedArg;
}

export interface NodeIndent {
    type: 'blank' | 'leaf' | 'end' | 'straight';
    color?: string;
}

interface CreateNodeProps extends Pick<NodeState, ValuesType<typeof createNodePropList>> {
    parentIndents?: NodeIndent[];
}

const createNodePropList = [
    'path',
    'parentPath',
    'key',
    'nodeClass',
    'isLast',
    'canDisable',
    'isDisabled',
    'isCollapsed',
    'isHidden',
    'isParentMoving',
    'canMove',
    'canBeMovedTo',
    'argumentType',
] as const;

interface IterateExpressionCbProps
    extends Pick<
        NodeState,
        'key' | 'isLast' | 'canDisable' | 'nodeClass' | 'canMove' | 'argumentType'
    > {
    expression: ExpressionNode;
    path: PathComponent[];
    parentPath: PathComponent[] | null;
}

export type IterateExpressionCallback = (
    options: Omit<IterateExpressionCbProps, 'nodeClass'>,
) => void;

export function getExpressionCompiler() {
    const nodeMap: { [key: string]: NodeState } = {};
    let counter = 0;

    const tps = LruCache.memoize(
        (path: PathComponent[]) => joinPaths('expression', ...path),
        path => path.join(','),
        { maxSize: 10e3 },
    );
    const getNode = (path: string | null) => (path == null ? null : nodeMap[path]);

    const _createNode = ({
        path,
        nodeClass,
        parentPath,
        parentIndents,
        ...rest
    }: CreateNodeProps): NodeState => {
        const parent = getNode(parentPath);
        const node: NodeState = {
            id: `node-${counter++}`,
            path,
            parentPath,
            color: nodeClass?.uiConfig?.style.color,
            background: nodeClass?.uiConfig?.style.background,
            nodeClass,
            parentType: parent?.nodeClass.name ?? null,
            ...rest,
            indents: (parentIndents || []).map(item => ({
                ...item,
                type: item.type === 'end' ? 'blank' : item.type === 'leaf' ? 'straight' : item.type,
            })),
        };

        if (parent) {
            node.indents.push({
                type: node.isLast ? 'end' : 'leaf',
                color: parent.background,
            });
        }

        return node;
    };

    const createNodeMemo = LruCache.memoize(
        _createNode,
        props =>
            createNodePropList
                .map(name => {
                    const value = props[name];
                    if (value && name === 'argumentType')
                        return value.key + value.type + value.suggestions + value.forceSuggestions;
                    return value;
                })
                .join('_') + (props.parentIndents || []).map(x => `${x.type}-${x.color}`).join('-'),
        { maxSize: 10e3 },
    );

    return (
        root: ExpressionNode,
        { defaultCollapseDepth }: { defaultCollapseDepth: number },
    ): CompiledExpression => {
        const nodes: NodeState[] = [];
        let moveCopy: null | { type: 'move' | 'copy'; path: string } = null;

        iterateExpression(root, ({ path, expression }) => {
            const isMove = !!(expression as any)?.$ui?.move;
            const isCopy = !!(expression as any)?.$ui?.copy;

            if ((isMove || isCopy) && moveCopy) {
                throw new Error(
                    `Only a single node can be marked as moved/copied at a time, found "${
                        moveCopy.path
                    }" and "${tps(path)}"`,
                );
            }

            if (isMove || isCopy) moveCopy = { type: isMove ? 'move' : 'copy', path: tps(path) };
        });

        iterateExpression(root, props => {
            const { expression: expr, path, parentPath, canDisable, canMove } = props;
            const pathString = tps(path);
            const parentPathString = parentPath && tps(parentPath);
            const parent = parentPath && getNode(parentPathString);
            const isParentMoving = !!(moveCopy && pathString.startsWith(moveCopy.path));
            const selfCollapsed = (expr as BaseExpression)?.$ui?.collapsed;
            const depth = parent ? parent.indents.length + 1 : 0;

            if (!props.nodeClass) throw new Error('no nodeClass');

            const node = createNodeMemo({
                ...(_.pick(props, createNodePropList) as unknown as CreateNodeProps),
                path: pathString,
                parentPath: parentPathString,
                canDisable,
                isDisabled: !!(
                    parent?.isDisabled ||
                    (canDisable && (expr as BaseExpression)?.$ui?.disabled)
                ),
                isCollapsed: !!(
                    parent?.isCollapsed ||
                    selfCollapsed ||
                    (selfCollapsed !== false && depth + 1 >= defaultCollapseDepth)
                ),
                isHidden: !!(parent?.isDisabled || parent?.isCollapsed),
                parentIndents: parent?.indents,
                canMove:
                    canMove &&
                    // not supporting Text inputs yet
                    !!props.nodeClass &&
                    // only a single node can be moved at a time
                    (!moveCopy || pathString === moveCopy.path),
                canBeMovedTo:
                    canMove &&
                    (!moveCopy ||
                        // children of the currently moving node cannot be sorted
                        (!pathString.startsWith(moveCopy.path) &&
                            // parents of the currently moving node cannot be sorted
                            !isParentMoving)),
                isParentMoving,
            });

            nodeMap[pathString] = node;
            nodes.push(node);
        });

        return {
            moveCopy,
            nodes,
        };
    };
}

function iterateExpression(
    root: ExpressionNode,
    nodeCb: (options: IterateExpressionCbProps) => void,
) {
    const next: IterateExpressionCallback = options => {
        const { path, parentPath } = options;
        let { expression } = options;

        // backwards compatibility to older version of expressions created
        //  with the transformer (v1) and transformer-compiler (<v1.3)
        if (expression == null) expression = Primitive.evaluation('null');
        else {
            switch (typeof expression) {
                case 'string':
                    expression = Primitive.string(expression);
                    break;
                case 'number':
                    expression = Primitive.number(expression);
                    break;
                case 'boolean':
                    expression = Primitive.boolean(expression);
                    break;
                default:
                    break;
            }
        }

        let $type: keyof typeof nodes = (expression as any)?.$type;
        if (!$type) {
            // eslint-disable-next-line no-console
            console.warn(
                `deprecated expression format at "${path.join('.')}" in argumentType=`,
                options.argumentType,
                'expression =',
                root,
            );
            // throw new Error(`missing $type field at "${path.join('.')}" in argumentType=${JSON.stringify(options.argumentType)}`);
            expression = Primitive.json(expression);
            $type = expression.$type;
        }

        const nodeClass = nodes[$type];
        if (!nodeClass) throw new Error(`Unknown expression $type="${$type}"`);

        nodeCb({
            ...options,
            path,
            nodeClass,
            parentPath,
        });

        if (!Reflect.has(TypeRenderers, nodeClass.TYPE.type)) {
            throw new Error(`node TYPE="${(nodeClass.TYPE as any).type}" not handled`);
        }

        TypeRenderers[nodeClass.TYPE.type as keyof typeof TypeRenderers].iterate({
            shape: nodeClass.TYPE as any,
            path,
            next,
            expression,
        });
    };

    next({
        expression: root,
        path: [],
        parentPath: null,
        isLast: true,
        canDisable: false,
        key: null,
        canMove: false,
    });
}
