const imported_stylus_components = require('.cache/react-style-loader/src/pages/TaskDefinition/components/TaskPipeline.styl');
import { Spinner } from '@blueprintjs/core';
import { ReactFlow, Controls, Background, Position, MarkerType } from 'reactflow';
import TaskTypeIcon from 'src/pages/Tasks/components/TaskTypeIcon';

import useTaskTree from '../hooks/useTaskTree';
import { NodeTags } from './TaskTree';

import type { TaskTreeNode } from '../hooks/useTaskTree';
import type { ClassAndStyle } from '@pi/ui';

export interface TaskPipelineProps extends ClassAndStyle {
    taskId: string;
}

const TaskPipeline: React.FC<TaskPipelineProps> = ({ taskId, ...props }) => {
    const { loading, root } = useTaskTree(taskId);
    const flowProps = useFlowProps(root);

    if (loading) return <Spinner />;

    return (
        <Root {...props}>
            <ReactFlow {...flowProps} fitView>
                <Controls />
                <Background />
            </ReactFlow>
        </Root>
    );
};

export default TaskPipeline;

type BaseFlowProps = React.ComponentProps<typeof ReactFlow>;

interface FlowProps extends BaseFlowProps {
    nodes: NonNullable<BaseFlowProps['nodes']>;
    edges: NonNullable<BaseFlowProps['edges']>;
}

function useFlowProps(root: TaskTreeNode | undefined) {
    const props: FlowProps = {
        nodes: [],
        edges: [],
    };

    if (!root) return props;

    let minY = 0;

    const iterate = (node: TaskTreeNode, pos: { x: number; y: number }) => {
        pos = { ...pos };
        minY = Math.min(minY, pos.y);

        if (node.dependencies?.length) {
            ++pos.x;

            node.dependencies.forEach(dep => {
                props.edges.push({
                    id: node.id + '__' + dep.id,
                    source: dep.id,
                    target: node.id,
                    style: {
                        stroke: 'red',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: 'red',
                    },
                });
            });
        }

        if (node.children?.length) {
            node.children.forEach((child, index) => {
                props.edges.push({
                    id: node.id + '__' + child.id,
                    source: node.id,
                    target: child.id,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                    },
                });

                iterate(child, {
                    x: pos.x + 1,
                    y: pos.y + -1 * Math.floor(node.children.length / 2) + index,
                });
            });
        }

        props.nodes.push({
            id: node.id,
            position: {
                x: pos.x,
                y: pos.y,
            },
            data: {
                label: (
                    <>
                        <Row>
                            <TaskTypeIcon type={node.task.type} enabled={node.task.enabled} />
                            <div style={{ flex: '1 1 0' }}>
                                <div>{node.task.name}</div>
                                <NodeTags node={node} />
                            </div>
                            <TypeLabel>{node.task.type}</TypeLabel>
                        </Row>
                    </>
                ),
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            style: {
                background: 'var(--menu-background-color)',
                color: 'var(--bp-pt-text-color)',
            },
        });
    };

    iterate(root, { x: 0, y: 0 });

    for (const node of props.nodes) {
        node.position.x *= 230;
        node.position.y = 50 + (minY * -1 + node.position.y) * 80;
    }

    return props;
}

const Root = imported_stylus_components.Root;
const Row = imported_stylus_components.Row;
const TypeLabel = imported_stylus_components.TypeLabel;