import './startup/console-error-squelch';

import ReactDOM from 'react-dom/client';

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'reactflow/dist/style.css';
import '@pi/polyfills';
import '@pi/ui';

import './global.styl';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // do not enable, will trigger every hook twice because... that is what passes as good sane design nowadays. Enable it for a bit if you feel like testing but keep this disabled so it's not a massive waste of time for everyone
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>
);
