import Node from '../Node';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, Expression } from '../../types';

export interface AllNodeT extends BaseExpression {
    $type: 'AllNode';
    value: Expression[];
}

export default class AllNode extends Node<AllNodeT> {
    static TYPE = Type.RestArray();

    static uiConfig = {
        ...config.presets.block,
        description: 'Evaluates all child nodes',
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [this.getDefaultFor('SetNode')],
        };
    }

    async execute() {
        for (const [index, expr] of this.expression.value.entries()) {
            await this.context.evaluateLog(index, expr);
        }
    }
}
