import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { addPassiveDOMListener } from 'src/utils/addPassiveListener';

export function useWindowResize(
    { throttle = 20, callback, direction = 'xy', initial = true }: {
        throttle?: number;
        direction?: 'x' | 'y' | 'xy';
        callback: () => void;
        initial?: boolean;
    }
) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    const cb = useCallback(() => callbackRef.current(), []);
    const lastDirectionRef = useRef<{ w: number; h: number }>({ w: -1, h: -1 })

    const onChange = useCallback(
        throttle ? _.throttle(cb, throttle) : cb,
        [throttle],
    );

    const triggerChange = useCallback(() => {
        const dir = { w: window.innerWidth, h: window.innerHeight };
        const xChanged = dir.w !== lastDirectionRef.current.w;
        const yChanged = dir.h !== lastDirectionRef.current.h;
        lastDirectionRef.current = dir;
        if (xChanged && (direction === 'x' || direction === 'xy')) return onChange();
        if (yChanged && (direction === 'y' || direction === 'xy')) return onChange();
    }, [direction, onChange]);

    useEffect(() => {
        if (initial) cb();
    }, [initial]);

    useEffect(() => addPassiveDOMListener(window, 'resize', triggerChange), [triggerChange]);
}
