import { MODEL_NAMES } from './constants';

import type { ApiClient } from './ApiClient';
import type { ModelTypes } from './modelTypes';
import type {
    ApiModelCountParams,
    ApiModelCountPostParams,
    ApiModelDeleteParams,
    ApiModelDistinctParams,
    ApiModelDistinctV2Params,
    ApiModelFindOneAndUpdateParams,
    ApiModelFindOneParams,
    ApiModelFindParams,
    ApiModelFindPostParams,
    ApiModelId,
    ApiModelUpdateManyParams,
    ModelName,
    UpdatePayload,
} from './typeDefs';

type GetModelType<
    Name extends ModelName,
    Type extends 'input' | 'output',
> = Name extends keyof ModelTypes ? ModelTypes[Name][Type] : any;

export class ApiModelClient<
    Name extends ModelName,
    Input = GetModelType<Name, 'input'>,
    Output = GetModelType<Name, 'output'>,
> {
    constructor(public api: ApiClient, public modelName: Name) {
        this.modelName = modelNameMap[modelName.toLowerCase() as keyof typeof modelNameMap] as Name;
    }

    getUrl(path: string) {
        path = path ? path.replace(/^\/+/, '') : '';
        return `/model/${this.modelName}/${path}`.replace(/\/+$/, '');
    }

    find(params: ApiModelFindParams): Promise<Output[]> {
        return this.api.get(this.getUrl(''), params);
    }

    findPost(params: ApiModelFindPostParams): Promise<Output[]> {
        return this.api.post(this.getUrl('/find'), params);
    }

    findDistinct<DistinctItem = string>(params: ApiModelDistinctParams): Promise<DistinctItem[]> {
        return this.api.get(this.getUrl(''), params);
    }

    findDistinctV2<DistinctItem = string>(
        params: ApiModelDistinctV2Params,
    ): Promise<DistinctItem[]> {
        return this.api.post(this.getUrl('/distinct'), params);
    }

    findOne(params: ApiModelFindOneParams): Promise<Output | null> {
        return this.api.get(this.getUrl('/one'), params);
    }

    findById(id: ApiModelId, params?: ApiModelFindOneParams): Promise<Output | null> {
        return this.api.get(this.getUrl('/' + id), params);
    }

    count(params: ApiModelCountParams): Promise<number> {
        return this.api.get(this.getUrl('/count'), params).then(res => res.count);
    }

    countPost(params: ApiModelCountPostParams): Promise<number> {
        return this.api.post(this.getUrl('/count'), params).then(res => res.count);
    }

    create(data: Input): Promise<Output> {
        return this.api.post(this.getUrl(''), data);
    }

    /**
     * Finds a document by id, applies the given changes and calls .save() on it
     *
     * Runs schema and middleware
     */
    updateById(id: ApiModelId, data: UpdatePayload<Input>): Promise<Output> {
        return this.api.patch(this.getUrl('/' + id), data);
    }

    /**
     * ❗ Bypasses schema and middleware. You should be using updateById() 99% of the time
     *
     * Performs a low level MongoDB findOneAndUpdate
     *
     * @see updateById()
     */
    findOneAndUpdate({
        update,
        ...params
    }: ApiModelFindOneAndUpdateParams<Input>): Promise<Output | null> {
        return this.api.patch(this.getUrl('/findOneAndUpdate'), update, { params });
    }

    updateMany(params: ApiModelUpdateManyParams<Input>): Promise<{ ok: 0 | 1; n: number }> {
        return this.api.patch(this.getUrl('/many'), params);
    }

    updateOrCreate(data: Input & { isNew?: boolean }) {
        if (data.isNew) return this.create(data);
        return this.updateById((data as any)._id, data);
    }

    aggregate<Result = any>(
        pipeline: Array<Record<string, any>>,
        options?: Record<string, any>,
    ): Promise<Result[]> {
        return this.api.post<Result[]>(this.getUrl('/aggregate'), { pipeline, options });
    }

    deleteById(id: ApiModelId): Promise<Output> {
        return this.api.delete(this.getUrl('/' + id));
    }

    deleteMany(params: ApiModelDeleteParams): Promise<Output> {
        return this.api.delete(this.getUrl('/'), { params });
    }
}

const modelNameMap: Record<Lowercase<ModelName>, ModelName> = Object.fromEntries(
    MODEL_NAMES.map(name => [name.toLowerCase(), name]),
) as any;
