import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import Type from 'src/Type';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';

import type { BaseExpression, ParentContext, Expression } from 'src/types';

export interface AndNodeT extends BaseExpression {
    $type: 'AndNode';
    value: Expression[];
}

export default class AndNode extends ValueNode<AndNodeT> {
    static TYPE = Type.RestArray();

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            Returns \`true\` if all child expressions are truthy

            ### Example

            \`\`\`
            And
                - Equals
                    - $name
                    - "Apple"
                - Equals
                    - $brand
                    - "365 Everyday Value"
            \`\`\`

            Will return \`true\` if the "name" field is "Apple" and the brand is "365"
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [Primitive.evaluation(''), Primitive.evaluation('')],
        };
    }

    async execute({ sourcePath }: ParentContext) {
        for (const [index, expr] of this.expression.value.entries()) {
            if (!(await this.context.evaluateLog(index, expr, { sourcePath }))) return false;
        }

        return true;
    }
}
