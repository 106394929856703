export function flattenObject({
    value,
    filter = () => true,
    traverse = () => true,
}: {
    value: any;
    filter?: (v: any) => boolean;
    traverse?: (v: any) => boolean;
}) {
    const result: Record<string, any> = {};

    const it = (v: any, path: (string | number)[]): void => {
        if (!traverse(v)) return;

        if (filter(v)) result[path.join('.')] = v;

        if (v != null && typeof v === 'object') {
            if (Array.isArray(v)) return v.forEach((child, index) => it(child, [...path, index]));
            for (const key in v) {
                it(v[key], [...path, key]);
            }
        }
    };

    it(value, []);
    return result;
}
