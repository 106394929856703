import { FULL_MATH_REG, SUBSTITUTE_REG, FUNC_REG } from 'src/Context';

/**
 * Converts a node's value to a node if it's a raw value, otherwise just returns the value
 *
 * @param {*} value Node value, could be primitive or a Node Expression
 * @return {Expression} Converted value
 */
export function convertToNode(value: any) {
    if (value === null || value.$type) return value;
    switch (typeof value) {
        case 'number':
            return { $type: 'NumericNode', value };
        case 'boolean':
            return { $type: 'BooleanNode', value };
        case 'object':
            // NOTE: Not completely sure if typeof = object should always be an ObjectNode
            return { $type: 'ObjectNode', value };
        case 'string': {
            const isMath = value.match(FULL_MATH_REG);
            const isVar = value.match(SUBSTITUTE_REG);
            const isFunc = value.match(FUNC_REG);
            const isEval = isMath || isVar || isFunc;
            return {
                $type: isEval ? 'EvaluationNode' : 'StringNode',
                value: isMath ? value.replace(/^\{(.*)+\}$/, '$1') : value,
            };
        }
        default:
            return value;
    }
    return;
}

export default convertToNode;
