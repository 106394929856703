(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@blueprintjs/core"), require("lodash"), require("prop-types"), require("@pi/ui"), require("@pi/transformer-compiler"), require("@blueprintjs/popover2"));
	else if(typeof define === 'function' && define.amd)
		define("transformer", ["react", "@blueprintjs/core", "lodash", "prop-types", "@pi/ui", "@pi/transformer-compiler", "@blueprintjs/popover2"], factory);
	else if(typeof exports === 'object')
		exports["transformer"] = factory(require("react"), require("@blueprintjs/core"), require("lodash"), require("prop-types"), require("@pi/ui"), require("@pi/transformer-compiler"), require("@blueprintjs/popover2"));
	else
		root["transformer"] = factory(root["react"], root["@blueprintjs/core"], root["lodash"], root["prop-types"], root["@pi/ui"], root["@pi/transformer-compiler"], root["@blueprintjs/popover2"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__5918__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__5099__, __WEBPACK_EXTERNAL_MODULE__413__, __WEBPACK_EXTERNAL_MODULE__4437__, __WEBPACK_EXTERNAL_MODULE__1094__) => {
return 