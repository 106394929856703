import _ from 'lodash';

import { isObject } from './performanceFunctions';

export default function addAttributeDefaults(attribute: { type: string; [key: string]: any }) {
    if (typeof attribute?.type !== 'string') {
        throw new Error('Attribute must have string "type" field');
    }

    const result: {
        type: string;
        key?: string;
        uid?: string;
        source?: string;
        parent?: string;
        value?: any;
    } = {
        type: attribute.type,
    };

    const { key, parent, source, value } = attribute;

    if (key != null) result.key = String(key);
    if (parent != null) result.parent = String(parent);
    if (source) result.source = String(source);

    result.uid = result.type + (result.key ? '#' + result.key : '');

    if (value) {
        if (isObject(value)) {
            result.value = Object.fromEntries(Object.entries(value).filter(p => p[1] != null));
        } else if (Array.isArray(value)) {
            result.value = value.filter(x => x != null);
        } else {
            result.value = value;
        }
    } else if (typeof value == 'boolean') {
        result.value = value;
    }

    return result;
}
