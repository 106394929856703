import textBlock from '@pi/text-block';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, ParentContext, ValueExpression } from '../../types';

export interface JoinNodeT extends BaseExpression {
    $type: 'JoinNode';
    value: ValueExpression;
    separator: string;
}

export default class JoinNode extends ValueNode<JoinNodeT> {
    static TYPE = Type.Object(
        { key: 'separator', type: 'StringNode', suggestions: 'off' },
        { key: 'value', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.default,
        group: 'Text',
        description: textBlock`
            Joins all the values into a single string

            **Note:** The "separator" option defaults to a single space

            Use "\\n" (no quotes) for a new line as a separator
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            separator: Primitive.evaluation('" "'),
            value: super.getDefaultFor('ArrayNode'),
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const separator = await this.context.evaluate(this.expression.separator);
        if (separator != null && typeof separator !== 'string')
            throw new Error('separator must be a string');

        if (this.expression.value.$type !== 'ArrayNode') throw new Error('value must be ArrayNode');

        const value = await this.context.evaluate(this.expression.value);
        if (!Array.isArray(value)) throw new Error('value must be an array');

        const result: any[] = [];
        for (const entry of value) {
            const val = await this.context.evaluate(entry, { sourcePath });
            if (Array.isArray(val)) {
                result.push(...val);
                continue;
            }
            if (val == null || typeof val === 'object') continue;
            result.push(val);
        }

        return result.join(separator == null ? '' : String(separator));
    }
}
