import _ from 'lodash';
import config from 'src/utils/config';
import { isObject } from 'src/utils/performanceFunctions';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, UiConfig } from 'src/types';

export interface JSONNodeT extends BaseExpression {
    $type: 'JSONNode';
    value: Record<string | number, any>;
}

export default class JSONNode extends ValueNode<JSONNodeT> {
    static TYPE = Type.Primitive({
        key: 'value',
        suggestions: 'off',
    });

    static uiConfig: UiConfig = {
        ...config.presets.primitive,
        description: 'A valid JSON object or array',
    };

    validate() {
        const { value } = this.expression;
        if (!isObject(value) && !_.isArray(value)) return 'value must be an object or an array';
    }

    static getDefault() {
        return {
            ...super.getDefault(),
            value: [],
        };
    }

    async execute() {
        return this.expression.value;
    }
}
