import React from 'react';
import type { FieldT } from '@pi/react-form';
import {
    JsonEditor,
    JsonEditorProps,
    JSONRenderer,
    JSONRendererProps,
    NumericInput,
    NumericInputProps,
    TextInput,
    TextInputProps,
} from '@pi/ui';

export type MakeFieldProps<Props extends { value?: any;[key: string]: any }> = Omit<
    Props,
    'value' | 'onChange'
> & FieldT<Props['value']>;

export const NumericInputField: React.FC<MakeFieldProps<NumericInputProps>> = ({ input, meta, ...props }) =>
    <NumericInput
        {...props}
        value={input.value}
        onChange={input.onChange}
        intent={meta.valid ? props.intent : 'danger'}
    />;

export const TextInputField: React.FC<MakeFieldProps<TextInputProps>> = ({ input, meta, ...props }) =>
    <TextInput
        {...props}
        value={input.value}
        onChange={input.onChange}
        intent={meta.valid ? props.intent : 'danger'}
    />;

export type JSONRendererFieldProps = MakeFieldProps<JSONRendererProps>;

export const JSONRendererField: React.FC<JSONRendererFieldProps> = ({ input, meta, ...props }) =>
    <JSONRenderer
        {...props}
        data={input.value}
        onChange={input.onChange}
    />;

export type JsonEditorFieldProps = MakeFieldProps<JsonEditorProps>;

export const JsonEditorField: React.FC<JsonEditorFieldProps> = ({ input, meta, ...props }) =>
    <JsonEditor
        {...props}
        value={input.value}
        onChange={input.onChange}
    />;
