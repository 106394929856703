import _ from 'lodash';
import findPaths from 'src/utils/findPaths';

export default function parseSubtypes({
    valueSchema,
}: {
    valueSchema: Record<string, unknown>;
}): any {
    const subtypePaths = findPaths(valueSchema, '__SUBTYPE__');
    const newValueSchema = JSON.parse(JSON.stringify(valueSchema));
    subtypePaths.forEach(path => {
        _.unset(newValueSchema, path);
    });
    return newValueSchema;
}
