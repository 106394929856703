import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import Type from 'src/Type';
import config from 'src/utils/config';
import parserFunctions from 'src/utils/parserFunctions';

import ValueNode from '../ValueNode';

import type { BaseExpression, ParentContext, ValueExpression } from 'src/types';
import type { StringNodeT } from './StringNode';

export interface FunctionCallNodeT extends BaseExpression {
    $type: 'FunctionCallNode';
    name: StringNodeT;
    args: ValueExpression;
}

export default class FunctionCallNode extends ValueNode<FunctionCallNodeT> {
    static TYPE = Type.Object(
        {
            key: 'name',
            label: 'Name',
            type: 'StringNode',
            suggestions: 'internal:FunctionName',
            forceSuggestion: true,
        },
        { key: 'args', label: 'Arguments', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.function,
        description: textBlock`
            Invoke a function you've previously defined with Function Define Node
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            name: Primitive.string('my_function'),
            args: super.getDefaultFor('ArrayNode'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'name')) return 'missing "name"';
        if (!Reflect.has(this.expression, 'args')) return 'missing "args"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const name = await this.context.evaluateLog('name', this.expression.name);
        this.context.setLastStackEntryMeta(name);

        if (name.startsWith('internal:')) {
            const internalName = name.split(':')[1] as keyof typeof parserFunctions;
            const fn: any = parserFunctions[internalName];
            if (!fn) throw new Error(`No internal function with name="${name}"`);
            const args: any[] = await this.context.evaluateLog('args', this.expression.args);
            return fn(...args);
        }

        if (!this.context.functions[name])
            throw new Error(`No function defined with name="${name}"`);

        const functionCalls = this.context.cache.get('functionCalls');
        if (!functionCalls[name]) functionCalls[name] = 0;
        // assume 8 is big enough
        if (functionCalls[name] > 8) throw new Error('Infinite recursive call');

        const { args: argNames, body } = this.context.functions[name];
        const args: any[] = await this.context.evaluateLog('args', this.expression.args);

        if (!Array.isArray(args)) throw '"args" must be an Array';

        if (args.length > argNames.length) {
            throw new Error(`Too many arguments, expected at most ${argNames.length}`);
        }

        ++functionCalls[name];

        const result = await this.context.variableBlock({
            path: sourcePath || '',
            variables: Object.fromEntries(
                argNames.map((name, index) => [name, args[index] ?? null]),
            ),
            callback: () => this.context.evaluateLog('body', body),
        });

        --functionCalls[name];

        return result;
    }
}
