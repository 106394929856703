import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { addPassiveDOMListener } from 'src/utils/addPassiveListener';

export function useWindowScroll(
    { throttle = 20, callback }: { throttle?: number; callback: (event: Event) => void }
) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    const cb = useCallback((e: Event) => callbackRef.current(e), []);

    useEffect(() => {
        const handler = throttle ? _.throttle(cb, throttle) : cb;
        return addPassiveDOMListener(window, 'scroll', handler);
    }, [throttle]);
}
