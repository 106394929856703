const imported_stylus_components = require('.cache/react-style-loader/src/components/ImagePreviewer.styl');
import { AnchorButton, Button, ButtonGroup, Icon as BpIcon } from '@blueprintjs/core';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import type { ClassAndStyle } from '..';

const DEFAULT_ZOOM = 2000;
const MINIMUM_ZOOM = 1000;
const MAXIMUM_ZOOM = 8000;

interface ImagePreviewerProps extends ClassAndStyle {
    onRotationChange?: (newImageRotation: number) => void;
    additionalActionsSlot?: React.ReactNode;
    imageUrls: Array<string>;
    onSelectionChange?: (index: number) => void;
    openButton?: boolean;
    rotateButtons?: boolean;
    allowZoom?: boolean;
    thumbnailsPosition?: 'left' | 'bottom';
}

export function ImagePreviewer({
    imageUrls,
    onSelectionChange,
    additionalActionsSlot,
    onRotationChange,
    openButton = true,
    rotateButtons = true,
    allowZoom = true,
    thumbnailsPosition = 'bottom',
    ...props
}: ImagePreviewerProps): React.ReactElement {
    const [disableZoom, setDisableZoom] = useState(false);
    const [selected, setSelected] = useState(0);
    const [imageStyle, setImageStyle] = useState<React.CSSProperties>();
    const [overlayStyle, setOverlayStyle] = useState<React.CSSProperties>();
    const [locked, setLocked] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [clientX, setClientX] = useState<number>();
    const [clientY, setClientY] = useState<number>();

    const previewRef = React.useRef<HTMLDivElement>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);

    const changeSelected = useCallback((index: number) => {
        setSelected(index);
        setClientX(undefined);
        setClientY(undefined);
        setLocked(false);
    }, []);

    useEffect(() => {
        if (!previewRef.current) return;
        const imageWidth = imageRef.current?.width;
        const imageHeight = imageRef.current?.height;
        const rect = previewRef.current.getBoundingClientRect();
        if (clientX == null || clientY == null || !allowZoom) {
            setImageStyle(() => ({
                position: 'static',
                maxHeight: rect.height || '100%',
                transform: `rotate(${rotation}deg)`,
            }));

            setOverlayStyle(() => ({
                display: 'none',
            }));

            return;
        }

        let width = imageWidth || rect.width;
        let height = imageHeight || rect.height;
        if (width > height) {
            height = (height / width) * zoom;
            width = zoom;
        } else {
            width = (width / height) * zoom;
            height = zoom;
        }
        const xDelta = rect.width - width;
        const yDelta = rect.height - height;
        const top =
            clientY == null ? yDelta / 2 : ((-1 * (rect.top - clientY)) / rect.height) * yDelta;
        const left =
            clientX == null ? xDelta / 2 : ((-1 * (rect.left - clientX)) / rect.width) * xDelta;

        setImageStyle(currImageStyle => ({
            ...currImageStyle,
            top,
            left,
            width,
            height,
            position: undefined,
            maxHeight: undefined,
            transform: `rotate(${rotation}deg)`,
        }));

        setOverlayStyle(() => ({
            display: locked ? 'block' : 'none',
        }));
    }, [allowZoom, clientX, clientY, rotation, zoom, locked]);

    const handleWheel = useCallback(
        (event: React.WheelEvent<HTMLDivElement>) => {
            event.stopPropagation();
            setZoom(_.clamp(zoom + event.deltaY * 10, MINIMUM_ZOOM, MAXIMUM_ZOOM));
        },
        [zoom],
    );

    const handleMouseMove = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (disableZoom || locked) return;

            setClientX(event.clientX);
            setClientY(event.clientY);
        },
        [disableZoom, locked],
    );

    const handleClick = useCallback(() => {
        if (disableZoom) return;

        setLocked(prevLockValue => !prevLockValue);
    }, [disableZoom]);

    const handleMouseLeave = useCallback(() => {
        if (locked) return;

        setClientX(undefined);
        setClientY(undefined);
    }, [locked]);

    const handleRotate = useCallback(
        (degrees: number) => {
            setRotation(currRotation => {
                const newRotation = (currRotation + degrees) % 360;
                setImageStyle(currImageStyle => ({
                    ...currImageStyle,
                    transform: `rotate(${newRotation}deg)`,
                }));
                onRotationChange?.(newRotation);
                return newRotation;
            });
        },
        [onRotationChange],
    );

    const handleDisable = useCallback(() => {
        setDisableZoom(true);
    }, []);

    const handleEnable = useCallback(() => {
        setDisableZoom(false);
    }, []);

    if (!imageUrls.length || !imageUrls[selected]) return <></>;

    const src = imageUrls[selected];

    return (
        <Root {...props} data-thumbnails-position={thumbnailsPosition}>
            <Content>
                <ThumbnailList>
                    {imageUrls.map((source, index) => (
                        <Icon
                            key={index}
                            style={{ backgroundImage: `url("${source}")` }}
                            onClick={() => {
                                changeSelected(index);
                                onSelectionChange?.(index);
                            }}
                            data-selected={index === selected ? '' : undefined}
                        />
                    ))}
                </ThumbnailList>
                <Preview
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onWheel={allowZoom ? handleWheel : undefined}
                    onClick={handleClick}
                    ref={previewRef}
                    data-locked={allowZoom && locked ? '' : undefined}
                >
                    <PreviewImage src={src} style={imageStyle} ref={imageRef} />
                    <OverlayText style={overlayStyle}>Pan & Zoom Locked</OverlayText>
                </Preview>
                <Actions onMouseEnter={handleDisable} onMouseLeave={handleEnable}>
                    {rotateButtons && (
                        <Button minimal onClick={() => handleRotate(90)}>
                            <BpIcon icon='repeat' />
                        </Button>
                    )}
                    {rotateButtons && (
                        <Button minimal onClick={() => handleRotate(-90)}>
                            <BpIcon icon='reset' />
                        </Button>
                    )}
                    {allowZoom && (
                        <Button disabled minimal onClick={() => setZoom(DEFAULT_ZOOM)}>
                            {(1 + (zoom - MINIMUM_ZOOM) / 1000).toFixed(1)}x
                        </Button>
                    )}
                    {openButton ? (
                        <AnchorButton href={src} target='_blank'>
                            open
                        </AnchorButton>
                    ) : (
                        <AnchorButton minimal href={src} target='_blank' icon='share'>
                            Open in new tab
                        </AnchorButton>
                    )}
                    {additionalActionsSlot}
                </Actions>
            </Content>
        </Root>
    );
}

const Content = imported_stylus_components.Content;
const ThumbnailList = imported_stylus_components.ThumbnailList;
const Preview = imported_stylus_components.Preview;
const OverlayText = imported_stylus_components.OverlayText;
const Actions = imported_stylus_components.Actions.withComponent(ButtonGroup);
const PreviewImage = imported_stylus_components.PreviewImage.withComponent('img');
const Root = imported_stylus_components.Root;
const Icon = imported_stylus_components.Icon;