import textBlock from '@pi/text-block';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, ValueExpression } from '../../types';

export interface ConcatNodeT extends BaseExpression {
    $type: 'ConcatNode';
    value: ValueExpression;
}

export default class ConcatNode extends ValueNode<ConcatNodeT> {
    static TYPE = Type.Object({ key: 'value', type: 'ValueNode', suggestions: 'off' });

    static uiConfig = {
        ...config.presets.list,
        description: textBlock`
            Joins multiple arrays together
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: super.getDefaultFor('ArrayNode'),
        };
    }

    async execute() {
        const value = await this.context.evaluate(this.expression.value);
        if (!Array.isArray(value)) throw new Error('value must be an array');

        return value.flat();
    }
}
