import _ from 'lodash';

const FRACTION_SLASH_CODE = 8260;

export type Measurement = {
    value: number;
    display: string;
    unit?: string;
    literal?: string;
    fraction?: string;
    range?: number[];
    qualifier?: string;
    count?: number;
};

export function sanitizeMeasurement(str: string | number) {
    if ((typeof str != 'number' && !str) || str === 'n/a') return null;
    str = `${str}`.toLowerCase();

    for (const [find, replace] of SANITIZE_PIPELINE) {
        str = str.replace(new RegExp(find, 'g'), replace).trim();

        if (!str) return null;
    }

    str = str.trim();

    return str;
}

const parseNumber = (numString: string) => {
    numString = numString.trim();

    // handle ".24"
    if (numString[0] === '.') numString = `0${numString}`;

    const number: number = numString.includes('/')
        ? eval(`const __x__ = ${numString};
        __x__;`) // parse fractions like "2/3"
        : parseFloat(numString);

    if (Number.isNaN(number)) return null;

    return _.round(number, 3);
};

// Plucked from api/updateServingInfo.js
// const UNIT_PREFFERENCE = [
//     /^g|gr|gram|grams\b/i, // grams
//     /^(mg|milligram)s?\b/, // mg
//     /^(kg|kilo(gram)?s?)\b/i, // kg
//     /^(lbs?|pounds?)\b/i, // lb
//     /^(ounces?|(oz)(\.|\s+|\b))/i, // oz

//     /^(l|liters?)\b/, // liter
//     /^(milliliter|ml)\b/i, // ml
//     /^(fl(\b|\s+|\.)|fluid ounces?)/i, // floz
//     /^gal(lons?)?/i, // gal
//     /^(inch(es)? cube|cubic inch(es)?)/i, // inch3
// ];
export function parseMeasurements(rawValue: string | number) {
    const str = sanitizeMeasurement(rawValue);
    if (!str) return [];

    const matches = str.match(REG_SERVING_GLOBAL);
    if (!matches || !matches.length) return [];

    const list: Measurement[] = [];

    for (const serving of matches) {
        const match = serving.match(REG_SERVING);
        if (!match) continue;
        let [, qualifier, rawValue] = match;
        // the serving reg only matches a single word. to pick everything up we remove what is not a unit
        const unit = serving.slice(serving.indexOf(rawValue) + rawValue.length).trim();
        let value = rawValue?.replace(/(\d),(\d)/gi, '$1$2').trim();
        qualifier = qualifier?.trim();
        let obj: Measurement;
        if (REG_RANGE.test(value)) {
            const arr = value.split(/-|,|\s+(?:to|x)\s+/).map(parseNumber) as number[];
            obj = {
                display: '',
                value: arr[1],
                unit: `${arr[2] || ''} ${unit}`.trim(),
            };
            if (arr.length === 2 && arr[0] > arr[1]) {
                obj.count = arr[0];
                obj.display = `${value} ${obj.unit}`;
            } else {
                obj.range = arr;
                obj.display = `${obj.range!.join('-')} ${obj.unit}`;
            }
        } else if (REG_NUMBER_LITERAL.test(value)) {
            obj = {
                display: '',
                value: NUMBER_LITERALS.indexOf(value) + 1,
                literal: value,
            };
        } else {
            const isVulgarFraction = REG_VULGAR_FRACTION.test(value);
            obj = {
                display: '',
                value: 0,
            };

            if (isVulgarFraction) {
                obj.fraction = value;
                let sanitizedVulgarValue = value;

                if (value.length > 1 && !value.includes(' ')) {
                    const vulgarIndex = value.length - 1;
                    sanitizedVulgarValue =
                        value.substring(0, vulgarIndex) + ' ' + value[vulgarIndex];
                }

                // replaces unicode character with the normal equivalent form -
                // see "compatibility decomposition" for more details
                value = sanitizedVulgarValue
                    .normalize('NFKD')
                    .replace(String.fromCharCode(FRACTION_SLASH_CODE), '/');
            }

            if (REG_COMPLEX_FRACTION.test(value)) {
                value.split(' ').forEach(part => (obj.value += parseNumber(part) || 0));
            } else {
                obj.value = parseNumber(value)!;
            }
            if (value.includes('/') && !isVulgarFraction) obj.fraction = value;
        }

        if (!obj!.unit) obj!.unit = (unit || '').replace(REG_CLEAN_UNIT, '').trim();

        if (obj.unit && REG_METRIC.test(obj.unit)) {
            obj.unit = METRIC_MAPPING[obj.unit.split(' ')[0].toLowerCase()] || obj.unit;
        }

        if (!obj.unit) delete obj.unit;

        if (obj.display === '') {
            const updatedValue = obj.fraction
                ? obj.fraction
                : obj.literal
                ? String(NUMBER_LITERALS.indexOf(obj.literal) + 1)
                : String(rawValue);
            if (obj.unit) obj.display = `${updatedValue} ${obj.unit}`.trim();
            else obj.display = updatedValue;
        }
        obj.display = obj.display.trim();

        if (qualifier) {
            obj.qualifier = qualifier;
            obj.display = `${obj.qualifier} ${obj.display}`;
        }

        list.push(obj);
    }

    return list;
}

export function parseServing(rawString: string, prop?: string, unitSuffix?: string) {
    const sanitizedString = sanitizeMeasurement(rawString);
    if (!sanitizedString) return null;

    const measurements = parseMeasurements(sanitizedString);

    return convertMeasurementsToServing(sanitizedString, measurements, prop, unitSuffix);
}

export function convertMeasurementsToServing(
    sanitizedString: string,
    list: Measurement[],
    prop = 'servingSize',
    unitSuffix = 'Uom',
) {
    const KEY = prop;
    const UNIT = unitSuffix;
    const SECONDARY_KEY = `secondary${prop[0].toUpperCase()}${prop.slice(1)}`;
    const result: any = { [KEY]: null, [`${KEY}${UNIT}`]: null };

    const setServing = (name: string, obj: Measurement) => {
        const displayKey = `${name}Display`;
        result[name] = obj.value;
        result[`${name}${UNIT}`] = obj.unit;

        if (obj.fraction) result[displayKey] = obj.fraction;
        else if (obj.literal) result[displayKey] = obj.literal;
        else if (obj.range) result[displayKey] = obj.range.join('-');
        else result[displayKey] = String(result[name]);

        if (result[displayKey] && obj.qualifier) {
            result[displayKey] = `${obj.qualifier} ${result[displayKey]}${obj.unit || ''}`;
        }
    };

    let secondaryMeasurement: Measurement;

    for (const obj of list) {
        if (obj.unit && METRIC_MAPPING[obj.unit]) {
            secondaryMeasurement = obj;
            setServing(SECONDARY_KEY, obj);
            list = list.filter(x => x !== obj);
            break;
        }
    }
    // for (let reg of UNIT_PREFFERENCE) {
    //     const obj = _.find(list, x => x.unit && reg.test(x.unit));
    //     if (obj) {
    //         setServing(SECONDARY_KEY, obj);
    //         list = list.filter(x => x !== obj);
    //         break;
    //     }
    // }

    if (list.length) {
        const obj = _.find(list, x => x.unit) || list[0];
        setServing(KEY, obj as Measurement);
    } else if (result[SECONDARY_KEY] != null) {
        setServing(KEY, secondaryMeasurement!);
    } else setServing(KEY, { value: 1, unit: sanitizedString, display: '' });

    return result;
}

const SANITIZE_PIPELINE = [
    // handle international characters
    [/⁄+/g, '/'],

    // remove random shit
    [
        /(%\s*)?daily value|makes?|amt if mix|amt mix in|amt of mix( in)?|servings? sizes?|(ne[tw])?\s*wt\.?:?|^servings?:?/gi,
        '',
    ],

    // remove starting random words
    [/^\s*per\b\s*/i, ''],

    // unit: fl oz
    [/(fluid\s*(?:ounces?|oz))|(fl\.?\s*(?:oz|zo|pz)\.?)/gi, 'fl oz'],

    // unit: oz
    [/oz\.?|ounces?|0\s*z\.?/gi, 'oz'],

    [/\b0\s*lb/gi, ''],

    // remove abbreviation dots: 2 fl. oz => 2 fl oz
    [/([a-z])\./g, '$1'],

    // remove ending special chars
    [/[.;:*]+$/, ''],

    // remove start and end quotes ... some people actually enter them :/
    [/(^\s*"+)|("+\s*$)/g, ''],

    // replace | | with ( ): foo | bar | baz => foo (bar) baz
    [/\|\s*([^|]+)\s*\|/g, '($1)'],

    // replace all brackets with ()
    [/[[{]/g, '('],
    [/[\]}]/g, ')'],

    // spaces after dashes: 5 - 6 => 5-6
    [/(.)\s*-\s*(.)/gi, '$1-$2'],

    // spaces after numbers before special chars: 5 . => 5. | 6 /7 => 6/7
    [/(\d)\s+([/."])/g, '$1$2'],
    [/(\d)\s*([/.])\s*(\d)/g, '$1$2$3'],

    // spaces after numbers and units
    [/(\d)([a-z])/gi, '$1 $2'],

    // spaces in dash separation: 28g/ 5pc => 28g / 5pc
    [/([^0-9])\s*\/\s*/g, '$1 / '],

    // unwrap () at the beginning of strings
    [/^\(([^)]+)\)/, '$1'],

    // fix conversion issue where 28g were always wrapper in ()
    [/\((\d+[^)]+)\)([^(]+\))/g, '($1$2'],

    // space between letters and numbers
    [/([a-z])(\d)/gi, '$1 $2'],

    // no spaces between numbers and grams: 5 grams => 5g
    [/(\d+)\s*g(rams?)?$/gi, '$1g'],

    // no spaces after opening brackets
    [/\(+\s*/g, ' ('],

    // no space before closing brackets
    [/\s*\)+/g, ') '],

    // spaces after dots bracket combo 2 . (5g) => 2.5g)
    [/(\d)\s*\.\s*\((\d)/g, '$1.$2'],

    // remove orphan open brackets
    [/^([^(]*)\)/g, '$1'],

    // remove orphan close brackets
    [/\(([^)]*)$/g, '$1'],

    // spaces after commas
    [/\b,+\B/g, ', '],

    // fix extra spaces - should be last: "12  14" => "12 14"
    [/\s\s+/g, ' '],

    // remove or
    [/\s+\bor\b/i, ''],
] as const;

const NUMBER_LITERALS = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
];

const METRIC_MAPPING: Record<string, string> = {
    g: 'g',
    gr: 'g',
    grs: 'g',
    gram: 'g',
    grams: 'g',
    mg: 'mg',
    mgs: 'mg',
    milligram: 'mg',
    milligrams: 'mg',
    kg: 'kg',
    kgs: 'kg',
    kilo: 'kg',
    kilos: 'kg',
    kilogram: 'kg',
    kilograms: 'kg',
    l: 'l',
    liter: 'l',
    liters: 'l',
    ml: 'ml',
    mls: 'ml',
    milliliter: 'ml',
    milliliters: 'ml',
};

const P_NUMBER = '(?:\\.\\d+|\\d+(?:\\.\\d+|\\/\\d+|\\,\\d+)?)';
const P_NUMBER_LITERAL = `(?:\\b(?:${NUMBER_LITERALS.join('|')}))`;
const P_RANGE_DELIMITER = '(?:-|,|\\s+(?:x|to)\\s+)';
const P_RANGE = `${P_NUMBER}\\s*${P_RANGE_DELIMITER}\\s*${P_NUMBER}`;
const P_VULGAR_RANGE = '(\\d*\\s?[¼-¾⅐-⅞])';
const P_WORD = `(?:(?:"|[a-z]|(?:${P_NUMBER})["'])+\\s*[a-z]*\\s*((\\/\\s*(\\s*[1-9][0-9]*\\/[1-9][0-9]*\\s*[a-z]+|[a-z"'.-]+))|[a-z"'.-]*)\\s*(\\(\\D+.*\\))*)`;
const P_QUALIFIER = '[<>]=?|about';
const P_FRACTION = '\\d+ \\d+\\/\\d+';
const P_SERVING = `(${P_QUALIFIER})?\\s*(${P_VULGAR_RANGE}|${P_FRACTION}|${P_RANGE}|${P_NUMBER_LITERAL}|${P_NUMBER})\\s*(${P_WORD}+\\s*)*`;

const REG_METRIC = new RegExp(`^(${Object.keys(METRIC_MAPPING).join('|')})\\b`, 'i');
const REG_RANGE = new RegExp(P_RANGE, 'i');
const REG_NUMBER_LITERAL = new RegExp(P_NUMBER_LITERAL, 'i');
const REG_SERVING = new RegExp(P_SERVING, 'i');
const REG_SERVING_GLOBAL = new RegExp(P_SERVING, 'gi');
const REG_CLEAN_UNIT = /\s*(?:about)\s*|[-]$|approx\s*$|w$|with$/gi;
const REG_COMPLEX_FRACTION = /\d+ \d+\/\d+/;
const REG_VULGAR_FRACTION = new RegExp(P_VULGAR_RANGE);

export default parseMeasurements;
