import textBlock from '@pi/text-block';
import Type from 'src/Type';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';

import type { Expression } from 'src/types';

export interface TaskIdNodeT {
    $type: 'TaskIdNode';
    value: Expression;
}

export default class TaskIdNode extends ValueNode<TaskIdNodeT> {
    static TYPE = Type.Object({
        label: 'Task',
        key: 'value',
        type: 'StringNode',
        suggestions: 'external:Tasks',
        forceSuggestion: true,
    });

    static uiConfig = {
        ...config.presets.tasker,
        description: textBlock`
            Returns the TaskDefinition._id for a given TaskDefinition name
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            value: Primitive.string(''),
        };
    }

    async execute() {
        return await this.context.evaluateLog('value', this.expression.value);
    }
}
