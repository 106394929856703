import textBlock from '@pi/text-block';
import Type from 'src/Type';
import config from 'src/utils/config';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';

import type { BaseExpression, Expression } from 'src/types';

export interface ValueBlockNodeT extends BaseExpression {
    $type: 'ValueBlockNode';
    value: Expression[];
}

export default class ValueBlockNode extends ValueNode<ValueBlockNodeT> {
    static TYPE = Type.RestArray();

    static uiConfig = {
        ...config.presets.function,
        group: config.presets.block.group,
        description: textBlock`
            Works similarly to All Node, but it returns the last node in the block

            Useful in places like Function Define Node where you want to set some variables and then run the logic that's needed to return the result
        `,
    };

    static getDefault(): ValueBlockNodeT {
        return {
            ...(super.getDefault() as ValueBlockNodeT),
            value: [this.getDefaultFor('VariableNode'), Primitive.evaluation('"return result"')],
        };
    }

    async execute() {
        let lastResult;
        for (const [index, expr] of this.expression.value.entries()) {
            lastResult = await this.context.evaluateLog(index, expr);
        }
        return lastResult;
    }
}
