import textBlock from '@pi/text-block';
import Type from 'src/Type';
import config from 'src/utils/config';
import { Primitive } from 'src';

import ValueNode from '../ValueNode';

import type { StringNodeT } from './StringNode';
import type { BaseExpression } from 'src/types';

export interface DocumentNodeT extends BaseExpression {
    $type: 'DocumentNode';
    model: StringNodeT;
    id: StringNodeT;
}

export default class DocumentNode extends ValueNode<DocumentNodeT> {
    static TYPE = Type.Object(
        {
            key: 'model',
            label: 'Model',
            type: 'StringNode',
            suggestions: 'external:DatabaseModels',
            forceSuggestion: true,
        },
        { key: 'id', label: '_id', type: 'StringNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.default,
        style: {
            ...config.presets.default.style,
            background: '#FFC940',
        },
        description: textBlock`
            Fetches the given model from the database
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            model: Primitive.string('Company'),
            id: Primitive.evaluation(''),
        };
    }

    async execute() {
        const model = await this.context.evaluate(this.expression.model);
        const id = await this.context.evaluate(this.expression.id);

        if (typeof id !== 'string' || typeof model !== 'string') return null;

        if (!/^[a-f0-9]{24}$/.test(id)) return null;

        return this.context.transformation.options.fetchDocument(model, id);
    }
}
