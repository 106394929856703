import _ from 'lodash';

import { parseMeasurements, parseServing } from './parseMeasurements';

const TEMPLATE_REG = /%s/g;

const hashFraction = (frac: number) => _.round(frac, 2).toString().slice(-4);
const FRACTIONS = Object.fromEntries(
    [
        [1 / 10, '⅒', '1/10'],
        [1 / 2, '½', '1/2'],
        [1 / 3, '⅓', '1/3'],
        [1 / 4, '¼', '1/4'],
        [1 / 5, '⅕', '1/5'],
        [1 / 6, '⅙', '1/6'],
        [1 / 7, '⅐', '1/7'],
        [1 / 8, '⅛', '1/8'],
        [1 / 9, '⅑', '1/9'],
        [2 / 3, '⅔', '2/3'],
        [2 / 5, '⅖', '2/5'],
        [3 / 4, '¾', '3/4'],
        [3 / 5, '⅗', '3/5'],
        [3 / 8, '⅜', '3/8'],
        [4 / 5, '⅘', '4/5'],
        [5 / 6, '⅚', '5/6'],
        [5 / 8, '⅝', '5/8'],
    ].map(([key, unicode, ascii]: any) => [hashFraction(key), { unicode, ascii }]),
);

const ifNaN = (number: any, defaultValue: any) =>
    typeof number !== 'number' || Number.isNaN(number) ? defaultValue : number;

const emptyFilter = (value: any) =>
    value != null && value !== '' && (typeof value !== 'object' || !_.isEmpty(value));

const ISO_DATE_REG = /^(\d\d\d\d)-(\d\d)-(\d\d)(?:T(\d\d):(\d\d):(\d\d))?/;

const toDate = (val: string | number | Date): Date | null => {
    if (val instanceof Date) return val;

    if (typeof val === 'number') return new Date(val);

    const strVal = String(val);
    if (ISO_DATE_REG.test(strVal)) return new Date(strVal);

    return null;
};

const dateTime = (val: string | number | Date): number | null => toDate(val)?.getTime() ?? null;

const TIME_UNITS = {
    year: 365 * 24 * 3600e3,
    month: 30.5 * 24 * 3600e3,
    day: 24 * 3600e3,
    hour: 3600e3,
    minute: 60e3,
    second: 1e3,
};

export default {
    toString: (val: any) => String(val),
    toStr: (val: any) => String(val),
    toJSON: (val: any, indent?: number) => JSON.stringify(val, null, indent),
    parseInt: (val: any) => ifNaN(parseInt(val, 10), val),
    parseFloat: (val: any) => ifNaN(parseFloat(val), val),
    regexp: (val: string, flags = 'i') => {
        if (typeof val !== 'string') return null;
        return new RegExp(val, flags);
    },

    toDate,
    dateTime,
    dateFormat: (val: string | number | Date) => toDate(val)?.toISOString() ?? null,
    dateModify: (val: string | number | Date, modifier: number, unit: keyof typeof TIME_UNITS) => {
        if (!TIME_UNITS[unit]) {
            throw new Error(
                `dateModify(): Invalid unit ${unit}. Allowed: ${Object.keys(TIME_UNITS).join(
                    ', ',
                )}`,
            );
        }

        const date = toDate(val);
        if (!date) return null;

        return dateTime(new Date(date.getTime() + modifier * TIME_UNITS[unit]));
    },

    parseMeasurements: (val: string) =>
        !['string', 'number'].includes(typeof val) ? null : parseMeasurements(val),

    parseServing: (val: string, prop?: string, unit?: string) =>
        typeof val !== 'string' ? null : parseServing(val, prop, unit),

    fraction: (val: number, ascii = false) => {
        if (typeof val !== 'number') return val;
        const hash = hashFraction(val);
        return FRACTIONS[hash]?.[ascii ? 'ascii' : 'unicode'] ?? hash;
    },

    sort: (value: any, path?: string) => _.sortBy(value, path!),

    // replace default filter filter to only remove empty values (null | '')
    filter: (obj: any, filter: (v: any, k: number) => any = emptyFilter) => _.filter(obj, filter),

    // replace default pickBy filter to only remove empty values (null | '')
    pickBy: (obj: any, filter: (v: any, k: string) => any = emptyFilter) => _.pickBy(obj, filter),

    push: (arr: any[], ...values: any[]) => {
        if (!Array.isArray(arr)) throw new Error('must be an array');
        arr.push(...values);
    },

    max: (...args: any[]) => (Array.isArray(args[0]) ? Math.max(...args[0]) : Math.max(...args)),
    min: (...args: any[]) => (Array.isArray(args[0]) ? Math.min(...args[0]) : Math.min(...args)),

    // Object Methods
    ..._.pick(_, [
        'assign',
        'countBy',
        'defaults',
        'defaultsDeep',
        'entries',
        'get',
        'groupBy',
        'invert',
        'invertBy',
        'keyBy',
        'keys',
        'mapKeys',
        'mapValues',
        'merge',
        'omit',
        'omitBy',
        'orderBy',
        'partition',
        'pick',
        'set',
        'size',
        'some',
        'values',
    ]),

    // Array Methods
    ..._.pick(_, [
        'castArray',
        'compact',
        'concat',
        'difference',
        'flatten',
        'flattenDeep',
        'flattenDepth',
        'intersection',
        'join',
        'last',
        'map',
        'range',
        'slice',
        'union',
        'unionBy',
        'uniq',
        'uniqBy',
        'without',
    ]),

    // String Methods
    ..._.pick(_, [
        'camelCase',
        'capitalize',
        'deburr',
        'endsWith',
        // 'escape',
        // 'escapeRegExp',
        'kebabCase',
        'lowerCase',
        'lowerFirst',
        'pad',
        'padEnd',
        'padStart',
        // 'parseInt',
        'repeat',
        'replace',
        'snakeCase',
        'split',
        'startCase',
        'startsWith',
        // 'template',
        'toLower',
        'toUpper',
        'trim',
        'trimEnd',
        'trimStart',
        'truncate',
        // 'unescape',
        'upperCase',
        'upperFirst',
        'words',
    ]),

    // Number Methods
    ..._.pick(_, ['clamp', 'inRange']),

    // Math Methods
    ..._.pick(_, [
        'ceil',
        'floor',
        // 'max',
        'maxBy',
        'mean',
        'meanBy',
        // 'min',
        'minBy',
        'round',
        'sum',
        'sumBy',
    ]),

    // Custom Methods

    format: (template: string, ...args: any[]) => {
        if (args.some(a => a == null)) return '';
        let index = -1;
        const result = template.replace(TEMPLATE_REG, () => args[++index]);
        if (index >= args.length) return '';
        return result;
    },

    default: (value: any, defaultValue: any) => (value == null ? defaultValue : value),
};
