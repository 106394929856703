/* eslint-disable no-console */

import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { action, makeAutoObservable, toJS } from 'mobx';
import { flattenObject } from 'src/utils/flattenObject';

import { Field } from './Field';

import type { FieldMeta, FormChange, FormChangeHandler, FormOptions } from './types';

/**
 * Main store that handles all the context for a given form
 */
export class FormStore<
    Value extends Record<string, any>,
    ValidValue extends Record<string, any> = Value,
> {
    readonly value: Readonly<Partial<Value>>;
    readonly options: FormOptions<Value, ValidValue>;
    /** Errors returned from the form validation function */
    errors: Record<string, string> = {};
    /** Errors registered manually by other processes which must be cleared manually */
    asyncErrors: Record<string, string | { message: string; [K: string]: any }> = {};
    meta!: FieldMeta & { numErrors: number };
    cachedFields: Record<string, (path: string) => Field<any>> = {};
    changeHandlers: FormChangeHandler[] = [];
    validationContext: Record<string, any> = {};
    isContainer = false;

    constructor(options: FormOptions<Value, ValidValue>) {
        this.options = {
            initialValue: {},
            validate: () => null,
            debug: false,
            ...options,
        };
        this.value = this.options.initialValue || ({} as Partial<Value>);
        this.validate();

        if (this.options.debug) {
            const debugMethods = ['runChangeHandlers', 'handleSubmit'] as const;
            const debugActions = [
                'set',
                'reset',
                'changeField',
                'changeFields',
                'updateField',
                'directUpdate',
                'validate',
            ] as const;

            for (const name of debugMethods) {
                const old: any = this[name];
                this[name] = (...args: any[]) => {
                    console.log(`%cFormStore.${name}`, 'color: orange', args);
                    return old.apply(this, args as any)!;
                };
            }

            for (const name of debugActions) {
                const old: any = this[name];
                this[name] = (...args: any[]) => {
                    console.group(`FormStore.${name} @action`);
                    console.log('args =', args);
                    const result = old.apply(this, args as any)!;
                    console.groupEnd();
                    return result;
                };
            }
        }

        makeAutoObservable(this, {
            cachedFields: false,
            changeHandlers: false,
            validationContext: false,
            asyncErrors: false,
        });
    }

    get<Type = any>(path: string | string[]): Type {
        if (path === '' || !path.length) return this.value as unknown as Type;
        return _.get(this.value, path);
    }

    runChangeHandlers(changes: FormChange[]) {
        this.changeHandlers.forEach(fn => fn({ form: this, changes }));
    }

    /**
     * This should only be used if you need to update the top level props - like "isContainer"
     *
     * Most of the times you only need: "set" "changeField" and "directUpdate" methods
     */
    @action updateSelf(updater: () => void) {
        updater();
    }

    @action private set(path: string, value: any) {
        _.set(this, path ? 'value.' + path : 'value', value);
    }

    @action reset(value: Partial<Value> | undefined = this.options.initialValue) {
        Object.assign(this, { value });
    }

    @action changeField<V = any>(name: string, value: V) {
        if (this.get(name) === value) return;
        this.set(name, value);
        this.runChangeHandlers([{ path: name, value }]);
        this.validate();
    }

    /**
     * Useful when doing bulk operations but should be avoided in favor of the singular changeField() otherwise
     * @see changeField()
     */
    @action changeFields(diff: Record<string, any>, { events = true } = {}) {
        const changes: FormChange[] = [];
        for (const [path, value] of Object.entries(diff)) {
            this.set(path, value);
            changes.push({ path, value });
        }
        if (events) this.runChangeHandlers(changes);
        this.validate();
    }

    @action updateField<V = any>(name: string, updater: (value: V) => void) {
        const field = this.field(name);
        updater(field.value as any);
        this.runChangeHandlers([{ path: name, value: field.value }]);
        this.validate();
    }

    /**
     * Action handler to update the data directly. Does not trigger change handlers
     *
     * You should really avoid using this, it's only meant as a performance tool for mass updating values
     *
     * Use the hooks like `useField()` or `FormStore.changeField()` under normal circumstances
     *
     * @note You mist call FormStore.validate() yourself after updating if desired
     *
     * @see useField()
     * @see changeField()
     *
     */
    @action directUpdate(updater: (formValue: Value) => void) {
        updater(this.value as any);
    }

    @action validate() {
        const newErrors = { ..._.mapValues(this.asyncErrors, x => (x as any).message || x) };
        Object.assign(
            newErrors,
            flattenObject({
                value: this.options.validate?.(toJS(this.value), this.validationContext) || {},
                filter: v => typeof v !== 'object',
            }),
        );
        for (const key in this.errors) {
            if (!newErrors[key]) delete this.errors[key];
        }
        Object.assign(this.errors, newErrors);
        const numErrors = _.size(this.errors);
        this.meta = {
            valid: !numErrors,
            error: numErrors ? `Has ${numErrors} error${numErrors > 1 ? 's' : ''}` : undefined,
            numErrors,
        };
    }

    field<Path extends string, T = Path extends keyof Value ? Value[Path] : unknown>(
        path: Path,
    ): Field<T> {
        if (!this.cachedFields[path]) {
            this.cachedFields[path] = memoizeOne(p => new Field<T>(p, this));
        }

        return this.cachedFields[path](path);
    }

    getFieldMeta(path: string) {
        const error = this.errors[path];

        return {
            valid: !error,
            error,
        };
    }

    handleSubmit = () => {
        this.options.onSubmit?.(toJS(this.value) as ValidValue);

        return true;
    };
}
