const imported_stylus_components = require('.cache/react-style-loader/src/components/Tree/Tree.styl');
import React from 'react';
import _ from 'lodash';
import {
    Field,
    type FieldInput,
    type FieldT,
    type FormContextT,
    FormEvent,
    joinPaths,
    type FormEventProps,
} from '@pi/react-form';
import memoizeOne from 'memoize-one';

import {
    getExpressionCompiler,
    type NodeState,
    type CompiledExpression,
} from './getExpressionCompiler';
import moveNode, { addItemToParent } from './moveNode';
import TypeRenderers, {
    type AnyTypeRendererInstance,
    type NodeSelectOptionCreator,
    type TypeRendererConfig,
} from '../TypeRenderers';
import createGetNodeSelectOptions from '../TypeRenderers/createGetNodeSelectOptions';

import type { Expression } from '@pi/transformer-compiler';

type RegisterRendererFn = (path: string, renderer: AnyTypeRendererInstance) => void;

export interface TreeContextType {
    onPaste: TypeRendererConfig['onPaste'];
    getSuggestions: TypeRendererConfig['getSuggestions'];
    registerRenderer: RegisterRendererFn;
    getRenderer: (path: string) => AnyTypeRendererInstance;
    /** returns the *immutable* form value */
    getFormValue: () => any;
    /** when all else fails and you just want to say fuck it */
    getForm: () => FormContextT;
    getNodeSelectOptions: NodeSelectOptionCreator;
    argumentChangeHandlers?: {
        [K in Expression['$type']]?: Record<
            string,
            (options: {
                newValue: any;
                input: FieldInput<any>;
                context: TreeContextType;
                node: NodeState;
            }) => false | void
        >;
    };
}

const TreeContext = React.createContext<TreeContextType>(null as any);

export interface TreeProps {
    /** Only show the selected subset of nodes */
    includeNodes?: Expression['$type'][];
    getSuggestions: TypeRendererConfig['getSuggestions'];
    argumentChangeHandlers: TreeContextType['argumentChangeHandlers'];
}

export class Tree extends React.PureComponent<TreeProps> {
    static defaultProps = {
        getSuggestions: () => [],
    };

    private form!: FormContextT;

    renderers: { [key: string]: AnyTypeRendererInstance } = {};

    getContext = memoizeOne(
        (
            getSuggestions,
            includeNodes: TreeProps['includeNodes'],
            argumentChangeHandlers?: TreeContextType['argumentChangeHandlers'],
        ): TreeContextType => ({
            getSuggestions,
            registerRenderer: this.registerRenderer,
            getRenderer: this.getRenderer,
            onPaste: this.onPaste,
            getFormValue: () => this.form.getValue(),
            getForm: () => this.form,
            getNodeSelectOptions: createGetNodeSelectOptions(includeNodes),
            argumentChangeHandlers: argumentChangeHandlers || {},
        }),
    );

    compileExpression = getExpressionCompiler();

    onPaste: TypeRendererConfig['onPaste'] = (toPath, position) => {
        const { moveCopy } = this.form.getValue().compiledExpression;
        const fromPath = moveCopy.path;
        this.form.changeField(joinPaths(fromPath, '$ui.' + moveCopy.type), false);
        this.form.changeField('compiledExpression.moveCopy', null);

        if (moveCopy.type === 'copy') {
            const data = this.form.getValue();
            const { parent, parentPath } = addItemToParent({
                data,
                path: toPath,
                position,
                child: _.cloneDeep(_.get(data, moveCopy.path)),
            });
            this.form.changeField(parentPath, parent);
        } else if (moveCopy.type === 'move') {
            const { fromParent, fromParentPath, toParent, toParentPath } = moveNode(
                this.form.getValue(),
                fromPath,
                toPath,
                position,
            );
            this.form.changeField(fromParentPath, fromParent);
            if (fromParent !== toParent) {
                this.form.changeField(toParentPath, toParent);
            }
        }
    };

    registerRenderer: RegisterRendererFn = (path, renderer) => (this.renderers[path] = renderer);

    getRenderer = (path: string) => this.renderers[path];

    handlePostChange: FormEventProps['callback'] = ({ name, formValueDraft }) => {
        if (/^(expression|builderConfig)/.test(name)) {
            formValueDraft.compiledExpression = this.compileExpression(
                formValueDraft.expression,
                formValueDraft.builderConfig || {},
            );
        }
    };

    render() {
        const { getSuggestions, includeNodes, argumentChangeHandlers } = this.props;

        return (
            <TreeContext.Provider
                value={this.getContext(getSuggestions, includeNodes, argumentChangeHandlers)}
            >
                <Field name='compiledExpression' component={RootNode} />
                <FormEvent
                    on='init'
                    callback={({ formValue, context }) => {
                        context.changeField(
                            'compiledExpression',
                            this.compileExpression(
                                formValue.expression,
                                formValue.builderConfig || {},
                            ),
                        );
                        this.form = context;
                    }}
                />
                <FormEvent on='post:change' callback={this.handlePostChange} />
            </TreeContext.Provider>
        );
    }
}

export default Tree;

const RootNode: React.FC<FieldT<CompiledExpression>> = ({ input }) => {
    if (!input.value) return null;
    const { moveCopy, nodes } = input.value;

    return (
        <Root data-sortable={!!moveCopy}>
            {nodes.map((node, index) => (
                <Field
                    // using node.id causes fewer re-renders overall but re-renders RestObject keys and loses focus
                    key={index}
                    name={node.path}
                    component={ExpressionField}
                    node={node}
                    moveCopy={moveCopy}
                />
            ))}
        </Root>
    );
};

const ExpressionField: React.FC<{
    input: FieldInput<any>;
    node: NodeState;
    moveCopy: TypeRendererConfig['moveCopy'];
}> = ({ input, node, moveCopy }) => {
    const expr = input.value;
    const TRClass = TypeRenderers[node.nodeClass.TYPE.type as keyof typeof TypeRenderers];
    const context = React.useContext(TreeContext);
    const {
        getSuggestions,
        registerRenderer,
        getRenderer,
        onPaste,
        getFormValue,
        getNodeSelectOptions,
        argumentChangeHandlers,
    } = context;

    const onUiChange = React.useCallback(
        (diff: any) => {
            input.produce(draft => {
                draft.$ui = Object.assign(draft.$ui || {}, diff);
            });
        },
        [input],
    );

    const customChangeHandler =
        argumentChangeHandlers?.[node.parentType!]?.[node.argumentType?.key || '_noop_'];
    const onChange = customChangeHandler
        ? (newValue: any) => {
              // return exactly "false" to prevent normal change handler
              if (customChangeHandler({ newValue, input, context, node }) !== false) {
                  input.onChange(newValue);
              }
          }
        : input.onChange;

    const renderer = new TRClass({
        expression: expr,
        node,
        onChange,
        onUiChange,
        getSuggestions,
        getRenderer,
        moveCopy,
        onPaste,
        getFormValue,
        getNodeSelectOptions,
    });

    registerRenderer(node.path, renderer);

    return renderer.render() as any;
};

const Root = imported_stylus_components.Root;