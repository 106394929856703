import _ from 'lodash';
import { computed, makeAutoObservable } from 'mobx';
import { isDomEvent } from 'src/utils/isDomEvent';
import type { FormStore } from './FormStore';

let fieldIdCounter = 0;

/**
 * Handles retrieving and updating the value of a certain path in the current form
 *
 * **NOTE:** All fields are MobX observables so you'll need to use the observer() wrapper to get proper reactivity
 *
 * @example
 *  const MyComponent = () =>
 *      <Form initialValues={{ name: 'foo', user: { name: 'uname', email: 'a@b.c' } }}>
 *          <MyInput path='name' />
 *          <MyInput path='user.email' />
 *          <MyUserForm path='user' />
 *      </Form>;
 *
 *  const MyInput: React.FC<{ path: string }> = observer(({ path }) => {
 *      const field = useField(path);
 *
 *      return <input {...field.props} />
 *  });
 *
 *  // example using a nested object
 *  const MyUserForm: React.FC<{ path: string }> = observer(({ path }) => {
 *      const user = useField(path);
 *      const name = user.field('name');
 *      const email = user.field('email');
 *
 *      return <>
 *          <input {...name.props} />
 *          <input {...email.props} />
 *      </>;
 *  });
 */
export class Field<Value = any> {
    readonly id = 'field_' + (++fieldIdCounter);
    private onChangeListener?: null | ((value: Value) => void);

    constructor(public path: string, public form: FormStore<any>) {
        makeAutoObservable(this, {
            id: false,
            onChange: false,
        });
    }

    makePath(childPath: string | number): string {
        let str = String(childPath);
        if (str[0] === '.') str = str.slice(1);
        if (this.path !== '') return this.path + '.' + str;
        return str;
    }

    listenOnChange(listener: null | ((value: Value) => void)) {
        this.onChangeListener = listener;
    }

    @computed
    get value(): Value {
        if (!this.path) return this.form.value as any;
        return _.get(this.form.value, this.path);
    }

    @computed
    get props() {
        return {
            name: this.path,
            value: (this.value == null ? '' : this.value) as Value,
            onChange: this.onChange,
        };
    }

    @computed
    get meta() {
        return this.form.getFieldMeta(this.path);
    }

    field<Path extends string | number, T = Path extends keyof Value ? Value[Path] : unknown>(
        path: Path,
    ): Field<T> {
        return this.form.field(this.makePath(path));
    }

    onChange = (v: any) => {
        if (isDomEvent(v) && v.target) {
            if (v.target.tagName === 'INPUT' && ['checkbox', 'radio'].includes((v.target as any).type)) {
                v = (v.target as any).checked;
            } else {
                v = (v.target as any).value;
            }
        }
        this.onChangeListener?.(v);
        this.form.changeField(this.path, v);
    };

    update(updater: (v: Value) => void) {
        this.form.updateField<Value>(this.path, updater);
    }
}

