import _ from 'lodash';
import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import Type from 'src/Type';
import config from 'src/utils/config';

import Node from '../Node';

import type { BaseExpression, ParentContext, ValueExpression, PrimitiveValue } from 'src/types';
import type { StringNodeT } from '../value/StringNode';

export interface Pv2PreviewAttributeNodeT extends BaseExpression {
    $type: 'Pv2PreviewAttributeNode';
    attributes: ValueExpression;
    id: ValueExpression | PrimitiveValue;
    label: ValueExpression | PrimitiveValue;
    groupId: ValueExpression | PrimitiveValue;
    groupTitle: ValueExpression | PrimitiveValue;
    order?: ValueExpression;
    tooltip?: StringNodeT | null;
    value: ValueExpression;
}

export default class Pv2PreviewAttributeNode extends Node<Pv2PreviewAttributeNodeT> {
    static TYPE = Type.Object(
        { key: 'attributes', label: 'Attributes', type: 'ValueNode', suggestions: 'off' },
        { key: 'groupId', label: 'Table ID', type: 'StringNode', suggestions: 'off' },
        { key: 'groupTitle', label: 'Table Name', type: 'ValueNode', suggestions: 'off' },
        { key: 'id', label: 'Row ID', type: 'StringNode', suggestions: 'off' },
        { key: 'label', label: 'Row Name', type: 'ValueNode', suggestions: 'off' },
        { key: 'tooltip', label: 'Tooltip', type: 'StringNode', suggestions: 'off' },
        { key: 'order', label: 'Row Order', type: 'ValueNode', suggestions: 'off' },
        { key: 'value', label: 'Row Value', type: 'ValueNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.pv2,
        description: textBlock`
            Create a preview mapping for Pv2 Attribute meant to be used on the Product page on the Manager platform

            You usually want to use this in conjunction with Pv2AttributeMatcherNode
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            attributes: super.getDefaultFor('Pv2AttributeMatcherNode'),
            label: Primitive.string(''),
            groupId: Primitive.string(''),
            groupTitle: Primitive.null(),
            id: Primitive.string(''),
            tooltip: Primitive.string(''),
            order: Primitive.number(0),
            value: Primitive.evaluation('$$attr.value'),
        };
    }

    validate() {
        if (!Reflect.has(this.expression, 'attributes')) return 'missing "attribute"';
        if (!Reflect.has(this.expression, 'label')) return 'missing "label"';
        if (!Reflect.has(this.expression, 'groupId')) return 'missing "groupId"';
        if (!Reflect.has(this.expression, 'order')) return 'missing "order"';
        if (!Reflect.has(this.expression, 'value')) return 'missing "value"';
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const attributes = _.filter(
            _.castArray(await this.context.evaluate(this.expression.attributes, { sourcePath })),
            attr => attr?.type,
        );
        let groupId = 'other';
        let groupTitle = 'Other';
        const skipValue = this.expression.value === null; // if the value is set to null explicitly, allow skipping

        if (!attributes.length) return;

        let result: Record<string, any> = {};

        await this.context.variableBlock({
            path: sourcePath!,
            variables: { attr: attributes[0], attrs: attributes },
            callback: async () => {
                const gId = await this.context.evaluate(this.expression.groupId, { sourcePath });

                if (typeof gId === 'string') {
                    groupId = gId;
                    groupTitle = await this.context.evaluate(this.expression.groupTitle, {
                        sourcePath,
                    });
                }

                result = {
                    id: await this.context.evaluate(this.expression.id, { sourcePath }),
                    attributes: _.map(attributes, attr => attr.uid || attr.type),
                    type: 'text',
                    label: await this.context.evaluate(this.expression.label, { sourcePath }),
                    order: await this.context.evaluate(this.expression.order, { sourcePath }),
                    tooltip: await this.context.evaluate(this.expression.tooltip, { sourcePath }),
                };

                if (!skipValue) {
                    result.value = await this.context.evaluate(this.expression.value, {
                        sourcePath,
                    });
                }
            },
        });

        if (typeof result.tooltip !== 'string' || !result.tooltip.trim().length) {
            delete result.tooltip;
        }

        if (!skipValue) {
            if (result.value == null) return;
            if (!_.isObject(result.value)) result.value = String(result.value).trim();
        }

        const previewTables: any = this.context.get('previewTables', {});
        previewTables[groupId] = previewTables[groupId] || {
            id: groupId,
            title: groupTitle,
            tableOrder: _.size(previewTables),
            rows: [],
        };
        previewTables[groupId].rows.push(result);
        this.context.set('previewTables', previewTables);

        this.context.onComplete({
            id: 'Pv2PreviewAttributeNode',
            handler: result => {
                if (!result.previewTables) return;

                result.previewTables = Object.values(result.previewTables)
                    .map((table: any) => ({
                        ...table,
                        rows: _.sortBy(table.rows, 'order').map(row => _.omit(row, 'order')),
                    }))
                    .sort((a, b) => a.tableOrder - b.tableOrder)
                    .map((table: any) => _.omit(table, 'tableOrder'));
            },
        });
    }
}
