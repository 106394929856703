import { LocalStorage } from '@pi/ui';
import { type MturkFeedbackData } from 'src/pages/Mturk/useMturkFeedbackData';

import type { Moment } from 'moment';

export type GcpTokenType = {
    token: string;
    expirationDate: Moment;
};
export interface LocalStorageType {
    darkMode: boolean;
    jwt: string;
    gcpToken: GcpTokenType;
    mturkFeedback: MturkFeedbackData;
    pinnedTaskDefinition: string | null;
    formBuilder: {
        debug: {
            value: boolean;
            errors: boolean;
            config: boolean;
        };
    };
}

const LS = new LocalStorage<LocalStorageType>({
    namespace: 'admin_v2',
    getDefaults: () => ({
        jwt: '',
        darkMode: false,
        formBuilder: {
            debug: {
                value: true,
                config: true,
                errors: true,
            },
        },
    }),
});

export default LS;

if (process.env.NODE_ENV === 'development') {
    (window as any).LS = LS;
}
