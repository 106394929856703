import _ from 'lodash';
import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';
import iterateExpressionItems from 'src/utils/iterateExpressionItems';
import Type from 'src/Type';
import config from 'src/utils/config';

import ValueNode from '../ValueNode';

import type { BaseExpression, ParentContext, ValueExpression } from 'src/types';
import type { StringNodeT } from './StringNode';
import type { BooleanNodeT } from './BooleanNode';

export interface Pv2AttributeMatcherNodeT extends BaseExpression {
    $type: 'Pv2AttributeMatcherNode';
    input: ValueExpression;
    match: StringNodeT;
    if?: ValueExpression | null;
    ignoreMatches?: BooleanNodeT;
    multiple?: BooleanNodeT;
}

export default class Pv2AttributeMatcherNode extends ValueNode<Pv2AttributeMatcherNodeT> {
    static TYPE = Type.Object(
        { key: 'input', label: 'Input', type: 'ValueNode' },
        { key: 'match', label: 'Match Attribute Type', type: 'StringNode', suggestions: 'off' },
        {
            key: 'ignoreMatches',
            label: 'Ignore previous matches',
            type: 'BooleanNode',
            suggestions: 'off',
        },
        { key: 'multiple', label: 'Multiple', type: 'BooleanNode', suggestions: 'off' },
        { key: 'if', type: 'ValueNode' },
    );

    static uiConfig = {
        ...config.presets.pv2,
        description: textBlock`
            Finds one attribute that matches the given "Math Attribute Type" expression

            - A list of results can be returned if Multiple = true
            - By default different nodes will not overlap, i.e. 2 different identically-configured Matcher nodes with match "nutrition:*" will have the first one match "calories" and the 2nd one match "protein"
            - The selected attribute will be defined as a variable (defaults to "$$attr")
            - If no attribute is found, nothing happens
            - ❗ You can use "*" to match everything between ":"
            - ❗ You can use "**" to match anything
            - You can separate multiple matches with ","

            Example:

            | Match Attribute Type  | Returns     |
            |---|---|
            | nutrition:panel       | All "nutrition:panel" attributes |
            | nutrition:*           | All attributes that start with "nutrition:*" |
            | *:calories            | All attributes that end with "calories", like "nutrition:calories" |
            | *:*:raw               | All attributes that end in "raw" that have 2 levels |
            | **calories**          | Matches any attribute that has calories anywhere in the name |
            | nutrition:panel, supplements: panel | All "nutrition:panel" and "supplements:panel" attributes |
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            input: Primitive.evaluation('$attributes'),
            match: Primitive.string(''),
            if: Primitive.null(),
            ignoreMatches: Primitive.boolean(false),
            multiple: Primitive.boolean(false),
        };
    }

    async execute({ sourcePath }: ParentContext = {}) {
        const matchString = await this.context.evaluate(this.expression.match, { sourcePath });
        if (!matchString || typeof matchString !== 'string') return;

        const reg = getAttributeFilterReg(matchString);
        const ignoreMatches = await this.context.evaluate(this.expression.ignoreMatches, {
            sourcePath,
        });
        const multiple = await this.context.evaluate(this.expression.multiple, { sourcePath });
        const input = await this.context.evaluate(this.expression.input, { sourcePath });

        const filter = async (attr: any, sp = sourcePath, index?: number | string) => {
            if (!attr?.type || typeof attr.type !== 'string') return;
            if (!reg.test(attr.type)) return;

            const cacheId = String(attr._id || attr.uid || attr.type);
            if (!ignoreMatches && this.context.cache.get('previewAttributeVisited')[cacheId]) {
                return;
            }

            let matched = true;

            if (this.expression.if) {
                matched = !!(await this.context.variableBlock({
                    path: sp || '',
                    variables: {
                        attr,
                        attr_key: index,
                        attr_index: index,
                    },
                    callback: path =>
                        this.context.evaluate(this.expression.if, {
                            sourcePath: path,
                            isOutput: true,
                        }),
                }));
            }

            if (matched) this.context.cache.assign('previewAttributeVisited', cacheId, true);

            return matched;
        };

        if (!multiple) {
            if (!Array.isArray(input)) return null;
            for (const attr of input) {
                if (await filter(attr, sourcePath)) return attr;
            }
            return null;
        }

        const result: any[] = [];

        await iterateExpressionItems({
            items: this.expression.input,
            itemName: Primitive.string('attr'),
            context: this.context,
            callback: async ({ value: attr, sourcePath, key }) => {
                if (await filter(attr, sourcePath, key)) result.push(attr);
            },
        });

        return result;
    }
}

const REG = {
    partSeparator: /[,\n]+/,
    all: /./,
    doubleWildcard: /\*\*/g,
    singleWildcard: /\*/g,
    doubleToken: /__DOUBLE__/g,
    singleToken: /__SINGLE__/g,
};

const getAttributeFilterReg = _.memoize((matchString: string) => {
    const matchList = _.filter(matchString.split(REG.partSeparator).map(m => m.trim()));

    if (!matchList.length) return REG.all;

    const parts = matchList.map(str =>
        _.escapeRegExp(
            str.replace(REG.doubleWildcard, '__DOUBLE__').replace(REG.singleWildcard, '__SINGLE__'),
        )
            .replace(REG.singleToken, '[^:]*')
            .replace(REG.doubleToken, '.*'),
    );

    return new RegExp('^(?:' + parts.join('|') + ')$', 'i');
});
