import React from 'react';
import _ from 'lodash';
import { markdownToReact } from '@pi/ui';
import textBlock from '@pi/text-block';

import DefaultTypeRenderer, { type Action } from './DefaultTypeRenderer';
import TextWithSuggestions from '../TextWithSuggestions';

import type { TypeRendererIterateOptions } from '.';

export default class EvaulationType extends DefaultTypeRenderer {
    static iterate(_options: TypeRendererIterateOptions<'Evaluation'>) {
        // noop
    }

    collapsible = false;

    get isEvaluationNode() {
        return this.config.expression?.$type === 'EvaluationNode';
    }

    getLabelText() {
        if (this.isEvaluationNode) {
            return super.getLabelText({
                ...this.config,
                labelSuffix:
                    typeof this.config.expression === 'string'
                        ? 'Evaluation →'
                        : this.config.labelSuffix,
            });
        }

        return super.getLabelText({
            ...this.config,
            labelSuffix: '',
            nodeName: 'null',
        });
    }

    renderActions() {
        return super.renderActions();
    }

    getActions(): Action[] {
        return [
            ...super.getActions(),
            {
                key: 'description',
                enabled: true,
                render: () =>
                    this.renderActionButton({
                        tooltip: markdownToReact(
                            textBlock`
                                Evaluate variables and math expressions

                                ## Variables

                                \`$columnCount\`, \`$product.name\`, \`$Sitem.name\`, etc.

                                ## Math Expressions

                                i.e. \`$columnCount + 1\` etc.
                                (available operators: \`+\`, \`-\`, \`*\`, \`/\`, etc)

                                ## Functions

                                \`join(',', $categories)\` becomes \`Dairy, Produce\`.
                                Most lodash and expr-eval functions are available
                            `,
                            { style: { maxWidth: '25rem' } },
                        ),
                        icon: 'info-sign',
                    }),
            },
        ];
    }

    renderLabel() {
        const { node, expression, onChange, getSuggestions } = this.config;
        const { argumentType } = node;

        if (!this.isEvaluationNode) return super.renderLabel();

        return super.renderLabel(
            <TextWithSuggestions
                value={expression?.value == null ? '' : String(expression.value)}
                onChange={value =>
                    onChange({
                        ...expression,
                        value,
                    })
                }
                path={node.path}
                getSuggestions={getSuggestions}
                argumentType={argumentType}
                className='ml025'
            />,
        );
    }
}
