import textBlock from '@pi/text-block';
import Primitive from 'src/utils/Primitive';

import ValueNode from '../ValueNode';
import Type from '../../Type';
import config from '../../utils/config';

import type { BaseExpression, ParentContext, ValueExpression } from 'src/types';
import type { ObjectNodeT } from './ObjectNode';

export interface SwitchNodeT extends BaseExpression {
    $type: 'SwitchNode';
    on: ValueExpression;
    default: ValueExpression;
    value: ObjectNodeT;
}

export default class SwitchNode extends ValueNode<SwitchNodeT> {
    static TYPE = Type.Object(
        { key: 'on', label: 'On', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'default', label: 'Default', type: 'ValueNode', suggestions: 'primitive' },
        { key: 'value', label: 'Cases', type: 'ObjectNode', suggestions: 'off' },
    );

    static uiConfig = {
        ...config.presets.block,
        description: textBlock`
            If the value of the "On" expression is defined in the "Cases" object, the specific case is evaluated and nothing else

            If no match is found, the "Default" expression is returned instead (if defined)

            A "Switch" statement is equivalent to multiple if .. else statements chained:

            \`\`\`
            If $someValue == 'one'
            then 100
            else
                If $someValue == 'two'
                then 200
                else 300
            \`\`\`

            The above block is equivalent to

            \`\`\`
            Switch $someValue
                case 'one': 100
                case 'two': 200
                default: 300
            \`\`\`
        `,
    };

    static getDefault() {
        return {
            ...super.getDefault(),
            on: Primitive.evaluation(''),
            default: Primitive.null(),
            value: {
                $type: 'ObjectNode',
                value: {
                    'case 1': Primitive.evaluation(''),
                    'case 2': Primitive.evaluation(''),
                    'case 3': Primitive.evaluation(''),
                },
            },
        };
    }

    async execute({ sourcePath }: ParentContext) {
        const on = await this.context.evaluateLog('on', this.expression.on, { sourcePath });
        const caseExpr = this.expression.value?.value?.[on];

        if (!caseExpr) {
            return await this.context.evaluateLog(`default(${on})`, this.expression.default, {
                sourcePath,
            });
        }

        return await this.context.evaluateLog('value.' + on, caseExpr, { sourcePath });
    }
}
