import config from 'src/utils/config';

import ValueNode from '../ValueNode';
import Type from '../../Type';

import type { BaseExpression, UiConfig } from 'src/types';

export interface StringNodeT extends BaseExpression {
    $type: 'StringNode';
    value: string;
}

export default class StringNode extends ValueNode<StringNodeT> {
    static TYPE = Type.Primitive({
        key: 'value',
    });

    static uiConfig: UiConfig = {
        ...config.presets.primitive,
        description: 'Plain text node',
    };

    validate() {
        if (typeof this.expression.value !== 'string' && this.expression.value != null)
            return 'value must be a string or null';
    }

    static getDefault() {
        return {
            ...super.getDefault(),
            value: '',
        };
    }

    async execute() {
        return this.context.evaluate(this.expression.value);
    }
}
