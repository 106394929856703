import _ from 'lodash';
import moment from 'moment';
import { type IDBPDatabase } from 'idb';

import getDb, { deleteKeys } from './db';

import type { Moment } from 'moment';
import type { DBShape, DraftStoreName } from './db';

interface DbDraftCacheOptions {
    expireDays: number;
    maxEntries: number;
}

export interface DbDraftEntry<Value extends Record<string, any>> {
    key: string;
    value: {
        hash: string;
        id: string;
        version: number;
        date: Date;
        value: Value;
    };
    indexes: {
        hash: string;
        id: string;
        date: Date;
    };
}

export interface CacheMetadata {
    cacheKey: string;
    cacheId: string;
    cacheVersion: number;
    date: Moment;
}

type DbDraftListener = () => void;

export default class DbDraftCache<
    StoreName extends DraftStoreName,
    Entry extends DBShape[StoreName]['value'] = DBShape[StoreName]['value'],
> {
    private options: DbDraftCacheOptions;
    private listeners = new Set<DbDraftListener>();

    constructor(public readonly storeName: StoreName, options: Partial<DbDraftCacheOptions> = {}) {
        this.options = {
            expireDays: 7,
            maxEntries: 50,
            ...options,
        };
    }

    private get db(): IDBPDatabase<DBShape> {
        return getDb();
    }

    async deleteExpired(): Promise<void> {
        const { expireDays } = this.options;
        const keys = await this.db.getAllKeysFromIndex(
            this.storeName,
            'date',
            IDBKeyRange.upperBound(moment().subtract(expireDays, 'days').toDate()),
        );

        if (!keys.length) return;

        this.triggerEvents();

        return deleteKeys(this.storeName, keys);
    }

    get(key: string): Promise<DBShape[StoreName]['value'] | undefined> {
        return this.db.get(this.storeName, key);
    }

    getAllKeys(id: string): Promise<Array<DBShape[StoreName]['key']>> {
        return this.db.getAllKeysFromIndex(this.storeName, 'id', id as any);
    }

    async getLatestVersion(id: string): Promise<DBShape[StoreName]['value'] | undefined | null> {
        const keys = await this.getAllKeys(id);
        if (!keys.length) return null;
        return (await this.db.get(this.storeName, keys.pop()!)) || null;
    }

    async getAllVersionMetadata(id: string): Promise<CacheMetadata[]> {
        const keys = await this.getAllKeys(id);

        return keys.map(cacheKey => {
            const [cacheId, time, cacheVersion] = cacheKey.split(':');

            return {
                cacheKey,
                cacheId,
                cacheVersion: parseInt(cacheVersion, 10),
                date: moment(parseInt(time, 10)),
            };
        });
    }

    async storeNewVersion(data: Pick<Entry, 'id' | 'value'>): Promise<string> {
        const { maxEntries } = this.options;

        // avoid duplicate entries when value matches
        const lastVersion = await this.getLatestVersion(data.id);
        if (_.isEqual(lastVersion?.value, data.value)) return lastVersion!.hash;

        const date = new Date();
        const version = (lastVersion?.version || 0) + 1;

        const result = await this.db.add(this.storeName, {
            ...(data as any),
            version,
            hash: [data.id, date.getTime(), String(version).padStart(6, '0')].join(':'),
            date,
        });

        const keys = await this.db.getAllKeysFromIndex(this.storeName, 'id', data.id as any);
        await deleteKeys(this.storeName, Array.from(keys).reverse().slice(maxEntries));

        this.triggerEvents();

        return result;
    }

    storeNewVersionDebounced = _.debounce(
        <T extends Record<string, any>>(
            data: T,
            getEntry: (data: T) => Pick<Entry, 'id' | 'value'>,
        ) => {
            void this.storeNewVersion(getEntry(data));
        },
        2000,
    );

    async clear(id: string): Promise<void> {
        await deleteKeys(this.storeName, await this.getAllKeys(id));
        this.triggerEvents();
    }

    /**
     * Register a function to be called whenever __any__ change happens to this collection (add, remove)
     */
    onChange(listener: DbDraftListener): () => void {
        this.listeners.add(listener);
        return () => {
            this.listeners.delete(listener);
        };
    }

    private triggerEvents() {
        setTimeout(() => {
            this.listeners.forEach(fn => fn());
        }, 1);
    }
}
