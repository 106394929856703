import { Button, type ButtonProps, type DialogProps } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

import { Dialog } from './Dialog';

export interface ConfirmButtonProps extends ButtonProps {
    onClick: () => void;
    dialogProps?: Omit<DialogProps, 'isOpen' | 'onClose' | 'actions'>;
    confirmButtonProps?: Omit<ButtonProps, 'onClick'>;
    cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
    onClick,
    dialogProps,
    confirmButtonProps,
    cancelButtonProps,
    ...rest
}) => {
    const [open, setOpen] = useState(false);

    const onConfirm = useCallback(() => {
        setOpen(false);
        onClick();
    }, [onClick]);

    const onCancel = useCallback(() => setOpen(false), []);

    return (
        <>
            <Button {...rest} onClick={() => setOpen(true)} />

            <Dialog
                {...dialogProps}
                isOpen={open}
                onClose={onCancel}
                noCloseButton
                actions={
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            intent='none'
                            text='cancel'
                            {...cancelButtonProps}
                            onClick={onCancel}
                        />
                        <Button
                            intent='primary'
                            text='confirm'
                            {...confirmButtonProps}
                            onClick={onConfirm}
                        />
                    </div>
                }
            />
        </>
    );
};
