const imported_stylus_components = require('.cache/react-style-loader/src/components/JSONCoverage.styl');
import React from 'react';
import PropTypes from 'prop-types';
import { toPathString } from '@pi/path-utils';
import memoizeOne from 'memoize-one';

import JSONRenderer, { DefaultRowRenderer } from './JSONRenderer';

import type { JSONRendererProps, JSONRowRenderer } from './JSONRenderer';

export type JSONCoverageProps = JSONRendererProps & { coverage: Coverage };

type Coverage = {
    covered?: string[];
    warn?: string[];
    notCovered?: string[];
};

type CoverageMap = { [key: string]: keyof JSONCoverageProps['coverage'] };

const CoverageContext = React.createContext<{ coverage: Coverage; coverageMap: CoverageMap }>({
    coverage: {},
    coverageMap: {},
});

export class JSONCoverage extends React.Component<JSONCoverageProps> {
    props!: JSONCoverageProps;

    static propTypes = {
        data: PropTypes.any,
        coverage: PropTypes.shape({
            covered: PropTypes.arrayOf(PropTypes.string),
            warn: PropTypes.arrayOf(PropTypes.string),
            notCovered: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
    };

    getContextValue = memoizeOne((coverage: Coverage) => {
        const coverageMap: CoverageMap = {};

        for (const type in coverage) {
            for (const path of coverage[type as keyof typeof coverage]!) {
                coverageMap[path] = type as keyof typeof coverage;
            }
        }

        return {
            coverage,
            coverageMap,
        };
    });

    render() {
        const { coverage, ...props } = this.props;

        return <CoverageContext.Provider value={this.getContextValue(coverage)}>
            <Root>
                <JSONRenderer {...props} RowRenderer={CoverageRowRenderer} />
            </Root>
        </CoverageContext.Provider>;
    }
}

export default JSONCoverage;

export class CoverageRowRenderer extends React.Component<React.ComponentProps<JSONRowRenderer>> {
    context!: React.ContextType<typeof CoverageContext>;
    static contextType = CoverageContext;

    render() {
        const { data: { path } } = this.props;
        const { coverage, coverageMap } = this.context;
        const { covered = [], warn = [], notCovered = [] } = coverage;
        let coverageType: any;

        if (path.length === 0) {
            // coverageType = 'warn';
            // if (warn.length === 0 && notCovered.length === 0) coverageType = 'covered';
            // if (covered.length < 1) coverageType = 'notCovered';
        } else {
            // Infer the coverageType either by finding an exact match in the coverageMap
            // Or by inheriting the coverage information from any parent that has one
            for (let i = path.length; i > 0; --i) {
                const pathStr = toPathString(path.slice(0, i));
                if (coverageMap[pathStr]) {
                    coverageType = coverageMap[pathStr];
                    break;
                }
            }
        }

        return <DefaultRowRenderer
            {...this.props}
            data-coverage={coverageType}
        />;
    }
}

const Root = imported_stylus_components.Root;